import axios from '@/plugins/axios'

const actions = {
  getSales: ({ commit, state, rootState }, data) => {
    return axios.post('/api/sales?page=' + data.page, data.searchData, {
      headers: {
        Authorization: 'Bearer ' + rootState.loginSignup.api_token
      }
    })
      .then(res => {
        return res
      }).catch(error => {
        return Promise.reject(error)
      })
  }
}

export default {
  actions
}
