import axios from '@/plugins/axios'

const actions = {
  getSellerParts: ({ commit, state, rootState }, params) => {
    return axios.get('/api/seller-parts?page=' + params.page, {
      headers: {
        Authorization: 'Bearer ' + rootState.loginSignup.api_token
      },
      params: params.search
    })
      .then(res => {
        return res
      }).catch(error => {
        return Promise.reject(error)
      })
  },
  addSellerPart: ({ commit, state, rootState }, sellerPart) => {
    return axios.post('/api/seller-parts', sellerPart, {
      headers: {
        Authorization: 'Bearer ' + rootState.loginSignup.api_token
      }
    })
      .then(res => {
        return res
      }).catch(error => {
        return Promise.reject(error)
      })
  },
  updateSellerPart: ({ commit, state, rootState }, sellerPart) => {
    return axios.put('/api/seller-parts/' + sellerPart.id, sellerPart, {
      headers: {
        Authorization: 'Bearer ' + rootState.loginSignup.api_token
      }
    })
      .then(res => {
        return res
      }).catch(error => {
        return Promise.reject(error)
      })
  },
  deleteSellerParts: ({ commit, state, rootState }, modelId) => {
    return axios.put('/api/delete-seller-parts', { model_id: modelId }, {
      headers: {
        Authorization: 'Bearer ' + rootState.loginSignup.api_token
      }
    })
      .then(res => {
        return res
      }).catch(error => {
        return Promise.reject(error)
      })
  }
}

export default {
  actions
}
