<template>
  <div class="row">
    <div class="col-6 col-sm-3 form-group">
      <label for="brand_id">{{ $t('cars.Brand') }}</label>
      <select id="brand_id" v-model="car_brand_model.brand_id" class="form-control" :disabled="isBrandFieldDisabled" required="required" :data-error="$t('sellerParts.Brand is required')">
        <option value="0" disabled>{{ $t('cars.Brand') }}</option>
        <option v-for="brand in brands" :key="brand.id" :value="brand.id">{{ brand[getFieldByLocale()] }}</option>
      </select>
      <div v-if="!error.brand_id" class="help-block with-errors"></div>
      <div class="error-message-block">{{ error.brand_id }}</div>
    </div>
    <div class="col-6 col-sm-3 form-group">
      <label for="model_id">{{ $t('cars.Model') }}</label>
      <select id="model_id" v-model="car_model.car_model_id" class="form-control" :disabled="car_brand_model.isModelFieldDisabled" required="required" :data-error="$t('sellerParts.Model is required')">
        <option value="0" disabled>{{ $t('cars.Model') }}</option>
        <option v-for="model in filteredModels" :key="model.id" :value="model.id">{{ model[getFieldByLocale()] }}</option>
      </select>
      <div v-if="!error.car_model_id" class="help-block with-errors"></div>
      <div class="error-message-block">{{ error.car_model_id }}</div>
    </div>
    <div class="col-6 col-sm-3 form-group">
      <label for="from_year">{{ $t('sellerParts.From Year') }}</label>
      <select id="from_year" v-model="car_model.from_year" class="form-control" required="required" :data-error="$t('sellerParts.From Year is required')">
        <option value="0" disabled>{{ $t('sellerParts.From Year') }}</option>
        <option v-for="year in (new Date().getFullYear() - 1968)" :key="year" :value="new Date().getFullYear() + 2 - year">{{ new Date().getFullYear() + 2 - year  }}</option>
      </select>
      <div v-if="!error.from_year" class="help-block with-errors"></div>
      <div class="error-message-block">{{ error.from_year }}</div>
    </div>
    <div class="col-6 col-sm-3 form-group">
      <label for="to_year">{{ $t('sellerParts.To Year') }}</label>
      <select id="to_year" v-model="car_model.to_year" class="form-control" :disabled="!car_model.from_year" required="required" :data-error="$t('sellerParts.To Year is required')">
        <option value="0" disabled>{{ $t('sellerParts.To Year') }}</option>
        <option v-for="year in (new Date().getFullYear() - getFromYear(car_model.from_year) + 2)" :key="year" :value="new Date().getFullYear() + 2 - year">{{ new Date().getFullYear() + 2 - year  }}</option>
      </select>
      <div v-if="!error.to_year" class="help-block with-errors"></div>
      <div class="error-message-block">{{ error.to_year }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CarBrandModel',
  props: ['car_model', 'car_brand_model', 'error', 'brands', 'isBrandFieldDisabled', 'index', 'chosenModelIds'],
  computed: {
    filteredModels () {
      if (this.car_brand_model.models) {
        return this.car_brand_model.models.filter((elem) => {
          if (this.car_model.car_model_id && this.car_model.car_model_id === elem.id) {
            return true
          } else {
            return !this.chosenModelIds.includes(elem.id)
          }
        })
      }
      return this.car_brand_model.models
    }
  },
  watch: {
    'car_brand_model.brand_id' () {
      this.$emit('brandIdChanged', this.index)
    }
  },
  methods: {
    getFromYear (fromYear) {
      return fromYear && parseInt(fromYear) !== 0 ? fromYear : new Date().getFullYear()
    }
  }
}
</script>
