<template>
  <div>

    <div  class="modal fade" id="shipping-policy-form" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content amber-border-on-modal">
          <div class="modal-header border-bottom-0">
            <button type="button" class="close modal-close-button" data-dismiss="modal">×</button>
          </div>

            <div v-if="!isRtl" class="modal-body">
                <h4>Pieces Warranty</h4>
                <p>1. The warranty period is set by the seller for a minimum of 3 days and a maximum of 30 days. <br>

2. The warranty period starts from the date the buyer receives the item and the seller calculates it <br>

3. The seller of the used parts must identify and teach them to be identified upon return <br>

4. In the case of the new parts, the warranty shall be in accordance with what is customary in the Saudi spare parts market <br>

5. The buyer is not entitled to open the piece and replace its components or parts or tampering with it. In doing so, the guarantee is considered null and void by the buyer. <br>

6. Should the new pieces be returned, they should not have been installed or used and will be returned with a carton and a piece number sticker as delivered to the buyer. <br>

7. Warranty does not include faulty installation or incorrect programming and any piece damage caused by installation is considered a non-warranty <br>

8. WARRANTY INCLUDES THE ITEM THAT DOES NOT WORK AS REQUIRED, AND INCLUDES THE DIFFERENCE OF THE FORM OR ITEM SPECIFICATIONS WHY THE REQUEST OR MISCELLANEOUS PREPARATION <br></p>
                <hr>
                <h4>Withdraw and Replacing</h4>
                <p>1. On the wrong request by the buyer or his desire to return without a reason, the buyer pays 10% of the item's value, including 5% to the seller the value of the jaw and 5% to Sparaat. <br>

2. The used piece shall be returned in its condition as it was delivered without removing or replacing part of it or tampering with it or spraying it with a piece of wood. <br>

3. Returns are made during the warranty period and after the warranty period the seller and the spies are not fully responsible for it <br>

4. In the event of a breakdown in the used piece, the seller shall bear a 5% commission for Sparaat <br>

5. When the item is returned by the buyer, the refund is not refunded to the buyer immediately, but it is made after delivery to the seller, confirming its condition and signs and is signed by the seller on the return form and then the amount is transferred to the buyer. <br>

6. The seller shall return the item within the warranty period or even upon the wrong request from the buyer or at the buyer's desire to return after payment of 10% of the value of the item. <br>

7. Electrical and electronic parts are not refunded anymore ensuring their condition as delivered <br></p>
                <hr>
                <h4>Pieces Quality</h4>
                <p>1. Sparaat does not assume any responsibility for the quality of the pieces and their suitability to work, and that seller bears full responsibility <br>

2. When buying used parts, the buyer does not have the quality of the new pieces and he should realize that <br>

3. As for the quality of the traditional and commercial pieces, at the request of the item, the country of manufacture of the counterfeit item is requested to be presented to the seller at the request of the buyer and must comply with it <br>

4. Consumer items are not sold for quality and performance <br></p>
                <hr>
                <h4>Delivery and Shipping</h4>
                <p>1. When the buyer agrees to the price offered, he is contacted by Sparaat to determine the location and time to deliver the piece. <br>

2. The item is prepared and delivered by the seller to the spiders within 24 hours from the time the buyer approves the request and then delivers it to the buyer within a maximum of 24 hours. <br>

3. Weekends and holidays are taken into consideration <br>

4. The amount of delivery to the buyer is 25 riyals within the city of Jeddah <br>

5. Delivery costs are non-refundable and additional fees are charged in case of large pieces and need forklift <br>

6. In case of return, if the reason is the buyer or the desire to return, the wages of delivery of the return will be 25 riyals, although the warranty and damage of the used piece is borne by the seller. <br></p>
            </div>

<div v-else class="modal-body">
                <h4>ضمان القطع</h4>
                <p>
    ١. يتم تحديد مدة الضمان من قبل البائع كمدة اقلها 1 يوم واقصاها ٣٠ يوم.
    <br>
    ٢. تبدأ فترة الضمان من تاريخ استلام المشتري للقطعة وعلى البائع احتساب ذلك.
    <br>
    ٣. يجب على البائع للقطع المستعملة تحديدها وتعليمها كي يتم التعرف عليها عند الارجاع.
    <br>
    ٤. في حالة القطع الجديدة يتم الضمان حسب ما هو متعارف عليه في سوق قطع الغيار السعودي.
    <br>
    ٥. لا يحق للمشتري فتح القطعة وتبديل مكوناتها او أجزاءها او العبث بها وفي حال فعل ذلك فإن الضمان يعتبر لاغي ويتحملها المشتري.
    <br>
    ٦. في حالة ارجاع القطع الجديدة يجب ان لا يكون تم تركيبها او استخدامها ويتم ترجيعها بالكرتون وملصق رقم القطعة كما تم تسليمها للمشتري.
    <br>
    ٧. الضمان لا يشمل التركيب الخاطئ او البرمجة غير الصحيحة واي ضرر بالقطعة من جراء التركيب يعتبر الضمان لاغي.
    <br>
    ٨. الضمان يشمل القطعة التي لا تعمل بالشكل المطلوب، ولأتشمل اختلاف شكل او مواصفات القطعة بسبب الطلب او التحضير الخاطئ.

    </p>
              <hr>
              <h4>الأرجاع والاستبدال</h4>
              <p>
    ١. عند الطلب الخاطئ من قبل المشتري او رغبته بالإرجاع بدون سبب يتحمل المشتري ١٠٪&rlm; من قيمة القطعة، منها ٥٪&rlm; للبائع قيمة الفك و ٥٪&rlm; لسبيرات.
    <br>
    ٢. يتم ارجاع القطعة المستعملة في حالتها كما تم تسليمها بدون فك او تبديل جزء منها او العبث فيها او رشها بويا لقطع البودي.
    <br>
    ٣. يتم الارجاع خلال فترة الضمان وبعد انتهاء فترة الضمان يعتبر البائع وسبيرات غير مسؤول عنها تماماً
    <br>
    ٤. في حال وجود عطل في القطعة المستعملة يتحمل البائع ٥٪&rlm; عمولة لسبيرات.
    <br>
    ٥. عند ارجاع القطعة من قبل المشتري لا يتم ارجاع المبلغ للمشتري فوراً وانما يتم ذلك بعد تسليمها للبائع والتأكد من حالتها والعلامات عليها ويتم التوقيع من قبل البائع على نموذج الارجاع ثم يتم تحويل المبلغ للمشتري.
    <br>
    ٦. يلتزم البائع بإرجاع القطعة خلال فترة الضمان او حتى عند الطلب الخاطئ من المشتري او عند رغبة المشتري بالإرجاع بعد دفع ١٠٪&rlm; من قيمة القطعة.
    <br>
    ٧. القطع الكهربائية والالكترونية لا ترد الابعد التأكد من حالتها كما سٌلمت.
    </p>
              <hr>
              <h4>جودة القطع</h4>
              <p>
    ١. سبيرات لا يتحمل اي مسؤولية عن جودة القطع وصلاحيتها للعمل، ويتحمل ذلك البائع مسؤولية كاملة
    <br>
    ٢. على المشتري عند شراء قطع مستعملة فإنها ليست بجودة القطع الجديدة وعليه إدراك ذلك.
    <br>
    ٣. بالنسبة لجودة القطع التقليدية والتجارية فإنه عند طلب القطعة يتم طلب تحديد بلد صنع القطعة المقلدة كي يحضرها البائع حسب طلب المشتري وعليه الالتزام بذلك.
    <br>
    ٤. لا يتم بيع القطع الاستهلاكية لعدم ضمان جودتها واداءها.
    </p>
              <hr>
              <h4>الشحن والتوصيل</h4>
              <p>
    ١. عند موافقة المشتري على السعر المقدم يتم التواصل معه من قبل سبيرات لتحديد الموقع والزمان لتسليم القطعة.
    <br>
    ٢. يتم تحضير القطعة من قبل فريق سبيرات واستلامها من البائع والتشييك على سلامتها والتأكد من صحة الطلب، واذا لزم الامر تصويرها وأرسلها للمشتري للموافقة عليها، ثم ارسالها عبر شركة الشحن الى المشتري.
    <br>
    ٣. يتم مراعاة عطلة نهاية الاسبوع والعطل الرسمية.
    <br>
    ٤. يتم احتساب مبلغ توصيل على المشتري 30ريال داخل المملكة العربية السعودية للوزن اقل من 25 كجم والحجم اقل من 50*50*40 سم للكرتون او احتساب معادلة الشحن للاحجام ( الطول* العرض * الارتفاع/4000)، حيث ان كل كيلو زائد فوق ال25 يتحسب عليه ريال اضافي.
    <br>
    ٥. تكاليف التوصيل غير مستردة ويتم احتساب اجور اضافية في حال القطع كبيرة الحجم وتحتاج رافعه
    <br>
    ٦. في حالة الارجاع إذا كان السبب من المشتري او رغبة منه بالإرجاع يتحمل أجور الشحن ذهباً واياباً 60 ريال, وان كان بسبب الضمان وتلف القطعة المستعملة يتحملها البائع.
    </p>
            </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'ShippingPolicy'
}
</script>

<style scoped>
  h4 {
    text-align: center;
  }
</style>
