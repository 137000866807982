<template>
  <div>
    <!--hero section start-->

    <HeroSection>
      <h1>{{ $t('requests.Create New Request') }}</h1>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb justify-content-center bg-transparent p-0 m-0">
          <li class="breadcrumb-item"><router-link class="text-dark" :to="{ name: 'Dashboard', params: { lang: this.$i18n.locale }}">{{ $t('menu.Home') }}</router-link></li>
          <li class="breadcrumb-item"><router-link class="text-dark" :to="{ name: 'BuyerCars', params: { lang: this.$i18n.locale }}">{{ $t('cars.My Cars') }}</router-link></li>
          <li class="breadcrumb-item active text-primary" aria-current="page">{{ $t('requests.Create New Request') }}</li>
        </ol>
      </nav>
    </HeroSection>
    <!--hero section end-->

    <!--body content start-->

    <div class="page-content">

      <section v-if="isCarSelected" style="position: relative;">
        <Spinner v-if="isSpinnerShown"/>

        <div v-if="car" class="container">
          <div class="row justify-content-center text-center">
            <div class="col-12 col-lg-10 p-3 border-in-amber mb-6 d-flex justify-content-center align-items-center">
              <span>{{ carCategories[car.car_category_id][getFieldByLocale()] +' - '+ car.brand[getFieldByLocale()] +' - '+ car.model[getFieldByLocale()] + ' ('+ car.model_year +')' }}</span>
            </div>
          </div>
        </div>

        <div  v-for="request in requests" :key="request.index" >
          <RequestFrom :partCategories="partCategories" :car="car" v-model="request.request" :error="request.errors"
                       :localeField="getFieldByLocale()" :number="request.index" :isShown="request.requestsToSubmit"
                       v-on:removeCurrentRequest="removeRequest"
                       v-on:fileUploadingStarted="request.isfileUplaoding = true"  v-on:fileUploadingFinished="request.isfileUplaoding = false" />
        </div>

        <div class="container" style="margin-top: -1rem;">
          <div class="row justify-content-center text-center">
            <div class="col-12 col-lg-10">
              <div class="row">
                <div class="col-md-6">
                  <UploadedImageConditions />
                </div>
                <div class="col-md-6 align-self-center">
                  <button id="upload-btn" @click.prevent="addAnotherRequest()" class="add-another-request-btn btn btn-link text-dark btn-sm bg-primary-soft m-1 mt-5 mb-3"><span>{{ $t('requests.Add Another Request') }}</span></button>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12 align-self-center">
                  <button id="upload-btn" @click.prevent="createBulkRequest()" class="btn btn-primary mt-5" :disabled="!isReadyForSubmission || isAnyFileUploading"><span>{{ $t('common.Submit') }}</span></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section v-else>
        <div class="container" style="position: relative;">
        <Spinner v-if="isSpinnerShown"/>
          <div class="row justify-content-center">
            <div class="col-12 col-lg-10">
                <form class="row justify-content-center" method="post">
                  <div class="messages"></div>
                  <div class="form-group col-md-6">
                    <label for="origin_id">{{ $t('cars.Choose a Car') }}</label>
                    <select id="origin_id" v-model="car_id" class="form-control">
                      <option value="0" disabled>{{ $t('cars.Car') }}</option>
                      <option v-for="car in cars" :disabled="car.status === 0" :key="car.id" :value="car.id">{{ car.brand[getFieldByLocale()] }} - {{ car.model[getFieldByLocale()] + ' ('+ car.model_year +')'}}</option>
                    </select>
                    <div class="error-message-block">
                      <span v-if="isShownCarSelectError">{{ $t('cars.Please select a car from the list') }}</span>
                      {{ car_id_error }}
                    </div>
                    <div class="mt-2 form-group">
                      <router-link :to="{ name: 'AddCar', query: { isFromRequestForm: 'true' }}" class="btn btn-link text-in-grey btn-sm p-0 text-decoration-underline"><span>+ {{ $t('cars.Add new car to request part for') }}</span></router-link>
                    </div>
                  </div>
                  <div class="col-md-12 text-center mt-4">
                    <button class="btn btn-primary" @click.prevent="selectCar()" :disabled="!car_id"><span>{{ $t('common.Next') }}</span>
                    </button>
                  </div>
                </form>
            </div>
          </div>
        </div>
      </section>
    </div>

    <!--body content end-->
  </div>
</template>

<script>
import UploadedImageConditions from '../../components/UploadedImageConditions.vue'
import RequestFrom from '../../components/request/RequestFrom.vue'

export default {
  components: {
    RequestFrom,
    UploadedImageConditions
  },
  data () {
    return {
      partCategories: null,
      car: null,
      cars: null,
      car_id: 0,
      car_id_error: null,
      isCarSelected: false,
      currentIndex: 0,
      isSpinnerShown: false,
      isShownCarSelectError: false,
      requests: [
        {
          index: 0,
          request: {
            car_id: null,
            condition: 0,
            part_name: '',
            details: null,
            part_category_id: 0,
            is_from_app: false,
            images: null
          },
          errors: {
            condition: null,
            part_name: null,
            details: null,
            part_category_id: null,
            images: null
          },
          requestsToSubmit: true,
          isfileUplaoding: false
        }
      ]
    }
  },
  mounted () {
    document.addEventListener('click', this.handleClickOutside)

    if (this.$route.query.id) {
      this.isCarSelected = true
      this.car_id = this.$route.query.id
      this.requests[0].car_id = this.$route.query.id

      this.getCarById()
    } else {
      this.isSpinnerShown = true
      this.$store.dispatch('getBuyerCars')
        .then(res => {
          this.isSpinnerShown = false
          this.cars = res.data.data
          if (this.cars.length === 0) {
            this.$router.push({ name: 'AddCar', query: { isFromRequestForm: 'true' } })
          }
        }).catch(error => {
          this.isSpinnerShown = false
          console.log(error)
        })
    }
  },
  computed: {
    isReadyForSubmission () {
      const incomplete = this.requests.filter((r) => {
        return r.requestsToSubmit && !(r.request.condition && r.request.part_name && r.request.part_category_id)
      })
      return incomplete.length === 0
    },
    isAnyFileUploading () {
      const incomplete = this.requests.filter((r) => {
        return (r.requestsToSubmit && r.isfileUplaoding)
      })
      return incomplete.length > 0
    }
  },
  methods: {
    removeRequest (index) {
      console.log('index', index)
      this.requests[index].requestsToSubmit = false
    },
    selectCar () {
      this.isShownCarSelectError = false
      this.getCarById()
    },
    getCarById () {
      this.isSpinnerShown = true
      this.$store.dispatch('getCarById', this.car_id)
        .then(res => {
          this.isSpinnerShown = false
          this.car = res.data.data
          if (!this.isCarSelected) {
            this.isCarSelected = true
            this.requests[0].car_id = this.car_id
          }
          this.getPartCategoriesByCarCategory()
        }).catch(error => {
          this.isSpinnerShown = false
          this.isShownCarSelectError = true
          console.log(error.response.data)
        })
    },
    getPartCategoriesByCarCategory () {
      this.isSpinnerShown = true
      this.$store.dispatch('getPartCategoriesByCarCategory', this.car.car_category_id)
        .then(res => {
          this.isSpinnerShown = false
          this.partCategories = res.data
        }).catch(error => {
          this.isSpinnerShown = false
          console.log(error.response.data)
        })
    },
    addAnotherRequest () {
      this.currentIndex++

      this.requests.push({
        index: this.currentIndex,
        request: {
          car_id: this.car_id,
          condition: 0,
          part_name: '',
          details: null,
          part_category_id: 0,
          is_from_app: false,
          images: null
        },
        requestsToSubmit: true,
        isfileUplaoding: false,
        errors: {
          condition: null,
          part_name: null,
          details: null,
          part_category_id: null,
          images: null
        }
      })
    },
    createBulkRequest () {
      /* for (var i = 0; i < this.requests.length; i++) {
        if (this.requestsToSubmit[i].val) {
          this.errors[i].condition = null
          this.errors[i].part_name = null
          this.errors[i].details = null
          this.errors[i].part_category_id = null
          this.errors[i].images = null
        } else {
          continue
        }
      } */

      const finalRequestsArray = this.requests.filter(e => e.requestsToSubmit).map(r => r.request)

      this.isSpinnerShown = true
      this.$store.dispatch('createBulkRequest', finalRequestsArray)
        .then(res => {
          this.isSpinnerShown = false
          this.$router.push({ name: 'Requests', params: { lang: this.$i18n.locale } })
        }).catch(error => {
          this.isSpinnerShown = false
          const keys = Object.keys(error.response.data[0])
          for (var i = 0; i < keys.length; i++) {
            var field = keys[i].split('.')

            var submittedRequestsErrorsArray = []
            submittedRequestsErrorsArray = this.requests.filter((elem, index) => {
              if (this.requests[index].requestsToSubmit) {
                return elem
              }
            })
            console.log('submittedRequestsErrorsArray', submittedRequestsErrorsArray)
            submittedRequestsErrorsArray[field[0]].errors[field[1]] = this.joinArrayByComma(error.response.data[0][keys[i]])
          }
        })
    }
  }
}
</script>
