<template>
  <div>
      <vue-dropzone dir="ltr" style="overflow: hidden;width: 100%;" ref="myVueDropzone" id="dropzone" @vdropzone-success="vdropzoneSuccess" @vdropzone-sending="disableSubmitting" @vdropzone-complete-multiple="enableSubmitting" :options="dropzoneOptions" :useCustomSlot="true">
        {{ placeholderMessage ? placeholderMessage : $t('common.Drop files here to upload') }}
        <span v-if="placeholderMessage" class="plus-sign">+</span>
      </vue-dropzone>
      <div id="images-error" class="help-block with-errors">{{ errorMessage }}</div>

      <input name="image-type" id="image-type" type="hidden" :value="imageType">
      <input name="max-uploaded-images-num" id="max-uploaded-images-num" type="hidden" :value="maxFilesNum">
      <div v-if="!isEditImages" id="uploaded-images-container" class="uploaded-images-container" @click="handleClick">
        <div v-for="(image, i) in addedImages" :key="i" class="mr-1 ml-1 mt-3 shadow uploaded-image-container">
          <span class="remove-symbol" :data-img-path="image">x</span>
          <img class="rounded uploaded-image" :src="image">
        </div>
      </div>

      <div v-else id="uploaded-images-container" class="uploaded-images-container" @click="handleClick">
        <div v-for="(image, i) in uploadedImages" :key="i" class="mr-1 ml-1 mt-3 shadow uploaded-image-container">
          <span class="remove-symbol" :data-img-path="image">x</span>
          <img class="rounded uploaded-image" :src="image">
        </div>
      </div>
  </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

export default {
  name: 'ImageUpload',
  props: ['maxFilesUpload', 'imageType', 'errorMessage', 'stringOfImages', 'isEditImages', 'clearDropzone', 'placeholderMessage'],
  components: {
    vueDropzone: vue2Dropzone
  },
  data () {
    return {
      isfileUplaoding: false,
      addedImages: [],
      uploadedImages: [],
      deletedImages: [],
      maxFilesNum: null,
      fullyUploadedFiles: [],

      dropzoneOptions: {
        url: process.env.VUE_APP_API_URL + '/api/image-upload',
        paramName: 'images',
        addRemoveLinks: false,
        maxFilesize: 10,
        headers: { 'My-Awesome-Header': 'header value' },
        parallelUploads: 4,
        maxFiles: 4,
        uploadMultiple: true,
        acceptedFiles: 'image/jpeg,image/jpg',
        accept: function (file, done) {
          if (file.name.split('.').pop() === 'jfif') {
            done('Cannot upload image of type jfif')
          } else {
            done()
          }
        },
        init: function () {
          this.on('addedfiles', function (file) {
            var maxUploadedImagesNum = document.getElementById('max-uploaded-images-num')
            this.options.maxFiles = parseInt(maxUploadedImagesNum.value)
            this.options.url = process.env.VUE_APP_API_URL + '/api/image-upload' + '?type=' + document.getElementById('image-type').value
          })
        }
      }
    }
  },
  mounted () {
    if (this.$route.name === 'EditProfile') {
      this.uploadedImages = this.stringOfImages ? this.stringOfImages.split(',') : []
    }

    if (this.maxFilesUpload || this.maxFilesUpload === 0) {
      this.maxFilesNum = this.maxFilesUpload
    } else {
      this.maxFilesNum = 4
    }
  },
  watch: {
    clearDropzone () {
      this.$refs.myVueDropzone.removeAllFiles(true)
      this.addedImages.splice(0, this.addedImages.length)
      this.deletedImages.splice(0, this.deletedImages.length)
      this.uploadedImages.splice(0, this.uploadedImages.length)
      this.fullyUploadedFiles.splice(0, this.fullyUploadedFiles.length)
      this.uploadedImages = this.stringOfImages ? this.stringOfImages.split(',') : []
    },
    maxFilesUpload () {
      this.maxFilesNum = this.maxFilesUpload
    },
    stringOfImages () {
      this.uploadedImages.splice(0, this.uploadedImages.length)
      this.uploadedImages = this.stringOfImages ? this.stringOfImages.split(',') : []
    },
    isfileUplaoding () {
      this.$emit('isfileUplaodingChange', this.isfileUplaoding)
    },
    addedImages () {
      this.$emit('addedImagesChange', this.addedImages)
    },
    deletedImages () {
      this.$emit('deletedImagesChange', this.deletedImages)
    }
  },
  methods: {
    vdropzoneSuccess (file, response) {
      this.fullyUploadedFiles.push(file)
      if (response.success) {
        response.images.forEach((element, index) => {
          if (!this.addedImages.includes(element)) {
            this.addedImages.push(element)
          }

          if (!this.uploadedImages.includes(element)) {
            this.uploadedImages.push(element)
          }
        })
      } else {
        this.error.images = response.message
      }
    },
    disableSubmitting (file, xhr, formData) {
      this.isfileUplaoding = true
    },
    enableSubmitting (response) {
      this.isfileUplaoding = false
    },
    handleClick (e) {
      e.stopPropagation()
      if (e.target.matches('.remove-symbol')) {
        // this.maxFilesNum = this.maxFilesNum + 1

        var imagePath = e.target.getAttribute('data-img-path')

        if (this.addedImages.includes(imagePath)) {
          var index = this.addedImages.indexOf(imagePath)
          this.addedImages = this.addedImages.filter(elem => elem !== imagePath)
          this.$refs.myVueDropzone.removeFile(this.fullyUploadedFiles[index])
          this.fullyUploadedFiles.splice(index, 1)
        }

        if (this.isEditImages) {
          this.uploadedImages = this.uploadedImages.filter(elem => elem !== imagePath)

          if (this.stringOfImages && this.stringOfImages.includes(imagePath)) {
            this.deletedImages.push(imagePath)
            this.maxFilesNum = this.maxFilesNum + 1
          }
        }
      }
    }
  }
}
</script>

<style>
.uploaded-images-container{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.uploaded-image-container{
  position: relative;
}

.uploaded-image{
  position: relative;
  width: 100px;
  height: 100px;
  object-fit: cover;
  object-position: 50% 50%;
  border: 2px solid #e5e5e5;
}
.remove-symbol{
  position: absolute;
  content: 'x';
  color: #8090b566;
  z-index: 10;
  width: 25px;
  top: 0;
  right: 0px;
  cursor: pointer;
  text-align: center;
}
.remove-symbol:hover {
  color: #8090b5;
}
</style>
