import axios from '@/plugins/axios'

const actions = {
  contactUs: ({ commit, state }, info) => {
    return axios.post('/api/contact-us', info)
      .then(res => {
        return res
      }).catch(error => {
        return Promise.reject(error)
      })
  }
}

export default {
  actions
}
