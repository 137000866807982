<template>
  <div>
    <!--hero section start-->
    <HeroSection>
      <h1>{{ $t('cars.Add New Car') }}</h1>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb justify-content-center bg-transparent p-0 m-0">
          <li class="breadcrumb-item"><router-link class="text-dark" :to="{ name: 'Dashboard', params: { lang: this.$i18n.locale }}">{{ $t('menu.Home') }}</router-link></li>
          <li class="breadcrumb-item"><router-link class="text-dark" :to="{ name: 'BuyerCars', params: { lang: this.$i18n.locale }}">{{ $t('cars.My Cars') }}</router-link></li>
          <li class="breadcrumb-item active text-primary" aria-current="page">{{ $t('cars.Add New Car') }}</li>
        </ol>
      </nav>
    </HeroSection>
    <!--hero section end-->

    <!--body content start-->

    <div class="page-content">

    <section>
      <div class="container" style="position: relative;">
        <Spinner v-if="isSpinnerShown"/>
        <div class="row justify-content-center mb-5 text-center">
          <div class="col-12 col-lg-8">
            <div> <span class="badge badge-primary-soft p-2">
                      <img class="car-icon" src="@/assets/images/other/dashboard2.png">
                  </span>
              <p class="lead mt-4 mb-0">{{ $t('cars.Please enter the car info that you need to send request parts for') }}</p>
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-12 col-lg-10">
              <form class="row" method="post">
                <div class="messages"></div>
                <div class="form-group col-md-6">
                  <label for="car_category_id">{{ $t('cars.Car Category') }}</label>
                  <select id="car_category_id" v-model="car.car_category_id" class="form-control">
                    <option value="0" disabled>{{ $t('cars.Car Category') }}</option>
                    <option v-for="category in carCategories" :key="category.id" :value="category.id">{{ category[getFieldByLocale()] }}</option>
                  </select>
                  <div v-if="!error.car_category_id" class="help-block with-errors"></div>
                  <div class="error-message-block">{{ error.car_category_id }}</div>
                </div>
                <div class="form-group col-md-6">
                  <label for="brand_id">{{ $t('cars.Brand') }}</label>
                  <select id="brand_id" v-model="car.brand_id" class="form-control" :disabled="isBrandFieldDisabled">
                    <option value="0" disabled>{{ $t('cars.Brand') }}</option>
                    <option v-for="brand in brands" :key="brand.id" :value="brand.id">{{ brand[getFieldByLocale()] }}</option>
                  </select>
                  <div v-if="!error.brand_id" class="help-block with-errors"></div>
                  <div class="error-message-block">{{ error.brand_id }}</div>
                </div>
                <div class="form-group col-md-6">
                  <label for="model_id">{{ $t('cars.Model') }}</label>
                  <select id="model_id" v-model="car.model_id" class="form-control" :disabled="isModelFieldDisabled">
                    <option value="0" disabled>{{ $t('cars.Model') }}</option>
                    <option v-for="model in models" :key="model.id" :value="model.id">{{ model[getFieldByLocale()] }}</option>
                  </select>
                  <div v-if="!error.model_id" class="help-block with-errors"></div>
                  <div class="error-message-block">{{ error.model_id }}</div>
                </div>
                <div class="form-group col-md-6">
                  <label for="model_year">{{ $t('cars.Year of Manufacture') }}</label>
                  <select id="model_year" v-model="car.model_year" class="form-control">
                    <option value="0" disabled>{{ $t('cars.Year of Manufacture') }}</option>
                    <option v-for="year in (new Date().getFullYear() - 1968)" :key="year" :value="new Date().getFullYear() + 2 - year">{{ new Date().getFullYear() + 2 - year  }}</option>
                  </select>
                  <div v-if="!error.model_year" class="help-block with-errors"></div>
                  <div class="error-message-block">{{ error.model_year }}</div>
                </div>
                <div class="form-group col-md-6">
                  <label for="chasse_no">{{ $t('cars.Chasse No') }}</label>
                  <input id="chasse_no" v-model="car.vin" type="text" name="chasse-no"  minlength="17" maxlength="17" class="form-control" :placeholder="$t('cars.Chasse No')" required="required" :data-error="$t('cars.Chasse No is required and it should have 17 characters')">
                  <div v-if="showVinDataError" class="help-block with-errors"></div>
                  <div class="error-message-block"><span v-if="isShownVinCharCountError">{{ $t('cars.Chasse No should have 17 characters') }}</span>{{ error.vin }}</div>
                </div>
                <div class="form-group col-md-6">
                  <label for="message">{{ $t('cars.Car Specifications') }}</label>
                  <textarea id="message" v-model="car.details" name="details" class="form-control" :placeholder="$t('cars.Ex: 6 cylinders')" rows="3"></textarea>
                  <div v-if="!error.details" class="help-block with-errors"></div>
                  <div class="error-message-block">{{ error.details }}</div>
                </div>
                <div class="form-group col-md-12">
                  <label for="dropzone">{{ $t('cars.Car Images') }}</label>
                  <ImageUpload :imageType="imageType" :errorMessage="error.images" :isEditImages="false" @isfileUplaodingChange="setIsfileUplaoding" @addedImagesChange="setAddedImages" />
                  <div class="row">
                    <div class="col-md-12">
                      <UploadedImageConditions />
                    </div>
                  </div>
                </div>
                <div class="col-md-12 text-center mt-4">
                  <button class="btn btn-primary" @click.prevent="addCar()" :disabled="!isReadyForSubmission || isfileUplaoding"><span>{{ $t('common.Add') }}</span>
                  </button>
                </div>
              </form>
        </div>
        </div>

      </div>
    </section>
    </div>

    <!--body content end-->
  </div>
</template>

<script>
import UploadedImageConditions from '../../components/UploadedImageConditions.vue'
import ImageUpload from '../../components/ImageUpload'

export default {
  components: {
    UploadedImageConditions,
    ImageUpload
  },
  data () {
    return {
      brands: null,
      models: null,
      modelYears: null,
      isBrandFieldDisabled: true,
      isModelFieldDisabled: true,
      isShownVinCharCountError: false,
      isSpinnerShown: false,

      imageType: null,
      isfileUplaoding: false,

      error: {
        brand_id: null,
        model_id: null,
        car_category_id: null,
        model_year: null,
        vin: null,
        details: null,
        images: null
      },
      car: {
        brand_id: 0,
        model_id: 0,
        car_category_id: 0,
        model_year: 0,
        vin: null,
        details: null,
        images: null
      }
    }
  },
  mounted () {
    this.imageType = 'client_cars'
  },
  computed: {
    isReadyForSubmission () {
      return this.car.car_category_id && this.car.brand_id && this.car.model_id && this.car.model_year && this.car.vin
    },
    authBuyer () {
      return this.$store.getters.isAuthenticatedBuyer
    },
    showVinDataError () {
      return !(this.error.vin || this.isShownVinCharCountError)
    }
  },
  watch: {
    'car.car_category_id' (val) {
      this.models = null
      this.car.brand_id = 0
      this.car.model_id = 0

      if (val === '0') {
        this.isBrandFieldDisabled = true
        this.isModelFieldDisabled = true
      } else {
        this.isModelFieldDisabled = true
        this.$store.dispatch('getBrandsByCarCategory', val)
          .then(res => {
            this.brands = res.data.data
            this.isBrandFieldDisabled = false
          }).catch(error => {
            console.log(error)
          })
      }
    },
    'car.brand_id' (val) {
      this.car.model_id = 0

      if (val === '0') {
        this.isModelFieldDisabled = true
      } else {
        this.$store.dispatch('getModelsByCategoryBrand', { car_category_id: this.car.car_category_id, brand_id: val })
          .then(res => {
            this.models = res.data.data
            this.isModelFieldDisabled = false
          }).catch(error => {
            console.log(error)
          })
      }
    }
  },
  methods: {
    setIsfileUplaoding (value) {
      this.isfileUplaoding = value
    },
    setAddedImages (value) {
      this.car.images = value.join(',')
    },
    addCar () {
      this.error = {
        brand_id: null,
        model_id: null,
        car_category_id: null,
        model_year: null,
        vin: null,
        details: null,
        images: null
      }
      this.isShownVinCharCountError = false

      if (this.car.vin.length < 17) {
        this.isShownVinCharCountError = true
        return
      }

      if (this.authBuyer) {
        this.isSpinnerShown = true
        this.$store.dispatch('addCar', this.car)
          .then(res => {
            this.isSpinnerShown = false

            if (this.$route.query.isFromRequestForm) {
              this.$router.push({ name: 'NewRequest', query: { id: res.data.data.id } })
            } else {
              this.$router.push({ name: 'BuyerCars', params: { lang: this.$i18n.locale } })
            }
          }).catch(error => {
            this.isSpinnerShown = false
            if (error.response) {
              for (var field in error.response.data[0]) {
                this.error[field] = this.joinArrayByComma(error.response.data[0][field])
              }
            }
          })
      } else {
        this.$router.push({ name: 'GuestRequest' })
      }
    }

  }
}
</script>
