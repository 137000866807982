const state = {
  ltrLangs: ['en', 'tr'],
  rtlLangs: ['ar', 'ur'],
  locale: localStorage.getItem('locale') || 'en'
}

const getters = {
  ltrLangs: state => {
    return state.ltrLangs
  },
  rtlLangs: state => {
    return state.rtlLangs
  },
  locale: state => {
    return state.locale
  }
}

const mutations = {
  setLocale: (state, locale) => {
    localStorage.setItem('locale', locale)
    state.locale = locale
  },
  clearLangState: (state) => {
    state.locale = ''
  }
}

export default {
  state,
  getters,
  mutations
}
