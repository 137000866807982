<template>
  <section class="padding-y-10">
    <div class="container">
      <Spinner v-if="isSpinnerShown"/>
      <div class="row justify-content-center">
        <div class="col-md-5 col-10">
          <div>
            <h2 class="text-center mt-5 mb-3">{{ $t('loginSignup.Sign In') }}</h2>
            <form method="post">
              <div class="messages"></div>
              <div class="form-group">
                <label>{{ $t('loginSignup.Username') }}</label>
                <input v-model="username" id="form_name" type="text" name="name" class="form-control" :placeholder="$t('loginSignup.Username')" required="required" :data-error="$t('loginSignup.Username is required')" readonly>
                <div v-if="!error.username" class="help-block with-errors"></div>
                <div class="error-message-block">{{ error.username }}</div>
              </div>
              <div class="form-group">
                <label>{{ $t('loginSignup.Password') }}</label>
                <input v-model="password" id="form_password" type="password" name="password" class="form-control" :placeholder="$t('loginSignup.Password')" required="required" :data-error="$t('loginSignup.password is required')">
                <div v-if="!error.password" class="help-block with-errors"></div>
                <div class="error-message-block">{{ error.password }}</div>
              </div>
              <div class="form-group mt-4 mb-5">
                <div class="remember-checkbox d-flex align-items-center justify-content-between">
                </div>
              </div> <button class="btn btn-primary btn-block" @click.prevent="loginByPassword()" :disabled="!isReadyForSubmission">Login Now</button>

            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'PasswordLoginForm',
  props: ['username'],
  data () {
    return {
      isSpinnerShown: false,
      error: {
        username: null,
        password: null
      },

      password: null

    }
  },
  computed: {
    isReadyForSubmission () {
      return this.password
    }
  },
  methods: {
    loginByPassword () {
      this.isSpinnerShown = true
      this.$store.dispatch('loginByPassword', {
        username: this.username,
        password: this.password
      })
        .then(res => {
          this.isSpinnerShown = false
          if (this.$route.query.request_part) {
            this.$router.push({ name: 'NewRequest', params: { lang: this.$i18n.locale } })
          } else {
            this.$router.push({ name: 'Dashboard', params: { lang: this.$i18n.locale } })
          }
        }).catch(error => {
          this.isSpinnerShown = false
          this.error.password = error.response.data.message
        })
    }
  }
}
</script>
