<template>
  <div>
    <BuyerSignupForm v-on:respond="showVerificationForm" v-if="!isSignedUp" />
    <CodeLoginForm v-on:codeNotSent="showPasswordLoginForm" :client_id="client_id" v-if="isSignedUp && !codeNotSent" />
    <PasswordLoginForm :username="username" v-if="codeNotSent"/>
  </div>
</template>

<script>
import BuyerSignupForm from '../../components/signup/BuyerSignupForm.vue'
import CodeLoginForm from '../../components/signup/CodeLoginForm.vue'
import PasswordLoginForm from '../../components/signup/PasswordLoginForm.vue'

export default {
  name: 'SignupBuyer',
  data () {
    return {
      isSignedUp: false,
      codeNotSent: false,
      client_id: null,
      username: null
    }
  },
  components: {
    BuyerSignupForm,
    CodeLoginForm,
    PasswordLoginForm
  },
  methods: {
    showVerificationForm (value) {
      this.isSignedUp = true
      this.client_id = value.id
      this.username = value.username

      this.$store.dispatch('requestLoginCode', {
        dial_code: '966',
        mobile: value.mobile
      })
        .then(res => {

        }).catch(error => {
          console.log(error)
        })
    },
    showPasswordLoginForm () {
      this.codeNotSent = true
    }
  }
}
</script>
