import axios from '@/plugins/axios'

const actions = {
  getPricedParts: ({ commit, state, rootState }, { page, searchParams }) => {
    return axios.get('/api/seller/priced-parts?page=' + page, {
      headers: {
        Authorization: 'Bearer ' + rootState.loginSignup.api_token
      },
      params: searchParams
    })
      .then(res => {
        return res
      }).catch(error => {
        return Promise.reject(error)
      })
  },
  updatePricedPart: ({ commit, state, rootState }, pricedPart) => {
    return axios.put('/api/seller/priced-parts/' + pricedPart.id, pricedPart, {
      headers: {
        Authorization: 'Bearer ' + rootState.loginSignup.api_token
      }
    })
      .then(res => {
        return res
      }).catch(error => {
        return Promise.reject(error)
      })
  },
  addPricedPart: ({ commit, state, rootState }, pricedPart) => {
    return axios.post('/api/seller/priced-parts', pricedPart, {
      headers: {
        Authorization: 'Bearer ' + rootState.loginSignup.api_token
      }
    })
      .then(res => {
        return res
      }).catch(error => {
        return Promise.reject(error)
      })
  }

}

export default {
  actions
}
