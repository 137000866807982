<template>
  <div>
    <div class="row">
      <div class="col-md-12 border-bottom-in-amber mb-5 text-right">
        <h3 class="text-black h4 text-uppercase">{{ $t('cart.Cart Totals') }}</h3>
      </div>
    </div>
    <slot></slot>
    <div class="row mb-3">
      <div class="col-6">
        <span class="text-black">{{ $t('cart.Subtotal') }}</span>
      </div>
      <div class="col-6" :class="isRtl ? 'text-left' : 'text-right'">
        <strong v-if="cartTotals.subtotal" class="text-black">{{ cartTotals.subtotal + ' ' + getCurrency() }}</strong>
      </div>
    </div>
    <div class="row mb-3" v-if="cartTotals.shipping_fee !== '0.00'">
      <div class="col-6">
        <span class="text-black">{{ $t('cart.Shipping Fee') }}</span>
      </div>
      <div class="col-6" :class="isRtl ? 'text-left' : 'text-right'">
        <strong class="text-black">{{ cartTotals.shipping_fee + ' ' + getCurrency() }}</strong>
      </div>
    </div>
    <div class="row mb-3" v-if="cartTotals.discount !== '0.00'">
      <div class="col-6">
        <span class="text-black">{{ $t('cart.Discount') }}</span>
      </div>
      <div class="col-6" :class="isRtl ? 'text-left' : 'text-right'">
        <strong class="text-black">{{ cartTotals.discount + ' ' + getCurrency() }}</strong>
      </div>
    </div>
    <div class="row mb-5">
      <div class="col-6">
      <span class="text-black">{{ $t('cart.Total') }}</span>
      </div>
      <div class="col-6" :class="isRtl ? 'text-left' : 'text-right'">
      <strong class="text-black">{{ cartTotals.total + ' ' + getCurrency() }}</strong>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 mb-1">
        <a target="_blank"  v-if="cartTotals.invoice_url != null" :href="cartTotals.invoice_url" class="btn btn-primary btn-md btn-block"> {{$t('checkout.Invoice details')}} </a>
        <p class="text-center" v-else>{{$t('orders.invoice')}}</p>
      </div>
    </div>
    <div class="row">
      <div v-if="btnRoute && btnLabel" class="col-md-12">
        <router-link :to="btnRoute" class="btn btn-primary btn-md btn-block">{{ btnLabel }}</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CartTotals',
  props: ['cartTotals', 'btnRoute', 'btnLabel'],
  data () {
    return {

    }
  }
}
</script>
