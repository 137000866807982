<template>
  <div>
    <!--hero section start-->

    <HeroSection>
      <h1>{{ $t('cart.Checkout') }}</h1>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb justify-content-center bg-transparent p-0 m-0">
          <li class="breadcrumb-item">
            <router-link class="text-dark" :to="{ name: 'Dashboard', params: { lang: this.$i18n.locale }}">
              {{ $t('menu.Home') }}
            </router-link>
          </li>
          <li class="breadcrumb-item">
            <router-link class="text-dark" :to="{ name: 'Cart', params: { lang: this.$i18n.locale }}">{{
                $t('menu.Cart')
              }}
            </router-link>
          </li>
          <li class="breadcrumb-item active text-primary" aria-current="page">{{ $t('cart.Checkout') }}</li>
        </ol>
      </nav>
    </HeroSection>

    <!--hero section end-->

    <!--body content start-->

    <div class="page-content">

      <section>
        <div class="container">
          <CartIcons :isHyperpayShown="isHyperpayShown"/>
          <div v-if="!isHyperpayShown" class="row checkout-page-content" style="position: relative;">
            <Spinner v-if="isSpinnerShownOnContent"/>
            <div class="col-lg-7 col-md-12">
              <div class="col-md-12">
                <div class="checkout-form box-shadow white-bg">
                  <h2 class="mb-4">{{ $t('cart.Shipping Info') }}</h2>
                  <form class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="shipping_recipient_name">{{ $t('cart.Recipient Name') }}</label>
                        <input v-model="cartInfo.shipping_recipient_name" id="shipping_recipient_name" type="text"
                               name="name" class="form-control" required="required"
                               :data-error="$t('cart.Recipient Name is required')">
                        <div v-if="!error.shipping_recipient_name" class="help-block with-errors"></div>
                        <div class="error-message-block">{{ error.shipping_recipient_name }}</div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="shipping_phone1">{{ $t('cart.Shipping phone1') }}</label>
                        <input v-model="cartInfo.shipping_phone1" id="shipping_phone1" type="text" name="phone1"
                               class="form-control" required="required"
                               :data-error="$t('cart.Shipping phone1 is required')">
                        <div v-if="!error.shipping_phone1" class="help-block with-errors"></div>
                        <div class="error-message-block">{{ error.shipping_phone1 }}</div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="shipping_phone2">{{ $t('cart.Shipping phone2') }}</label>
                        <input v-model="cartInfo.shipping_phone2" id="shipping_phone2" type="text" name="phone2"
                               class="form-control" required="required"
                               :data-error="$t('cart.Shipping phone2 is required')">
                        <div v-if="!error.shipping_phone2" class="help-block with-errors"></div>
                        <div class="error-message-block">{{ error.shipping_phone2 }}</div>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="shipping_city">{{ $t('cart.Shipping city') }}</label>
                        <select v-model="cartInfo.shipping_city" id="shipping_city" name="city" class="form-control"
                                required="required" :data-error="$t('cart.City is required')">
                          <option value=""></option>
                          <option v-for="city in cities" :key="city.id" :value="city.en_name">{{ city.name }}</option>
                        </select>
                        <div v-if="!error.shipping_city" class="help-block with-errors"></div>
                        <div class="error-message-block">{{ error.shipping_city }}</div>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="shipping_address">{{ $t('cart.Shipping Address') }}</label>
                        <textarea v-model="cartInfo.shipping_address" id="shipping_address" name="address"
                                  class="form-control text-area" rows="4" required="required"
                                  :data-error="$t('cart.Please, leave us an address')"></textarea>
                        <div v-if="!error.shipping_address" class="help-block with-errors"></div>
                        <div class="error-message-block">{{ error.shipping_address }}</div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>

            </div>

            <div v-if="cart" class="col-lg-5 col-md-12 md-mt-5">
              <div class="row mb-5">
                <div class="col-md-12">
                  <h3 class="mb-3 text-black">{{ $t('cart.Coupon Code') }}</h3>
                  <div class="p-3 p-lg-5 border">
                    <div v-if="!cart.coupon_code">
                      <label class="text-black mb-3">{{ $t('cart.Enter your coupon code if you have one') }}</label>
                      <div class="input-group">
                        <input v-model="cartInfo.coupon_code" id="coupon" type="text" name="coupon" class="form-control"
                               :placeholder="$t('cart.Coupon Code')">
                      </div>
                      <div class="text-center">
                        <div v-if="error.coupon_code" class="text-danger pt-2">{{ $t('cart.Invalid Code') }}</div>
                        <button type="button" class="btn btn-sm btn-primary p-1 mt-2" v-on:click="applyCoupon()">
                          {{ $t('cart.Apply Coupon') }}
                        </button>
                      </div>
                    </div>
                    <div class="d-flex justify-content-between" v-else>
                      <h4 class="mb-0">{{ cart.coupon_code }}</h4>
                      <button type="button" class="btn btn-sm btn-secondary p-1 mt-2" v-on:click="removeCoupon()">
                        {{ $t('cart.Remove Coupon') }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row justify-content-end">
                <div class="col-lg-8 col-sm-6 mx-3">
                  <CartTotals :cartTotals="cart" :btnRoute="null" :btnLabel="null"/>
                </div>
              </div>

            </div>
            <div class="col-lg-7 col-md-12">
              <div class="row">
                <div class="col-12">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label for="payment_method">{{ $t('cart.Payment Method') }}</label>
                          <div class="d-flex align-items-center payment-methods">
                            <div v-for="paymentMethod in paymentMethodsArr" :key="paymentMethod.id">
                              <span @click="selectPaymentMethod(paymentMethod.id)"><input
                                v-model="cartInfo.payment_method" type="radio" :class="isRtl ? 'ml-1': 'mr-1'"
                                :id="paymentMethod[getFieldByLocale()].split(' ').join('_')" name="payment_method"
                                :value="paymentMethod.id"></span>
                              <label :for="paymentMethod[getFieldByLocale()].split(' ').join('_')">{{
                                  paymentMethod[getFieldByLocale()]
                                }}</label><br>
                            </div>
                          </div>
                          <div v-if="!error.payment_method" class="help-block with-errors"></div>
                          <div class="error-message-block">{{ error.payment_method }}</div>
                        </div>
                      </div>
                      <div v-if="cartInfo.payment_method === bankTransferId" class="col-md-12">
                        <div class="text-center mb-3">
                          <a class="my-4 btn btn-grey" data-toggle="modal" data-target="#banks_modal"><i
                            class="fa fa-bank"></i>
                            {{ $t('cart.Bank accounts numbers') }}</a>
                        </div>
                        <div class="form-group">
                          <label for="account_name">{{ $t('cart.Transferred from account') }}</label>
                          <input v-model="cartInfo.account_name" id="account_name" type="text" name="account_name"
                                 class="form-control" required="required"
                                 :data-error="$t('cart.Account Holder Name is required')">
                          <div v-if="!error.account_name" class="help-block with-errors"></div>
                          <div class="error-message-block">{{ error.account_name }}</div>
                        </div>

                        <div class="form-group">
                          <label for="proof">{{ $t('cart.Transformation proof') }}</label>
                          <input @change="cartInfoHasImage" ref="proof" accept="image/*,.pdf" id="proof" type="file" name="proof" class="form-control"
                                 required="required" :data-error="$t('cart.Transformation Proof is required')">
                          <div v-if="!error.proof" class="help-block with-errors"></div>
                          <div class="error-message-block">{{ error.proof }}</div>
                        </div>

                      </div>

                      <div class="mb-5" style="padding: 0 15px;">
                        <div class="d-flex justify-content-between flex-wrap checkout-buttons-wrapper">
                          <router-link :to="{ name: 'Cart', params: { lang: this.$i18n.locale } }">
                            <div class="shadow-primary custom-checkout-button text-in-grey background-silver">
                              {{ $t('cart.Back To Cart') }}
                            </div>
                          </router-link>

                          <button @click.prevent="updateCart()" :disabled="!isReadyForSubmission"
                                  class="btn btn-primary shadow-primary custom-checkout-button text-white">
                            {{
                              cartInfo.payment_method === bankTransferId ? $t('cart.Complete The Process') : $t('cart.Go To Payment')
                            }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div v-if="isHyperpayShown" class="row justify-content-end">
            <div class="col-lg-7 col-md-12">
              <form :action="paymentFormAction" class="paymentWidgets" data-brands="VISA MASTER AMEX"></form>
            </div>

            <div class="col-lg-5 col-md-12 md-mt-5">
              <div v-if="cart" class="row justify-content-end">
                <div class="col-lg-8 col-sm-6 mx-3">
                  <CartTotals :cartTotals="cart" :btnRoute="null" :btnLabel="null"/>
                </div>
              </div>

            </div>
          </div>

        </div>
      </section>

    </div>
    <Modal :modal_id="'banks_modal'" hea>
      <div class="modal-body">
        <div>
          <div class="card p-2 mb-2" v-for="(bank, bi) of banks" :key="bi">
            {{ $t('cart.Bank') }}: {{ bank.bank }} <br>
            {{ $t('cart.Name') }}: {{ bank.name }} <br>
            {{ $t('cart.Account No') }}: {{ bank.no }} <br>
            {{ $t('cart.IBAN') }}: {{ bank.iban }}
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" data-dismiss="modal" class="btn btn-primary">{{ $t('common.Ok') }}</button>
      </div>
    </Modal>
    <!--body content end-->
  </div>
</template>

<script>
import CartIcons from '../../components/CartIcons.vue'
import CartTotals from '../../components/CartTotals.vue'
import i18n from '../../i18n'
import Modal from '../../components/Modal'

export default {
  name: 'Checkout',
  components: {
    CartIcons,
    CartTotals,
    Modal
  },
  data () {
    return {
      cities: [],
      cart: null,
      isHyperpayShown: false,
      isSpinnerShownOnContent: true,
      addresses: null,
      enableAddressEditing: true,
      cartInfo: {
        payment_method: null,
        account_name: null,
        coupon_code: null,
        shipping_recipient_name: null,
        shipping_phone1: null,
        shipping_phone2: null,
        shipping_city: null,
        shipping_address: null,
        hasFile: false
      },
      error: {
        payment_method: null,
        account_name: null,
        coupon_code: null,
        shipping_recipient_name: null,
        shipping_phone1: null,
        shipping_phone2: null,
        shipping_city: null,
        shipping_address: null,
        proof: null
      }
    }
  },
  mounted () {
    this.cartInfo.payment_method = this.onlinePaymentId
    this.getCart()
    this.getCitiesByCountry()
    this.getAllAddresses()
  },
  computed: {
    isReadyForSubmission () {
      var isAddressVaild = this.cartInfo.shipping_recipient_name && this.cartInfo.shipping_phone1 && this.cartInfo.shipping_phone2 && this.cartInfo.shipping_city && this.cartInfo.shipping_address
      var isPaymentValid = this.cartInfo.payment_method
      if (isPaymentValid) {
        isPaymentValid = this.cartInfo.payment_method === this.bankTransferId ? (this.cartInfo.payment_method && this.cartInfo.account_name && this.cartInfo.hasFile) : this.cartInfo.payment_method
      }
      console.log(this.cartInfo)
      return isPaymentValid && isAddressVaild
    },
    paymentFormAction () {
      return '/' + i18n.locale + '/cart/checkout-confirm?order_id=' + this.cart?.id
    }
  },
  methods: {
    getCitiesByCountry () {
      this.$store.dispatch('getCitiesbyCountry', 57)
        .then(res => {
          this.cities = res.data.data
          console.log('cities res', res.data)
          this.isCityFieldDisabled = false
        }).catch(error => {
          console.log(error)
        })
    },
    selectPaymentMethod (paymentId) {
      this.cartInfo.payment_method = paymentId
    },
    getAllAddresses () {
      this.$store.dispatch('getAllAddresses')
        .then(res => {
          console.log(res.data.data)
        }).catch(error => {
          console.log(error)
        })
    },
    applyCoupon () {
      this.isSpinnerShownOnContent = true
      this.$store.dispatch('applyCoupon', this.cartInfo.coupon_code)
        .then(res => {
          this.isSpinnerShownOnContent = false
          this.cart = res.data.data
          this.cartInfo.coupon_code = ''
          this.error.coupon_code = false
        }).catch(error => {
          this.isSpinnerShownOnContent = false
          this.error.coupon_code = true
          console.log(error)
        })
    },
    removeCoupon () {
      this.isSpinnerShownOnContent = true
      this.$store.dispatch('removeCoupon')
        .then(res => {
          this.isSpinnerShownOnContent = false
          this.cart = res.data.data
        }).catch(error => {
          this.isSpinnerShownOnContent = false
          console.log(error)
        })
    },
    getCart () {
      this.isSpinnerShownOnContent = true
      this.$store.dispatch('getCart')
        .then(res => {
          this.isSpinnerShownOnContent = false
          this.cart = res.data.data
          this.cartInfo.shipping_recipient_name = this.cart.shipping_recipient_name
          this.cartInfo.shipping_phone1 = this.cart.shipping_phone1
          this.cartInfo.shipping_phone2 = this.cart.shipping_phone2
          this.cartInfo.shipping_city = this.cart.shipping_city
          this.cartInfo.shipping_address = this.cart.shipping_address
          this.cartInfo.coupon_code = this.cart.coupon_code
        }).catch(error => {
          this.isSpinnerShownOnContent = false
          console.log(error)
        })
    },
    updateCart () {
      this.error = {
        payment_method: null,
        account_name: null,
        coupon_code: null,
        shipping_recipient_name: null,
        shipping_phone1: null,
        shipping_phone2: null,
        shipping_city: null,
        shipping_address: null,
        proof: null
      }

      const swalWithBootstrapButtons = this.$swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger'
        },
        buttonsStyling: false
      })

      this.isSpinnerShownOnContent = true
      // if payment method is bank transfer
      // create a FormData object for proof image file
      let order = {}
      this.cartInfo.coupon_code = this.cart.coupon_code
      if (this.cartInfo.payment_method === 1) {
        const formData = new FormData()
        for (const key in this.cartInfo) {
          formData.append(key, this.cartInfo[key])
          console.log(key, this.cartInfo[key])
        }
        formData.append('proof', this.$refs.proof.files[0])
        order = formData
      } else {
        order = this.cartInfo
      }
      this.$store.dispatch('updateCart', order)
        .then(res => {
          if (this.cartInfo.payment_method === this.onlinePaymentId) {
            this.$store.dispatch('createCheckout', this.cart.id)
              .then(res => {
                console.log(res.data.checkout_id.id, res.data.checkout_id, res.data)
                var paymentScript = document.createElement('script')
                paymentScript.id = 'payment-widget'
                paymentScript.src = 'https://oppwa.com/v1/paymentWidgets.js?checkoutId=' + res.data.checkout_id.id
                document.getElementsByTagName('body')[0].appendChild(paymentScript)

                this.isSpinnerShownOnContent = false
                this.isHyperpayShown = true
              }).catch(error => {
                console.log(error)
              })
          } else {
            swalWithBootstrapButtons.fire({
              title: this.$t('common.The process was done successfully!'),
              text: '',
              icon: 'success',
              confirmButtonText: this.$t('common.Ok')
            }).then((result) => {
              if (result.isConfirmed) {
                this.$router.push({
                  name: 'CheckoutConfirm',
                  query: { order_id: this.cart.id }
                })
              }
            })
          }
        }).catch(error => {
          this.isSpinnerShownOnContent = false
          console.dir(error)
          if (error.response) {
            for (var field in error.response.data[0]) {
              console.log(field)
              this.error[field] = this.joinArrayByComma(error.response.data[0][field])
            }
          }
        })
    },
    cartInfoHasImage () {
      if (this.$refs.proof?.files?.length > 0) {
        this.cartInfo.hasFile = true
      } else {
        this.cartInfo.hasFile = false
      }
    }
  }
}
</script>

<style scoped>
.form-control:not(.text-area) {
  height: 2.3rem !important;
}

.form-control {
  border-radius: 17px !important;
}

[type="radio"]:checked, [type="radio"]:not(:checked) {
  position: relative;
  opacity: 1;
  pointer-events: none;
}

.custom-checkout-button {
  width: 12.5rem;
  font-size: .9rem;
  font-weight: 400;
  text-align: center;
  text-transform: capitalize;
  border-radius: 8px;
  padding: 8px 0px;
}
</style>
