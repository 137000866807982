<template>
  <div>
    <!--hero section start-->
    <HeroSection>
      <h1>{{ $t('sellerParts.Add New Part Categories') }}</h1>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb justify-content-center bg-transparent p-0 m-0">
          <li class="breadcrumb-item"><router-link class="text-dark" :to="{ name: 'Dashboard', params: { lang: this.$i18n.locale }}">{{ $t('menu.Home') }}</router-link></li>
          <li class="breadcrumb-item"><router-link class="text-dark" :to="{ name: 'partCategories', params: { lang: this.$i18n.locale }}">{{ $t('sellerParts.My Store') }}</router-link></li>
          <li class="breadcrumb-item active text-primary" aria-current="page">{{ $t('sellerParts.Add New Part Categories') }}</li>
        </ol>
      </nav>
    </HeroSection>
    <!--hero section end-->

    <!--body content start-->

    <div class="page-content">

    <section>
      <div class="container" style="position: relative;">
        <Spinner v-if="isSpinnerShown"/>
        <div class="row justify-content-center mb-5 text-center">
          <div class="col-12 col-lg-8">
            <div> <span class="badge badge-primary-soft p-2">
                      <img class="total-parts-icon" src="@/assets/images/other/total-parts.png">
                  </span>
              <h2 class="mt-4 mb-0">{{ $t('sellerParts.Add New Part Categories') }}</h2>
              <p class="lead mb-0">{{ $t('sellerParts.Please enter the part categories info that you have in your store') }}</p>
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-12 col-lg-10">
              <form class="row" method="post">
                <div class="messages"></div>
                <div class="form-group col-md-6">
                  <label for="car_category_id">{{ $t('cars.Car Category') }}</label>
                  <select id="car_category_id" v-model="partCategory.car_category_id" class="form-control" :class="{'border-danger':error.car_category_id}">
                    <option value="0" disabled>{{ $t('cars.Car Category') }}</option>
                    <option v-for="category in carCategories" :key="category.id" :value="category.id">{{ category[getFieldByLocale()] }}</option>
                  </select>
                  <div v-if="!error.car_category_id" class="help-block with-errors"></div>
                  <div class="error-message-block">{{ error.car_category_id }}</div>
                </div>
                <div class="form-group col-md-6">
                  <label for="brand_id">{{ $t('cars.Brand') }}</label>
                  <select id="brand_id" v-model="partCategory.brand_id" class="form-control" :class="{'border-danger':error.brand_id}" :disabled="isBrandFieldDisabled">
                    <option value="0" disabled>{{ $t('cars.Brand') }}</option>
                    <option v-for="brand in brands" :key="brand.id" :value="brand.id">{{ brand[getFieldByLocale()] }}</option>
                  </select>
                  <div v-if="!error.brand_id" class="help-block with-errors"></div>
                  <div class="error-message-block">{{ error.brand_id }}</div>
                </div>
                <div class="form-group col-md-6">
                  <label for="model_ids">{{ $t('cars.Model') }}</label>
                  <multiselect id="model_ids" :disabled="isModelFieldDisabled" :class="{'invalid':error.model_ids}" v-model="partCategory.model_ids" :options="models" :multiple="true" :selectLabel="''" :selectedLabel="''" :deselectLabel="''" :close-on-select="false" :clear-on-select="false" :preserve-search="true" :placeholder="$t('cars.Model')" label="name" track-by="name" :preselect-first="true">
                    <template slot="selection" slot-scope="{ values, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.length }} {{ $t('sellerParts.options selected') }}</span></template>
                  </multiselect>
                  <div v-if="!error.model_ids" class="help-block with-errors"></div>
                  <div class="error-message-block">{{ error.model_ids }}</div>
                </div>
                <div class="form-group col-md-6">
                  <label for="part_category_ids">{{ $t('sellerParts.Part Categories') }}</label>
                  <multiselect id="part_category_ids" :disabled="isPartsCategoriesFieldDisabled" :class="{'invalid':error.part_category_ids}"
                               v-model="partCategory.part_category_ids" :options="partCategories"
                               @remove="onRemove" :group-values="'categories'" :group-label="'label'"
                               :group-select="true" :selectGroupLabel="''" :multiple="true" :selectLabel="''"
                               :selectedLabel="''" :deselectLabel="''" :close-on-select="false" :clear-on-select="false"
                               :preserve-search="true" :placeholder="$t('sellerParts.Part Categories')"
                               label="name" track-by="name" :preselect-first="true">
                    <template slot="selection" slot-scope="{ values, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.length }} {{ $t('sellerParts.options selected') }}</span></template>
                  </multiselect>
                  <div v-if="!error.part_category_ids" class="help-block with-errors"></div>
                  <div class="error-message-block">{{ error.part_category_ids }}</div>
                </div>
                <div class="form-group col-md-6">
                  <label for="from_year">{{ $t('sellerParts.From Year') }}</label>
                  <select id="from_year" v-model="partCategory.from_year" class="form-control" :class="{'border-danger':error.from_year}">
                    <option value="0" disabled>{{ $t('sellerParts.From Year') }}</option>
                    <option v-for="year in (new Date().getFullYear() - 1968)" :key="year" :value="new Date().getFullYear() + 2 - year">{{ new Date().getFullYear() + 2 - year  }}</option>
                  </select>
                  <div v-if="!error.from_year" class="help-block with-errors"></div>
                  <div class="error-message-block">{{ error.from_year }}</div>
                </div>
                <div class="form-group col-md-6">
                  <label for="to_year">{{ $t('sellerParts.To Year') }}</label>
                  <select id="to_year" v-model="partCategory.to_year" class="form-control" :disabled="isToYearFieldDisabled" :class="{'border-danger':error.to_year}">
                    <option value="0" disabled>{{ $t('sellerParts.To Year') }}</option>
                    <option v-for="year in (new Date().getFullYear() - getFromYear + 2)" :key="year" :value="new Date().getFullYear() + 2 - year">{{ new Date().getFullYear() + 2 - year  }}</option>
                  </select>
                  <div v-if="!error.to_year" class="help-block with-errors"></div>
                  <div class="error-message-block">{{ error.to_year }}</div>
                </div>
                <div class="form-group col-md-6">
                  <label for="condition">{{ $t('sellerParts.Parts Status') }}</label>
                  <select id="condition" v-model="partCategory.condition" class="form-control" :class="{'border-danger':error.condition}">
                    <option value="0" disabled>{{ $t('sellerParts.Parts Status') }}</option>
                    <option v-for="condition in partConditions" :key="condition.id" :value="condition.id">{{ condition[getFieldByLocale()] }}</option>
                  </select>
                  <div v-if="!error.condition" class="help-block with-errors"></div>
                  <div class="error-message-block">{{ error.condition }}</div>
                </div>

                <div class="col-md-12 text-center mt-4">
                  <button class="btn btn-primary" @click.prevent="addPartsCategrories()" ><span>{{ $t('common.Add') }}</span>
                  </button>
                </div>
              </form>
        </div>
        </div>
      </div>
    </section>

    </div>

    <!--body content end-->
  </div>
</template>
<script>
import Multiselect from 'vue-multiselect'
import { sellerPartMixin } from '../../mixins/sellerPartMixin'

export default {
  name: 'AddPartCategory',
  data () {
    return {
      isSpinnerShown: false
    }
  },
  components: {
    Multiselect
  },
  mixins: [sellerPartMixin],
  mounted () {
    this.isFormShownForTheFirstTime = false
  },
  computed: {
    getFromYear () {
      if (this.partCategory) {
        return this.partCategory.from_year && parseInt(this.partCategory.from_year) !== 0 ? this.partCategory.from_year : new Date().getFullYear()
      } else {
        return new Date().getFullYear()
      }
    }
  },
  methods: {
    validate () {
      if (this.isReadyForSubmission) {
        return true
      }
      if (!this.partCategory.model_ids || this.partCategory.model_ids.length === 0) {
        this.error.model_ids = this.$t('general.validation_required')
      }
      if (!this.partCategory.from_year) {
        this.error.from_year = this.$t('general.validation_required')
      }
      if (!this.partCategory.to_year) {
        this.error.to_year = this.$t('general.validation_required')
      }
      if (!this.partCategory.part_category_ids || this.partCategory.part_category_ids.length === 0) {
        this.error.part_category_ids = this.$t('general.validation_required')
      }
      if (!this.partCategory.condition) {
        this.error.condition = this.$t('general.validation_required')
      }
      if (!this.partCategory.car_category_id) {
        this.error.car_category_id = this.$t('general.validation_required')
      }
      if (!this.partCategory.brand_id) {
        this.error.brand_id = this.$t('general.validation_required')
      }
      return false
    },
    addPartsCategrories () {
      this.error = {
        car_category_id: null,
        brand_id: null,
        model_ids: null,
        part_category_ids: null,
        from_year: null,
        to_year: null,
        condition: null
      }
      if (!this.validate()) {
        return
      }

      var finalModelIdsArray = this.partCategory.model_ids.map((element) => {
        return element.id
      })

      var finalPartCategoryIdsArray = this.partCategory.part_category_ids.map((element) => {
        return element.id
      })

      this.isSpinnerShown = true
      this.$store.dispatch('addSellerPart', {
        model_ids: finalModelIdsArray,
        part_category_ids: finalPartCategoryIdsArray,
        from_year: this.partCategory.from_year,
        to_year: this.partCategory.to_year,
        condition: this.partCategory.condition
      })
        .then(res => {
          this.isSpinnerShown = false
          this.$router.push({ name: 'partCategories', params: { lang: this.$i18n.locale } })
        }).catch(error => {
          this.isSpinnerShown = false
          if (error.response) {
            for (var field in error.response.data[0]) {
              this.error[field] = this.joinArrayByComma(error.response.data[0][field])
            }
          }
        })
    }
  }
}
</script>
<style>
  .invalid .multiselect__tags{
    border-color: #ff0000
  }
</style>
