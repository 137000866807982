<template>
  <div>
    <!--hero section start-->

    <HeroSection>
      <h1>{{ $t('sellerParts.My Store') }}</h1>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb justify-content-center bg-transparent p-0 m-0">
          <li class="breadcrumb-item"><router-link class="text-dark" :to="{ name: 'Dashboard', params: { lang: this.$i18n.locale }}">{{ $t('menu.Home') }}</router-link></li>
          <li class="breadcrumb-item active text-primary" aria-current="page">{{ $t('sellerParts.My Store') }}</li>
        </ol>
      </nav>
    </HeroSection>

    <!--hero section end-->

    <!--body content start-->

    <div class="page-content">
      <section style="position: relative;">
        <Spinner v-if="isSpinnerShown"/>
        <div class="container">
          <div class="custom-button-wrapper">
            <router-link :to="{ name: 'AddPartCategory', params: { lang: $i18n.locale}}"  class="background-amber font-weight-600 shadow-primary  btn btn-warning btn-sm"> + {{ $t('menu.Add part category') }}</router-link>
            <button class="btn btn-light btn-sm mx-3" @click="toggleSearch()"><i class="fa fa-search"></i> {{ $t('sellerParts.search_btn_label') }}</button>
          </div>
          <div class="bg-light p-3 mb-4" v-show="searchVisible">
            <SellerPartSearch @input="getSellerParts()" v-model="searchData"></SellerPartSearch>
          </div>

          <!-- / .row -->
          <div class="row">
              <!-- Blog Card -->
              <div class="col-md-6" v-for="sellerPart in sellerParts" :key="sellerPart.id">
                <div class="card bg-light mb-5">
                  <table class="card-body pt-5 bg-white list-unstyled cases-meta">
                    <tbody>
                    <tr>
                      <td><h2 class="h6 font-weight-medium mb-0">{{ $t('cars.Category') }}</h2></td>
                      <td><p class="mb-2">{{ carCategories[sellerPart.model.brand_category.car_category_id][getFieldByLocale()] }}</p></td>
                    </tr>
                    <tr>
                      <td><h2 class="h6 font-weight-medium mb-0">{{ $t('cars.Brand') }}</h2></td>
                      <td><p class="mb-2">{{ sellerPart.model.brand_category.brand.name }}</p></td>
                    </tr>
                    <tr>
                      <td><h2 class="h6 font-weight-medium mb-0">{{ $t('cars.Model') }}</h2></td>
                      <td><p class="mb-2">{{ sellerPart.model.name }}</p></td>
                    </tr>
                    <tr>
                      <td><h2 class="h6 font-weight-medium mb-0">{{ $t('sellerParts.Part Categories') }}</h2></td>
                      <td><p class="mb-2"> {{ showCategoriesNames(sellerPart.parts) }} </p></td>
                    </tr>
                    <tr>
                      <td><h2 class="h6 font-weight-medium mb-0">{{ $t('sellerParts.Parts Status') }}</h2></td>
                      <td><p class="mb-2">{{ partConditions[sellerPart.condition][getFieldByLocale()] }}</p></td>
                    </tr>
                    <tr>
                      <td><h2 class="h6 font-weight-medium mb-0 pb-2">{{ $t('sellerParts.Year of Manufacture') }}</h2></td>
                      <td><p class="mb-2">( {{ sellerPart.from_year }} - {{ sellerPart.to_year }} )</p></td>
                    </tr>
                    </tbody>
                  </table>
                  <div class="card-footer bg-transparent border-0 text-center">
                    <ul class="list-inline mb-0 d-flex justify-content-around">
                      <li class="list-inline-item px-4"> <a data-toggle="modal" data-target="#edit_form" @click.prevent="showEditForm(sellerPart)" class="text-in-grey font-weight-600 font-size-14 text-in-grey">{{ $t('common.Edit') }}</a>
                      </li>
                      <li class="list-inline-item px-4"> <a @click.prevent="deleteSellerParts(sellerPart)" class="text-in-grey font-weight-600 font-size-14 text-in-grey">{{ $t('common.Delete') }}</a>
                      </li>
                    </ul>
                  </div>
                  <div></div>
                </div>
              </div>
              <!-- End Blog Card -->
            <div class="col-md-12">
              <nav aria-label="..." class="pagination-navigation mt-8" v-if="pageCount">
                <paginate
                  v-model="page"
                  :page-count="pageCount"
                  :page-range="10"
                  :margin-pages="1"
                  :click-handler="clickCallback"
                  :prev-text="$t('common.Prev')"
                  :next-text="$t('common.Next')"
                  :prev-class="isRtl ? 'page-item ml-auto' : 'page-item mr-auto'"
                  :prev-link-class="'page-link'"
                  :next-class="isRtl ? 'page-item mr-auto' : 'page-item ml-auto'"
                  :next-link-class="'page-link'"
                  :container-class="'pagination'"
                  :page-class="'page-item'"
                  :page-link-class="'dark-link-text page-link border-0 rounded'">
                </paginate>
              </nav>
            </div>
          </div>
        </div>
      </section>

      <Modal :modal_id="'edit_form'">
        <section>
          <div class="container" style="position: relative;">
            <Spinner v-if="isSpinnerShownOnEditModal"/>
            <div class="row justify-content-center mb-5 text-center">
              <div class="col-12 col-lg-8">
                <div> <span class="badge badge-primary-soft p-2">
                          <img class="total-parts-icon" src="@/assets/images/other/total-parts.png">
                      </span>
                  <h2 class="mt-4 mb-0">{{ $t('sellerParts.Edit Part Category') }}</h2>
                </div>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-12 col-lg-10">
                  <form class="row" method="post">
                    <div class="messages"></div>
                    <div class="form-group col-md-6">
                      <label for="car_category_id">{{ $t('cars.Car Category') }}</label>
                      <select id="car_category_id" v-model="partCategory.car_category_id" class="form-control" disabled>
                        <option value="0" disabled>{{ $t('cars.Car Category') }}</option>
                        <option v-for="category in carCategories" :key="category.id" :value="category.id">{{ category[getFieldByLocale()] }}</option>
                      </select>
                      <div v-if="!error.car_category_id" class="help-block with-errors"></div>
                      <div class="error-message-block">{{ error.car_category_id }}</div>
                    </div>
                    <div class="form-group col-md-6">
                      <label for="brand_id">{{ $t('cars.Brand') }}</label>
                      <select id="brand_id" v-model="partCategory.brand_id"  class="form-control" disabled>
                        <option value="0" disabled>{{ $t('cars.Brand') }}</option>
                        <option v-for="brand in brands" :key="brand.id" :value="brand.id">{{ brand[getFieldByLocale()] }}</option>
                      </select>
                      <div v-if="!error.brand_id" class="help-block with-errors"></div>
                      <div class="error-message-block">{{ error.brand_id }}</div>
                    </div>
                    <div class="form-group col-md-6">
                      <label for="model_ids">{{ $t('cars.Model') }}</label>
                      <multiselect id="model_ids" disabled v-model="partCategory.model_ids" :options="models" :multiple="true" :selectLabel="''" :selectedLabel="''" :deselectLabel="''" :close-on-select="false" :clear-on-select="false" :preserve-search="true" :placeholder="$t('cars.Model')" label="name" track-by="name" :preselect-first="true">
                        <template slot="selection" slot-scope="{ values, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.length }} {{ $t('sellerParts.options selected') }}</span></template>
                      </multiselect>
                      <div v-if="!error.model_ids" class="help-block with-errors"></div>
                      <div class="error-message-block">{{ error.model_ids }}</div>
                    </div>
                    <div class="form-group col-md-6">
                      <label for="part_category_ids">{{ $t('sellerParts.Part Categories') }}</label>
                      <multiselect id="part_category_ids" :disabled="isPartsCategoriesFieldDisabled" v-model="partCategory.part_category_ids" :options="partCategories" @remove="onRemove" :group-values="'categories'" :group-label="'label'" :group-select="true" :selectGroupLabel="''" :multiple="true" :selectLabel="''" :selectedLabel="''" :deselectLabel="''" :close-on-select="false" :clear-on-select="false" :preserve-search="true" :placeholder="$t('sellerParts.Part Categories')" label="name" track-by="name" :preselect-first="true">
                        <template slot="selection" slot-scope="{ values, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.length }} {{ $t('sellerParts.options selected') }}</span></template>
                      </multiselect>
                      <div v-if="!error.part_category_ids" class="help-block with-errors"></div>
                      <div class="error-message-block">{{ error.part_category_ids }}</div>
                    </div>
                    <div class="form-group col-md-6">
                      <label for="from_year">{{ $t('sellerParts.From Year') }}</label>
                      <select id="from_year" v-model="partCategory.from_year" class="form-control">
                        <option value="0" disabled>{{ $t('sellerParts.From Year') }}</option>
                        <option v-for="year in (new Date().getFullYear() - 1968)" :key="year" :value="new Date().getFullYear() + 2 - year">{{ new Date().getFullYear() + 2 - year  }}</option>
                      </select>
                      <div v-if="!error.from_year" class="help-block with-errors"></div>
                      <div class="error-message-block">{{ error.from_year }}</div>
                    </div>
                    <div class="form-group col-md-6">
                      <label for="to_year">{{ $t('sellerParts.To Year') }}</label>
                      <select id="to_year" v-model="partCategory.to_year" class="form-control" :disabled="isToYearFieldDisabled">
                        <option value="0" disabled>{{ $t('sellerParts.To Year') }}</option>
                        <option v-for="year in (new Date().getFullYear() - getFromYear + 2)" :key="year" :value="new Date().getFullYear() + 2 - year">{{ new Date().getFullYear() + 2 - year  }}</option>
                      </select>
                      <div v-if="!error.to_year" class="help-block with-errors"></div>
                      <div class="error-message-block">{{ error.to_year }}</div>
                    </div>
                    <div class="form-group col-md-6">
                      <label for="condition">{{ $t('sellerParts.Parts Status') }}</label>
                      <select id="condition" v-model="partCategory.condition" class="form-control">
                        <option value="0" disabled>{{ $t('sellerParts.Parts Status') }}</option>
                        <option v-for="condition in partConditions" :key="condition.id" :value="condition.id">{{ condition[getFieldByLocale()] }}</option>
                      </select>
                      <div v-if="!error.condition" class="help-block with-errors"></div>
                      <div class="error-message-block">{{ error.condition }}</div>
                    </div>

                    <div class="col-md-12 text-center mt-4">
                      <button class="btn btn-primary" @click.prevent="editSellerPart()" :disabled="!isReadyForSubmission"><span>{{ $t('common.Update') }}</span>
                      </button>
                    </div>
                  </form>
            </div>
            </div>
          </div>
        </section>
      </Modal>

    </div>
  </div>
</template>

<script>
import Paginate from 'vuejs-paginate'
import Modal from '../../components/Modal.vue'
import Multiselect from 'vue-multiselect'
import { sellerPartMixin } from '../../mixins/sellerPartMixin'
import SellerPartSearch from '../../components/SellerPartSearch'

export default {
  name: 'sellerParts',
  data () {
    return {
      page: null,
      pageCount: null,
      sellerParts: null,
      isSpinnerShown: false,
      searchVisible: false,
      searchData: null
    }
  },
  components: {
    SellerPartSearch,
    Paginate,
    Modal,
    Multiselect
  },
  mixins: [sellerPartMixin],
  mounted () {
    if (!this.$route.query.page) {
      this.page = 1
    } else {
      this.page = parseInt(this.$route.query.page)
    }

    this.getSellerParts(this.page)
  },
  computed: {
    getFromYear () {
      if (this.partCategory) {
        return this.partCategory.from_year && parseInt(this.partCategory.from_year) !== 0 ? this.partCategory.from_year : new Date().getFullYear()
      } else {
        return new Date().getFullYear()
      }
    }
  },
  methods: {
    getSellerParts (page) {
      if (!page) {
        this.page = 1
        page = 1
      }
      console.log(this.searchData)
      this.isSpinnerShown = true
      this.$store.dispatch('getSellerParts', { page: page, search: this.searchData })
        .then(res => {
          this.isSpinnerShown = false

          if (res.data.current_page > res.data.last_page) {
            this.page = res.data.last_page
            this.clickCallback(res.data.last_page)
          }

          this.sellerParts = res.data.data
          this.pageCount = res.data.last_page
        }).catch(error => {
          this.isSpinnerShown = false
          console.log(error)
        })
    },
    clickCallback (pageNum) {
      this.$router.push({ name: 'partCategories', query: { page: pageNum } })
      this.getSellerParts(pageNum)
    },
    showCategoriesNames (partsArray) {
      var partsNames = partsArray.map((element) => {
        return element.part_category.name
      })

      if (this.isRtl) {
        return partsNames.join(' ,')
      } else {
        return partsNames.join(', ')
      }
    },
    showEditForm (sellerPart) {
      this.isSpinnerShownOnEditModal = true
      this.isFormShownForTheFirstTime = true

      this.getBrandsByCarCategory(sellerPart.model.brand_category.car_category_id)
      this.getPartCategoriesByCarCategory(sellerPart.model.brand_category.car_category_id)
      this.getModelsByCategoryBrand(sellerPart.model.brand_category.car_category_id, sellerPart.model.brand_category.brand_id)

      this.partCategory.id = sellerPart.id
      this.partCategory.car_category_id = sellerPart.model.brand_category.car_category_id
      this.partCategory.brand_id = sellerPart.model.brand_category.brand_id
      this.partCategory.model_ids = [{ name: sellerPart.model.name, id: sellerPart.model.id }]
      this.partCategory.part_category_ids = this.getPartCategoriesSelectionArray(sellerPart.parts)
      this.partCategory.from_year = sellerPart.from_year
      this.partCategory.to_year = sellerPart.to_year
      this.partCategory.condition = sellerPart.condition
    },
    editSellerPart () {
      this.error = {
        car_category_id: null,
        brand_id: null,
        model_ids: null,
        part_category_ids: null,
        from_year: null,
        to_year: null,
        condition: null
      }

      var finalModelIdsArray = this.partCategory.model_ids.map((element) => {
        return element.id
      })

      var finalPartCategoryIdsArray = this.partCategory.part_category_ids.map((element) => {
        return element.id
      })

      this.isSpinnerShownOnEditModal = true
      this.$store.dispatch('updateSellerPart', {
        id: this.partCategory.id,
        model_id: finalModelIdsArray[0],
        part_category_ids: finalPartCategoryIdsArray,
        from_year: this.partCategory.from_year,
        to_year: this.partCategory.to_year,
        condition: this.partCategory.condition
      })
        .then(res => {
          this.isSpinnerShownOnEditModal = false
          document.getElementById('modal-close-btn').click()
          this.getSellerParts(this.page)
        }).catch(error => {
          this.isSpinnerShownOnEditModal = false
          if (error.response) {
            for (var field in error.response.data[0]) {
              this.error[field] = this.joinArrayByComma(error.response.data[0][field])
            }
          }
        })
    },
    toggleSearch () {
      this.searchVisible = !this.searchVisible
      if (!this.searchVisible) {
        this.searchData = { }
        this.getSellerParts()
      }
    },
    deleteSellerParts (sellerPart) {
      const swalWithBootstrapButtons = this.$swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger'
        },
        buttonsStyling: false
      })

      swalWithBootstrapButtons.fire({
        title: this.$t('common.Are you sure?'),
        text: this.$t('sellerParts.You are about to delete part categories!'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('common.Yes, Delete it'),
        cancelButtonText: this.$t('common.No, cancel'),
        reverseButtons: false
      }).then((result) => {
        if (result.isConfirmed) {
          this.isSpinnerShown = true
          this.$store.dispatch('deleteSellerParts', sellerPart.model.id)
            .then(res => {
              this.isSpinnerShown = false
              this.getSellerParts(this.page)

              swalWithBootstrapButtons.fire({
                title: this.$t('common.Deleted!'),
                text: this.$t('sellerParts.The part categories have been deleted'),
                icon: 'success',
                confirmButtonText: this.$t('common.Ok')
              })
            }).catch(error => {
              this.isSpinnerShown = false
              console.log(error)
            })
        }
      })
    }
  }
}
</script>

<style scoped>
tr {
  border: 2px solid  #f9c421;
  vertical-align: baseline;
}
td {
  padding: 5px 9px 0px;
}
h6 {
  padding: 8px 15px!important;
}
.card-footer {
  border-bottom: 2px solid  #f9c421!important;
  border-left: 2px solid  #f9c421!important;
  border-right: 2px solid  #f9c421!important;
  background-color: rgba(249, 196, 33, 0.65)!important;
}
.card {
  border: none;
 }
</style>
