<template>
  <div class="mt-2 upload-image-conditions">
    <div class="mb-0">
      <div class="d-flex align-items-center">
        <div class="badge-primary-soft rounded p-1 svg-wrapper">
          <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check">
            <polyline points="20 6 9 17 4 12"></polyline>
          </svg>
        </div>
        <p class="mb-0 font-size-14" :class="isRtl ? 'mr-2': 'ml-2'">{{ $t('cars.Image should not be bigger than 10MB') }}</p>
      </div>
    </div>
    <div class="mb-0">
      <div class="d-flex align-items-center">
        <div class="badge-primary-soft rounded p-1 svg-wrapper">
          <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check">
            <polyline points="20 6 9 17 4 12"></polyline>
          </svg>
        </div>
        <p class="mb-0 font-size-14" :class="isRtl ? 'mr-2': 'ml-2'">{{ $t('cars.Image should be in jpg or jpeg format') }}</p>
      </div>
    </div>
    <div>
      <div class="d-flex align-items-center" >
        <div class="badge-primary-soft rounded p-1 svg-wrapper">
          <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check">
            <polyline points="20 6 9 17 4 12"></polyline>
          </svg>
        </div>
        <p class="mb-0 font-size-14" :class="isRtl ? 'mr-2': 'ml-2'">{{ $t('cars.Up to 4 images can be uploaded') }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UploadedImageConditions'
}
</script>

<style scoped>
  .upload-image-conditions .badge-primary-soft{
    color: #f9c421;
  }
  .upload-image-conditions .svg-wrapper {
    line-height: 1;
    background: unset;
  }
  .upload-image-conditions .svg-wrapper svg {
    width: 1rem;
    height: 1rem;
  }
</style>
