<template>
  <div class="d-flex justify-content-center align-items-center pt-3 pb-10">
    <div :class="$route.name === 'Cart' ? 'active-icon': ''" class="cart-icon-container d-flex justify-content-center align-items-center"><img class="cart-icon" src="@/assets/images/other/shopping-cart.png"  alt="..."></div>
    <div :class="$route.name === 'Cart' ? 'active-icon': ''" class="cart-line"></div>
    <div :class="$route.name === 'Checkout' && !isHyperpayShown ? 'active-icon': ''" class="cart-icon-container d-flex justify-content-center align-items-center"><img class="cart-icon" src="@/assets/images/other/address.png"  alt="..."></div>
    <div :class="$route.name === 'Checkout' && !isHyperpayShown ? 'active-icon': ''" class="cart-line"></div>
    <div :class="$route.name === 'Checkout' && isHyperpayShown ? 'active-icon': ''" class="cart-icon-container d-flex justify-content-center align-items-center"><img class="cart-icon" src="@/assets/images/other/card.png"  alt="..."></div>
  </div>
</template>

<script>
export default {
  name: 'CartIcons',
  props: ['isHyperpayShown']
}
</script>

<style>
  .cart-icon {
    height: 3rem;
  }
  .cart-icon-container {
    width: 7rem;
    height: 7rem;
    border-radius: 50%;
    border: 10px solid #f9c421;
    opacity: .5;
  }
  .cart-line {
    width: 14%;
    height: 10px;
    background-color: #f9c421;
    opacity: .5;
  }
  .active-icon {
    opacity: 1!important;
  }
  @media only screen and  (max-width: 992px) {
    .cart-icon {
      height: 2.5rem;
    }
    .cart-icon-container {
      width: 5rem;
      height: 5rem;
      border: 8px solid #f9c421;
    }
    .cart-line {
      width: 14%;
      height: 8px;
      background-color: #f9c421;
      opacity: .5;
    }
  }
</style>
