<template>
  <div class="row mx-1 seller-offer">
    <div class="col-sm-12 order-lg-12">
      <Spinner v-if="isSpinnerShown"/>

      <div class="part-item mb-5 px-3" v-for="offer in offers" :key="offer.id">
        <div class="row align-items-center">
          <div class="col-12 p-0">
            <div class="row part-item-content">
              <div  class="part-item--img">
                <a data-toggle="modal" data-target="#view_modal" @click.prevent="showOffer(offer)">
                  <div class="part-img">
                    <img v-if="offer.images === null || offer.images === ''" src="/assets/images/product/01.jpg" alt="">
                    <img v-else :src="getFirstImage(offer.images)" alt="">
                  </div>
                </a>
              </div>
              <div class="part-item--desc d-flex flex-wrap">
                <div class="part-name-model">
                  <a data-toggle="modal" data-target="#view_modal" @click.prevent="showOffer(offer)">
                    <div class="text-in-grey part-name">{{ offer.request.part_name }} <small class="text-in-amber font-size-14" :class="isRtl ? 'mr-2' : 'ml-2'">({{ partConditions[offer.condition][getFieldByLocale()] }})</small></div>
                  </a>
                  <div class="text-muted">{{ offer.brand[getFieldByLocale()] }} {{ offer.model[getFieldByLocale()] }} - {{ offer.request.car.model_year }}</div>
                  <div class="text-muted">{{ offer.request.car.vin }}</div>
                </div>
                <div>
                  <div v-if="offer.origin && offer.condition === newPartCondition" class="d-flex flex-nowrap">
                    <div class="part-item-label text-muted">{{ $t('sellerParts.Part Origin') }}</div>
                    <div class="part-item-value text-in-amber">{{ offer.origin[getFieldByLocale()] }}</div>
                  </div>
                  <div class="d-flex flex-nowrap">
                    <div class="part-item-label text-muted" :class="isRtl ? '' : 'part-item-lv-w-140' ">{{ $t('sellerParts.Warranty Duration') }}</div>
                    <div class="part-item-value text-in-amber">{{ offer.warranty_duration ? offer.warranty_duration : 0 }} {{ $t('sellerParts.day(s)') }}</div>
                  </div>
                  <div class="d-flex flex-nowrap">
                    <div class="part-item-label text-muted">{{ $t('requests.Request No') }}</div>
                    <div class="part-item-value text-in-amber part-item-lv-w-110">{{ offer.request.id }}</div>
                  </div>
                </div>
                <div>
                  <div class="d-flex flex-nowrap">
                    <div class="part-item-label text-muted">{{ $t('sellerParts.Part Price') }}</div>
                    <div class="part-item-value text-in-amber font-weight-600 font-size-16 part-item-lv-w-110">{{ offer.part_price }} {{ getCurrency () }} <small>{{ $t('sellerParts.The price includes the added tax') }}</small></div>
                  </div>
                  <div class="d-flex flex-nowrap">
                    <div class="part-item-label text-muted">{{ $t('sellerParts.Buyer City') }}</div>
                    <div class="part-item-value text-in-amber part-item-lv-w-110">{{ offer.buyer.city[getFieldByLocale()] }}</div>
                  </div>
                </div>
                <div>
                  <div class="d-flex flex-nowrap">
                    <div class="part-item-label text-muted">{{ $t('offers.Offer Status') }}</div>
                    <div class="part-item-value text-in-amber part-item-lv-w-120">{{ offerStatus[offer.status][getFieldByLocale()] }} {{offer.order_status_label ? (' - ' + offer.order_status_label) : '' }}</div>
                  </div>
                  <div class="d-flex flex-nowrap" v-if="offer.status === offerPendingSellerPricing">
                    <div class="part-item-label text-muted">{{ $t('requests.Request Date') }}</div>
                    <div v-if="offer.request" class="part-item-value text-in-amber part-item-lv-w-120">{{ offer.request.created_at.split('T')[0].split('-').reverse().join('/') }}</div>
                  </div>
                  <div class="d-flex flex-nowrap" v-else>
                    <div class="part-item-label text-muted">{{ $t('offers.Offer Date') }}</div>
                    <div v-if="offer.submitted_at" class="part-item-value text-in-amber part-item-lv-w-120">{{ offer.submitted_at.split('T')[0].split('-').reverse().join('/') }}</div>
                  </div>
                </div>
                <div class="w-100">
                  <div class="d-flex flex-nowrap">
                    <div class="part-item-label part-item-details-label text-muted">{{ $t('requests.Request Details') }}</div>
                    <div class="part-item-value text-in-amber one-line-text">{{ offer.request.details }}</div>
                  </div>
                </div>
                <div class="w-100">
                  <div class="d-flex flex-nowrap">
                    <div class="part-item-label part-item-details-label text-muted">{{ $t('sellerParts.Seller Note') }}</div>
                    <div class="part-item-value text-in-amber one-line-text">{{ offer.seller_note }}</div>
                  </div>
                </div>
                <div class="mt-3 d-flex flex-nowrap part-item-buttons">
                  <div class="d-flex flex-nowrap align-self-center">
                    <a v-if="parseInt(offer.status) === offerPendingSellerPricing || parseInt(offer.status) === offerPendingBuyerApprove" data-toggle="modal" data-target="#edit_form" @click.prevent="showEditForm(offer)" class="btn-amber text-in-grey" :class="parseInt(offer.status) === offerPendingSellerPricing ? 'create-offer-btn' : ''"><i class="nav-icon fa fa-cogs" :class="isRtl? 'ml-1':'mr-1'"></i>{{ parseInt(offer.status) === offerPendingSellerPricing ? $t('offers.Create Offer') : $t('common.Edit') }}</a>
                  </div>
                  <div class="d-flex flex-nowrap align-self-center">
                      <a :id="'view-details-btn-' + offer.id" data-toggle="modal" data-target="#view_modal" @click.prevent="showOffer(offer)" class="btn-grey text-in-grey d-block">{{ $t('common.Details') }}</a>
                  </div>
                  <div class="d-flex flex-nowrap align-self-center">
                    <a v-if="parseInt(offer.status) === offerPendingSellerPricing || parseInt(offer.status) === offerPendingBuyerApprove" @click.prevent="cancelOffer(offer)" class="btn-grey text-in-grey">{{ parseInt(offer.status) === offerPendingSellerPricing ? $t('offers.Ignore Part Request') : $t('common.Cancel') }}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal :modal_id="'view_modal'">
        <section v-if="offer" class="gallery-text-section">
          <div class="container">
            <div class="col-12">
              <div class="row justify-content-center">
                <div class="col-11">
                  <ul class="nav nav-tabs p-0" id="myTab" role="tablist">
                    <li class="nav-item">
                      <a class="nav-link active" @click="showImages(offer.images)" id="offer-tab" data-toggle="tab" href="#offer-content" role="tab" aria-controls="offer-content" aria-selected="true">{{ $t('offers.Seller offer') }}</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" @click="showImages(offer.request.images)" id="request-tab" data-toggle="tab" href="#request-content" role="tab" aria-controls="request-content" aria-selected="false">{{ $t('requests.Request') }}</a>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="tab-content mt-3" id="myTabContent">
                  <div class="tab-pane fade show active" id="offer-content" role="tabpanel" aria-labelledby="offer-tab">
                    <div class="row">
                      <div class="col-md-6" >
                          <div id="image-gallery"  class="carousel slide" data-ride="carousel">
                            <div v-if="offer.images" class="carousel-inner">
                              <div class="carousel-item " v-for="(image, i) in getAllImages(offer.images)" :key="i" @click="index = i" :class="{'active': i===0}" >
                                  <img :src="image" alt="" width="1100" height="500">
                              </div>
                            </div>
                            <div v-else class="carousel-inner">
                              <div class="carousel-item active" @click="index = 0">
                                  <img  src="/assets/images/product/01.jpg" alt="" width="1100" height="500">
                              </div>
                            </div>
                            <ul v-if="offer.images" class="carousel-indicators">
                              <li data-target="#image-gallery" v-for="(image, i) in getAllImages(offer.images)" :class="{'active': i===0}" :key="i" :data-slide-to="i" >
                                <img :src="image" alt="" width="1100" height="500" style="width: 24%;">
                              </li>
                            </ul>
                            <ul v-else class="carousel-indicators">
                              <li class="active" data-target="#image-gallery">
                                <img src="/assets/images/product/01.jpg" alt="" width="1100" height="500" style="width: 24%;">
                              </li>
                            </ul>
                          </div>
                          <div class="modal-buttons d-flex flex-nowrap part-item-buttons">
                            <div class="d-flex flex-nowrap align-self-center">
                              <a v-if="parseInt(offer.status) === offerPendingSellerPricing || parseInt(offer.status) === offerPendingBuyerApprove" data-dismiss="modal" data-toggle="modal" data-target="#edit_form" @click.prevent="showEditForm(offer)" class="btn-amber text-in-grey" :class="parseInt(offer.status) === offerPendingSellerPricing ? 'create-offer-btn' : ''"><i class="nav-icon fa fa-cogs" :class="isRtl? 'ml-1':'mr-1'"></i>{{ parseInt(offer.status) === offerPendingSellerPricing ? $t('offers.Create Offer') : $t('common.Edit') }}</a>
                            </div>
                          </div>
                      </div>

                      <div class="col-md-6">
                        <h2 class="title">{{ $t('offers.Offer Details') }}</h2>
                        <p class="font-weight-600 font-size-20 mb-0 text-in-grey custom-line-height">{{ offer.request.part_name }} <small class="text-in-amber font-size-14" :class="isRtl ? 'mr-2' : 'ml-2'">({{ partConditions[offer.condition][getFieldByLocale()] }})</small></p>
                        <p>{{ offer.brand[getFieldByLocale()] }} {{ offer.model[getFieldByLocale()] }} - {{ offer.request.car.model_year }}<br>{{ offer.request.car.vin }}</p>
                        <div class="row mt-4">
                          <div class="col-md-6 col-sm-6 col-xs-12">
                            <ul class="cases-meta list-unstyled text-muted">
                              <li class="mb-3"><span class="text-dark"> {{ $t('sellerParts.Part Category') }}: </span><br> {{ offer.part_category[getFieldByLocale()] }}</li>
                              <li class="mb-3" v-if="offer.condition === newPartCondition && offer.origin"><span class="text-dark"> {{ $t('sellerParts.Part Origin') }}: </span><br> {{ offer.origin[getFieldByLocale()] }}</li>
                              <li class="mb-3"><span class="text-dark"> {{ $t('sellerParts.Buyer City') }}: </span><br> {{ offer.buyer.city[getFieldByLocale()] }}</li>
                            </ul>
                          </div>
                          <div class="col-md-6 col-sm-6 col-xs-12">
                            <ul class="cases-meta list-unstyled text-muted">
                              <li class="mb-3" v-if="offer.condition === usedPartCondition" ><span class="text-dark"> {{ $t('sellerParts.Disassemble Cost') }}: </span><br> {{ offer.disassemble_cost }} {{ getCurrency () }}</li>
                              <li class="mb-3"><span class="text-dark"> {{ $t('sellerParts.Part Price') }}: </span><br> {{ offer.part_price }} {{ getCurrency () }}<br><small>{{ $t('sellerParts.The price includes the added tax') }}</small></li>
                              <li class="mb-3"><span class="text-dark"> {{ $t('sellerParts.Warranty Duration') }}: </span><br> {{ offer.warranty_duration ? offer.warranty_duration : 0 }} {{ $t('sellerParts.day(s)') }}</li>
                            </ul>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6 col-sm-6 col-xs-12">
                              <div class="mb-3 text-muted"><span class="text-dark"> {{ $t('sellerParts.Seller') }}: </span><br> {{ offer.seller.seller_code }}</div>
                          </div>
                          <div class="col-md-6 col-sm-6 col-xs-12">
                              <div class="mb-3 text-muted"><span class="text-dark"> {{ $t('sellerParts.Seller Place') }}: </span><br> {{ offer.seller.city[getFieldByLocale()] }}</div>
                          </div>
                          <div class="col-12">
                              <div class="mb-3 text-muted"><span class="text-dark"> {{ $t('sellerParts.Seller Note') }}: </span><br> {{ offer.seller_note }}</div>
                          </div>
                        </div>
                        <span class="product-price text-in-red">{{ $t('offers.Offer Status') }}:  {{ offerStatus[offer.status][getFieldByLocale()] }} {{offer.order_status_label ? (' - ' + offer.order_status_label) : '' }}</span>
                      </div>
                    </div>
                  </div>

                  <div class="tab-pane fade" id="request-content" role="tabpanel" aria-labelledby="request-tab">
                    <div class="row">
                      <div class="col-md-6" >
                          <div id="request-image-gallery"  class="carousel slide" data-ride="carousel">
                            <div v-if="offer.request.images" class="carousel-inner">
                              <div class="carousel-item " v-for="(image, i) in getAllImages(offer.request.images)" :key="i" @click="index = i" :class="{'active': i===0}" >
                                  <img :src="image" alt="" width="1100" height="500">
                              </div>
                            </div>
                            <div v-else class="carousel-inner">
                              <div class="carousel-item active" @click="index = 0">
                                  <img  src="/assets/images/product/01.jpg" alt="" width="1100" height="500">
                              </div>
                            </div>
                            <ul v-if="offer.request.images" class="carousel-indicators">
                              <li data-target="#request-image-gallery" v-for="(image, i) in getAllImages(offer.request.images)" :class="{'active': i===0}" :key="i" :data-slide-to="i" >
                                <img :src="image" alt="" width="1100" height="500" style="width: 24%;">
                              </li>
                            </ul>
                            <ul v-else class="carousel-indicators">
                              <li class="active" data-target="#request-image-gallery">
                                <img src="/assets/images/product/01.jpg" alt="" width="1100" height="500" style="width: 24%;">
                              </li>
                            </ul>
                          </div>
                      </div>

                      <div class="col-md-6 d-flex flex-column">
                        <h2 class="title">{{ $t('requests.Request Details') }}</h2>
                        <div class="row mt-4">
                          <div class="col-md-6 col-sm-6 col-xs-12">
                            <ul class="cases-meta list-unstyled text-muted">
                              <li class="mb-3"><span class="text-dark"> {{ $t('requests.Part Name') }}: </span><br> <span class="font-weight-600 font-size-20">{{ offer.request.part_name }}</span> <small class="text-in-amber font-size-14" :class="isRtl ? 'mr-2' : 'ml-2'">({{ partConditions[offer.condition][getFieldByLocale()] }})</small></li>
                              <li class="mb-3"><span class="text-dark"> {{ $t('cars.Car Brand') }}: </span><br> {{ offer.brand[getFieldByLocale()] }}</li>
                              <li class="mb-3"><span class="text-dark"> {{ $t('cars.Car Model') }}: </span><br> {{ offer.model[getFieldByLocale()] + ' ('+ offer.request.car.model_year +')' }}</li>
                            </ul>
                          </div>
                          <div class="col-md-6 col-sm-6 col-xs-12">
                            <ul class="cases-meta list-unstyled text-muted">
                              <li class="mb-3"><span class="text-dark"> {{ $t('requests.Part Category') }}: </span><br> {{ offer.part_category[getFieldByLocale()] }}</li>
                              <li class="mb-3"><span class="text-dark"> {{ $t('cars.Chasse No') }}: </span><br> <span class="vin-value">{{ offer.request.car.vin }}</span></li>
                            </ul>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12">
                              <div class="mb-3 text-muted"><span class="text-dark"> {{ $t('cars.Car Specifications') }}: </span><br> {{ offer.request.car.details }}</div>
                          </div>
                          <div class="col-12">
                              <div class="mb-3 text-muted"><span class="text-dark"> {{ $t('requests.Request Details') }}: </span><br> {{ offer.request.details }}</div>
                          </div>
                        </div>
                        <span class="product-price text-in-red">{{ $t('requests.Request Status') }}:  {{ requestStatus[offer.request.status][getFieldByLocale()] }}</span>
                        <div class="row mt-auto">
                          <div class="col-md-6" :class="isRtl ? 'mr-auto' : 'ml-auto'">
                            <ul class="cases-meta list-unstyled text-muted mt-3">
                              <li class="mb-3"><span class="text-dark"> {{ $t('requests.Request No') }}: </span> {{ offer.request.id }}</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
            </div>

          </div>
        </section>
      </Modal>

      <Modal :modal_id="'edit_form'">
        <section>
          <div class="container" style="position: relative;">
            <Spinner v-if="isSpinnerShownOnEditModal"/>
            <div class="row justify-content-center mb-5 text-center">
              <div class="col-12 col-lg-8">
                <div> <span class="badge badge-primary-soft p-2">
                          <img class="my-2 mx-1 sent-offers-icon" src="@/assets/images/other/sent-offers.png">
                      </span>
                  <h2 class="mt-4 mb-0">{{ parseInt(offerEditObject.status) === offerPendingSellerPricing ? $t('offers.Create Offer') : $t('offers.Edit Offer') }}</h2>
                  <p class="lead mb-0">{{ $t('offers.Please enter the price and the requested part info for the sent offer') }}</p>
                </div>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-12 col-lg-10">
                  <form class="row" method="post">
                    <div class="messages"></div>
                    <div class="form-group col-md-6">
                      <label for="condition">{{ $t('sellerParts.Part Status') }}</label>
                      <select id="condition" v-model="partCondition" class="form-control" disabled>
                        <option value="0" disabled>{{ $t('sellerParts.Part Status') }}</option>
                        <option v-for="condition in partConditions" :key="condition.id" :value="condition.id">{{ condition[getFieldByLocale()] }}</option>
                      </select>
                    </div>
                    <div v-if="partCondition === newPartCondition" class="form-group col-md-6">
                      <label for="origin_id">{{ $t('sellerParts.Part Origin') }}</label>
                      <select id="origin_id" v-model="offerEditObject.origin_id" class="form-control">
                        <option value="0" disabled>{{ $t('sellerParts.Part Origin') }}</option>
                        <option v-for="partOrigin in partOrigins" :key="partOrigin.id" :value="partOrigin.id">{{ partOrigin[getFieldByLocale()] }}</option>
                      </select>
                      <div v-if="!error.origin_id" class="help-block with-errors"></div>
                      <div class="error-message-block">{{ error.origin_id }}</div>
                    </div>
                    <div v-if="partCondition === usedPartCondition" class="form-group col-md-6">
                      <label for="disassemble_cost">{{ $t('sellerParts.Disassemble Cost') }} <small>({{ getCurrency() }})</small></label>
                      <input id="disassemble_cost" v-model="offerEditObject.disassemble_cost" data-parsley-type="number" type="text" name="disassemble_cost" class="form-control" :placeholder="$t('sellerParts.Disassemble Cost')">
                      <div v-if="!error.disassemble_cost" class="help-block with-errors"></div>
                      <div class="error-message-block">{{ error.disassemble_cost }}</div>
                    </div>
                    <div class="form-group col-md-6">
                      <label for="part_price">{{ $t('sellerParts.Part Price') }} <small>({{ getCurrency() }})</small> <small>{{ $t('sellerParts.The price includes the added tax') }}</small></label>
                      <input id="part_price" v-model="offerEditObject.part_price" data-parsley-type="number" type="text" name="part_price" class="form-control" :placeholder="$t('sellerParts.Part Price')">
                      <div v-if="!error.part_price" class="help-block with-errors"></div>
                      <div class="error-message-block">{{ error.part_price }}</div>
                    </div>
                    <div class="form-group col-md-6">
                      <label for="warranty_duration">{{ $t('sellerParts.Warranty Duration') }} <small>({{ $t('sellerParts.day(s)') }})</small></label>
                      <input id="warranty_duration" v-model="offerEditObject.warranty_duration" type="number" name="warranty_duration" min=0 oninput="validity.valid||(value='');" class="form-control" :placeholder="$t('sellerParts.Warranty Duration')">
                      <div v-if="!error.warranty_duration" class="help-block with-errors"></div>
                      <div class="error-message-block">{{ error.warranty_duration }}</div>
                    </div>
                    <div class="form-group col-md-6">
                      <label for="seller_note">{{ $t('sellerParts.Seller Note') }}</label>
                      <textarea id="seller_note" v-model="offerEditObject.seller_note" name="seller_note" class="form-control" :placeholder="$t('sellerParts.Seller Note')" rows="3"></textarea>
                      <div v-if="!error.seller_note" class="help-block with-errors"></div>
                      <div class="error-message-block">{{ error.seller_note }}</div>
                    </div>
                    <div class="form-group col-md-12">
                      <label for="dropzone">{{ $t('sellerParts.Part Images') }}</label>
                      <ImageUpload :imageType="imageType" :errorMessage="error.images" :isEditImages="true" :stringOfImages="offerEditObject.images" :maxFilesUpload="maxFilesUpload" :clearDropzone="clearDropzone" @isfileUplaodingChange="setIsfileUplaoding" @addedImagesChange="setAddedImages" @deletedImagesChange="setDeletedImages" />

                      <div class="row">
                        <div class="col-md-12">
                          <UploadedImageConditions />
                        </div>
                      </div>

                    </div>
                    <div class="col-12" v-if="parseInt(offerEditObject.status) === offerPendingSellerPricing && offer.request.standard_part_id">
                      <label><input type="checkbox" v-model="offerEditObject.save_price"> {{ $t('offers.Save Price')}}</label>
                      <div class="row" v-if="offerEditObject.save_price">
                        <div class="form-group col-12 ">
                          <div class="form-control text-center font-size-20">
                            {{offer.brand.name}} - {{offer.model.name}}
                          </div>
                        </div>
                        <div class="form-group col-12 col-lg-6">
                          <label for="save_from_year">{{ $t('sellerParts.From Year') }}</label>
                          <select id="save_from_year" v-model="offerEditObject.save_from_year" class="form-control"  :class="{'border-danger':error.save_from_year}">
                            <option value="0" disabled>{{ $t('sellerParts.From Year') }}</option>
                            <option v-for="year in years" :key="year" :value="year">{{ year }}</option>
                          </select>
                          <div class="error-message-block">{{ error.save_from_year }}</div>
                        </div>
                        <div class="form-group col-12 col-lg-6">
                          <label for="save_to_year">{{ $t('sellerParts.To Year') }}</label>
                          <select id="save_to_year" v-model="offerEditObject.save_to_year" class="form-control"  :class="{'border-danger':error.save_to_year}">
                            <option value="0" disabled>{{ $t('sellerParts.To Year') }}</option>
                            <option v-for="year in years" :key="year" :value="year">{{ year  }}</option>
                          </select>
                          <div class="error-message-block">{{ error.save_to_year }}</div>
                        </div>
                        <div class="form-group col-12 col-lg-6">
                          <label for="save_part_no">{{ $t('sellerParts.Part Number') }}</label>
                          <input id="save_part_no" v-model="offerEditObject.save_part_no" type="text" name="save_part_no" class="form-control" :placeholder="$t('sellerParts.Part Number')">
                          <div class="error-message-block">{{ error.save_part_no }}</div>
                        </div>
                        <div class="form-group col-12 col-lg-6">
                          <label for="save_stock">{{ $t('sellerParts.Stock') }}</label>
                          <input id="save_stock" v-model="offerEditObject.save_stock" type="number" min="1" name="save_stock" class="form-control" :placeholder="$t('sellerParts.Stock')">
                          <div class="error-message-block">{{ error.save_stock }}</div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12 text-center mt-4">
                      <button class="btn btn-primary" @click.prevent="editOffer()" :disabled="!isReadyForSubmission || isfileUplaoding"><span>{{ parseInt(offerEditObject.status) === offerPendingSellerPricing ? $t('offers.Send Offer') : $t('common.Update') }}</span>
                      </button>
                    </div>
                  </form>
            </div>
            </div>
          </div>
        </section>
      </Modal>

      <CoolLightBox
        :items="items"
        :index="index"
        @close="index = null">
      </CoolLightBox>

      <nav aria-label="..." class="pagination-navigation mt-8" v-if="pageCount">
        <paginate
          v-model="page"
          :page-count="pageCount"
          :page-range="10"
          :margin-pages="1"
          :click-handler="clickCallback"
          :prev-text="$t('common.Prev')"
          :next-text="$t('common.Next')"
          :prev-class="isRtl ? 'page-item ml-auto' : 'page-item mr-auto'"
          :prev-link-class="'page-link'"
          :next-class="isRtl ? 'page-item mr-auto' : 'page-item ml-auto'"
          :next-link-class="'page-link'"
          :container-class="'pagination'"
          :page-class="'page-item'"
          :page-link-class="'dark-link-text page-link border-0 rounded'">
        </paginate>
      </nav>

    </div>
  </div>
</template>

<script>
import Paginate from 'vuejs-paginate'
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import Modal from '../../components/Modal.vue'
import UploadedImageConditions from '../../components/UploadedImageConditions.vue'
import ImageUpload from '../../components/ImageUpload'

export default {
  name: 'OfferList',
  props: ['offers', 'pageCount'],
  data () {
    return {
      page: null,
      offer: null,
      items: [],
      index: null,
      isSpinnerShown: false,
      isSpinnerShownOnEditModal: false,

      clearDropzone: false,
      isfileUplaoding: false,
      imageType: null,
      maxFilesUpload: null,

      partOrigins: null,
      partCondition: null,
      error: {
        origin_id: null,
        disassemble_cost: null,
        warranty_duration: null,
        part_price: null,
        seller_note: null,
        images: null,
        save_part_no: null,
        save_stock: null,
        save_from_year: null,
        save_to_year: null
      },
      offerEditObject: {
        id: null,
        origin_id: null,
        disassemble_cost: null,
        warranty_duration: null,
        part_price: null,
        seller_note: null,
        images: null,
        added_images: '',
        deleted_images: '',
        save_price: false,
        save_part_no: null,
        save_stock: null,
        save_from_year: null,
        save_to_year: null
      }
    }
  },
  updated () {
    if (this.$route.query.page) {
      this.page = parseInt(this.$route.query.page)
    }
  },
  mounted () {
    this.imageType = 'offers'

    if (this.$route.query.page) {
      this.page = parseInt(this.$route.query.page)
    } else {
      this.page = 1
    }
    this.getPartOrigins()

    window.$(document).on('hidden.bs.modal', function (event) {
      if (window.$('.modal:visible').length) {
        window.$('body').addClass('modal-open')
      }
    })
  },
  components: {
    Paginate,
    CoolLightBox,
    Modal,
    ImageUpload,
    UploadedImageConditions
  },
  computed: {
    isReadyForSubmission () {
      var isCommonFieldsValuable = this.offerEditObject.part_price !== null && this.offerEditObject.part_price !== '' && this.offerEditObject.warranty_duration !== null && this.offerEditObject.warranty_duration !== ''
      var priceSaveValid = this.offerEditObject.save_price ? (this.offerEditObject.save_to_year && this.offerEditObject.save_from_year && this.offerEditObject.save_part_no && this.offerEditObject.save_stock) : true
      if (this.partCondition === this.newPartCondition) {
        return parseInt(this.offerEditObject.origin_id) && isCommonFieldsValuable && priceSaveValid
      } else {
        return this.offerEditObject.disassemble_cost !== null && this.offerEditObject.disassemble_cost !== '' && isCommonFieldsValuable && priceSaveValid
      }
    },
    years () {
      const year = new Date().getFullYear() + 1
      return Array.from({ length: year - 1970 }, (value, index) => year - index)
    }
  },
  methods: {
    setIsfileUplaoding (value) {
      this.isfileUplaoding = value
    },
    setAddedImages (value) {
      this.offerEditObject.added_images = value.join(',')
    },
    setDeletedImages (value) {
      this.offerEditObject.deleted_images = value.join(',')
    },
    getPartOrigins () {
      this.isSpinnerShown = true
      this.$store.dispatch('getPartOrigins')
        .then(res => {
          this.isSpinnerShown = false
          this.partOrigins = res.data.data
        }).catch(error => {
          this.isSpinnerShown = false
          console.log(error)
        })
    },
    cancelOffer (offer) {
      const swalWithBootstrapButtons = this.$swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger'
        },
        buttonsStyling: false
      })
      swalWithBootstrapButtons.fire({
        title: this.$t('common.Are you sure?'),
        text: offer.status === this.offerPendingSellerPricing ? this.$t('offers.You are about to ignore part request!') : this.$t('offers.You are about to cancel an offer!'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: offer.status === this.offerPendingSellerPricing ? this.$t('offers.Yes, ignore it') : this.$t('offers.Yes, cancel it'),
        cancelButtonText: this.$t('common.No'),
        reverseButtons: false
      }).then((result) => {
        if (result.isConfirmed) {
          this.isSpinnerShown = true
          this.$store.dispatch('cancelOffer', offer.id)
            .then(res => {
              this.isSpinnerShown = false
              this.$emit('getCurrentPageOffers')

              swalWithBootstrapButtons.fire({
                title: offer.status === this.offerPendingSellerPricing ? this.$t('offers.Ignored!') : this.$t('offers.Cancelled!'),
                text: offer.status === this.offerPendingSellerPricing ? this.$t('offers.The part request has been ignored') : this.$t('offers.The offer has been cancelled'),
                icon: 'success',
                confirmButtonText: this.$t('common.Ok')
              })
            }).catch(error => {
              this.isSpinnerShown = false
              console.log(error)
            })
        }
      })
    },
    clickCallback (pageNum) {
      this.$emit('clickCallback', pageNum)
    },
    showOffer (offer) {
      window.$('a[href="#offer-content"]').tab('show')

      this.items = []
      this.index = null

      this.offer = offer
      this.showImages(offer.images)
    },
    showImages (images) {
      this.items = []
      this.index = null

      if (images) {
        images.split(',').forEach((element, index) => {
          this.items.push(element)
        })
      } else {
        this.items.push('/assets/images/product/01.jpg')
      }
    },
    showEditForm (offer) {
      this.clearDropzone = !this.clearDropzone

      this.offer = offer
      this.partCondition = parseInt(offer.condition)
      this.offerEditObject.id = offer.id
      this.offerEditObject.status = offer.status
      this.offerEditObject.origin_id = offer.origin_id ? offer.origin_id : 0
      this.offerEditObject.part_price = offer.part_price
      this.offerEditObject.seller_note = offer.seller_note
      this.offerEditObject.warranty_duration = (offer.warranty_duration === 0 && parseInt(offer.status) === this.offerPendingSellerPricing) ? 1 : offer.warranty_duration
      this.offerEditObject.disassemble_cost = offer.disassemble_cost
      this.offerEditObject.images = offer.images
      this.offerEditObject.added_images = ''
      this.offerEditObject.deleted_images = ''
      this.offerEditObject.save_price = ''
      this.offerEditObject.save_stock = ''
      this.offerEditObject.save_part_no = ''
      this.offerEditObject.save_from_year = ''
      this.offerEditObject.save_to_year = ''

      if (!offer.images) {
        this.maxFilesUpload = 4
      } else if (offer.images.split(',').length < 4) {
        this.maxFilesUpload = 4 - offer.images.split(',').length
      } else {
        this.maxFilesUpload = 0
      }
    },
    editOffer () {
      this.error = {
        origin_id: null,
        disassemble_cost: null,
        warranty_duration: null,
        part_price: null,
        seller_note: null,
        images: null,
        save_stock: null,
        save_part_no: null,
        save_from_year: null,
        save_to_year: null
      }

      this.isSpinnerShownOnEditModal = true
      this.$store.dispatch('updateOffer', this.offerEditObject)
        .then(res => {
          this.isSpinnerShownOnEditModal = false
          document.getElementById('modal-close-btn').click()
          this.$emit('getCurrentPageOffers')
        }).catch(error => {
          this.isSpinnerShownOnEditModal = false
          if (error.response) {
            for (var field in error.response.data[0]) {
              this.error[field] = this.joinArrayByComma(error.response.data[0][field])
            }
          }
          this.$swal.fire({
            title: this.$t('seller.not active'),
            text: '',
            icon: 'error',
            confirmButtonText: this.$t('common.Ok')
          })
        })
    }
  }
}
</script>

<style scoped>
  .product-img {
    height: 21rem;
  }
  .product-img img{
    width: 100%!important;
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
  }
  .rtl .pagination{
    direction: rtl;
  }
  @media (max-width: 992px) {
    .pagination-navigation {
      overflow-x: scroll;
    }
  }
</style>
