import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import i18n from './i18n'
import VueSweetalert2 from 'vue-sweetalert2'
import Notifications from 'vue-notification'
import velocity from 'velocity-animate'
import VueGtag from 'vue-gtag'

// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css'

Vue.config.productionTip = false

Vue.use(Notifications, { velocity })

Vue.use(VueSweetalert2)

Vue.component('Spinner',
  () => import('./components/Spinner.vue')
)

Vue.component('HeroSection',
  () => import('./components/HeroSection.vue')
)

Vue.mixin({
  data: function () {
    return {
      carCategories: {
        10: {
          id: 10,
          en_name: 'Car',
          ar_name: 'سيارة'
        },
        11: {
          id: 11,
          en_name: 'Truck',
          ar_name: 'شاحنة'
        },
        1: {
          id: 1,
          en_name: 'Truck Extensions',
          ar_name: 'ملحقات الشاحنات'
        }
      },
      newPartRequestConditions: {
        2: {
          id: 2,
          en_name: 'New',
          ar_name: 'جديد'
        },
        3: {
          id: 3,
          en_name: 'Used',
          ar_name: 'مستعمل'
        }
      },
      partConditions: {
        2: {
          id: 2,
          en_name: 'New',
          ar_name: 'جديد'
        },
        3: {
          id: 3,
          en_name: 'Used',
          ar_name: 'مستعمل'
        },
        4: {
          id: 4,
          en_name: 'New or Used',
          ar_name: 'جديد او مستعمل'
        }
      },
      newPartCondition: 2,
      usedPartCondition: 3,
      newOrUsedPartCondition: 4,
      requestStatus: {
        0: {
          id: 0,
          en_name: 'NOT Submitted',
          ar_name: 'الطلب غير مرسل'
        },
        1: {
          id: 1,
          en_name: 'Submitted and waiting for offers',
          ar_name: 'الطلب بانتظار التسعيرات'
        },
        2: {
          id: 2,
          en_name: 'Requested part not found',
          ar_name: 'الطلب غير متوفر'
        },
        3: {
          id: 3,
          en_name: 'Requested part was cancelled',
          ar_name: 'الطلب ملغى'
        },
        4: {
          id: 4,
          en_name: 'Requested part was archived',
          ar_name: 'الطلب مؤرشف'
        },
        5: {
          id: 5,
          en_name: 'Request Part is unclear',
          ar_name: 'الطلب غير واضح'
        }
      },
      paymentMethods: {
        1: {
          id: 1,
          en_name: 'Bank Transfer',
          ar_name: 'حوالة بنكية'
        },
        3: {
          id: 3,
          en_name: 'Online Payment',
          ar_name: 'الدفع عبر النت'
        }
      },
      paymentMethodsArr: [
        {
          id: 3,
          en_name: 'Online Payment',
          ar_name: 'الدفع عبر النت'
        },
        {
          id: 1,
          en_name: 'Bank Transfer',
          ar_name: 'حوالة بنكية'
        }
      ],
      bankTransferId: 1,
      onlinePaymentId: 3,
      deliveryTypes: {
        1: {
          id: 1,
          en_name: 'From Sparaat Warehouse',
          ar_name: 'من مستودع سبيرات'
        },
        2: {
          id: 2,
          en_name: 'Delivery to buyer',
          ar_name: 'التوصيل إلى المشتري'
        }
      },
      requestNotFoundStatus: 2,
      requestCancelledStatus: 3,
      requestArchivedStatus: 4,
      requestUnclearStatus: 5,
      offerStatus: {
        0: {
          id: 0,
          en_name: 'Deleted',
          ar_name: 'محذوف',
          slug: 'deleted',
          notification_type: ['offer_deleted'],
          label: 'offers.Deleted Offers'
        },
        1: {
          id: 1,
          en_name: 'Waiting for seller pricing',
          ar_name: 'بانتظار تسعير البائع',
          slug: 'pendingSellerPricing',
          notification_type: ['new_request'],
          label: 'offers.Pending Seller Pricing'
        },
        2: {
          id: 2,
          en_name: 'Waiting for buyer approval',
          ar_name: 'بانتظار موافقة المشتري',
          slug: 'pendingBuyerApproval',
          notification_type: ['new_offer', 'new_request_auto_priced'],
          label: 'offers.Pending Buyer Approve'
        },
        3: {
          id: 3,
          en_name: 'Ignored',
          ar_name: 'تم التجاهل',
          slug: 'ignored',
          notification_type: ['offer_ignored'],
          label: 'offers.Ignored Offers'
        },
        4: {
          id: 4,
          en_name: 'Cancelled',
          ar_name: 'ملغى',
          slug: 'canceled',
          notification_type: ['offer_cancelled'],
          label: 'offers.Cancelled Offers'
        },
        5: {
          id: 5,
          en_name: 'Buyer took another offer',
          ar_name: 'أخذ المشتري تسعيرةً أخرى',
          slug: 'tookAnother',
          notification_type: ['another_offer'],
          label: 'offers.Buyer Took Another Offer'
        },
        6: {
          id: 6,
          en_name: 'Accepted',
          ar_name: 'مقبول',
          slug: 'accepted',
          notification_type: ['offer_accepted', 'paid', 'delivered', 'expired'],
          label: 'offers.Accepted Offers'
        }
      },
      offerDeleted: 0,
      offerPendingSellerPricing: 1,
      offerPendingBuyerApprove: 2,
      offerIgnored: 3,
      offerCanceled: 4,
      buyerTookAnotherOffer: 5,
      OfferAccepted: 6,
      sellerType: 1,
      buyerType: 2,
      banks: [
        {
          bank: 'البنك الأهلي',
          name: 'مؤسسة محمد غالب قطان التجارية',
          no: '10400000225405',
          iban: 'SA0310000010400000225405'
        },
        {
          bank: 'بنك الرياض',
          name: 'مؤسسة محمد غالب قطان التجارية',
          no: '1901196279940',
          iban: 'SA2520000001901196279940'
        },
        {
          bank: 'بنك الراجحي',
          name: 'مؤسسة محمد غالب قطان التجارية',
          no: '453608010435024',
          iban: 'SA2380000453608010435024'
        }
      ]
    }
  },
  computed: {
    isRtl () {
      return this.$store.getters.rtlLangs.includes(this.$i18n.locale)
    }
  },
  methods: {
    removeIdFromRoute (page = null) {
      const query = Object.assign({}, this.$route.query)
      delete query.id
      if (page) {
        Object.assign(query, { page: page })
      }
      this.$router.replace({ query })
    },
    getFieldByLocale () {
      switch (this.$i18n.locale) {
        case 'en':
          return 'en_name'
        case 'ar':
          return 'ar_name'
        case 'tr':
          return 'tr_name'
        case 'ur':
          return 'ur_name'
      }
    },
    getCurrency () {
      switch (this.$i18n.locale) {
        case 'en':
          return 'SAR'
        case 'ar':
          return 'ر.س'
        case 'tr':
          return 'SAR'
        case 'ur':
          return 'SAR'
        default:
          return 'SAR'
      }
    },
    getFirstImage (images) {
      return images.split(',')[0]
    },
    getAllImages (images) {
      return images.split(',')
    },
    joinArrayByComma (arrayOfItems) {
      if (Array.isArray(arrayOfItems)) {
        if (this.isRtl) {
          return arrayOfItems.join(' ,')
        } else {
          return arrayOfItems.join(', ')
        }
      }
    }
  }

})

Vue.use(VueGtag, {
  config: {
    id: 'AW-682811407',
    params: {
      send_page_view: false
    }
  }
})

router.beforeEach((to, from, next) => {
  let language = to.params.lang

  if (!language) {
    language = 'en'
  }
  // set the current language for i18n.
  i18n.locale = language
  localStorage.setItem('locale', language)
  // Vue.prototype.$http.defaults.headers.lang = i18n.locale
  next()
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
