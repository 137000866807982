<template>
  <div class="row ">
    <div :class="itemClass">
      <label for="car_category_id">{{ $t('cars.Car Category') }}</label>
      <select name="car_category_id" id="car_category_id"
              @change="getBrandsAndPartCategoriesByCarCategory(model.car_category_id)"
              class="form-control form-control-sm" v-model="model.car_category_id">
        <option value="">{{ $t('cars.Car Category') }}</option>
        <option v-for="category in carCategories" :key="category.id" :value="category.id">
          {{ category[getFieldByLocale()] }}
        </option>
      </select>
    </div>
    <div :class="itemClass">
      <label for="brand_id">{{ $t('cars.Brand') }}</label>
      <select id="brand_id" v-model="model.brand_id" class="form-control form-control-sm"
              :disabled="!model.car_category_id || !isBrandLoaded"
              @change="getModelsByCategoryBrand(model.car_category_id, model.brand_id)">
        <option value="">{{ $t('cars.Brand') }}</option>
        <option v-for="brand in brands" :key="brand.id" :value="brand.id">{{ brand.name }}</option>
      </select>
    </div>
    <div :class="itemClass">
      <label for="model_id">{{ $t('cars.Model') }}</label>
      <select id="model_id" v-model="model.model_id" class="form-control form-control-sm"
              :disabled="!model.brand_id || !isBrandLoaded || !isModelLoaded">
        <option value="">{{ $t('cars.Model') }}</option>
        <option v-for="carModel in models" :key="carModel.id" :value="carModel.id">{{ carModel.name }}</option>
      </select>
    </div>

    <div :class="itemClass">
      <label for="part_category">{{ $t('sellerParts.Part Category') }}</label>
      <select id="part_category" v-model="model.part_category_id"
              :disabled="!model.car_category_id || !isPartCategoriesLoaded"
              class="form-control form-control-sm">
        <option value="">{{ $t('sellerParts.Part Category') }}</option>
        <option v-for="partCategory in partCategories" :key="partCategory.id" :value="partCategory.id">
          {{ partCategory.name }}
        </option>
      </select>
    </div>
    <AutocompletePartName
      :size-class="'col-md-12'"
      :triggerPartNameOnFocus="triggerPartNameOnFocus"
      :isPartNameFieldDisabled="!isPartNameFieldDisabled" :errorMessage="error.standard_part_id"
      :standardPartInfo="standardPartInfo" :isStandardPartIdRequested="true"
      :clearData="clearStandardPartData" @standardPartIdChanged="setStandardPartId"/>

    <div :class="itemClass">
      <label for="part_origin_id">{{ $t('sellerParts.Part Origin') }}</label>
      <select id="part_origin_id" v-model="model.part_origin_id" class="form-control form-control-sm">
        <option value="">{{ $t('sellerParts.Part Origin') }}</option>
        <option v-for="origin in partOrigins" :key="origin.id" :value="origin.id">{{ origin.name }}</option>
      </select>
    </div>

    <div :class="itemClass">
      <label for="part_status">{{ $t('sellerParts.Part Status') }}</label>
      <select id="part_status" v-model="model.part_status" class="form-control form-control-sm">
        <option value="">{{ $t('sellerParts.Part Status') }}</option>
        <option v-for="condition in [partConditions[newPartCondition],partConditions[usedPartCondition]]"
                :key="condition.id" :value="condition.id">{{ condition[getFieldByLocale()] }}
        </option>
      </select>
    </div>

    <div :class="itemClass" v-if="requestIdVisible">
      <label for="number">{{ $t('sellerParts.Number') }}</label>
      <input id="number" v-model="model.number" class="form-control form-control-sm">
    </div>
  </div>
</template>

<script>

import AutocompletePartName from '@/components/AutocompletePartName'
export default {
  name: 'SellerPartSearch',
  components: { AutocompletePartName },
  props: ['value', 'showPartName', 'dir', 'showRequestId'],
  data () {
    return {
      model: {
        car_category_id: null,
        part_category_id: null,
        part_origin_id: null,
        brand_id: null,
        part_status: null,
        model_id: null,
        standard_part_id: null,
        request_id: null
      },
      brands: [],
      models: [],
      /* new */
      partCategories: [],
      partOrigins: [],
      triggerPartNameOnFocus: true,
      /* new */
      isBrandLoaded: false,
      isPartCategoriesLoaded: false,
      isModelLoaded: false,
      partNameVisible: false,
      requestIdVisible: false,
      itemClass: 'col',
      timeOut: null,
      error: {
        // standard_part_id: false
      },
      clearStandardPartData: false
    }
  },
  computed: {
    isPartNameFieldDisabled () {
      return this.model.part_category_id && this.model.car_category_id
    },
    standardPartInfo () {
      return {
        part_category_id: this.model.part_category_id,
        car_category_id: this.model.car_category_id
      }
    }
  },
  mounted () {
    this.partNameVisible = !!this.showPartName
    this.requestIdVisible = !!this.showRequestId
    this.itemClass = this.dir === 'vertical' ? 'col-md-12 col-sm-4 col-6' : 'col'
    this.getPartOrigins()
  },
  watch: {
    model: {
      deep: true,
      handler (newVal) {
        const that = this
        clearTimeout(this.timeOut)
        this.timeOut = setTimeout(function () {
          console.log('debounceee')
          that.$emit('input', newVal)
          console.log(newVal)
        }, 500)
      }
    }
  },
  methods: {
    getBrandsByCarCategory (carCategoryId) {
      this.brands = []
      this.models = []
      this.isBrandLoaded = false
      this.model.brand_id = null
      this.model.model_id = null
      if (!carCategoryId) {
        return
      }
      this.$store.dispatch('getBrandsByCarCategory', carCategoryId)
        .then(res => {
          this.brands = res.data.data
          this.isBrandLoaded = true
          this.isBrandFieldDisabled = false
        }).catch(error => {
          this.isBrandLoaded = true
          console.log(error)
        })
    },
    getModelsByCategoryBrand (carCategoryId, brandId) {
      this.models = []
      this.model.model_id = null
      this.isModelLoaded = false
      if (!carCategoryId || !brandId) {
        return
      }
      this.$store.dispatch('getModelsByCategoryBrand', {
        car_category_id: carCategoryId,
        brand_id: brandId
      })
        .then(res => {
          this.models = res.data.data.map((element) => {
            return {
              name: element.name,
              id: element.id
            }
          })
          this.isModelLoaded = true
          this.isModelFieldDisabled = false
        }).catch(error => {
          this.isModelLoaded = true
          console.log(error)
        })
    },
    setStandardPartId (standardPartId) {
      this.model.standard_part_id = standardPartId
    },
    getPartOrigins () {
      this.isSpinnerShown = true
      this.$store.dispatch('getPartOrigins')
        .then(res => {
          this.isSpinnerShown = false
          this.partOrigins = res.data.data
        }).catch(error => {
          this.isSpinnerShown = false
          console.log(error)
        })
    },
    getPartCategoriesByCarCategory (carCategoryId) {
      this.isPartCategoriesLoaded = false
      this.$store.dispatch('getPartCategoriesByCarCategory', carCategoryId)
        .then(res => {
          this.partCategories = res.data
          this.isPartCategoryFieldDisabled = false
          this.isPartCategoriesLoaded = true
        }).catch(error => {
          console.log(error.response.data)
        })
    },
    getBrandsAndPartCategoriesByCarCategory (id) {
      this.getBrandsByCarCategory(id)
      this.getPartCategoriesByCarCategory(id)
    }
  }
}
</script>

<style scoped>
.form-control {
  height: 2rem;
}
</style>
