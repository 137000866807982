<template>
<div>
  <!--hero section start-->

    <HeroSection>
      <h1>{{ $t('orders.Purchase Order Details') }}</h1>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb justify-content-center bg-transparent p-0 m-0">
          <li class="breadcrumb-item"><router-link class="text-dark" :to="{ name: 'Dashboard', params: { lang: this.$i18n.locale }}">{{ $t('menu.Home') }}</router-link></li>
          <li class="breadcrumb-item"><router-link class="text-dark" :to="{ name: 'Orders', params: { lang: this.$i18n.locale }}">{{ $t('menu.Orders') }}</router-link></li>
          <li class="breadcrumb-item active text-primary" aria-current="page">{{ $t('orders.Purchase Order Details') }}</li>
        </ol>
      </nav>
    </HeroSection>

    <!--hero section end-->

    <!--body content start-->

    <div class="page-content"  style="position: relative;">
      <Spinner v-if="isSpinnerShown"/>
    <section>
      <div class="container" v-if="order">
        <div class="row mb-8">
          <div class="col-lg-6">
              <div class="col-md-12 p-3 border-in-amber mb-3">
                <span class="text-in-amber">{{ $t('orders.Date') }}: </span><span>{{ order.created_at ? order.created_at.split('T')[0]: '' }}</span>
              </div>
              <div class="col-md-12 p-3 border-in-amber mb-3">
                <span class="text-in-amber">{{ $t('orders.Order Id') }}: </span><span>{{ order.id }}</span>
              </div>
              <div class="col-md-12 p-3 border-in-amber mb-3">
                <span class="text-in-amber">{{ $t('orders.Status') }}: </span><span>{{ order.status_label }}</span>
              </div>
              <div class="col-md-12 p-3 border-in-amber mb-3">
                <span class="text-in-amber">{{ $t('orders.Payment Method') }}: </span><span v-if="order.payment_method">{{ paymentMethods[order.payment_method][getFieldByLocale()] }}</span>
              </div>
              <div class="col-md-12 p-3 border-in-amber">
                <span class="text-in-amber">{{ $t('orders.Address') }}: </span><span>{{ order.shipping_address }}</span>
              </div>
          </div>
          <div class="col-lg-6 pl-5 mt-5 mt-lg-0">
            <div class="row justify-content-end">
              <div class="col-md-7" style="margin: 0 15px 0 0;">
                <CartTotals :cartTotals="order" :btnRoute="null" :btnLabel="null">
                  <div class="row mb-3" v-for="(offer, i) in offers" :key="i">
                    <div class="col-6">
                      <span class="text-in-amber">{{ $t('orders.Item') }} {{ i + 1 }}:</span>
                    </div>
                    <div class="col-6" :class="isRtl ? 'text-left' : 'text-right'">
                      <strong class="text-black">{{ offer.total_price + ' ' + getCurrency() }}</strong>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12 border-bottom-in-amber mb-5" :class="isRtl ? 'text-left' : 'text-right'">
                    </div>
                  </div>
                </CartTotals>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="table-responsive">
              <table class="cart-table table table-bordered">
                <thead>
                  <tr>
                    <th scope="col">{{ $t('cart.Seller') }}</th>
                    <th scope="col">{{ $t('cart.Part') }}</th>
                    <th scope="col">{{ $t('cart.Price') }}</th>
                    <th scope="col">{{ $t('cart.Disassemble Cost') }}</th>
                    <th scope="col">{{ $t('cart.Total') }}</th>
                    <th scope="col">{{ $t('cart.Shipping Fee') }}</th>
                    <th scope="col">{{ $t('sales.Shipping') }}</th>
                    <th scope="col" v-if="canRemove">{{ $t('cart.Remove') }}</th>
                  </tr>
                </thead>
                <tbody v-for="(sellerOffers, j) in offersBySellers" :key="j">
                  <tr v-for="(offer, i) in sellerOffers" :key="i">
                    <td v-if="i === 0" :rowspan="sellerOffers.length">
                      <h5 class="mb-0">
                        {{ offer.seller.seller_code }}
                        <span v-if="offer.seller.city"> {{ offer.seller.city.name }}</span>
                      </h5>
                    </td>
                    <td>
                      <div class="media align-items-center">
                        <a data-toggle="modal" data-target="#view_modal" @click.prevent="showOffer(offer)">
                          <img v-if="!offer.images" class="img-fluid mb-2 mb-lg-0 part-image" :class="isRtl ? 'ml-lg-2' : 'mr-lg-2'" src="/assets/images/product/01.jpg" alt="">
                          <img v-else class="img-fluid mb-2 mb-lg-0 part-image" :class="isRtl ? 'ml-lg-2' : 'mr-lg-2'" :src="getFirstImage(offer.images)" alt="">
                        </a>
                        <a data-toggle="modal" data-target="#view_modal" @click.prevent="showOffer(offer)">
                        <div class="media-body" :class="isRtl ? 'text-right': 'text-left'">
                          <h5 class="mb-0 font-weight-500 text-muted">{{ offer.request ? offer.request.part_name : offer.request_part_name }}</h5>
                        </div>
                        </a>
                      </div>
                    </td>

                    <td>
                      <h5 class="mb-0">{{ offer.sparaat_price + ' ' + getCurrency() }}</h5>
                    </td>
                    <td>
                      <h5 class="mb-0">{{ offer.disassemble_cost + ' ' + getCurrency() }}</h5>
                    </td>
                    <td>
                      <h5 class="mb-0">{{ offer.total_price + ' ' + getCurrency() }}<br><small>{{ $t('sellerParts.The price includes the added tax') }}</small></h5>
                    </td>
                    <td v-if="i === 0" :rowspan="sellerOffers.length">
                      <div class="d-flex justify-content-center align-items-center">
                        {{ order.shipping_fee / order.sellers_count }} {{ getCurrency() }}
                      </div>
                    </td>
                    <td>
                      <a v-if="offer.tracking_reference" :href="offer.tracking_url" target="_blank">{{ offer.tracking_reference }}</a>
                    </td>
                    <td v-if="canRemove">
                      <button  @click.prevent="removePartFromOrder(order.id, offer.id)" class="btn btn-primary btn-sm"><i class="ti-close"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>

    <OfferViewModal :offer="offer" />

    </div>

    <!--body content end-->

</div>
</template>

<script>
import OfferViewModal from '../../components/offer/OfferViewModal.vue'
import CartTotals from '../../components/CartTotals.vue'

export default {
  name: 'ViewOrder',
  computed: {
    canRemove: function () {
      return this.order.payment_method === this.bankTransferId && this.order.status === 'pending_payment'
    }
  },
  data () {
    return {
      order: null,
      offers: null,
      offersBySellers: {},
      isSpinnerShown: false,
      offer: null
    }
  },
  components: {
    OfferViewModal,
    CartTotals
  },
  mounted () {
    this.getOrderDetails()
  },
  methods: {
    showOffer (offer) {
      this.offer = offer
    },
    getOrderDetails () {
      this.isSpinnerShown = true
      this.$store.dispatch('getOrderDetails', this.$route.params.id)
        .then(res => {
          this.isSpinnerShown = false
          this.order = res.data.data
          this.offers = res.data.data.offers
          if (this.offersBySellers.length) {
            this.offersBySellers.splice(0, this.offersBySellers.length)
          }
          this.offersBySellers = {}
          res.data.data.offers.map((elem, i) => {
            if (!this.offersBySellers[elem.seller_id]) {
              this.offersBySellers[elem.seller_id] = []
            }
            this.offersBySellers[elem.seller_id].push(elem)
          })
        }).catch(error => {
          this.isSpinnerShown = false
          console.log(error)
        })
    },
    removePartFromOrder (orderId, offerId) {
      const swalWithBootstrapButtons = this.$swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger'
        },
        buttonsStyling: false
      })

      swalWithBootstrapButtons.fire({
        title: this.$t('common.Are you sure?'),
        text: this.$t('cart.You are about to remove a part!'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('common.Yes, remove it'),
        cancelButtonText: this.$t('common.No, cancel'),
        reverseButtons: false
      }).then((result) => {
        if (result.isConfirmed) {
          document.getElementsByClassName('close')[0].click()
          this.isSpinnerShown = true
          this.$store.dispatch('removePartFromOrder', {
            orderId: orderId,
            offerId: offerId
          })
            .then(res => {
              this.isSpinnerShown = false

              this.order = null
              this.offers = null
              this.getOrderDetails()
              swalWithBootstrapButtons.fire({
                title: this.$t('common.Removed!'),
                text: this.$t('cart.The part has been removed'),
                icon: 'success',
                confirmButtonText: this.$t('common.Ok')
              })
            }).catch(error => {
              this.isSpinnerShown = false
              console.log(error)
            })
        }
      })
    }
  }
}
</script>
