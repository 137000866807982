<template>
  <div>
    <BuyerDashboard v-if="authBuyer" />
    <SellerDashboard v-else />
  </div>
</template>

<script>
import BuyerDashboard from '../../components/dashboards/BuyerDashboard.vue'
import SellerDashboard from '../../components/dashboards/SellerDashboard.vue'

export default {
  name: 'Dashboards',
  data () {
    return {

    }
  },
  components: {
    BuyerDashboard,
    SellerDashboard
  },
  computed: {
    authBuyer () {
      return this.$store.getters.isAuthenticatedBuyer
    },
    authSeller () {
      return this.$store.getters.isAuthenticatedSeller
    }
  }
}
</script>
