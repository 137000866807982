<template>
      <div class="container" v-if="isShown">
        <div class="row justify-content-center mb-5 text-center">
          <div v-if="parseInt(number) !== 0" class="col-12">
            <div class="row justify-content-center">
              <div class="col-12 col-lg-10">
                <button  @click="removeCurrentRequest" class="close">×</button>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-8">
            <div> <span class="badge badge-primary-soft p-2">
                      <img class="request-icon" src="@/assets/images/other/dashboard1.png">
                  </span>
              <p class="lead mt-4 mb-0">{{ $t('requests.Please enter the part info that you need for your car') }}</p>
              <p class="warning-message mb-0">{{ $t('requests.* Please enter the information of one part only and select it from the list or write it down') }}</p>
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-12 col-lg-10">
              <form class="row" method="post">
                <div class="messages"></div>
                <AutocompletePartName :isPartNameFieldDisabled="isPartNameFieldDisabled" :errorMessage="error.part_name"
                                      :standardPartInfo="standardPartInfo" :isStandardPartIdRequested="false"
                                      @partNameChanged="setPartName" @enablePartCategoryField="enablePartCategoryField" :show-part-category="true" v-model="selectedStandardPart"/>
                <div class="form-group col-md-6">
                  <label for="part_category_id">{{ $t('requests.Part Category') }}</label>
                  <select id="part_category_id" v-model="request.part_category_id" class="form-control" :disabled="isPartNameFieldDisabled || isCategoryFieldDisabled">
                    <option value="0" disabled>{{ $t('requests.Part Category') }}</option>
                    <option v-for="partCategory in partCategories" :key="partCategory.id" :value="partCategory.id">{{ partCategory[localeField] }}</option>
                  </select>
                  <div v-if="!error.part_category_id" class="help-block with-errors"></div>
                  <div class="error-message-block">{{ error.part_category_id }}</div>
                </div>
                <div class="form-group col-md-6">
                  <label >{{ $t('requests.Part Status') }}</label>
                  <div class="row">
                    <div class="form-check col-6" v-for="condition in newPartRequestConditions" :key="condition.id">

                      <label   :for="'status'+condition.id+'_'+number"><input  type="radio" v-model="request.condition" :id="'status'+condition.id+'_'+number" :value="condition.id"> {{ condition[localeField] }}</label>
                    </div>
                  </div>
                  <div v-if="!error.condition" class="help-block with-errors"></div>
                  <div class="error-message-block">{{ error.condition }}</div>
                </div>
                <div class="form-group col-md-6">
                  <label for="details">{{ $t('requests.Part Details') }}</label>
                  <textarea id="details" v-model="request.details" name="details" class="form-control" :placeholder="$t('requests.Please enter Request Details')" rows="3"></textarea>
                  <div v-if="!error.details" class="help-block with-errors"></div>
                  <div class="error-message-block">{{ error.details }}</div>
                </div>
                <div class="form-group col-md-12">
                  <label for="dropzone-container1">{{ $t('requests.Part Images') }}</label>
                  <ImageUpload :imageType="imageType" :errorMessage="error.images" :isEditImages="false" :placeholderMessage="$t('requests.Please add part images to make the pricing process easier for the seller')" @isfileUplaodingChange="setIsfileUplaoding" @addedImagesChange="setAddedImages" />

                </div>
              </form>
          </div>
        </div>
      </div>
</template>

<script>
import ImageUpload from '../../components/ImageUpload'
import AutocompletePartName from '../../components/AutocompletePartName'

export default {
  name: 'RequestFrom',
  components: {
    ImageUpload,
    AutocompletePartName
  },
  props: ['partCategories', 'car', 'value', 'error', 'localeField', 'number', 'isShown', 'isfileUplaoding'],
  data () {
    return {
      results: null,
      isOpen: false,
      isLoading: false,
      arrowCounter: -1,
      request: {},
      imageType: null,
      selectedStandardPart: null,
      isCategoryFieldDisabled: false
    }
  },
  watch: {
    car (newVal, oldVal) {
      if (!newVal) {
        this.request.car_id = null
        return
      }
      this.request.car_id = newVal.id
    },
    selectedStandardPart (newVal, oldVal) {
      this.request.part_category_id = newVal.part_category_id
      this.request.standard_part_id = newVal.id
      this.$emit('input', this.request)

      this.isCategoryFieldDisabled = true
    },
    request: {
      handler: function (newVal, oldVal) {
        this.$emit('input', this.request)
      },
      deep: true
    }
  },
  mounted () {
    this.imageType = 'requests'
    if (this.car) {
      this.request.car_id = this.car.id
    }
    document.addEventListener('click', this.handleClickOutside)
  },
  destroyed () {
    document.removeEventListener('click', this.handleClickOutside)
  },
  computed: {
    standardPartInfo () {
      return {
        part_category_id: null, // this.request.part_category_id,
        car_category_id: this.car ? this.car.car_category_id : ''
      }
    },
    isPartNameFieldDisabled () {
      return !this.car
    }
  },
  methods: {
    setIsfileUplaoding (value) {
      if (value) {
        this.$emit('fileUploadingStarted')
      } else {
        this.$emit('fileUploadingFinished')
      }
    },
    setAddedImages (value) {
      this.request.images = value.join(',')
      this.$emit('input', this.request)
    },
    setPartName (partName) {
      this.request.part_name = partName
      this.$emit('input', this.request)
    },
    handleClickOutside (evt) {
      if (this.$el.contains(evt.target)) {
        this.isOpen = false
        this.arrowCounter = -1
      }
    },
    removeCurrentRequest () {
      this.$emit('removeCurrentRequest', this.number)
    },
    enablePartCategoryField () {
      this.isCategoryFieldDisabled = false
    }
  }
}
</script>
<style>

[type="radio"]:checked, [type="radio"]:not(:checked){
  position:relative;
  opacity:1;
}

</style>
