<template>
  <div>
    <div class="modal fade" :id="modal_id" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div class="modal-content  amber-border-on-modal">
            <div class="modal-header border-bottom-0">
              <button :id=" modal_id === 'edit_form' ? 'modal-close-btn' : ''" type="button" class="close modal-close-button" data-dismiss="modal">×</button>
            </div>
              <slot></slot>
          </div>
        </div>
      </div>

  </div>
</template>

<script>
export default {
  name: 'Modal',
  props: ['modal_id'],
  created () {
    const unregisterRouterGuard = this.$router.beforeEach((to, from, next) => {
      this.back()
      next()
    })

    this.$once('hook:destroyed', () => {
      unregisterRouterGuard()
    })
  },
  methods: {
    back () {
      document.getElementsByClassName('close')[0].click()
    }
  }
}
</script>

<style scoped>
.gallery-text-section{
  padding: 1rem 0 3rem;
}
.gallery-text-section h2 {
  color: #f9c421;
}
@media (min-width: 992px)
{
  .modal-lg {
    max-width: 1000px;
  }
}
@media (max-width: 992px){
  .modal-lg{
    max-width: 90%;
  }
}
@media (max-width: 576px){
  .modal-lg{
    margin: .5rem auto;
  }
}
#image-gallery{
  display: flex;
    flex-direction: column;
    align-items: center;
}
.carousel-item,.carousel-inner {
  width: 25rem;
  height: 25rem;
  cursor: pointer;
}
.carousel-inner{
  border: 2px solid #e5e5e5;
}
.carousel-inner img {
  width: 100%!important;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
}
.carousel-indicators{
  width: 25rem;
  position: relative;
  margin-top: 0.3rem;
  margin-left: 0;
  margin-right: 0;
  padding: 0!important;
  justify-content: flex-start;
}
.carousel-indicators li{
  width: 6.025rem;
  height: 6.025rem!important;
  opacity: 1;
  display: flex;
  justify-content: center;
  overflow-x: hidden;
  height: auto;
  border: 2px solid #e5e5e5;
  margin-left: 0;
  margin-right: 0;
}
.carousel-indicators li:not(:last-of-type) {
  margin-right: 0.2rem;
}
.rtl .carousel-indicators li:not(:last-of-type) {
  margin-left: 0.2rem;
  margin-right: 0;
}
.carousel-indicators img{
  display: block;
  object-fit: cover;
  width: 100%!important;
  height: 100%;
  object-position: 50% 50%;
}

@media only screen and (max-width: 992px) {
  #image-gallery{
    align-items: flex-start;
  }
  .carousel-indicators li:not(:last-of-type) {
    margin-right: unset;
  }
  .rtl .carousel-indicators li:not(:last-of-type) {
    margin-left: unset;
    margin-right: 0;
  }
  .carousel-item,.carousel-inner{
    width: 100%;
    height: 19rem;
    max-width: 19rem;
  }
  .carousel-indicators {
    width: 100%;
    max-width: 19rem;
  }
  .carousel-indicators li{
      width: 25%;
      height: 4.5rem!important;
      max-width: 4.5rem;
  }
}

</style>
