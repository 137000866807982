<template>
  <section v-if="slides && slides.length > 0">
      <div class="owl-carousel" data-items="1" data-autoplay="true">
        <div v-for="(slide, i) in slides" :key="i" class="item"><img :src="slide"></div>
      </div>
    </section>
</template>

<script>
export default {
  name: 'Slider',
  data () {
    return {
      isSpinnerShown: false,
      slides: null
    }
  },
  mounted () {
    this.isSpinnerShown = true
    this.$store.dispatch('getSlider')
      .then(res => {
        this.slides = res.data.data
        this.isSpinnerShown = false
        window.setTimeout(() => {
          const $carousel = window.$('.owl-carousel')

          $carousel.owlCarousel({
            items: $carousel.data('items'),
            slideBy: $carousel.data('slideby'),
            center: $carousel.data('center'),
            loop: true,
            rtl: true,
            margin: $carousel.data('margin'),
            dots: $carousel.data('dots'),
            nav: $carousel.data('nav'),
            autoplay: $carousel.data('autoplay'),
            autoplayTimeout: $carousel.data('autoplay-timeout'),
            navText: ['<span class="la la-angle-left"><span>', '<span class="la la-angle-right"></span>'],
            responsive: {
              0: { items: $carousel.data('xs-items') ? $carousel.data('xs-items') : 1 },
              576: { items: $carousel.data('sm-items') },
              768: { items: $carousel.data('md-items') },
              1024: { items: $carousel.data('lg-items') },
              1200: { items: $carousel.data('items') }
            }
          })
        }, 0)
      }).catch(error => {
        this.isSpinnerShown = false
        console.log(error)
      })
  }
}
</script>

<style>
  .item img {
    margin: 0 auto;
  }
</style>
