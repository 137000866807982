<template>
  <section>

    <HeroSection>
      <h1>{{ $t('requests.Create New Request') }}</h1>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb justify-content-center bg-transparent p-0 m-0">
          <li class="breadcrumb-item"><router-link class="text-dark" :to="{ name: 'Home', params: { lang: this.$i18n.locale }}">{{ $t('menu.Home') }}</router-link></li>

          <li class="breadcrumb-item active text-primary" v-if="step==='car'"  aria-current="page">{{ $t('cars.Add New Car') }}</li>
          <li class="breadcrumb-item active text-primary" v-if="step==='request'"  aria-current="page">{{ $t('requests.Create New Request') }}</li>
          <li class="breadcrumb-item active text-primary" v-if="step==='signup'"  aria-current="page">{{ $t('loginSignup.Sign Up Buyer') }}</li>
        </ol>
      </nav>
    </HeroSection>
    <div class="position-relative">
      <Spinner v-if="isSpinnerShown"/>
      <BuyerSignupFormOnly  v-if="step==='signup'" v-on:change="onRequestChange" :error="registerErrors" v-model="user" />
      <CarForm v-if="step==='car'" v-on:change="onRequestChange" v-model="car" />
      <div v-if="step==='request'" >
        <div v-for="(request, reqindex) in requests" :key="request.index" >
          <RequestFrom :partCategories="partCategories" :localeField="getFieldByLocale()" v-on:removeCurrentRequest="removeRequest" :number="reqindex"
                       :is-shown="true" v-on:change="onRequestChange" v-model="requests[reqindex]" :car="car" :error="request_errors[reqindex]"/>
        </div>
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-12 col-lg-10">
              <button id="upload-btn" @click.prevent="addAnotherRequest()" class="add-another-request-btn btn btn-link text-dark btn-sm bg-primary-soft m-1 mt-5 mb-3"><span>{{ $t('requests.Add Another Request') }}</span></button>
            </div>
          </div>
        </div>
      </div>
      <div class=" row">
        <div class="col-md-12 text-center mt-4 mb-5">
          <button class="btn btn-primary" @click.prevent="next()" :disabled="!isValid">
            <span v-if="step!=='signup'">{{ $t('common.Next') }}</span>
            <span v-if="step==='signup'">{{ $t('common.Submit') }}</span>
          </button>
        </div>
      </div>
    </div>

  </section>
</template>

<script>
import BuyerSignupFormOnly from '../../components/signup/BuyerSignupFormOnly.vue'
import CarForm from '../../components/car/CarForm'
import RequestFrom from '../../components/request/RequestFrom.vue'

export default {
  name: 'BuyerSignupRequest',
  data () {
    return {
      counter: 1,
      isSpinnerShown: false,
      partCategories: null,
      step: 'car',
      isSignedUp: false,
      codeNotSent: false,
      client_id: null,
      username: null,
      user: {},
      car: {},
      requests: [{}],
      registerErrors: {
        full_name: null,
        username: null,
        password: null,
        mobile: null,
        email: null,
        city_id: null
      },
      request_errors: [{
        condition: null,
        part_name: null,
        details: null,
        part_category_id: null,
        images: null
      }]
    }
  },
  watch: {
    request: function () {
      // console.log(this.requests)
      // console.log(this.request_errors)
    }
  },
  components: {
    CarForm,
    BuyerSignupFormOnly,
    RequestFrom
  },
  computed: {
    requestValid () {
      // eslint-disable-next-line no-unused-vars
      const s = this.counter
      for (const request of this.requests) {
        if (!request.condition || !request.part_name || !request.part_category_id) {
          console.log('invalid request')
          return false
        }
      }
      console.log('valid request')
      return true
    },
    isValid () {
      return (this.step === 'car' && this.car.is_valid) ||
        (this.step === 'signup' && this.user.is_valid) ||
        (this.step === 'request' && this.requestValid)
    }
  },
  methods: {
    next () {
      if (this.step === 'car') {
        this.getPartCategoriesByCarCategory()
        this.step = 'request'
      } else if (this.step === 'request') {
        this.step = 'signup'
      } else if (this.step === 'signup') {
        this.signUp()
      }
    },
    getPartCategoriesByCarCategory () {
      this.isSpinnerShown = true
      this.$store.dispatch('getPartCategoriesByCarCategory', this.car.car_category_id)
        .then(res => {
          this.isSpinnerShown = false
          this.partCategories = res.data
        }).catch(error => {
          this.isSpinnerShown = false
          console.log(error.response.data)
        })
    },
    showVerificationForm (value) {
      this.isSignedUp = true
      this.client_id = value.id
      this.username = value.username

      this.$store.dispatch('requestLoginCode', {
        dial_code: '966',
        mobile: value.mobile
      })
        .then(res => {

        }).catch(error => {
          console.log(error)
        })
    },
    showPasswordLoginForm () {
      this.codeNotSent = true
    },
    onRequestChange () {
    },
    addAnotherRequest () {
      this.requests.push({})
      this.request_errors.push({
        condition: null,
        part_name: null,
        details: null,
        part_category_id: null,
        images: null
      })
      this.counter++
    },
    removeRequest (index) {
      this.requests.splice(index, 1)
      this.request_errors.splice(index, 1)
      setTimeout(() => {
        this.counter++
      }, 200)
    },
    signUp () {
      this.isSpinnerShown = true
      const that = this
      this.$store.dispatch('createRequestRegister', {
        account: this.user,
        car: this.car,
        requests: this.requests
      })
        .then(res => {
          this.$router.push({ name: 'Dashboard', params: { lang: this.$i18n.locale } })
          this.isSpinnerShown = false
        }).catch(error => {
          this.isSpinnerShown = false
          if (error.response) {
            for (var field in error.response.data[0]) {
              const type = field.split('.')[0]
              const fieldName = field.split('.')[1]
              if (type === 'account') {
                that.registerErrors[fieldName] = error.response.data[0][field][0]
              }
            }
          }
        })
    }
  }
}
</script>
