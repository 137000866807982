<template>
<div>
    <div class="modal fade" id="login-form" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header border-bottom-0">
            <button type="button" id="login-form-close-btn"  class="close mt-3 mr-5" data-dismiss="modal">×</button>
          </div>
          <section class="p-0 modal-width">
            <div class="container">
              <div class="row align-items-center justify-content-center">
                <div class="col-12" style="position: relative;">
                  <Spinner v-if="isSpinnerShown"/>
                  <div class="p-5 d-flex justify-content-center align-items-center flex-column">
                    <img class="img-fluid mb-8" src="@/assets/images/footer/logo-white.png" alt="Image">
                    <form class="row" method="post">
                      <div class="col-sm-12">
                        <div class="messages mb-3" v-if="showCodeNotSentMessage && !codeNotSent">{{ $t('loginSignup.Please enter the code we have sent to your phone') }} <br> {{ $t('loginSignup.If you did not recieved the code') }} <span class="continue-to-verify-label mb-3 text-in-amber" @click.prevent="codeNotSent=true">{{ $t('loginSignup.Continue to verify later') }}</span></div>
                      </div>
                      <div class="form-group col-sm-12 mb-0" v-if="!codeNotSent">
                        <label class="text-muted" for="InputName1">{{ $t('loginSignup.Phone Number') }}</label>
                      </div>
                      <div class="col-sm-12">
                        <div class="row dial-phone-number" dir="ltr">
                          <div class="form-group col-sm-6 dial-code" v-if="!codeNotSent">
                            <input type="text" value="+966" class="form-control input-1" readonly>
                            <div class="help-block with-errors"></div>
                          </div>
                          <div class="form-group col-sm-6 phone-number" v-if="!codeNotSent">
                            <!-- TODO: fix the mask mask="599999999"-->
<!--                        <input-mask v-model="phone.mobile" :disabled="mobileFieldDisabled" type="text" class="form-control input-1 text-center" id="mobile-field" :placeholder="$t('loginSignup.Phone number')" required="required" :data-error="$t('loginSignup.Phone number is required')"></input-mask>-->
                            <input v-model="phone.mobile" :disabled="mobileFieldDisabled" type="text" class="form-control input-1 text-center" id="mobile-field" :placeholder="$t('loginSignup.Phone number')" required="required" :data-error="$t('loginSignup.Phone number is required')"/>
                            <div v-if="!error.mobile" class="help-block with-errors"></div>
                            <div class="error-message-block">{{ error.mobile }}</div>
                          </div>
                        </div>
                      </div>
                      <div v-if="isPhoneSubmitted && !codeNotSent" class="form-group col-sm-12 mb-0">
                        <label class="text-muted" for="InputName1">{{ $t('loginSignup.Verification Code') }}</label>
                        <input v-model="codeVerification.code" type="text" maxlength="6" class="form-control input-1" id="verification-code" :placeholder="$t('loginSignup.Verification code')" required="required" :data-error="$t('loginSignup.Verification code is required')">
                        <div v-if="!error.code" class="help-block with-errors"></div>
                        <div class="error-message-block">{{ error.code }}</div>
                      </div>
                      <div v-if="codeNotSent" class="form-group col-sm-12">
                        <label class="text-muted" for="InputName1">{{ $t('loginSignup.Username') }}</label>
                        <input v-model="userPassword.username" type="text" class="form-control input-1" id="username-field" :placeholder="$t('loginSignup.Username')" required="required" :data-error="$t('loginSignup.Username is required')">
                        <div v-if="!error.username" class="help-block with-errors"></div>
                        <div class="error-message-block">{{ error.username }}</div>
                      </div>
                      <div v-if="codeNotSent" class="form-group col-12">
                        <label class="text-muted" for="InputPassword1">{{ $t('loginSignup.Password') }}</label>
                        <input v-model="userPassword.password" type="password" class="form-control input-1" id="password-field" :placeholder="$t('loginSignup.Password')" required="required" :data-error="$t('loginSignup.Password is required')">
                        <div v-if="!error.password" class="help-block with-errors"></div>
                        <div class="error-message-block">{{ error.password }}</div>
                      </div>
                      <div class="col-12 mt-5">
                        <!-- TODO fix signin button validation v-bind:disabled="isMobileNotValid" -->
                        <button v-if="!isPhoneSubmitted" class="btn btn-primary text-uppercase w-100" @click.prevent="requestLoginCode()" >{{ $t('loginSignup.Sign in') }}</button>
                        <button v-if="isPhoneSubmitted && !codeNotSent" class="btn btn-primary text-uppercase w-100" @click.prevent="verifyCode()" :disabled="isCodeNotValid">{{ $t('loginSignup.Verify') }}</button>
                        <button v-if="codeNotSent" class="btn btn-primary text-uppercase w-100" @click.prevent="loginByPassword()" :disabled="!isReadyForSubmission">{{ $t('loginSignup.Sign in') }}</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import InputMask from 'vue-input-mask'

export default {
  name: 'Login',
  data () {
    return {
      isPhoneSubmitted: false,
      codeNotSent: false,
      showCodeNotSentMessage: false,
      mobileFieldDisabled: false,
      showMessageTimeoutId: null,
      isMobileNotValid: true,
      isCodeNotValid: true,
      isSpinnerShown: false,
      error: {
        mobile: null,
        client_id: null,
        code: null,
        username: null,
        password: null
      },
      phone: {
        dial_code: '966',
        mobile: null
      },
      codeVerification: {
        client_id: null,
        code: null
      },
      userPassword: {
        username: null,
        password: null
      }
    }
  },
  components: {
    // InputMask
  },
  computed: {
    isReadyForSubmission () {
      return this.userPassword.username && this.userPassword.password
    },
    auth () {
      return this.$store.getters.isAuthenticated
    }
  },
  watch: {
    'phone.mobile' () {
      if (this.phone.mobile !== null) {
        var counter = 0
        for (var i = 0; i < 9; i++) {
          if (this.phone.mobile[i] !== '_') {
            counter = counter + 1
          }
        }

        if (counter === 9) {
          this.isMobileNotValid = false
        } else {
          this.isMobileNotValid = true
        }
      }
    },
    'codeVerification.code' () {
      if (this.codeVerification.code !== null) {
        if (this.codeVerification.code.length === 6) {
          this.isCodeNotValid = false
        } else {
          this.isCodeNotValid = true
        }
      }
    },
    codeNotSent () {
      if (this.codeNotSent === true) {
        this.$nextTick(() => {
          document.getElementById('username-field').focus()
          document.getElementById('username-field').setSelectionRange(0, 0)
        })
      }
    }
  },
  methods: {
    requestLoginCode () {
      this.error.mobile = null

      this.isSpinnerShown = true
      this.$store.dispatch('requestLoginCode', this.phone)
        .then(res => {
          this.isSpinnerShown = false
          this.codeVerification.client_id = res.data.data.client_id
          this.isPhoneSubmitted = true

          this.mobileFieldDisabled = true

          this.$nextTick(() => {
            document.getElementById('verification-code').focus()
            document.getElementById('verification-code').setSelectionRange(0, 0)
          })

          this.showMessageTimeoutId = setTimeout(() => {
            this.showCodeNotSentMessage = true
          }, 5000)
        }).catch(error => {
          this.isSpinnerShown = false
          this.error.mobile = error.response.data.message
        })
    },
    verifyCode () {
      this.error.code = null

      this.isSpinnerShown = true
      this.$store.dispatch('verifyCode', this.codeVerification)
        .then(res => {
          this.isSpinnerShown = false

          this.hideLoginForm()

          this.clearLoginData()

          if (this.$route.name === 'Home' || this.$route.name === 'BuyerSignup' || this.$route.name === 'SellerSignup') {
            this.$router.push({ name: 'Dashboard', params: { lang: this.$i18n.locale } })
          }
        }).catch(error => {
          this.isSpinnerShown = false
          this.error.code = error.response.data.message
        })
    },
    loginByPassword () {
      this.error.password = null
      this.isSpinnerShown = true

      this.$store.dispatch('loginByPassword', this.userPassword)
        .then(res => {
          this.isSpinnerShown = false

          this.hideLoginForm()

          this.clearLoginData()

          if (this.$route.name === 'Home' || this.$route.name === 'BuyerSignup' || this.$route.name === 'SellerSignup') {
            this.$router.push({ name: 'Dashboard', params: { lang: this.$i18n.locale } })
          }
        }).catch(error => {
          this.isSpinnerShown = false
          this.error.password = error.response.data.message
        })
    },
    hideLoginForm () {
      window.$('#login-form').modal('hide')
      window.$('body').removeClass('modal-open')
      window.$('.modal-backdrop').remove()
    },
    clearLoginData () {
      if (this.showMessageTimeoutId !== null) {
        clearTimeout(this.showMessageTimeoutId)
      }

      this.isPhoneSubmitted = false
      this.codeNotSent = false
      this.showCodeNotSentMessage = false
      this.mobileFieldDisabled = false
      this.isMobileNotValid = true
      this.isCodeNotValid = true
      this.isSpinnerShown = false
      this.error = {
        mobile: null,
        client_id: null,
        code: null,
        username: null,
        password: null
      }
      this.phone = {
        dial_code: '966',
        mobile: null
      }
      this.codeVerification = {
        client_id: null,
        code: null
      }
      this.userPassword = {
        username: null,
        password: null
      }
    }
  }
}
</script>

<style scoped>
  .continue-to-verify-label:hover{
    cursor: pointer;
  }
  #login-form {
    background-color: #000000b8;
  }
  #login-form .modal-content {
    background-color: transparent !important;
    border: none;
  }
  #login-form .close {
    color: #fff;
    border: 2px solid #f9c421;
    opacity: 1!important;
    font-weight: 400;
    font-size: 2rem;
    line-height: .8;
    padding: .6rem .8rem;
    border-radius: 8px;
  }
  #login-form .btn.btn-primary {
    background-color: transparent!important;
  }
  #login-form .messages {
    color: #fff;
  }
  #login-form .full-screen-modal {
    width: 100%;
    height: 100%;
    min-width: unset;
    min-height: unset;
  }
  #login-form .modal-content {
    height: 100%;
    width: 100%;
  }
  #login-form {
    padding: 0!important;
  }

  .modal-dialog {
    width: auto;
    height: 100%;
    max-width: 100%;
    margin: 0!important;
  }
  .modal-dialog-centered {
        min-height: 100%;
  }
  .modal-content {
    width: 100%;
    height: 100%;
  }
  .modal-width {
    margin: 0 auto;
  }
  @media (min-width: 992px)
  {
    .modal-width {
      max-width: 800px;
    }
  }
  @media (min-width: 576px)
  {
    .modal-width {
      max-width: 500px;
    }
  }

</style>
