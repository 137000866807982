<template>
  <div class="row mx-1">
    <div class="col-sm-12 order-lg-12">
        <Spinner v-if="isSpinnerShown"/>

        <div class="part-item mb-5" v-for="request in requests" :key="request.id">
          <div class="row align-items-center">
            <div class="col-12">
              <div class="row part-item-content border-in-black">
                <div  class="part-item--img">
                  <a data-toggle="modal" data-target="#view_modal" @click.prevent="showRequest(request)">
                    <div class="part-img">
                      <img v-if="request.images === null || request.images === ''" src="/assets/images/product/01.jpg" alt="">
                      <img v-else :src="getFirstImage(request.images)" alt="">
                    </div>
                  </a>
                </div>
                <div class="flex-grow-1 px-2">
                  <div>
                    <div class="part-name-model ">
                      <a data-toggle="modal" data-target="#view_modal" @click.prevent="showRequest(request)">
                        <h4 class="text-in-black part-name">{{ request.part_name }} <small class="text-in-amber font-size-14" :class="isRtl ? 'mr-2' : 'ml-2'">({{ partConditions[request.condition][getFieldByLocale()] }})</small></h4>
                      </a>
                      <div class="text-muted">{{ request.brand[getFieldByLocale()] }} {{ request.model[getFieldByLocale()] }} - {{ request.car.model_year }}</div>
                    </div>
                  </div>
                  <div class="part-item--desc d-flex flex-wrap mt-2">
                    <div>
                      <div class="d-flex flex-nowrap">
                        <div class="part-item-label text-muted part-item-lv-w-120">{{ $t('requests.Request Status') }}</div>
                        <div class="part-item-value text-in-amber part-item-lv-w-150">{{ request.status_label }}</div>
                      </div>
                      <div class="d-flex flex-nowrap">
                        <div class="part-item-label text-muted part-item-lv-w-120">{{ $t('requests.Request No') }}</div>
                        <div class="part-item-value text-in-amber part-item-lv-w-150">{{ request.id }}</div>
                      </div>
                    </div>
                    <div>
                      <div class="d-flex flex-nowrap">
                        <div class="part-item-label text-muted part-item-lv-w-120">{{ $t('offers.Received Offers') }}</div>
                        <div class="part-item-value text-in-amber part-item-lv-w-85">{{ request.offers_count }} {{ request.offers_count > 1 ? $t('offers.offers') : $t('offers.offer')  }}</div>
                      </div>
                      <div class="d-flex flex-nowrap">
                        <div class="part-item-label text-muted part-item-lv-w-120">{{ $t('requests.Request Date') }}</div>
                        <div class="part-item-value text-in-amber part-item-lv-w-85">{{ request.created_at.split('T')[0].split('-').reverse().join('/') }}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class=" d-flex flex-nowrap part-item-buttons request-part-item-buttons flex-lg-column align-items-lg-stretch align-items-center text-center">
                  <div class="">
                    <a  :id="'view-details-btn-' + request.id" data-toggle="modal" data-target="#view_modal" @click.prevent="showRequest(request)" class="btn-amber text-white d-block">{{ $t('common.Details') }}</a>
                  </div>
                  <div v-if="parseInt(request.status) !== requestArchivedStatus" class="">
                    <router-link :to="{ name: 'EditRequest', params: { lang: $i18n.locale, id: request.id}}" class="btn-grey text-in-grey"><i class="nav-icon fa fa-cogs" :class="isRtl? 'ml-1':'mr-1'"></i><span>{{ $t('common.Edit') }}</span></router-link>
                  </div>
                  <div v-if="parseInt(request.status) !== requestArchivedStatus" class="">
                    <a v-if="request.can_delete" @click.prevent="removeRequest(request.id)" class="btn-grey btn-block text-in-grey">{{ $t('common.Remove') }}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      <Modal :modal_id="'view_modal'">
        <section v-if="request" class="gallery-text-section">
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div class="row justify-content-center">
                  <div class="col-11">
                    <ul class="nav nav-tabs p-0" id="myTab" role="tablist">
                      <li class="nav-item">
                        <a class="nav-link active" id="request-tab" data-toggle="tab" href="#request-content" role="tab" aria-controls="request-content" aria-selected="true">{{ $t('requests.Request') }}</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" id="offers-tab" data-toggle="tab" href="#offers-content" role="tab" aria-controls="offers-content" aria-selected="false">{{ $t('buyer.Offers') }}</a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="tab-content mt-3" id="myTabContent">
                  <div class="tab-pane fade show active" id="request-content" role="tabpanel" aria-labelledby="request-tab">
                    <div class="row">
                      <div class="col-md-6" >
                          <div id="image-gallery"  class="carousel slide" data-ride="carousel">
                            <div v-if="request.images" class="carousel-inner">
                              <div class="carousel-item " v-for="(image, i) in getAllImages(request.images)" :key="i" @click="index = i" :class="{'active': i===0}" >
                                  <img :src="image" alt="" width="1100" height="500">
                              </div>
                            </div>
                            <div v-else class="carousel-inner">
                              <div class="carousel-item active" @click="index = 0">
                                  <img  src="/assets/images/product/01.jpg" alt="" width="1100" height="500">
                              </div>
                            </div>
                            <ul v-if="request.images" class="carousel-indicators">
                              <li data-target="#image-gallery" v-for="(image, i) in getAllImages(request.images)" :class="{'active': i===0}" :key="i" :data-slide-to="i" >
                                <img :src="image" alt="" width="1100" height="500" style="width: 24%;">
                              </li>
                            </ul>
                            <ul v-else class="carousel-indicators">
                              <li class="active" data-target="#image-gallery">
                                <img src="/assets/images/product/01.jpg" alt="" width="1100" height="500" style="width: 24%;">
                              </li>
                            </ul>
                          </div>
                      </div>

                      <div class="col-md-6 d-flex flex-column">
                        <h2 class="title">{{ $t('requests.Request Details') }}</h2>
                        <div class="row mt-4">
                          <div class="col-md-6 col-sm-6 col-xs-12">
                            <ul class="cases-meta list-unstyled text-muted">
                              <li class="mb-3"><span class="text-dark"> {{ $t('requests.Part Name') }}: </span><br> <span class="font-weight-600 font-size-20">{{ request.part_name }}</span> <small class="text-in-amber font-size-14" :class="isRtl ? 'mr-2' : 'ml-2'">({{ partConditions[request.condition][getFieldByLocale()] }})</small></li>
                              <li class="mb-3"><span class="text-dark"> {{ $t('cars.Car Category') }}: </span><br> {{ carCategories[request.car.car_category_id][getFieldByLocale()] }}</li>
                              <li class="mb-3"><span class="text-dark"> {{ $t('cars.Car Brand') }}: </span><br> {{ request.brand[getFieldByLocale()] }}</li>
                              <li class="mb-3"><span class="text-dark"> {{ $t('cars.Car Model') }}: </span><br> {{ request.model[getFieldByLocale()] + ' ('+ request.car.model_year +')' }}</li>
                            </ul>
                          </div>
                          <div class="col-md-6 col-sm-6 col-xs-12">
                            <ul class="cases-meta list-unstyled text-muted">
                              <li class="mb-3"><span class="text-dark"> {{ $t('requests.Part Category') }}: </span><br> {{ request.part_category[getFieldByLocale()] }}</li>
                              <li class="mb-3"><span class="text-dark"> {{ $t('offers.Received Offers') }}: </span><br> {{ request.offers_count }} {{ request.offers_count > 1 ? $t('offers.offers') : $t('offers.offer')  }}</li>
                              <li class="mb-3"><span class="text-dark"> {{ $t('cars.Chasse No') }}: </span><br> <span class="vin-value">{{ request.car.vin }}</span></li>
                            </ul>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12">
                              <div class="mb-3 text-muted"><span class="text-dark"> {{ $t('requests.Request Details') }}: </span><br> {{ request.details }}</div>
                          </div>
                        </div>
                        <span class="product-price text-in-red">{{ $t('requests.Request Status') }}:  {{ request.status_label }}</span>
                        <div class="row mt-auto">
                          <div class="col-md-6" :class="isRtl ? 'mr-auto' : 'ml-auto'">
                            <ul class="cases-meta list-unstyled text-muted mt-3">
                              <li class="mb-3"><span class="text-dark"> {{ $t('requests.Request No') }}: </span> {{ request.id }}</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane fade" id="offers-content" role="tabpanel" aria-labelledby="offers-tab">
                    <div v-if="request.offers">
                    <div class="part-item mb-5 px-3" v-for="offer in request.offers" :key="offer.id">
                      <div class="row align-items-center">
                        <div class="col-12">
                          <div class="row part-item-content">
                            <div  class="part-item--img">
                              <div class="part-img">
                                <img v-if="offer.images === null || offer.images === ''" src="/assets/images/product/01.jpg" alt="">
                                <img v-else :src="getFirstImage(offer.images)" alt="">
                              </div>
                            </div>
                            <div class="part-item--desc d-flex flex-wrap">
                              <div>
                                <div class="d-flex flex-nowrap">
                                  <div class="part-item-label text-muted part-item-lv-w-80">{{ $t('sellerParts.Condition') }}</div>
                                  <div class="part-item-value text-in-amber part-item-lv-w-80">{{ partConditions[offer.condition][getFieldByLocale()] }}</div>
                                </div>
                              </div>
                              <div>
                                <div class="d-flex flex-nowrap">
                                  <div class="part-item-label text-muted part-item-lv-w-80">{{ $t('sellerParts.Seller Note') }}</div>
                                  <div class="part-item-value text-in-amber part-item-lv-w-120">{{ offer.seller_note }}</div>
                                </div>
                                <div class="d-flex flex-nowrap">
                                  <div class="part-item-label text-muted part-item-lv-w-80">{{ $t('sellerParts.Part Price') }}</div>
                                  <div class="part-item-value text-in-amber font-weight-600 font-size-16 part-item-lv-w-120">{{ offer.sparaat_price }} {{ getCurrency () }} <small>{{ $t('sellerParts.The price includes the added tax') }}</small></div>
                                </div>
                              </div>
                              <div>
                                <div class="d-flex flex-nowrap">
                                  <div class="part-item-label text-muted part-item-lv-w-85">{{ $t('offers.Offer Status') }}</div>
                                  <div class="part-item-value text-in-amber part-item-lv-w-115">{{ offerStatus[offer.status][getFieldByLocale()] }} {{offer.order_status_label ? (' - ' + offer.order_status_label) : '' }}</div>
                                </div>
                                <div class="d-flex flex-nowrap">
                                  <div class="part-item-label text-muted part-item-lv-w-85">{{ $t('offers.Offer Date') }}</div>
                                  <div v-if="offer.submitted_at" class="part-item-value text-in-amber part-item-lv-w-115">{{ offer.submitted_at.split('T')[0].split('-').reverse().join('/') }}</div>
                                </div>

                              </div>
                              <div class="d-flex flex-nowrap align-self-center" v-if="offer.status !== offerIgnored">
                                <a @click.prevent="navigateToShowOffer(offer.id)" class="btn-amber text-white d-block">{{ $t('common.Details') }}</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    </div>
                    <EmptyListMessage v-if="request.offers && !request.offers.length">{{ $t('offers.No offers received yet') }}</EmptyListMessage>
                  </div>
                </div>

              </div>

            </div>
          </div>
        </section>
      </Modal>
      <CoolLightBox
        :items="items"
        :index="index"
        @close="index = null">
      </CoolLightBox>

      <nav aria-label="..." class="pagination-navigation mt-8" v-if="pageCount">
        <paginate
          v-model="page"
          :page-count="pageCount"
          :page-range="10"
          :margin-pages="1"
          :click-handler="clickCallback"
          :prev-text="$t('common.Prev')"
          :next-text="$t('common.Next')"
          :prev-class="isRtl ? 'page-item ml-auto' : 'page-item mr-auto'"
          :prev-link-class="'page-link'"
          :next-class="isRtl ? 'page-item mr-auto' : 'page-item ml-auto'"
          :next-link-class="'page-link'"
          :container-class="'pagination'"
          :page-class="'page-item'"
          :page-link-class="'dark-link-text page-link border-0 rounded'">
        </paginate>
      </nav>

    </div>
  </div>
</template>

<script>
import Paginate from 'vuejs-paginate'
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import Modal from '../Modal.vue'
import EmptyListMessage from '../../components/EmptyListMessage.vue'

export default {
  name: 'RequestList',
  props: ['requests', 'pageCount', 'status'],
  data () {
    return {
      isSpinnerShown: false,
      page: null,
      request: null,
      items: [],
      index: null
    }
  },
  updated () {
    if (this.$route.query.page) {
      this.page = parseInt(this.$route.query.page)
    }
  },
  mounted () {
    if (this.$route.query.page) {
      this.page = parseInt(this.$route.query.page)
    } else {
      this.page = 1
    }
  },
  components: {
    Paginate,
    Modal,
    CoolLightBox,
    EmptyListMessage
  },
  methods: {
    navigateToShowOffer (offerId) {
      document.getElementsByClassName('close')[0].click()
      this.$router.push({ name: 'Offers', query: { id: offerId } })
    },
    removeRequest (requestId) {
      const swalWithBootstrapButtons = this.$swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger'
        },
        buttonsStyling: false
      })

      swalWithBootstrapButtons.fire({
        title: this.$t('common.Are you sure?'),
        text: this.$t('requests.You are about to remove a request!'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('common.Yes, remove it'),
        cancelButtonText: this.$t('common.No, cancel'),
        reverseButtons: false
      }).then((result) => {
        if (result.isConfirmed) {
          this.isSpinnerShown = true
          this.$store.dispatch('removeRequest', requestId)
            .then(res => {
              this.isSpinnerShown = false
              this.$emit('removeRequest', requestId)
              swalWithBootstrapButtons.fire({
                title: this.$t('common.Removed!'),
                text: this.$t('common.The item has been removed'),
                icon: 'success',
                confirmButtonText: this.$t('common.Ok')
              })
            }).catch(error => {
              this.isSpinnerShown = false
              console.log(error)
            })
        }
      })
    },
    clickCallback (pageNum) {
      this.$emit('clickCallback', pageNum)
    },
    showRequest (request) {
      window.$('a[href="#request-content"]').tab('show')

      this.items = []
      this.index = null

      this.request = request
      if (request.images) {
        request.images.split(',').forEach((element, index) => {
          this.items.push(element)
        })
      } else {
        this.items.push('/assets/images/product/01.jpg')
      }
    }
  }
}
</script>

<style scoped>
  .product-img {
    height: 21rem;
  }
  .product-img img{
    width: 100%!important;
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
  }
  .rtl .pagination{
    direction: rtl;
  }
</style>
