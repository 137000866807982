<template>
  <div>
    <!--hero section start-->

    <HeroSection>
      <h1>{{ $t('dashboard.Dashboard') }}</h1>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb justify-content-center bg-transparent p-0 m-0">
        </ol>
      </nav>
    </HeroSection>

    <!--hero section end-->

    <!--body content start-->

    <div class="page-content">

    <Slider />

    <!--accordion start-->

    <section class="dashboard-accordion">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div id="accordion" class="accordion">
              <div class="card border-0 active mb-2">
                <div class="card-header border mb-0 background-grey">
                  <h6 class="mb-0">
                  <a class="text-in-amber" data-toggle="collapse" data-parent="#accordion" href="#collapse1" aria-expanded="true">{{ $t('offers.Pending Requests') }} <span class="badge font-weight-700 background-amber text-in-grey">{{ stats.new_requests_count }}</span></a>
                  </h6>
                </div>
                <div id="collapse1" class="collapse show" data-parent="#accordion">
                  <div class="card-body text-muted">
                    <div class="table-responsive">
                      <table class="table table-striped table-hover">
                        <thead>
                          <tr>
                            <th><strong>{{ $t('cars.Car Category') }}</strong></th>
                            <th><strong>{{ $t('cars.Car Brand') }}</strong></th>
                            <th><strong>{{ $t('cars.Car Model') }}</strong></th>
                            <th><strong>{{ $t('cars.Year of Manufacture') }}</strong></th>
                            <th><strong>{{ $t('requests.Part Category') }}</strong></th>
                            <th><strong>{{ $t('requests.Part Name') }}</strong></th>
                            <th><strong>{{ $t('requests.Condition') }}</strong></th>
                            <th><strong>{{ $t('common.Details') }}</strong></th>
                          </tr>
                        </thead>
                        <tbody style="position: relative;">
                          <Spinner v-if="isSpinnerShownOnOffers"/>
                          <tr v-for="offer in offers" :key="offer.id">
                            <td>{{ carCategories[offer.request.car.car_category_id][getFieldByLocale()] }}</td>
                            <td>{{ offer.brand[getFieldByLocale()] }}</td>
                            <td>{{ offer.model[getFieldByLocale()] }}</td>
                            <td>{{ offer.request.car.model_year }}</td>
                            <td>{{ offer.part_category[getFieldByLocale()] }}</td>
                            <td class="font-size-18 font-weight-600">{{ offer.request.part_name }}</td>
                            <td>{{ partConditions[offer.condition][getFieldByLocale()] }}</td>
                            <td><router-link :to="{ name: 'PendingSellerPricing', query: { status: offerStatus[offerPendingSellerPricing].slug, id: offer.id } }" class="btn-amber text-center text-in-grey d-block font-weight-500">{{ $t('common.Details') }}</router-link></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!--accordion end-->

    </div>

    <!--body content end-->

  </div>
</template>

<script>
import Slider from '../Slider.vue'

export default {
  name: 'SellerDashboard',
  components: {
    Slider
  },
  data () {
    return {
      offers: null,
      isSpinnerShown: false,
      isSpinnerShownOnOffers: false
    }
  },
  mounted () {
    this.isSpinnerShown = true
    this.$store.dispatch('getStats')
      .then(res => {
        this.isSpinnerShown = false
      }).catch(error => {
        this.isSpinnerShown = false
        console.log(error)
      })

    var pageNumber = 1
    this.getSellerPendingOffers(pageNumber)
  },
  computed: {
    stats () {
      return this.$store.getters.getStats
    }
  },
  methods: {
    getSellerPendingOffers (page) {
      this.isSpinnerShownOnOffers = true
      this.$store.dispatch('getSellerOffersByStatus', { status: this.offerStatus[this.offerPendingSellerPricing].slug, page: page })
        .then(res => {
          this.isSpinnerShownOnOffers = false
          this.offers = res.data.data.data

          this.pageCount = res.data.data.last_page
        }).catch(error => {
          this.isSpinnerShownOnOffers = false
          console.log(error)
        })
    }
  }
}
</script>
