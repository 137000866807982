<template>
  <div>
    <!--hero section start-->

    <HeroSection>
      <h1>{{ $t('buyer.Offers') }}</h1>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb justify-content-center bg-transparent p-0 m-0">
          <li class="breadcrumb-item"><router-link class="text-dark" :to="{ name: 'Dashboard', params: { lang: this.$i18n.locale }}">{{ $t('menu.Home') }}</router-link></li>
          <li class="breadcrumb-item active text-primary" aria-current="page">{{ $t('buyer.Offers') }}</li>
        </ol>
      </nav>
    </HeroSection>

    <!--hero section end-->

    <!--body content start-->

    <div class="page-content">

    <section style="position: relative;">
      <Spinner v-if="isSpinnerShown"/>
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <div class="part-item mb-5 px-3" v-for="offer in offers" :key="offer.id">
              <div class="row align-items-center">
                <div class="col-12">
                  <div class="row part-item-content border-in-black">

                    <div  class="part-item--img d-flex flex-column">
                      <a class="mb-auto" data-toggle="modal" data-target="#view_modal" @click.prevent="showOffer(offer)">
                        <div class="part-img">
                          <img v-if="offer.images === null || offer.images === ''" src="/assets/images/product/01.jpg" alt="">
                          <img v-else :src="getFirstImage(offer.images)" alt="">
                        </div>
                      </a>
                    </div>
                    <div class="part-item--desc d-flex flex-wrap">
                      <div class="part-name-model">
                        <a data-toggle="modal" data-target="#view_modal" @click.prevent="showOffer(offer)">
                          <div class="text-in-black part-name">{{ offer.request.part_name }}  <small class="text-in-amber font-size-14" :class="isRtl ? 'mr-2' : 'ml-2'">({{ partConditions[offer.condition][getFieldByLocale()] }})</small></div>
                        </a>
                        <div class="text-muted">{{ offer.request.brand[getFieldByLocale()] }} {{ offer.request.model[getFieldByLocale()] }} - {{ offer.request.car.model_year }}</div>
                      </div>
                      <div class="flex-grow-1">
                        <div class="d-flex flex-nowrap mb-3">
                          <div class="part-item-label text-muted font-weight-600 font-size-18 part-item-lv-w-110">{{ $t('sellerParts.Part Price') }}</div>
                          <div class="text-in-amber font-weight-600 font-size-18 flex-grow-1">{{ offer.sparaat_price }} {{ getCurrency () }} <small>{{ $t('sellerParts.The price includes the added tax') }}</small></div>
                        </div>
                        <div v-if="offer.condition === newPartCondition" class="d-flex flex-nowrap">
                          <div class="part-item-label text-muted part-item-lv-w-110">{{ $t('sellerParts.Part Origin') }}</div>
                          <div v-if="offer.origin" class="text-in-amber flex-grow-1">{{ offer.origin[getFieldByLocale()] }}</div>
                        </div>
                      </div>
                      <div class="mt-auto">
                        <div class="d-flex flex-nowrap">
                          <div class="part-item-label text-muted">{{ $t('offers.Offer Status') }}</div>
                          <div class="part-item-value text-in-amber part-item-lv-w-115">{{ offerStatus[offer.status][getFieldByLocale()] }} {{offer.order_status_label ? (' - ' + offer.order_status_label) : '' }}</div>
                        </div>
                        <div class="d-flex flex-nowrap">
                          <div class="part-item-label text-muted">{{ $t('offers.Offer Date') }}</div>
                          <div v-if="offer.submitted_at" class="part-item-value text-in-amber part-item-lv-w-115">{{ offer.submitted_at.split('T')[0].split('-').reverse().join('/') }}</div>
                        </div>
                      </div>
                      <div class="w-100 d-flex flex-wrap offer-bottom-section">
                        <div class="part-item-grouped-details mb-0">
                          <div class="mt-3 d-flex flex-nowrap align-items-end justify-content-end part-item-buttons part-item-lv-w-203">
                            <div class="display-none">
                              <a :id="'view-details-btn-' + offer.id" data-toggle="modal" data-target="#view_modal" @click.prevent="showOffer(offer)" class="btn-grey text-in-grey d-block">{{ $t('common.Details') }}</a>
                            </div>
                            <div v-if="parseInt(offer.request.status) !== requestArchivedStatus && parseInt(offer.status) === offerPendingBuyerApprove" class="d-flex flex-nowrap" :class="isRtl? 'ml-auto' : 'mr-auto'">
                              <a v-if="!offersInCartIndices.includes(offer.id)" class="btn-amber text-white font-size-14 p-2 add-to-cart-btn d-flex flex-column align-items-center" @click.prevent="addToCart(offer.id)">
                                <img src="@/assets/images/other/sparaat-add-to-cart-128.png" class="img-fluid mb-2" alt="...">
                                <div class="d-inline-block text-center">{{ $t('offers.Add To Cart') }}</div>
                              </a>
                              <a v-if="offersInCartIndices.includes(offer.id)" class="btn-amber text-white" @click.prevent="removeItemFromCart(offer.id)">{{ $t('offers.Remove From Cart') }}</a>
                            </div>
                          </div>
                        </div>
                        <div class="flex-grow-1 d-flex flex-column">
                          <div>
                            <div class="d-flex flex-nowrap mb-3">
                              <div class="part-item-label text-muted" :class="isRtl ? 'part-item-lv-w-110' : 'part-item-lv-w-140' ">{{ $t('sellerParts.Warranty Duration') }}</div>
                              <div class="text-in-amber flex-grow-1">{{ offer.warranty_duration ? offer.warranty_duration : 0 }} {{ $t('sellerParts.day(s)') }}</div>
                            </div>
                            <div class="d-flex flex-nowrap mb-2">
                              <div class="part-item-label part-item-details-label text-muted">{{ $t('sellerParts.Seller Note') }}</div>
                              <div class="part-item-value text-in-amber one-line-text">{{ offer.seller_note }}</div>
                            </div>
                          </div>
                          <div class="mt-auto">
                            <div class="d-flex flex-nowrap part-item-lv-w-208 request-no-field" :class="isRtl ? 'mr-auto ml-0' : 'ml-auto mr-0' ">
                              <div class="part-item-label text-muted">{{ $t('requests.Request No') }}</div>
                              <div class="part-item-value text-in-amber part-item-lv-w-115">{{ offer.request.id }}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <nav aria-label="..." class="pagination-navigation mt-8" v-if="pageCount">
              <paginate
                v-model="page"
                :page-count="pageCount"
                :page-range="10"
                :margin-pages="1"
                :click-handler="clickCallback"
                :prev-text="$t('common.Prev')"
                :next-text="$t('common.Next')"
                :prev-class="isRtl ? 'page-item ml-auto' : 'page-item mr-auto'"
                :prev-link-class="'page-link'"
                :next-class="isRtl ? 'page-item mr-auto' : 'page-item ml-auto'"
                :next-link-class="'page-link'"
                :container-class="'pagination'"
                :page-class="'page-item'"
                :page-link-class="'dark-link-text page-link border-0 rounded'">
              </paginate>
            </nav>

          </div>
        </div>
      </div>
    </section>

    <OfferViewModal :offer="offer" :isSpinnerShownOnModal="isSpinnerShownOnModal">
      <template v-slot:buttons>
        <div class="modal-buttons d-flex flex-column">
          <a v-if="!offersInCartIndices.includes(offer.id) && parseInt(offer.status) === offerPendingBuyerApprove && parseInt(offer.request.status) !== requestArchivedStatus" class="btn-amber text-white font-size-14 px-2 py-1 add-to-cart-btn" :class="isRtl ? 'mr-auto': 'ml-auto'" @click.prevent="addToCart(offer.id)">
            <img src="@/assets/images/other/sparaat-add-to-cart-128.png" class="img-fluid mb-0" alt="..." :class="isRtl ? 'ml-1': 'mr-1'">
            <div class="d-inline-block">{{ $t('offers.Add To Cart') }}</div>
          </a>
          <a v-if="offersInCartIndices.includes(offer.id) && parseInt(offer.status) === offerPendingBuyerApprove && parseInt(offer.request.status) !== requestArchivedStatus" class="btn-amber text-white" :class="isRtl ? 'mr-auto': 'ml-auto'" @click.prevent="removeItemFromCart(offer.id)">{{ $t('offers.Remove From Cart') }}</a>
        </div>
      </template>
    </OfferViewModal>

    </div>

    <!--body content end-->
  </div>
</template>

<script>
import Paginate from 'vuejs-paginate'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import OfferViewModal from '../../components/offer/OfferViewModal.vue'

export default {
  name: 'Offers',
  data () {
    return {
      page: 1,
      pageCount: null,
      offers: null,
      offer: null,
      cart: null,
      offersInCartIndices: [],
      isSpinnerShown: false,
      isSpinnerShownOnModal: false,
      id: null
    }
  },
  components: {
    Paginate,
    OfferViewModal
  },
  mounted () {
    this.id = parseInt(this.$route.query.id)
    this.getCurrentPageOffers()
    this.getCart()
  },
  updated () {
    if (this.id) {
      this.$nextTick(function () {
        if (document.getElementById('view-details-btn-' + this.id)) {
          document.getElementById('view-details-btn-' + this.id).click()
          this.id = null
        }
      })
    }
  },
  methods: {
    getCurrentPageOffers () {
      if (!this.$route.query.page) {
        this.page = 1
      } else {
        this.page = parseInt(this.$route.query.page)
      }

      this.getPendingOffers(this.page)
    },
    getPendingOffers (page) {
      this.isSpinnerShown = true
      this.$store.dispatch('getPendingOffers', page)
        .then(res => {
          this.isSpinnerShown = false

          if (res.data.data.current_page > res.data.data.last_page) {
            this.page = res.data.data.last_page
            this.clickCallback(res.data.data.last_page)
          }

          if (this.$route.query.id) {
            if ((res.data.data.data.filter(elem => elem.id === this.id).length === 0) && (res.data.data.current_page < res.data.data.last_page)) {
              this.getPendingOffers(res.data.data.current_page + 1)
            } else if (res.data.data.data.filter(elem => elem.id === this.id).length === 0) {
              this.id = null
              this.removeIdFromRoute()
              this.getPendingOffers(1)
            } else if ((res.data.data.data.filter(elem => elem.id === this.id).length !== 0)) {
              this.removeIdFromRoute(res.data.data.current_page)
            }
          }

          this.offers = res.data.data.data
          this.pageCount = res.data.data.last_page
        }).catch(error => {
          this.isSpinnerShown = false
          console.log(error)
        })
    },
    getCart () {
      this.isSpinnerShown = true
      this.isSpinnerShownOnModal = true
      this.$store.dispatch('getCart')
        .then(res => {
          this.isSpinnerShown = false
          this.isSpinnerShownOnModal = false
          this.cart = res.data.data
          if (this.cart.offers) {
            this.offersInCartIndices = this.cart.offers.map((elem, index) => {
              return elem.id
            })
          }
        }).catch(error => {
          this.isSpinnerShown = false
          this.isSpinnerShownOnModal = false
          console.log(error)
        })
    },
    clickCallback (pageNum) {
      this.$router.push({ name: 'Offers', query: { page: pageNum, id: this.$route.query.id } })
      this.getPendingOffers(pageNum)
    },
    addToCart (offerId) {
      const swalWithBootstrapButtons = this.$swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger'
        },
        buttonsStyling: false
      })

      this.isSpinnerShown = true
      this.isSpinnerShownOnModal = true
      this.$store.dispatch('addToCart', offerId)
        .then(res => {
          this.isSpinnerShown = false
          this.isSpinnerShownOnModal = false

          swalWithBootstrapButtons.fire({
            title: this.$t('common.The item has been added successfully!'),
            text: this.$t('common.To complete the purchase process, please go to cart'),
            icon: 'success',
            confirmButtonText: this.$t('common.Ok')
          })

          this.getCart()
        }).catch(error => {
          this.isSpinnerShown = false
          this.isSpinnerShownOnModal = false
          console.log(error)
        })
    },
    removeItemFromCart (offerId) {
      this.isSpinnerShown = true
      this.isSpinnerShownOnModal = true
      this.$store.dispatch('removeItemFromCart', offerId)
        .then(res => {
          this.isSpinnerShown = false
          this.isSpinnerShownOnModal = false
          this.getCart()
        }).catch(error => {
          this.isSpinnerShown = false
          this.isSpinnerShownOnModal = false
          console.log(error)
        })
    },
    showOffer (offer) {
      this.offer = offer
    }
  }
}
</script>

<style scoped>
  .dark-link-text {
    color: #1d1d33;
  }
  .product-img {
    height: 21rem;
  }
  .product-img img{
    width: 100%!important;
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
  }
  .rtl .pagination{
    direction: rtl;
  }
</style>
