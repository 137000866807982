import axios from '@/plugins/axios'

const actions = {
  getPendingOffers: ({ commit, state, rootState }, page) => {
    return axios.get('/api/buyer/offers/pending?page=' + page, {
      headers: {
        Authorization: 'Bearer ' + rootState.loginSignup.api_token
      }
    })
      .then(res => {
        return res
      }).catch(error => {
        return Promise.reject(error)
      })
  },
  ignoreOffer: ({ commit, state, rootState }, offerId) => {
    return axios.put('/api/buyer/offers/' + offerId + '/ignore', {}, {
      headers: {
        Authorization: 'Bearer ' + rootState.loginSignup.api_token
      }
    })
      .then(res => {
        return res
      }).catch(error => {
        return Promise.reject(error)
      })
  },
  getSellerOffers: ({ commit, state, rootState }, { page, searchParams }) => {
    return axios.get('/api/offers?page=' + page, {
      headers: {
        Authorization: 'Bearer ' + rootState.loginSignup.api_token
      },
      params: searchParams
    })
      .then(res => {
        return res
      }).catch(error => {
        return Promise.reject(error)
      })
  },
  getSellerOffersByStatus: ({ commit, state, rootState }, { status, page, searchParams }) => {
    return axios.get('/api/seller/offers/' + status + '?page=' + page, {
      headers: {
        Authorization: 'Bearer ' + rootState.loginSignup.api_token
      },
      params: searchParams
    })
      .then(res => {
        return res
      }).catch(error => {
        return Promise.reject(error)
      })
  },
  updateOffer: ({ commit, state, rootState }, offer) => {
    return axios.put('/api/seller/offers/' + offer.id, offer, {
      headers: {
        Authorization: 'Bearer ' + rootState.loginSignup.api_token
      }
    })
      .then(res => {
        return res
      }).catch(error => {
        return Promise.reject(error)
      })
  },
  cancelOffer: ({ commit, state, rootState }, offerId) => {
    return axios.put('/api/seller/offers/' + offerId + '/cancel', {}, {
      headers: {
        Authorization: 'Bearer ' + rootState.loginSignup.api_token
      }
    })
      .then(res => {
        return res
      }).catch(error => {
        return Promise.reject(error)
      })
  },
  getOfferById: ({ commit, state, rootState }, offerId) => {
    return axios.get('/api/offers/' + offerId, {
      headers: {
        Authorization: 'Bearer ' + rootState.loginSignup.api_token
      }
    })
      .then(res => {
        return res
      }).catch(error => {
        return Promise.reject(error)
      })
  }
}

export default {
  actions
}
