<template>
  <div class="register">
    <div class="container" style="position: relative;">
      <Spinner v-if="isSpinnerShown"/>
      <div class="row">
        <div class="col-lg-8 col-md-10 ml-auto mr-auto">
          <div class="register-form">
            <form method="post">
              <div class="messages"></div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="full_name">{{ $t('loginSignup.Full Name') }}</label>
                    <input id="full_name" v-model="buyer.full_name" type="text" name="full_name" class="form-control"
                           :placeholder="$t('loginSignup.Full Name')" required="required"
                           :data-error="$t('loginSignup.Full Name is required')">
                    <div v-if="!error.full_name" class="help-block with-errors"></div>
                    <div class="error-message-block">{{ error.full_name }}</div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="email">{{ $t('loginSignup.Email') }}</label>
                    <input id="email" v-model="buyer.email" type="email" name="email" class="form-control"
                           :placeholder="$t('loginSignup.Email')"
                           :data-error="$t('loginSignup.Email is required')">
                    <div v-if="!error.email" class="help-block with-errors"></div>
                    <div class="error-message-block">{{ error.email }}</div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <label for="mobile">{{ $t('loginSignup.Phone number') }}</label>
                  <div class="row dial-phone-number" dir="ltr">
                    <div class="form-group col-sm-6 dial-code">
                      <input type="text" value="+966" class="form-control input-1" readonly>
                      <div class="help-block with-errors"></div>
                      <div class="error-message-block"></div>
                    </div>
                    <div class="form-group col-sm-6 phone-number">
                      <input-mask mask="599999999" v-model="buyer.mobile" type="text" id="mobile"
                                  class="form-control text-center" :placeholder="$t('loginSignup.Phone number')"
                                  required="required"
                                  :data-error="$t('loginSignup.Phone number is required')"></input-mask>
                      <div v-if="!error.mobile" class="help-block with-errors"></div>
                      <div class="error-message-block">{{ error.mobile }}</div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="username">{{ $t('loginSignup.Username') }}</label>
                    <input id="username" v-model="buyer.username" type="text" name="username" class="form-control"
                           :placeholder="$t('loginSignup.Username')" required="required"
                           :data-error="$t('loginSignup.Username is required')">
                    <div v-if="!error.username" class="help-block with-errors"></div>
                    <div class="error-message-block">{{ error.username }}</div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="password">{{ $t('loginSignup.Password') }}</label>
                    <input id="password" v-model="buyer.password" type="password" name="password" class="form-control"
                           :placeholder="$t('loginSignup.Password')" required="required"
                           :data-error="$t('loginSignup.password is required')">
                    <div v-if="showPasswordDataError" class="help-block with-errors"></div>
                    <div class="error-message-block">
                      {{ error.password }}
                      <span v-if="isShownPasswordLengthError">{{
                          $t('loginSignup.Password should have at least 6 characters')
                        }}</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group" id="confirmPasswordForm">
                    <label for="form_password1">{{ $t('loginSignup.Confirm Password') }}</label>
                    <input id="form_password1" v-model="confirmationPassword" type="password" name="confirm_password"
                           class="form-control" :placeholder="$t('loginSignup.Confirm Password')" required="required"
                           :data-error="$t('loginSignup.Confirm Password is required')">
                    <div v-if="showConfirmPasswordDataError" class="help-block with-errors"></div>
                    <div class="error-message-block">
                      <span v-if="isShownrequiredConfirmPasswordError">{{
                          $t('loginSignup.Confirm Password is required')
                        }}</span>
                      <span v-if="isShownPasswordsDoNotMatchError">{{ $t('loginSignup.Passwords do not match') }}</span>
                      {{ validation.confirmationPassword }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="country_id">{{ $t('loginSignup.Country') }}</label>
                    <select id="country_id" v-model="country_id" class="form-control">
                      <option value="0" disabled>{{ $t('loginSignup.Country') }}</option>
                      <option v-for="country in countries" :key="country.id" :value="country.id">
                        {{ country[getFieldByLocale()] }}
                      </option>
                    </select>
                    <div v-if="!error.country_id" class="help-block with-errors"></div>
                    <div class="error-message-block">{{ error.country_id }}</div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="city_id">{{ $t('loginSignup.City') }}</label>
                    <select id="city_id" v-model="buyer.city_id" class="form-control" :disabled="isCityFieldDisabled">
                      <option value="0" disabled>{{ $t('loginSignup.City') }}</option>
                      <option v-for="city in cities" :key="city.id" :value="city.id">{{
                          city[getFieldByLocale()]
                        }}
                      </option>
                    </select>
                    <div v-if="!error.city_id" class="help-block with-errors"></div>
                    <div class="error-message-block">{{ error.city_id }}</div>
                  </div>
                </div>
              </div>
              <div class="row mt-5">
                <div class="col-md-12">
                  <div class="remember-checkbox clearfix mb-5">
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import InputMask from 'vue-input-mask'

export default {
  name: 'BuyerSignupFormOnly',
  props: ['error'],
  data () {
    return {
      cities: null,
      country_id: 0,
      countries: null,
      confirmationPassword: null,
      isCityFieldDisabled: true,
      isAutomatedUsername: false,
      automatedUsername: null,
      isSpinnerShown: false,
      isShownrequiredConfirmPasswordError: false,
      isShownPasswordsDoNotMatchError: false,
      isShownPasswordLengthError: false,
      validation: {
        full_name: null,
        username: null,
        password: null,
        mobile: null,
        email: null,
        city_id: null,
        confirmationPassword: null,
        country_id: null
      },
      buyer: {
        full_name: null,
        type: '2',
        username: null,
        password: null,
        dial_code: '966',
        mobile: null,
        email: null,
        city_id: 0,
        client_lang: null,
        is_valid: false
      }
    }
  },
  components: {
    InputMask
  },
  mounted () {
    this.$store.dispatch('getCountries')
      .then(res => {
        this.countries = res.data.data
      }).catch(error => {
        console.log(error)
      })
  },
  computed: {
    isReadyForSubmission () {
      return this.buyer.full_name && this.buyer.username && this.buyer.mobile && this.buyer.password && this.confirmationPassword && this.country_id && this.buyer.city_id
    },
    showPasswordDataError () {
      return !(this.error.password || this.isShownPasswordLengthError)
    },
    showConfirmPasswordDataError () {
      return !(this.validation.confirmationPassword || this.isShownrequiredConfirmPasswordError || this.isShownPasswordsDoNotMatchError)
    }
  },
  watch: {
    buyer: {
      handler: function (data) {
        this.validate()
        this.$emit('input', data)
      },
      deep: true
    },
    'confirmationPassword' () {
      this.validate()
    },
    'buyer.mobile' () {
      var counter = 0
      for (var i = 0; i < 9; i++) {
        if (this.buyer.mobile[i] !== '_') {
          counter = counter + 1
        }
      }

      if (counter === 9 && !this.buyer.username) {
        this.buyer.username = this.buyer.dial_code + this.buyer.mobile
        this.automatedUsername = this.buyer.dial_code + this.buyer.mobile
        this.isAutomatedUsername = true
      } else if (counter === 9 && this.isAutomatedUsername) {
        this.buyer.username = this.buyer.dial_code + this.buyer.mobile
        this.automatedUsername = this.buyer.dial_code + this.buyer.mobile
      } else if (this.buyer.username === (this.buyer.dial_code + this.buyer.mobile)) {
        this.isAutomatedUsername = true
      }
    },
    'buyer.username' () {
      if (this.automatedUsername !== this.buyer.username) {
        this.isAutomatedUsername = false
      }
    },
    country_id (val) {
      this.buyer.city_id = 0
      if (val === '0') {
        this.isCityFieldDisabled = true
      } else {
        this.$store.dispatch('getCitiesbyCountry', val)
          .then(res => {
            this.cities = res.data.data
            this.isCityFieldDisabled = false
          }).catch(error => {
            console.log(error)
          })
      }
    }
  },
  methods: {
    validate () {
      this.validation = {
        full_name: null,
        username: null,
        password: null,
        mobile: null,
        email: null,
        city_id: null,
        confirmationPassword: null,
        country_id: null
      }

      this.isShownrequiredConfirmPasswordError = false
      this.isShownPasswordsDoNotMatchError = false
      this.isShownPasswordLengthError = false

      this.buyer.client_lang = this.$i18n.locale

      if (this.buyer.password && this.buyer.password.length < 6) {
        this.buyer.is_valid = false
        this.isShownPasswordLengthError = true
      } else if (this.confirmationPassword === null) {
        this.buyer.is_valid = false
        this.isShownrequiredConfirmPasswordError = true
      } else if (this.buyer.password !== this.confirmationPassword) {
        this.buyer.is_valid = false
        this.isShownPasswordsDoNotMatchError = true
      } else {
        this.buyer.is_valid = !!this.isReadyForSubmission
      }
    }
  }
}
</script>
