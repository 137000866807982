import axios from '@/plugins/axios'

const actions = {
  getCountries: ({ commit, state }) => {
    return axios.get('/api/countries')
      .then(res => {
        return res
      }).catch(error => {
        return Promise.reject(error)
      })
  },
  getCitiesbyCountry: ({ commit, state }, countryId) => {
    return axios.get('/api/country/' + countryId + '/cities')
      .then(res => {
        return res
      }).catch(error => {
        return Promise.reject(error)
      })
  },
  getBrandsByCarCategory: ({ commit, state }, carCategoryId) => {
    return axios.get('/api/car-categories/' + carCategoryId + '/brands')
      .then(res => {
        return res
      }).catch(error => {
        return Promise.reject(error)
      })
  },
  getModelsByCategoryBrand: ({ commit, state }, categoryBrand) => {
    return axios.get('/api/car-categories/' + categoryBrand.car_category_id + '/brand/' + categoryBrand.brand_id + '/models')
      .then(res => {
        return res
      }).catch(error => {
        return Promise.reject(error)
      })
  },
  getPartCategoriesByCarCategory: ({ commit, state }, carCategoryId) => {
    return axios.get('/api/car-category/' + carCategoryId + '/part-categories')
      .then(res => {
        return res
      }).catch(error => {
        return Promise.reject(error)
      })
  },
  getPartOrigins: ({ commit, state }) => {
    return axios.get('/api/part-origins')
      .then(res => {
        return res
      }).catch(error => {
        return Promise.reject(error)
      })
  }
}

export default {
  actions
}
