<template>
  <div class="row " >
    <div :class="itemClass">
      <label for="car_category_id">{{ $t('cars.Car Category') }}</label>
      <select name="car_category_id" id="car_category_id" @change="getBrandsByCarCategory(model.car_category_id)"
              class="form-control form-control-sm" v-model="model.car_category_id">
        <option value="">{{ $t('cars.Car Category') }}</option>
        <option v-for="category in carCategories" :key="category.id" :value="category.id">{{ category[getFieldByLocale()] }}</option>
      </select>
    </div>
    <div :class="itemClass">
      <label for="brand_id">{{ $t('cars.Brand') }}</label>
      <select id="brand_id" v-model="model.brand_id" class="form-control form-control-sm" :disabled="!model.car_category_id || !isBrandLoaded"
      @change="getModelsByCategoryBrand(model.car_category_id, model.brand_id)">
        <option value="" >{{ $t('cars.Brand') }}</option>
        <option v-for="brand in brands" :key="brand.id" :value="brand.id">{{ brand.name }}</option>
      </select>
    </div>
    <div :class="itemClass">
      <label for="model_id">{{ $t('cars.Model') }}</label>
      <select id="model_id" v-model="model.model_id" class="form-control form-control-sm" :disabled="!model.brand_id || !isBrandLoaded || !isModelLoaded" >
        <option value="" >{{ $t('cars.Model') }}</option>
        <option v-for="carModel in models" :key="carModel.id" :value="carModel.id">{{ carModel.name }}</option>
      </select>
    </div>
    <div :class="itemClass" v-if="partNameVisible">
      <label for="part_name">{{ $t('requests.Part Name') }}</label>
      <input id="part_name" v-model="model.part_name" class="form-control form-control-sm" >
    </div>
    <div :class="itemClass" v-if="requestIdVisible">
      <label for="request_id">{{ $t('requests.Request No') }}</label>
      <input id="request_id" v-model="model.request_id" class="form-control form-control-sm" >
    </div>
  </div>
</template>

<script>
export default {
  name: 'SellerPartSearch',
  props: ['value', 'showPartName', 'dir', 'showRequestId'],
  data () {
    return {
      model: {
        car_category_id: null,
        brand_id: null,
        model_id: null,
        part_name: null,
        request_id: null
      },
      brands: [],
      models: [],
      isBrandLoaded: false,
      isModelLoaded: false,
      partNameVisible: false,
      requestIdVisible: false,
      itemClass: 'col',
      timeOut: null
    }
  },
  mounted () {
    this.partNameVisible = !!this.showPartName
    this.requestIdVisible = !!this.showRequestId
    this.itemClass = this.dir === 'vertical' ? 'col-md-12 col-sm-4 col-6' : 'col'
  },
  watch: {
    model: {
      deep: true,
      handler (newVal) {
        const that = this
        clearTimeout(this.timeOut)
        this.timeOut = setTimeout(function () {
          console.log('debounceee')
          that.$emit('input', newVal)
        }, 500)
      }
    }
  },
  methods: {
    getBrandsByCarCategory (carCategoryId) {
      this.brands = []
      this.models = []
      this.isBrandLoaded = false
      this.model.brand_id = null
      this.model.model_id = null
      if (!carCategoryId) {
        return
      }
      this.$store.dispatch('getBrandsByCarCategory', carCategoryId)
        .then(res => {
          this.brands = res.data.data
          this.isBrandLoaded = true
          this.isBrandFieldDisabled = false
        }).catch(error => {
          this.isBrandLoaded = true
          console.log(error)
        })
    },
    getModelsByCategoryBrand (carCategoryId, brandId) {
      this.models = []
      this.model.model_id = null
      this.isModelLoaded = false
      if (!carCategoryId || !brandId) {
        return
      }
      this.$store.dispatch('getModelsByCategoryBrand', { car_category_id: carCategoryId, brand_id: brandId })
        .then(res => {
          this.models = res.data.data.map((element) => {
            return { name: element.name, id: element.id }
          })
          this.isModelLoaded = true
          this.isModelFieldDisabled = false
        }).catch(error => {
          this.isModelLoaded = true
          console.log(error)
        })
    }
  }
}
</script>

<style scoped>
.form-control {
  height: 2rem;
}
</style>
