<template>
  <div>
    <!--hero section start-->

    <HeroSection>
      <h1>{{ $t('requests.Request List') }}</h1>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb justify-content-center bg-transparent p-0 m-0">
          <li class="breadcrumb-item"><router-link class="text-dark" :to="{ name: 'Dashboard', params: { lang: this.$i18n.locale }}">{{ $t('menu.Home') }}</router-link></li>
          <li class="breadcrumb-item active text-primary" aria-current="page">{{ $t('requests.Request List') }}</li>
        </ol>
      </nav>
    </HeroSection>

    <!--hero section end-->

    <!--body content start-->

    <div class="page-content">

    <section>
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <div class="tab" style="position: relative;">
              <Spinner v-if="isSpinnerShown"/>
              <!-- Nav tabs -->
              <nav class="requests-status-nav">
                <div class="nav nav-tabs d-flex justify-content-center w-100 border-0" id="nav-tab" role="tablist">
                  <a class="nav-item nav-link rounded mb-2" :class="{ 'active': !status }" id="nav-tab1" data-toggle="tab" @click="getRequests()" href="#tab1-1" role="tab" :aria-selected="!status">{{ $t('requests.New Requests') }}</a>
                  <a class="nav-item nav-link rounded mb-2" :class="{ 'active': parseInt(status) === requestNotFoundStatus }" id="nav-tab2" data-toggle="tab" @click="getRequests(requestNotFoundStatus)" href="#tab1-2" role="tab" :aria-selected="parseInt(status) === requestNotFoundStatus "> {{ $t('requests.Not Found Requests') }}</a>
                  <a class="nav-item nav-link rounded mb-2" :class="{ 'active': parseInt(status) === requestUnclearStatus }" id="nav-tab5" data-toggle="tab" @click="getRequests(requestUnclearStatus)" href="#tab1-5" role="tab" :aria-selected="parseInt(status) === requestUnclearStatus "> {{ $t('requests.Unclear Requests') }}</a>
                </div>
              </nav>
              <div class="tab-content px-5 pt-7" id="nav-tabContent">

                <div role="tabpanel" class="tab-pane fade" :class="{ 'show active': !status }" id="tab1-1">
                  <RequestList v-if="!status && requests && requests.length" :requests="requests"  :pageCount="pageCount" @removeRequest="removeRequest" @clickCallback="clickCallback" :status="status"/>
                  <EmptyListMessage v-else-if="!isSpinnerShown">{{ $t('requests.New Requests list is empty') }}</EmptyListMessage>
                </div>
                <div role="tabpanel" class="tab-pane fade" :class="{ 'show active': parseInt(status) === requestNotFoundStatus }" id="tab1-2">
                  <RequestList v-if="parseInt(status) === requestNotFoundStatus && requests && requests.length" :requests="requests"  :pageCount="pageCount" @removeRequest="removeRequest" @clickCallback="clickCallback" :status="status"/>
                  <EmptyListMessage v-else-if="!isSpinnerShown">{{ $t('requests.Not Found Requests list is empty') }}</EmptyListMessage>
                </div>
                <div role="tabpanel" class="tab-pane fade" :class="{ 'show active': parseInt(status) === requestUnclearStatus }" id="tab1-5">
                  <RequestList v-if="parseInt(status) === requestUnclearStatus && requests && requests.length" :requests="requests"  :pageCount="pageCount" @removeRequest="removeRequest" @clickCallback="clickCallback" :status="status"/>
                  <EmptyListMessage v-else-if="!isSpinnerShown">{{ $t('requests.Unclear Requests list is empty') }}</EmptyListMessage>
                </div>
              </div>
            </div>
            <!-- Tab panes -->
          </div>
        </div>

        <!------->
      </div>
    </section>

    </div>

    <!--body content end-->
  </div>
</template>

<script>
import RequestList from '../../components/request/RequestList.vue'
import EmptyListMessage from '../../components/EmptyListMessage.vue'

export default {
  name: 'Requests',
  data () {
    return {
      pageNumber: null,
      status: 4,
      pageCount: null,
      requests: null,
      isSpinnerShown: false,
      id: null
    }
  },
  components: {
    RequestList,
    EmptyListMessage
  },
  mounted () {
    this.id = parseInt(this.$route.query.id)
    this.getCurrentPageRequests()
  },
  updated () {
    if (this.id) {
      this.$nextTick(function () {
        if (document.getElementById('view-details-btn-' + this.id)) {
          document.getElementById('view-details-btn-' + this.id).click()
          this.id = null
          this.$nextTick(function () {
            window.$('a[href="#offers-content"]').tab('show')
          })
        }
      })
    }
  },
  methods: {
    removeRequest () {
      this.getCurrentPageRequests()
    },
    getCurrentPageRequests () {
      var pageNumber

      if (!this.$route.query.page) {
        pageNumber = 1
      } else {
        pageNumber = parseInt(this.$route.query.page)
      }

      if (this.$route.query.status) {
        this.status = parseInt(this.$route.query.status)
        this.getRequestsByStatus(this.status, pageNumber)
      } else {
        this.status = null
        this.getNewRequests(pageNumber)
      }
    },
    getNewRequests (page) {
      this.isSpinnerShown = true
      this.$store.dispatch('getBuyerNewRequests', page)
        .then(res => {
          this.isSpinnerShown = false

          if (res.data.data.current_page > res.data.data.last_page) {
            this.getRequests(null, res.data.data.last_page)
          }

          this.searchForElement(res.data.data, null)

          this.requests = res.data.data.data
          this.pageCount = res.data.data.last_page
        }).catch(error => {
          this.isSpinnerShown = false
          console.log(error)
        })
    },
    getRequestsByStatus (status, page) {
      this.isSpinnerShown = true
      this.$store.dispatch('getBuyerArchivedRequests', { status: status, page: page })
        .then(res => {
          this.isSpinnerShown = false

          if (res.data.data.current_page > res.data.data.last_page) {
            this.getRequests(status, res.data.data.last_page)
          }

          this.searchForElement(res.data.data, this.status)

          this.requests = res.data.data.data
          this.pageCount = res.data.data.last_page
        }).catch(error => {
          this.isSpinnerShown = false
          console.log(error)
        })
    },
    searchForElement (responseData, status) {
      if (this.$route.query.id) {
        if ((responseData.data.filter(elem => elem.id === this.id).length === 0) && (responseData.current_page < responseData.last_page)) {
          this.getRequests(this.status, responseData.current_page + 1, false)
        } else if ((responseData.data.filter(elem => elem.id === this.id).length === 0)) {
          this.id = null
          this.removeIdFromRoute()
          this.getRequests(this.status, 1, false)
        } else if ((responseData.data.filter(elem => elem.id === this.id).length !== 0)) {
          this.removeIdFromRoute(responseData.current_page)
        }
      }
    },
    getRequests (status = null, pageNumber = 1, isRoutePushed = true) {
      this.requests = null

      if (status) {
        this.status = status
        if (isRoutePushed) {
          this.$router.push({ name: 'Requests', query: { page: pageNumber, status: this.status, id: this.$route.query.id } })
        }
        this.getRequestsByStatus(status, pageNumber)
      } else {
        this.status = null
        if (isRoutePushed) {
          this.$router.push({ name: 'Requests', query: { page: pageNumber, id: this.$route.query.id } })
        }
        this.getNewRequests(pageNumber)
      }
    },
    clickCallback (pageNum) {
      this.getRequests(this.status, pageNum)
    }
  }
}
</script>

<style>
  .dark-link-text {
    color: #1d1d33;
  }
</style>
