<template>
  <div>
    <!--hero section start-->

    <HeroSection>
      <h1>{{ $t('requests.Requests Archive') }}</h1>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb justify-content-center bg-transparent p-0 m-0">
          <li class="breadcrumb-item"><router-link class="text-dark" :to="{ name: 'Dashboard', params: { lang: this.$i18n.locale }}">{{ $t('menu.Home') }}</router-link></li>
          <li class="breadcrumb-item active text-primary" aria-current="page">{{ $t('requests.Requests Archive') }}</li>
        </ol>
      </nav>
    </HeroSection>

    <!--hero section end-->

    <!--body content start-->

    <div class="page-content">

    <section>
      <div class="container" style="position: relative;">
        <Spinner v-if="isSpinnerShown"/>
        <div class="row">
          <div class="col-sm-12">
            <RequestList :requests="requests"  :pageCount="pageCount" @removeRequest="removeRequest" @clickCallback="clickCallback" :status="status"/>
          </div>
        </div>
        <!------->
      </div>
    </section>

    </div>
    <!--body content end-->
  </div>
</template>

<script>
import RequestList from '../../components/request/RequestList.vue'

export default {
  name: 'Requests',
  data () {
    return {
      pageNumber: null,
      status: 4,
      pageCount: null,
      requests: null,
      isSpinnerShown: false
    }
  },
  components: {
    RequestList
  },
  mounted () {
    this.getCurrentPageRequests()
  },
  methods: {
    removeRequest () {
      this.getCurrentPageRequests()
    },
    getCurrentPageRequests () {
      var pageNumber

      if (!this.$route.query.page) {
        pageNumber = 1
      } else {
        pageNumber = parseInt(this.$route.query.page)
      }

      this.status = this.requestArchivedStatus
      this.getRequestsByStatus(this.status, pageNumber)
    },
    getRequestsByStatus (status, page) {
      this.isSpinnerShown = true
      this.$store.dispatch('getBuyerArchivedRequests', { status: status, page: page })
        .then(res => {
          this.isSpinnerShown = false

          if (res.data.data.current_page > res.data.data.last_page) {
            this.getRequests(status, res.data.data.last_page)
          }

          this.searchForElement(res.data.data, this.status)

          this.requests = res.data.data.data
          this.pageCount = res.data.data.last_page
        }).catch(error => {
          this.isSpinnerShown = false
          console.log(error)
        })
    },
    searchForElement (responseData, status) {
      if (this.$route.query.id) {
        var id = parseInt(this.$route.query.id)
        if ((responseData.data.filter(elem => elem.id === id).length === 0) && (responseData.current_page < responseData.last_page)) {
          this.getRequests(this.status, responseData.current_page + 1, false)
        } else if ((responseData.data.filter(elem => elem.id === id).length === 0)) {
          this.removeIdFromRoute()
          this.getRequests(this.status, 1, false)
        } else if ((responseData.data.filter(elem => elem.id === id).length !== 0)) {
          this.$nextTick(function () {
            if (document.getElementById('view-details-btn-' + id)) {
              document.getElementById('view-details-btn-' + id).click()
              this.$nextTick(function () {
                window.$('a[href="#offers-content"]').tab('show')
              })
              this.removeIdFromRoute(responseData.current_page)
            }
          })
        }
      }
    },
    getRequests (status = null, pageNumber = 1, isRoutePushed = true) {
      this.requests = null

      this.status = status
      if (isRoutePushed) {
        this.$router.push({ name: 'RequestsArchive', query: { page: pageNumber, id: this.$route.query.id } })
      }
      this.getRequestsByStatus(status, pageNumber)
    },
    clickCallback (pageNum) {
      this.getRequests(this.status, pageNum)
    }
  }
}
</script>

<style>
  .dark-link-text {
    color: #1d1d33;
  }
</style>
