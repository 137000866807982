<template>
  <div id="navbarNav" class="sidebar collapse" :style="{'background-image': `url(${require('../assets/images/header/sidebar.png')})`}">
    <div>
      <ul class="list-unstyled list-group list-group-flush mb-4">
        <li class="nav-item mb-6"><router-link :to="{ name: 'Dashboard', params: { lang: this.$i18n.locale }}" class="sidebar-nav-item text-white font-weight-500 font-size-20"><img src="@/assets/images/header/sidebar-logo.png" class="img-fluid" alt="..."></router-link></li>
        <li class="nav-item mb-2 text-center rounded" :class="$route.name === 'Dashboard' ? 'active': ''"><router-link :to="{ name: 'Dashboard', params: { lang: this.$i18n.locale }}" class="sidebar-nav-item text-white font-weight-500 font-size-20" exact-active-class="active">{{ $t('menu.Dashboard') }}</router-link></li>

        <li v-if="authBuyer" class="nav-item mb-2 text-center rounded" :class="$route.name === 'NewRequest' ? 'active': ''"><router-link :to="{ name: 'NewRequest', params: { lang: this.$i18n.locale }}" class="sidebar-nav-item text-white font-weight-500 font-size-20" exact-active-class="active">{{ $t('cars.Request A Part') }}</router-link></li>
        <li v-if="authBuyer" class="nav-item mb-2 text-center rounded" :class="$route.name === 'Requests' ? 'active': ''"><router-link :to="{ name: 'Requests', params: { lang: this.$i18n.locale }}" class="sidebar-nav-item text-white font-weight-500 font-size-20" exact-active-class="active">{{ $t('menu.Requests') }}</router-link></li>
        <li v-if="authBuyer" class="nav-item mb-2 text-center rounded" :class="$route.name === 'RequestsArchive' ? 'active': ''"><router-link :to="{ name: 'RequestsArchive', params: { lang: this.$i18n.locale }}" class="sidebar-nav-item text-white font-weight-500 font-size-20" exact-active-class="active">{{ $t('menu.Requests Archive') }}</router-link></li>
        <li v-if="authBuyer" class="nav-item mb-2 text-center rounded" :class="$route.name === 'Offers' ? 'active': ''"><router-link :to="{ name: 'Offers', params: { lang: this.$i18n.locale }}" class="sidebar-nav-item text-white font-weight-500 font-size-20" exact-active-class="active">{{ $t('buyer.Offers') }}</router-link></li>
        <li v-if="authBuyer" class="nav-item mb-0 text-center rounded" :class="$route.name === 'Orders' ? 'active': ''"><router-link :to="{ name: 'Orders', params: { lang: this.$i18n.locale }}" class="sidebar-nav-item text-white font-weight-500 font-size-20" exact-active-class="active">{{ $t('menu.Orders') }}</router-link></li>

        <li v-if="authSeller" class="nav-item mb-2 text-center rounded" :class="$route.name === 'PendingSellerPricing' ? 'active': ''"><router-link :to="{ name: 'PendingSellerPricing', params: { lang: this.$i18n.locale }, query: { page: 1 }}" class="sidebar-nav-item text-white font-weight-500 font-size-20" exact-active-class="active">{{ $t('menu.New Requests') }}</router-link></li>
        <li v-if="authSeller" class="nav-item mb-2 text-center rounded" :class="$route.name === 'partCategories' ? 'active': ''"><router-link :to="{ name: 'partCategories', params: { lang: this.$i18n.locale }}" class="sidebar-nav-item text-white font-weight-500 font-size-20" exact-active-class="active">{{ $t('menu.My Store') }}</router-link></li>
        <li v-if="authSeller" class="nav-item mb-2 text-center rounded" :class="$route.name === 'pricedParts' ? 'active': ''"><router-link :to="{ name: 'pricedParts', params: { lang: this.$i18n.locale }}" class="sidebar-nav-item text-white font-weight-500 font-size-20" exact-active-class="active">{{ $t('menu.Priced Parts') }}</router-link></li>
        <li v-if="authSeller" class="nav-item mb-2 text-center rounded" :class="$route.name === 'sellerOffers' ? 'active': ''"><router-link :to="{ name: 'sellerOffers', params: { lang: this.$i18n.locale }, query: { page: 1, status: offerStatus[offerPendingBuyerApprove].slug }}" class="sidebar-nav-item text-white font-weight-500 font-size-20" exact-active-class="active">{{ $t('menu.Offers') }}</router-link></li>
        <li v-if="authSeller" class="nav-item mb-0 text-center rounded" :class="$route.name === 'Sales' ? 'active': ''"><router-link :to="{ name: 'Sales', params: { lang: this.$i18n.locale }}" class="sidebar-nav-item text-white font-weight-500 font-size-20" exact-active-class="active">{{ $t('menu.Sales') }}</router-link></li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SideBar',
  mounted () {
    window.$('#navbarNav').on('shown.bs.collapse', function () {
      if (parseInt(window.$(window).scrollTop()) === 0) {
        window.$('#header-bottom').addClass('fixed-bottom-header')
      }
    })

    window.$('#navbarNav').on('hidden.bs.collapse', function () {
      if (parseInt(window.$(window).scrollTop()) === 0) {
        window.$('#header-bottom').removeClass('fixed-bottom-header')
      }
    })

    window.$(document).click(function (event) {
      if (window.$(window).width() < 992 && window.$('.sidebar').hasClass('show')) {
        if ((!window.$(event.target).is('.navbar-toggler') && !window.$(event.target).closest('.sidebar').length) || window.$(event.target).is('.sidebar-nav-item') || window.$(event.target).closest('.sidebar-nav-item').length) {
          window.$('button.navbar-toggler').click()
        }
      }
    })
  },
  computed: {
    authBuyer () {
      return this.$store.getters.isAuthenticatedBuyer
    },
    authSeller () {
      return this.$store.getters.isAuthenticatedSeller
    }
  }
}
</script>
