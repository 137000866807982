<template>
  <div class="main-page-wrapper-bg" :style="{'background-image': `url(${require('@/assets/images/other/bg.jpg')})`}">
    <!--hero section start-->

    <HeroSection>
      <h1>{{ $t('loginSignup.Sign Up Seller') }}</h1>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb justify-content-center bg-transparent p-0 m-0">
          <li class="breadcrumb-item"><router-link class="text-dark" :to="{ name: 'Home', params: { lang: this.$i18n.locale }}">{{ $t('menu.Home') }}</router-link></li>
          <li class="breadcrumb-item">{{ $t('menu.Signup') }}</li>
          <li class="breadcrumb-item active text-primary" aria-current="page">{{ $t('loginSignup.Sign Up Seller') }}</li>
        </ol>
      </nav>
    </HeroSection>

    <!--hero section end-->

    <!--body content start-->

    <div class="page-content">

    <!--login start-->

    <section class="register">
      <div class="container" style="position: relative;">
        <Spinner v-if="isSpinnerShown"/>
        <div class="row justify-content-center text-center">
          <div class="col-lg-8 col-md-12">
            <div class="mb-6"> <span class="badge badge-primary-soft p-2">
                    <img class="user-profile-icon" src="@/assets/images/other/profile.png">
                  </span>
              <h2 class="mt-3">{{ $t('loginSignup.Sign Up Seller') }}</h2>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-8 col-md-10 ml-auto mr-auto">
            <div class="register-form">
              <form method="post">
                <div class="messages"></div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="full_name">{{ $t('loginSignup.Full Name') }}</label>
                      <input id="full_name" v-model="seller.full_name" type="text" name="full_name" class="form-control" :placeholder="$t('loginSignup.Full Name')" required="required" :data-error="$t('loginSignup.Full Name is required')">
                      <div v-if="!error.full_name" class="help-block with-errors"></div>
                      <div class="error-message-block">{{ error.full_name }}</div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="email">{{ $t('loginSignup.Email') }}</label>
                      <input id="email" v-model="seller.email" type="email" name="email" class="form-control"  :placeholder="$t('loginSignup.Email')" :data-error="$t('loginSignup.Email is required')">
                      <div v-if="!error.email" class="help-block with-errors"></div>
                      <div class="error-message-block">{{ error.email }}</div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <label for="mobile">{{ $t('loginSignup.Phone number') }}</label>
                    <div class="row dial-phone-number" dir="ltr">
                      <div class="form-group col-sm-6 dial-code">
                        <input type="text" value="+966" class="form-control input-1" readonly>
                        <div class="help-block with-errors"></div>
                      </div>
                      <div class="form-group col-sm-6 phone-number">
                        <input-mask mask="599999999" v-model="seller.mobile" id="mobile" type="text" class="form-control text-center" :placeholder="$t('loginSignup.Phone number')" required="required" :data-error="$t('loginSignup.Phone number is required')"></input-mask>
                        <div v-if="!error.mobile" class="help-block with-errors"></div>
                        <div class="error-message-block">{{ error.mobile }}</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="username">{{ $t('loginSignup.Username') }}</label>
                      <input id="username" v-model="seller.username" type="text" name="username" class="form-control" :placeholder="$t('loginSignup.Username')" required="required" :data-error="$t('loginSignup.Username is required')">
                      <div v-if="!error.username" class="help-block with-errors"></div>
                      <div class="error-message-block">{{ error.username }}</div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="password">{{ $t('loginSignup.Password') }}</label>
                      <input id="password" v-model="seller.password" type="password" name="password" class="form-control" :placeholder="$t('loginSignup.Password')" required="required" :data-error="$t('loginSignup.password is required')">
                      <div v-if="showPasswordDataError"  class="help-block with-errors"></div>
                      <div class="error-message-block">
                        {{ error.password }}
                        <span v-if="isShownPasswordLengthError">{{ $t('loginSignup.Password should have at least 6 characters') }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group" id="confirmPasswordForm">
                      <label for="form_password1">{{ $t('loginSignup.Confirm Password') }}</label>
                      <input id="form_password1" v-model="confirmationPassword" type="password" name="confirm_password" class="form-control" :placeholder="$t('loginSignup.Confirm Password')" required="required" :data-error="$t('loginSignup.Confirm Password is required')">
                      <div v-if="showConfirmPasswordDataError" class="help-block with-errors"></div>
                      <div class="error-message-block">
                        <span v-if="isShownrequiredConfirmPasswordError">{{ $t('loginSignup.Confirm Password is required') }}</span>
                        <span v-if="isShownPasswordsDoNotMatchError">{{ $t('loginSignup.Passwords do not match') }}</span>
                        {{ error.confirmationPassword }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="country_id">{{ $t('loginSignup.Country') }}</label>
                      <select id="country_id" v-model="country_id" class="form-control">
                        <option value="0" disabled>{{ $t('loginSignup.Country') }}</option>
                        <option v-for="country in countries" :key="country.id" :value="country.id">{{ country[getFieldByLocale()] }}</option>
                      </select>
                      <div v-if="!error.country_id" class="help-block with-errors"></div>
                      <div class="error-message-block">{{ error.country_id }}</div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="city_id">{{ $t('loginSignup.City') }}</label>
                      <select v-model="seller.city_id" class="form-control" :disabled="isCityFieldDisabled">
                        <option value="0" disabled>{{ $t('loginSignup.City') }}</option>
                        <option v-for="city in cities" :key="city.id" :value="city.id">{{ city[getFieldByLocale()] }}</option>
                      </select>
                      <div v-if="!error.city_id" class="help-block with-errors"></div>
                      <div class="error-message-block">{{ error.city_id }}</div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="store_name">{{ $t('loginSignup.Store Name') }}</label>
                      <input id="store_name" v-model="seller.store_name" type="text" name="store_name" class="form-control" :placeholder="$t('loginSignup.Store Name')" required="required" :data-error="$t('loginSignup.Store Name is required')">
                      <div v-if="!error.store_name" class="help-block with-errors"></div>
                      <div class="error-message-block">{{ error.store_name }}</div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="row">
                      <div class="col-12">
                        <div class="form-group">
                          <label for="dropzone">{{ $t('loginSignup.Store Image') }}</label>
                          <ImageUpload :imageType="imageType" :errorMessage="error.store_img" :isEditImages="false" @isfileUplaodingChange="setIsfileUplaoding" @addedImagesChange="setAddedImages" :maxFilesUpload="1" />

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div class="form-group">
                      <label>{{ $t('loginSignup.Store Location') }}</label><br>
                          <div id="map"></div>
                    </div>
                  </div>
                </div>
                <div class="row mt-5">
                  <div class="col-md-12">
                    <div class="remember-checkbox clearfix mb-5">
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 text-center">
                    <button class="btn btn-primary" @click.prevent="signup" :disabled="!isReadyForSubmission || isfileUplaoding">{{ $t('loginSignup.Create Account') }}</button>
                    <!--<span class="mt-4 d-block">{{ $t('loginSignup.Have An Account ?') }} <router-link :to="{ name: 'Login', params: { lang: this.$i18n.locale }}"><i>{{ $t('loginSignup.Sign In!') }}</i></router-link></span>-->
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!--login end-->

    </div>

    <!--body content end-->
  </div>
</template>

<script>
import InputMask from 'vue-input-mask'
import ImageUpload from '../../components/ImageUpload'

export default {
  name: 'SellerSignupForm',
  data () {
    return {
      map: null,
      marker: null,
      cities: null,
      country_id: 0,
      countries: null,
      confirmationPassword: null,
      isCityFieldDisabled: true,
      isAutomatedUsername: false,
      automatedUsername: null,
      isSpinnerShown: false,
      isShownrequiredConfirmPasswordError: false,
      isShownPasswordsDoNotMatchError: false,
      isShownPasswordLengthError: false,
      imageType: null,
      isfileUplaoding: false,

      error: {
        full_name: null,
        username: null,
        password: null,
        mobile: null,
        email: null,
        city_id: null,
        confirmationPassword: null,
        country_id: null,
        lat: null,
        lng: null,
        store_name: null,
        store_img: null
      },
      seller: {
        full_name: null,
        username: null,
        password: null,
        dial_code: '966',
        mobile: null,
        email: null,
        city_id: 0,
        client_lang: null,
        lat: null,
        lng: null,
        store_name: null,
        store_img: null
      }
    }
  },
  components: {
    ImageUpload,
    InputMask
  },
  mounted () {
    this.imageType = 'clients'

    this.$store.dispatch('getCountries')
      .then(res => {
        this.countries = res.data.data
      }).catch(error => {
        console.log(error)
      })

    this.initMap()
  },
  computed: {
    isReadyForSubmission () {
      return this.seller.full_name && this.seller.username && this.seller.mobile && this.seller.password && this.confirmationPassword && this.country_id && this.seller.city_id
    },
    showPasswordDataError () {
      return !(this.error.password || this.isShownPasswordLengthError)
    },
    showConfirmPasswordDataError () {
      return !(this.error.confirmationPassword || this.isShownrequiredConfirmPasswordError || this.isShownPasswordsDoNotMatchError)
    }
  },
  watch: {
    'seller.mobile' () {
      var counter = 0
      for (var i = 0; i < 9; i++) {
        if (this.seller.mobile[i] !== '_') {
          counter = counter + 1
        }
      }

      if (counter === 9 && !this.seller.username) {
        this.seller.username = this.seller.dial_code + this.seller.mobile
        this.automatedUsername = this.seller.dial_code + this.seller.mobile
        this.isAutomatedUsername = true
      } else if (counter === 9 && this.isAutomatedUsername) {
        this.seller.username = this.seller.dial_code + this.seller.mobile
        this.automatedUsername = this.seller.dial_code + this.seller.mobile
      } else if (this.seller.username === (this.seller.dial_code + this.seller.mobile)) {
        this.isAutomatedUsername = true
      }
    },
    'seller.username' () {
      if (this.automatedUsername !== this.seller.username) {
        this.isAutomatedUsername = false
      }
    },
    country_id (val) {
      this.seller.city_id = 0

      if (val === '0') {
        this.isCityFieldDisabled = true
      } else {
        this.$store.dispatch('getCitiesbyCountry', val)
          .then(res => {
            this.cities = res.data.data
            this.isCityFieldDisabled = false
          }).catch(error => {
            console.log(error)
          })
      }
    }
  },
  methods: {
    setIsfileUplaoding (value) {
      this.isfileUplaoding = value
    },
    setAddedImages (value) {
      this.seller.store_img = value.join(',')
    },
    initMap () {
      var myLatLng = { lat: 21.27520372782979, lng: 39.235387667280975 }

      this.map = new window.google.maps.Map(document.getElementById('map'), {
        zoom: 10,
        center: myLatLng
      })

      this.marker = new window.google.maps.Marker({
        position: myLatLng,
        map: this.map,
        draggable: true,
        title: 'Customer here!'
      })

      window.google.maps.event.addListener(this.marker, 'dragend', (event) => {
        this.seller.lat = this.marker.getPosition().lat()
        this.seller.lng = this.marker.getPosition().lng()
      })
    },
    markerLocation () {
      // Get location.
      var currentLocation = this.marker.getPosition()
      // Add lat and lng values to a field that we can save.
      this.seller.lat = currentLocation.lat() // latitude
      this.seller.lng = currentLocation.lng() // longitude
    },
    signup () {
      this.error = {
        full_name: null,
        username: null,
        password: null,
        mobile: null,
        email: null,
        city_id: null,
        confirmationPassword: null,
        country_id: null
      }

      this.isShownrequiredConfirmPasswordError = false
      this.isShownPasswordsDoNotMatchError = false
      this.isShownPasswordLengthError = false

      this.seller.client_lang = this.$i18n.locale

      if (this.seller.password.length < 6) {
        this.isShownPasswordLengthError = true
      } else if (this.confirmationPassword === null) {
        this.isShownrequiredConfirmPasswordError = true
      } else if (this.seller.password !== this.confirmationPassword) {
        this.isShownPasswordsDoNotMatchError = true
      } else {
        this.isSpinnerShown = true
        this.$store.dispatch('signupSeller', this.seller)
          .then(res => {
            this.isSpinnerShown = false
            this.$emit('respond', res.data.data)
          }).catch(error => {
            console.log(error)
            this.isSpinnerShown = false
            if (error.response) {
              for (var field in error.response.data[0]) {
                this.error[field] = error.response.data[0][field][0]
              }
            }
          })
      }
    }
  }
}
</script>
