import axios from '@/plugins/axios'

const state = {
  notifications_count: ''
}

const getters = {
  getNotificationsCount: (state) => {
    return state.notifications_count
  }
}

const mutations = {
  setNotificationsCount: (state, notificationsCount) => {
    state.notifications_count = notificationsCount !== 0 ? notificationsCount : ''
  },
  clearNotificationState: (state) => {
    state.notifications_count = ''
  }
}

const actions = {
  getNotificationsCount: ({ commit, state, dispatch }) => {
    dispatch('getNotifications', 1)
      .then(res => {
        commit('setNotificationsCount', res.data.unread_count)
      }).catch(error => {
        console.log(error)
      })
  },
  getNotifications: ({ commit, state, rootState }, page) => {
    return axios.get('/api/notifications?page=' + page, {
      headers: {
        Authorization: 'Bearer ' + rootState.loginSignup.api_token
      }
    })
      .then(res => {
        commit('setNotificationsCount', res.data.unread_count)
        return res
      }).catch(error => {
        return Promise.reject(error)
      })
  },
  markAllAsRead: ({ commit, state, rootState }) => {
    return axios.post('/api/notifications/read-all', {}, {
      headers: {
        Authorization: 'Bearer ' + rootState.loginSignup.api_token
      }
    })
      .then(res => {
        return res
      }).catch(error => {
        return Promise.reject(error)
      })
  },
  markOneAsRead: ({ commit, state, rootState }, notificationId) => {
    return axios.post('/api/notifications/read', { id: notificationId }, {
      headers: {
        Authorization: 'Bearer ' + rootState.loginSignup.api_token
      }
    })
      .then(res => {
        return res
      }).catch(error => {
        return Promise.reject(error)
      })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
