<template>
  <div>
    <!--hero section start-->

        <HeroSection>
          <h1>{{ $t('orders.Orders') }}</h1>
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb justify-content-center bg-transparent p-0 m-0">
              <li class="breadcrumb-item"><router-link class="text-dark" :to="{ name: 'Dashboard', params: { lang: this.$i18n.locale }}">{{ $t('menu.Home') }}</router-link></li>
              <li class="breadcrumb-item active text-primary" aria-current="page">{{ $t('orders.Orders') }}</li>
            </ol>
          </nav>
        </HeroSection>

        <!--hero section end-->

        <!--body content start-->

        <div class="page-content">
          <section class="pb-0" style="position: relative;">
            <Spinner v-if="isSpinnerShown"/>
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <div class="row">

                    <div class="col-12 col-md-6 col-lg-6 mb-5"  v-for="order in orders" :key="order.id">
                      <div class="card p-4 shadow border-0 flex-sm-row">

                        <div class="card-body p-0 mb-3 mb-sm-0" :class="isRtl ? 'ml-sm-5' : 'mr-sm-5'">
                          <div class="mb-2">
                            <h5 class="text-primary text-capitalize d-inline">{{ order.status_label }}</h5>
                          </div>
                          <span class="font-size-16">{{ $t('orders.Order Id') }}: {{ order.id }}</span><br>
                          <span class="font-size-16">{{ $t('orders.Parts Count') }}: {{ order.offers_count }}</span><br>
                          <span class="font-size-16 mb-0">{{ $t('orders.Total') }}: {{ order.total }} {{ getCurrency () }}</span><br>
                          <span class="font-size-16 text-in-amber mt-3" :class="isRtl? 'mr-auto' : 'ml-auto'"><small>{{ order.created_at ? order.created_at.split('T')[0].split('-').reverse().join('-') : ''}}</small></span>

                        </div>
                        <div class="w-50 d-flex align-self-center">
                          <router-link :to="{ name: 'ViewOrder', params: { lang: $i18n.locale, id: order.id}}"  class="view-details-btn background-amber font-weight-600 font-size-14 text-in-grey shadow-primary d-inline text-nowrap">{{ $t('orders.View Details') }}</router-link>
                        </div>
                      </div>
                    </div>

                  </div>
                  <!-- / .row -->
                  <nav aria-label="..." class="pagination-navigation mt-8" v-if="pageCount">
                    <paginate
                      v-model="page"
                      :page-count="pageCount"
                      :page-range="10"
                      :margin-pages="1"
                      :click-handler="clickCallback"
                      :prev-text="$t('common.Prev')"
                      :next-text="$t('common.Next')"
                      :prev-class=" isRtl ? 'page-item ml-auto' : 'page-item mr-auto'"
                      :prev-link-class="'page-link'"
                      :next-class=" isRtl ? 'page-item mr-auto' : 'page-item ml-auto'"
                      :next-link-class="'page-link'"
                      :container-class="'pagination'"
                      :page-class="'page-item'"
                      :page-link-class="'dark-link-text page-link border-0 rounded'">
                    </paginate>
                  </nav>
                </div>
              </div>
            </div>
          </section>

        </div>

        <!--body content end-->
  </div>
</template>

<script>
import Paginate from 'vuejs-paginate'

export default {
  name: 'Orders',
  data () {
    return {
      orders: null,
      page: 1,
      pageCount: null,
      isSpinnerShown: false
    }
  },
  components: {
    Paginate
  },
  mounted () {
    if (!this.$route.query.page) {
      this.page = 1
    } else {
      this.page = parseInt(this.$route.query.page)
    }

    this.getOrders(this.page)
  },
  methods: {
    getOrders (page) {
      this.isSpinnerShown = true
      this.$store.dispatch('getOrders', page)
        .then(res => {
          this.isSpinnerShown = false

          if (res.data.data.current_page > res.data.data.last_page) {
            this.page = res.data.data.last_page
            this.clickCallback(res.data.data.last_page)
          }

          this.orders = res.data.data.data
          this.pageCount = res.data.data.last_page
        }).catch(error => {
          this.isSpinnerShown = false
          console.log(error)
        })
    },
    clickCallback (pageNum) {
      this.$router.push({ name: 'Orders', query: { page: pageNum } })
      this.getOrders(pageNum)
    }
  }
}
</script>
