<template>
  <div>
    <!--hero section start-->

    <HeroSection>
            <h1>{{ $t('requests.Edit Request') }}</h1>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb justify-content-center bg-transparent p-0 m-0">
                <li class="breadcrumb-item"><router-link class="text-dark" :to="{ name: 'Dashboard', params: { lang: this.$i18n.locale }}">{{ $t('menu.Home') }}</router-link></li>
          <li class="breadcrumb-item"><router-link class="text-dark" :to="{ name: 'Requests', params: { lang: this.$i18n.locale }}">{{ $t('requests.Request List') }}</router-link></li>
                <li class="breadcrumb-item active text-primary" aria-current="page">{{ $t('requests.Edit Request') }}</li>
              </ol>
            </nav>
    </HeroSection>
    <!--hero section end-->

    <!--body content start-->

    <div class="page-content">

    <section>
      <div class="container" style="position: relative;">
        <Spinner v-if="isSpinnerShown"/>
        <div class="row justify-content-center mb-5 text-center">
          <div class="col-12 col-lg-8">
            <div> <span class="badge badge-primary-soft p-2">
                      <img class="request-icon" src="@/assets/images/other/dashboard1.png">
                  </span>
              <h2 class="mt-4 mb-0">{{ $t('requests.Edit Request') }}</h2>
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-12 col-lg-10">
              <form id="contact-form" class="row" method="post">
                <div class="messages"></div>
                <div class="form-group col-md-12">
                  <label for="details">{{ $t('requests.Request Details') }}</label>
                  <textarea id="details" v-model="request.details" name="details" class="form-control" :placeholder="$t('requests.Please enter Request Details')" rows="2"></textarea>
                  <div v-if="!error.details" class="help-block with-errors"></div>
                  <div class="error-message-block">{{ error.details }}</div>
                </div>
                <div class="form-group col-md-12">
                  <label for="dropzone-container1">{{ $t('requests.Part Images') }}</label>

                  <ImageUpload :imageType="imageType" :errorMessage="error.images" :isEditImages="true" :stringOfImages="request.images" :maxFilesUpload="maxFilesUpload" :placeholderMessage="$t('requests.Please add part images to make the pricing process easier for the seller')" @isfileUplaodingChange="setIsfileUplaoding" @addedImagesChange="setAddedImages" @deletedImagesChange="setDeletedImages" />

                  <div class="row">
                    <div class="col-md-12">
                      <UploadedImageConditions />
                    </div>
                  </div>

                </div>
                <div class="col-md-12 text-center mt-4">
                  <button class="btn btn-primary" @click.prevent="editRequest()" :disabled="!isReadyForSubmission || isfileUplaoding"><span>{{ $t('common.Update') }}</span>
                  </button>
                </div>
              </form>
        </div>
        </div>

      </div>
    </section>

    </div>

    <!--body content end-->
  </div>
</template>

<script>
import UploadedImageConditions from '../../components/UploadedImageConditions.vue'
import ImageUpload from '../../components/ImageUpload'

export default {
  components: {
    ImageUpload,
    UploadedImageConditions
  },
  data () {
    return {
      isSpinnerShown: false,

      isfileUplaoding: false,
      imageType: null,
      maxFilesUpload: null,

      error: {
        details: null,
        images: null
      },
      request: {
        id: null,
        details: null,
        images: null,
        added_images: '',
        deleted_images: ''
      }
    }
  },
  mounted () {
    this.imageType = 'requests'

    this.isSpinnerShown = true
    this.$store.dispatch('getBuyerRequestById', this.$route.params.id)
      .then(res => {
        this.isSpinnerShown = false
        this.request.id = res.data.data.id
        this.request.details = res.data.data.details
        this.request.images = res.data.data.images

        if (!res.data.data.images) {
          this.maxFilesUpload = 4
        } else if (res.data.data.images.split(',').length < 4) {
          this.maxFilesUpload = 4 - res.data.data.images.split(',').length
        } else {
          this.maxFilesUpload = 0
        }
      }).catch(error => {
        this.isSpinnerShown = false
        console.log(error)
      })
  },
  computed: {
    isReadyForSubmission () {
      return this.request.details
    }
  },
  methods: {
    setIsfileUplaoding (value) {
      this.isfileUplaoding = value
    },
    setAddedImages (value) {
      this.request.added_images = value.join(',')
    },
    setDeletedImages (value) {
      this.request.deleted_images = value.join(',')
    },
    editRequest () {
      this.error = {
        details: null,
        images: null
      }

      this.isSpinnerShown = true
      this.$store.dispatch('updateRequest', this.request)
        .then(res => {
          this.isSpinnerShown = false
          this.$router.push({ name: 'Requests', params: { lang: this.$i18n.locale } })
        }).catch(error => {
          this.isSpinnerShown = false
          console.log(error.response.data)

          if (error.response) {
            for (var field in error.response.data[0]) {
              this.error[field] = this.joinArrayByComma(error.response.data[0][field])
            }
          }
        })
    }
  }
}
</script>
