<template>
  <div class="main-page-wrapper-bg" :style="{'background-image': `url(${require('@/assets/images/other/bg.jpg')})`}">
    <!--hero section start-->

    <HeroSection>
      <h1>{{ $t('contactUs.Contact Us') }}</h1>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb justify-content-center bg-transparent p-0 m-0">
          <li class="breadcrumb-item"><router-link class="text-dark" :to="{ name: 'Home', params: { lang: this.$i18n.locale }}">{{ $t('menu.Home') }}</router-link></li>
          <li class="breadcrumb-item active text-primary" aria-current="page">{{ $t('contactUs.Contact Us') }}</li>
        </ol>
      </nav>
    </HeroSection>

    <!--hero section end-->

    <!--body content start-->

    <div class="page-content">

    <!--login start-->

    <section class="register">
      <div class="container" style="position: relative;">
        <Spinner v-if="isSpinnerShown"/>
        <div class="row justify-content-center text-center">
          <div class="col-lg-8 col-md-12">
            <div class="mb-6"> <span class="badge badge-primary-soft p-2">
                    <img class="user-profile-icon" src="@/assets/images/other/profile.png">
                  </span>
              <h2 class="mt-3">{{ $t('contactUs.Contact Us') }}</h2>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-8 col-md-10 ml-auto mr-auto">
            <div class="register-form">
              <form method="post">
                <div class="messages"></div>
                <div class="row justify-content-center">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="username">{{ $t('contactUs.Username') }}</label>
                      <input id="username" v-model="info.username" type="text" name="username" class="form-control" :placeholder="$t('contactUs.Username')" required="required" :data-error="$t('contactUs.Username is required')">
                      <div v-if="!error.username" class="help-block with-errors"></div>
                      <div class="error-message-block">{{ error.username }}</div>
                    </div>
                    <div class="form-group">
                      <label for="email">{{ $t('contactUs.Email') }}</label>
                      <input id="email" v-model="info.email" type="email" name="email" class="form-control"  :placeholder="$t('contactUs.Email')" required="required" :data-error="$t('contactUs.Email is required')">
                      <div v-if="!error.email" class="help-block with-errors"></div>
                      <div class="error-message-block">{{ error.email }}</div>
                    </div>
                    <div class="form-group">
                      <label for="details">{{ $t('contactUs.Message') }}</label>
                      <textarea id="details" v-model="info.message" name="details" class="form-control" :placeholder="$t('contactUs.What is on your mind?')" rows="5"></textarea>
                      <div v-if="!error.message" class="help-block with-errors"></div>
                      <div class="error-message-block">{{ error.message }}</div>
                    </div>
                  </div>
                </div>
                <div class="row mt-5">
                  <div class="col-md-12">
                    <div class="remember-checkbox clearfix mb-5">
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="g-recaptcha"
                      data-sitekey="6LdDE10aAAAAAJnnd0l0gsf8y7uJpzn2AHOqAcEq"
                      data-callback="ReCaptchaCallbackV3"
                      data-size="invisible">
                  </div>
                  <div class="col-md-12 text-center">
                    <button class="btn btn-primary" @click.prevent="sendInfo" :disabled="!isReadyForSubmission">{{ $t('contactUs.Send') }}</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!--login end-->

    </div>

    <!--body content end-->
  </div>
</template>

<script>
export default {
  name: 'ContactUs',
  data () {
    return {
      isSpinnerShown: false,
      error: {
        username: null,
        email: null,
        message: null
      },
      info: {
        username: null,
        email: null,
        message: null
      }
    }
  },
  computed: {
    isReadyForSubmission () {
      return this.info.username && this.info.email && this.info.message
    }
  },
  methods: {
    sendInfo () {
      this.error = {
        username: null,
        email: null,
        message: null
      }

      window.grecaptcha.execute()

      this.isSpinnerShown = true
      this.$store.dispatch('contactUs', this.info)
        .then(res => {
          this.isSpinnerShown = false
          this.$router.push({ name: 'Home' })
        }).catch(error => {
          this.isSpinnerShown = false
          if (error.response) {
            for (var field in error.response.data[0]) {
              this.error[field] = error.response.data[0][field][0]
            }
          }
        })
    }
  }
}
</script>
