<template>
  <div>
    <!--hero section start-->

    <HeroSection>
      <h1>{{ $t('cart.Checkout') }}</h1>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb justify-content-center bg-transparent p-0 m-0">
          <li class="breadcrumb-item">
            <router-link class="text-dark" :to="{ name: 'Dashboard', params: { lang: this.$i18n.locale }}">
              {{ $t('menu.Home') }}
            </router-link>
          </li>
          <li class="breadcrumb-item">
            <router-link class="text-dark" :to="{ name: 'Cart', params: { lang: this.$i18n.locale }}">{{
                $t('menu.Cart')
              }}
            </router-link>
          </li>
          <li class="breadcrumb-item active text-primary" aria-current="page">{{ $t('cart.Checkout') }}</li>
        </ol>
      </nav>
    </HeroSection>

    <!--hero section end-->

    <div class="page-content">
      <section>
        <div v-if="loading" class="m-5 p-5 d-flex flex-column align-items-center">
          <h3 class="text-center">
            {{$t('general.Please Wait')}}
          </h3>
          <div class="mt-2"><img class="spinner-img" src="/assets/images/spinner/Spinner-in-amber.svg"></div>
        </div>
        <div v-else class="m-5 p-5 d-flex flex-column align-items-center">
          <h2 class="text-center">
            {{$t('checkout.Great!')}}
          </h2>
          <p class="my-4">
            {{$t('checkout.Your order has been submitted successfully!')}}
          </p>
          <div>
            <a v-if="invoice_url != null && invoice_url.length > 5" class="btn btn-primary" :href="invoice_url" target="_blank">
              {{$t('checkout.Invoice details')}}
            </a>
            <router-link :to="{ name: 'Orders', params: { lang: this.$i18n.locale }}" v-else class="btn btn-primary">
              {{$t('checkout.We will send the invoice to your email address')}}
            </router-link>
            <router-link :to="{ name: 'Dashboard', params: { lang: this.$i18n.locale }}" class="btn btn-secondary mx-2">
              {{$t('checkout.Back to Home')}}
            </router-link>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>

export default {
  name: 'CheckoutConfirm',
  components: {},
  data () {
    return {
      cart: {},
      loading: true
    }
  },
  mounted () {
    console.log(this.$route.query)
    const orderId = this.$route.query.order_id
    const checkoutId = this.$route.query.id
    this.confirmCheckout(orderId, checkoutId)
  },
  computed: {
    invoice_url () {
      return this.cart ? this.cart.invoice_url : '#'
    }
  },
  methods: {
    confirmCheckout (orderId, checkoutId) {
      this.$store.dispatch('confirmCheckout', { orderId: orderId, checkoutId: checkoutId })
        .then(res => {
          if (res.data.success) {
            this.cart = res.data.cart
            this.sendGoogleAnalyticsConversionRequest(this.cart)
          } else {
            this.$swal.fire(
              {
                title: this.$t('checkout.Something wrong happened, try again later'),
                text: '',
                icon: 'error',
                confirmButtonText: this.$t('common.Ok')
              }
            ).then(() => {
              this.$router.push({ name: 'Dashboard', params: { lang: this.$i18n.locale } })
            })
          }
          console.log(res.data)
          this.loading = false
        }).catch(error => {
          this.$swal.fire(
            {
              title: this.$t('checkout.Something wrong happened, try again later'),
              text: '',
              icon: 'error',
              confirmButtonText: this.$t('common.Ok')
            }
          ).then(() => {
            this.$router.push({ name: 'Dashboard', params: { lang: this.$i18n.locale } })
          })
          console.log(error)
          this.loading = false
        })
    },
    sendGoogleAnalyticsConversionRequest (cart) {
      this.$gtag.event('conversion', {
        send_to: 'AW-682811407/oGHpCOmKzZkDEI_Ay8UC',
        value: cart.total,
        currency: 'SAR',
        transaction_id: cart.transaction_id
      })
      console.log('conversion request sent')
    }
  }
}
</script>

<style scoped>
.spinner-img {
  width:50px;
}
</style>
