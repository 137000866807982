<template>
  <div>
    <!--hero section start-->

    <HeroSection>
      <h1>{{ $t('offers.New Requests') }}</h1>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb justify-content-center bg-transparent p-0 m-0">
          <li class="breadcrumb-item"><router-link class="text-dark" :to="{ name: 'Dashboard', params: { lang: this.$i18n.locale }}">{{ $t('menu.Home') }}</router-link></li>
          <li class="breadcrumb-item active text-primary" aria-current="page">{{ $t('offers.New Requests') }}</li>
        </ol>
      </nav>
    </HeroSection>

    <!--hero section end-->

    <!--body content start-->

    <div class="page-content">

    <section>
      <div class="container">
        <div class="row">
          <div class="col-sm-12" style="position: relative;">
            <Spinner v-if="isSpinnerShown"/>
            <div class="row pt-3">
                <div class="col-md-3 col-12 mb-4">
                  <div>
                    <p class="text-in-grey border-bottom py-1">{{ $t('offers.Search') }}</p>
                    <SellerPartSearch :dir="'vertical'" :show-part-name="true" :show-request-id="true" v-model="searchParams" @input="doSearch()"></SellerPartSearch>
                  </div>
                </div>
                <div class="col-md-9 col-12">
                  <OfferList v-if="status === offerStatus[offerPendingSellerPricing].slug && offers && offers.length" :offers="offers"  :pageCount="pageCount" @getCurrentPageOffers="getCurrentPageOffers" @clickCallback="clickCallback"/>
                  <EmptyListMessage v-else-if="!isSpinnerShown">{{ $t('offers.New Requests list is empty') }}</EmptyListMessage>
                </div>
            </div>
          </div>
        </div>
        <!------->
      </div>
    </section>

    </div>

    <!--body content end-->
  </div>
</template>

<script>
import OfferList from '../../components/offer/OfferList.vue'
import EmptyListMessage from '../../components/EmptyListMessage.vue'
import SellerPartSearch from '../../components/SellerPartSearch'
import { sellerOffersMixin } from '../../mixins/sellerOffersMixin'

export default {
  name: 'PendingSellerPricing',
  components: {
    SellerPartSearch,
    OfferList,
    EmptyListMessage
  },
  mounted () {
    this.routeName = 'PendingSellerPricing'
  },
  mixins: [sellerOffersMixin],
  methods: {
    getCurrentPageOffers () {
      var pageNumber

      if (!this.$route.query.page) {
        pageNumber = 1
      } else {
        pageNumber = parseInt(this.$route.query.page)
      }

      this.status = this.offerStatus[this.offerPendingSellerPricing].slug
      this.getSellerOffersByStatus(this.status, pageNumber)
    }
  }
}
</script>
