import axios from '@/plugins/axios'

const state = {
  stats: ''
}

const getters = {
  getStats: (state) => {
    return state.stats
  }
}

const mutations = {
  setStats: (state, stats) => {
    state.stats = stats
  },
  clearDashboardState: (state) => {
    state.stats = ''
  }
}

const actions = {
  getSlider: ({ commit, state, rootState }) => {
    return axios.get('/api/slider', {
      headers: {
        Authorization: 'Bearer ' + rootState.loginSignup.api_token
      }
    })
      .then(res => {
        return res
      }).catch(error => {
        return Promise.reject(error)
      })
  },
  getStats: ({ commit, state, rootState }) => {
    return axios.get('/api/stats', {
      headers: {
        Authorization: 'Bearer ' + rootState.loginSignup.api_token
      }
    })
      .then(res => {
        commit('setStats', res.data)
        return res
      }).catch(error => {
        return Promise.reject(error)
      })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
