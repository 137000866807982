export const sellerPartMixin = {
  data () {
    return {
      brands: null,
      models: [],
      isBrandFieldDisabled: true,
      isModelFieldDisabled: true,
      isPartsCategoriesFieldDisabled: true,
      isToYearFieldDisabled: true,
      partCategories: [],
      isFormShownForTheFirstTime: false,
      isBrandLoaded: false,
      isModelLoaded: false,
      isPartCategoryLoaded: false,
      isSpinnerShownOnEditModal: false,
      error: {
        car_category_id: null,
        brand_id: null,
        model_ids: null,
        part_category_ids: null,
        from_year: null,
        to_year: null,
        condition: null
      },
      partCategory: {
        car_category_id: 0,
        brand_id: 0,
        model_ids: [],
        part_category_ids: [],
        from_year: 0,
        to_year: 0,
        condition: 0
      }
    }
  },
  computed: {
    isReadyForSubmission () {
      return this.partCategory.car_category_id && this.partCategory.brand_id && this.partCategory.model_ids.length !== 0 && this.partCategory.part_category_ids.length !== 0 && this.partCategory.from_year && this.partCategory.to_year && this.partCategory.condition
    },
    isDataLoaded () {
      return this.isBrandLoaded && this.isModelLoaded && this.isPartCategoryLoaded
    }
  },
  watch: {
    isDataLoaded (val) {
      if (val) {
        this.isFormShownForTheFirstTime = false
        this.isSpinnerShownOnEditModal = false
      }
    },
    'partCategory.car_category_id' (val) {
      if (!this.isFormShownForTheFirstTime) {
        this.models = []
        this.partCategory.brand_id = 0
        this.partCategory.model_ids = []
        this.partCategory.part_category_ids = []

        if (parseInt(val) === 0) {
          this.isBrandFieldDisabled = true
          this.isModelFieldDisabled = true
          this.isPartsCategoriesFieldDisabled = true
        } else {
          this.isModelFieldDisabled = true

          this.getBrandsByCarCategory(val)
          this.getPartCategoriesByCarCategory(val)
        }
      }
    },
    'partCategory.brand_id' (val) {
      if (!this.isFormShownForTheFirstTime) {
        this.partCategory.model_ids = []
        if (parseInt(val) === 0) {
          this.isModelFieldDisabled = true
        } else {
          this.getModelsByCategoryBrand(this.partCategory.car_category_id, val)
        }
      }
    },
    'partCategory.from_year' (val) {
      if (!this.isFormShownForTheFirstTime) {
        this.partCategory.to_year = 0
      }

      if (val === '0') {
        this.isToYearFieldDisabled = true
      } else {
        this.isToYearFieldDisabled = false
      }
    }
  },
  methods: {
    onRemove (removedOption, id) {
      if (removedOption.length > 0) {
        this.partCategory.part_category_ids.splice(0, this.partCategory.part_category_ids.length)
      }
    },
    getPartCategoriesSelectionArray (arrayOfObjects) {
      return arrayOfObjects.map((element) => {
        return { name: element.part_category.name, id: element.part_category.id }
      })
    },
    getBrandsByCarCategory (carCategoryId) {
      this.isBrandLoaded = false
      this.$store.dispatch('getBrandsByCarCategory', carCategoryId)
        .then(res => {
          this.brands = res.data.data
          this.isBrandLoaded = true
          this.isBrandFieldDisabled = false
        }).catch(error => {
          this.isBrandLoaded = true
          console.log(error)
        })
    },
    getPartCategoriesByCarCategory (carCategoryId) {
      this.isPartCategoryLoaded = false
      this.$store.dispatch('getPartCategoriesByCarCategory', carCategoryId)
        .then(res => {
          var categoriesGroup = []

          this.partCategories = res.data.map((element) => {
            return { name: element.name, id: element.id }
          })

          categoriesGroup.push({
            label: this.$t('sellerParts.Select All'),
            categories: this.partCategories
          })
          this.partCategories = categoriesGroup

          this.isPartCategoryLoaded = true
          this.isPartsCategoriesFieldDisabled = false
        }).catch(error => {
          this.isPartCategoryLoaded = true
          console.log(error.response.data)
        })
    },
    getModelsByCategoryBrand (carCategoryId, brandId) {
      this.isModelLoaded = false
      this.$store.dispatch('getModelsByCategoryBrand', { car_category_id: carCategoryId, brand_id: brandId })
        .then(res => {
          this.models = res.data.data.map((element) => {
            return { name: element.name, id: element.id }
          })
          this.isModelLoaded = true
          this.isModelFieldDisabled = false
        }).catch(error => {
          this.isModelLoaded = true
          console.log(error)
        })
    }
  }
}
