<template>
  <section class="padding-y-10">
    <div class="container" style="position: relative;">
      <Spinner v-if="isSpinnerShown"/>
      <div class="row justify-content-center">
        <div class="col-md-5 col-10">
          <div>
            <h2 class="text-center mt-5 mb-3">{{ $t('loginSignup.Sign In') }}</h2>
            <form method="post">
              <div class="messages"></div>
              <div class="form-group">
                <label>{{ $t('loginSignup.Verification Code') }}</label>
                <input id="form_code" v-model="code" type="text" name="verification_code" maxlength="6" class="form-control" :placeholder="$t('loginSignup.Verification code')" required="required" :data-error="$t('loginSignup.Verification code is required')">
                <div v-if="!error.code" class="help-block with-errors"></div>
                <div class="error-message-block">{{ error.code }}</div>
              </div>
              <div class="form-group mt-4 mb-5">
                <div class="remember-checkbox d-flex align-items-center justify-content-between">
                </div>
              </div>
              <button class="btn btn-primary btn-block" @click.prevent="verifyCode()" :disabled="isCodeNotValid">{{ $t('loginSignup.Verify') }}</button>

            </form>
            <div class="text-center text-muted mt-4">{{ $t('loginSignup.Please enter the code we have sent to your phone') }} <br> {{ $t('loginSignup.If you did not recieved the code') }} <span class="continue-to-verify-label mb-3 text-in-amber" @click.prevent="emitCodeNotSentEvent()">{{ $t('loginSignup.Login by password') }}</span></div>

          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'CodeLogin',
  props: ['client_id'],
  data () {
    return {
      code: null,
      isSpinnerShown: false,
      isCodeNotValid: true,
      error: {
        code: null
      }
    }
  },
  watch: {
    code () {
      if (this.code.length === 6) {
        this.isCodeNotValid = false
      } else {
        this.isCodeNotValid = true
      }
    }
  },
  methods: {
    emitCodeNotSentEvent () {
      this.$emit('codeNotSent')
    },
    verifyCode () {
      this.error = {
        code: null
      }

      this.isSpinnerShown = true
      this.$store.dispatch('verifyCode', {
        client_id: this.client_id,
        code: this.code
      })
        .then(res => {
          this.isSpinnerShown = false
          if (this.$route.query.request_part) {
            this.$router.push({ name: 'NewRequest', params: { lang: this.$i18n.locale } })
          } else {
            this.$router.push({ name: 'Dashboard', params: { lang: this.$i18n.locale } })
          }
        }).catch(error => {
          this.isSpinnerShown = false
          this.error.code = error.response.data.message
          console.log(error)
        })
    }
  }
}
</script>

<style scoped>
  .continue-to-verify-label:hover{
    cursor: pointer;
  }
</style>
