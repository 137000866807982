<template>
  <div>
    <!--hero section start-->

    <HeroSection>
      <h1>{{ $t('notifications.Notifications') }}</h1>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb justify-content-center bg-transparent p-0 m-0">
          <li class="breadcrumb-item"><router-link class="text-dark" :to="{ name: 'Dashboard', params: { lang: this.$i18n.locale }}">{{ $t('dashboard.Dashboard') }}</router-link></li>
          <li class="breadcrumb-item active text-primary" aria-current="page">{{ $t('notifications.Notifications') }}</li>
        </ol>
      </nav>
    </HeroSection>

    <!--hero section end-->

    <!--body content start-->

    <div class="page-content">
      <section class="pb-0" style="position: relative;">
        <Spinner v-if="isSpinnerShown"/>
        <div class="container">
          <div class="custom-button-wrapper">
            <a @click="markAllAsRead()" class="background-amber font-weight-600 shadow-primary d-inline custom-button">{{ $t('cart.Mark All As Read') }}</a>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="row">

                <div class="col-12  mb-5"  v-for="notification in notifications" :key="notification.id">
                  <div class="card p-4 shadow border-0 flex-lg-row" :class="notification.unread ? 'unread-notification' : ''">

                    <div class="card-body p-0 mb-3 mb-lg-0" :class="isRtl ? 'ml-sm-5' : 'mr-sm-5'">
                      <div class="mb-2">
                        <h5 class="text-primary d-inline">{{ notification.title }}</h5>
                      </div>
                      <p class="font-size-16">{{ notification.message }}</p>
                      <span class="font-size-16 text-in-amber mt-3 ml-auto"><small>{{ notification.created_at.split('T')[0].split('-').reverse().join('/') }}</small></span>
                    </div>
                    <div class="d-flex align-self-center">
                      <a class="view-details-btn background-amber font-weight-600 font-size-14 text-in-grey shadow-primary d-inline mx-2 text-nowrap" @click.prevent="redirectPage(notification.type, notification)">{{ $t('notifications.View Details') }}</a>
                    </div>
                  </div>
                </div>

              </div>
              <!-- / .row -->
              <nav aria-label="..." class="pagination-navigation mt-8" v-if="pageCount">
                <paginate
                  v-model="page"
                  :page-count="pageCount"
                  :page-range="10"
                  :margin-pages="1"
                  :click-handler="clickCallback"
                  :prev-text="$t('common.Prev')"
                  :next-text="$t('common.Next')"
                  :prev-class=" isRtl ? 'page-item ml-auto' : 'page-item mr-auto'"
                  :prev-link-class="'page-link'"
                  :next-class=" isRtl ? 'page-item mr-auto' : 'page-item ml-auto'"
                  :next-link-class="'page-link'"
                  :container-class="'pagination'"
                  :page-class="'page-item'"
                  :page-link-class="'dark-link-text page-link border-0 rounded'">
                </paginate>
              </nav>
            </div>
          </div>
        </div>
      </section>

    </div>

    <!--body content end-->

  </div>
</template>

<script>
import Paginate from 'vuejs-paginate'

export default {
  name: 'Notifications',
  data () {
    return {
      notifications: null,
      page: 1,
      pageCount: null,
      isSpinnerShown: false
    }
  },
  components: {
    Paginate
  },
  mounted () {
    if (!this.$route.query.page) {
      this.page = 1
    } else {
      this.page = parseInt(this.$route.query.page)
    }

    this.getNotifications(this.page)
  },
  computed: {
    authBuyer () {
      return this.$store.getters.isAuthenticatedBuyer
    }
  },
  methods: {
    getNotificationsCount () {
      this.$store.dispatch('getNotificationsCount')
        .then(res => {
        }).catch(error => {
          console.log(error)
        })
    },
    markOneAsRead (notificationId) {
      this.$store.dispatch('markOneAsRead', notificationId)
        .then(res => {
          this.getNotificationsCount()
        }).catch(error => {
          console.log(error)
        })
    },
    markAllAsRead () {
      this.$store.dispatch('markAllAsRead')
        .then(res => {
          this.getNotifications(this.page)
        }).catch(error => {
          console.log(error)
        })
    },
    redirectPage (type, notification) {
      this.markOneAsRead(notification.id)
      if (this.authBuyer) {
        if (notification.type === 'new_offers') {
          this.$router.push({ name: 'Requests', query: { id: notification.data.request_id } })
        } else if (notification.type === 'order_delivered' || notification.type === 'invoice_paid') {
          this.$router.push({ name: 'ViewOrder', params: { id: notification.data.order_id } })
        } else {
          this.$router.push({ name: 'Offers', query: { id: notification.data.offer_id } })
        }
      } else {
        this.$store.dispatch('getOfferById', notification.data.offer_id)
          .then(res => {
            var offer = res.data.data
            if (offer.status === this.offerPendingSellerPricing) {
              this.$router.push({ name: 'PendingSellerPricing', query: { id: notification.data.offer_id } })
            } else {
              this.$router.push({ name: 'sellerOffers', query: { status: this.offerStatus[offer.status].slug, id: notification.data.offer_id } })
            }
          }).catch(error => {
            console.log(error)
          })
      }

      /*
      if (this.authBuyer) {
        if (notification.type === 'new_offers') {
          this.$router.push({ name: 'Requests', query: { id: notification.data.request_id } })
        } else {
          this.$router.push({ name: 'Offers', query: { id: notification.data.offer_id } })
        }
      } else {
        for (const element of Object.entries(this.offerStatus)) {
          if (element[1].notification_type.includes(type)) {
            this.$router.push({ name: 'sellerOffers', query: { status: element[1].slug, id: notification.data.offer_id } })
          }
        }
      } */
    },
    getNotifications (page) {
      this.isSpinnerShown = true
      this.$store.dispatch('getNotifications', page)
        .then(res => {
          this.isSpinnerShown = false
          this.notifications = res.data.data
          this.pageCount = res.data.last_page
        }).catch(error => {
          this.isSpinnerShown = false
          console.log(error)
        })
    },
    clickCallback (pageNum) {
      this.$router.push({ name: 'Notifications', query: { page: pageNum } })
      this.getNotifications(pageNum)
    }
  }
}
</script>

<style scoped>
  .unread-notification {
    background-color: rgb(160 166 172 / 11%);
   background-color: rgb(63 63 63 / 8%);
  }

</style>
