<template>
  <div>
    <!--hero section start-->

    <HeroSection>
      <h1>{{ $t('menu.Sales') }}</h1>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb justify-content-center bg-transparent p-0 m-0">
          <li class="breadcrumb-item"><router-link class="text-dark" :to="{ name: 'Dashboard', params: { lang: this.$i18n.locale }}">{{ $t('menu.Home') }}</router-link></li>
          <li class="breadcrumb-item active text-primary" aria-current="page">{{ $t('menu.Sales') }}</li>
        </ol>
      </nav>
    </HeroSection>

    <!--hero section end-->

    <!--body content start-->

    <div class="page-content"  style="position: relative;">
      <Spinner v-if="isSpinnerShown"/>
      <section>
        <div class="container">
          <div class="row">
            <div class="form-group col-md-3">
              <label for="from_date">{{ $t('sales.From Date') }}</label>
              <input id="from_date" v-model="dates.from_date" type="date" name="from_date" class="form-control">
            </div>
            <div class="form-group col-md-3">
              <label for="to_date">{{ $t('sales.To Date') }}</label>
              <input id="to_date" v-model="dates.to_date" type="date" name="to_date" class="form-control">
            </div>
            <div class="form-group col-md-12 mb-0">
              <div class="bg-light p-3 mb-4">
                <SellerPartSearch @input="getSales()" v-model="searchData"></SellerPartSearch>
              </div>
            </div>
            <div class="col-sm-3 mb-4 align-self-center">
              <button id="upload-btn" @click.prevent="filterSales()" class="btn btn-primary" :disabled="!isFilteringEnabled"><span>{{ $t('common.Filter') }}</span></button>
            </div>
          </div>
          <div class="row">
            <div class="col sales-table">
              <div class="table-responsive">
                <table class="cart-table table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">{{ $t('sellerParts.Part Category') }}</th>
                      <th scope="col">{{ $t('sellerParts.Part Name') }}</th>
                      <th scope="col">{{ $t('cars.Car Model') }}</th>
                      <th scope="col">{{ $t('cars.Car Brand') }}</th>
                      <th scope="col">{{ $t('sellerParts.Part Number') }}</th>
                      <th scope="col">{{ $t('sellerParts.Condition') }}</th>
                      <th scope="col">{{ $t('sellerParts.Part Origin') }}</th>
                      <th scope="col">{{ $t('sellerParts.Price') }}</th>
                      <th scope="col">{{ $t('sales.Date') }}</th>
                      <th scope="col">{{ $t('sales.Shipping') }}</th>
<!--                      <th scope="col">{{ $t('sales.Returned') }}</th>-->

                    </tr>
                  </thead>
                  <tbody v-if="items">
                    <tr v-for="(item, i) in items" :key="i">
                      <td>
                        <h5 class="mb-0">{{ item.part_category }}</h5>
                      </td>
                      <td>
                        <p class="omb-0 font-weight-600 font-size-20 text-in-grey">{{ item.part_name }}</p>
                      </td>
                      <td>
                        <h5 class="mb-0">{{ item.car_model }}</h5>
                      </td>
                      <td>
                        <h5 class="mb-0">{{ item.car_brand }}</h5>
                      </td>
                      <td>
                        <h5 class="mb-0">{{ item.part_number }}</h5>
                      </td>
                      <td>
                        <h5 class="mb-0">{{ partConditions[item.condition][getFieldByLocale()] }}</h5>
                      </td>
                      <td>
                        <h5 class="mb-0">{{ item.origin }}</h5>
                      </td>
                      <td>
                        <h5 class="mb-0">{{ item.price + ' ' + getCurrency() }} <br><small>{{ $t('sellerParts.The price includes the added tax') }}</small></h5>
                      </td>
                      <td>
                        <h5 v-if="item.date != null" class="mb-0">{{ item.date.split('T')[0].split('-').reverse().join('/') }}</h5>
                        <h5 v-else> - </h5>
                      </td>
                      <td>
                        <a v-if="item.shipping_label_url" :href="item.shipping_label_url" target="_blank">{{ $t('sales.Shipping Policy') }}</a> <br>
                        <a v-if="item.tracking_reference" :href="item.tracking_url" target="_blank">{{ item.tracking_reference }}</a>
                      </td>
<!--                      <td>-->
<!--                        <span class="badge badge-danger" v-if="item.is_returned">{{ $t('Generic.Yes') }}</span> <br>-->
<!--                        <span class="badge badge-light" v-if="!item.is_returned">{{ $t('Generic.No') }}</span>-->
<!--                      </td>-->
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <nav aria-label="..." class="pagination-navigation mt-8" v-if="pageCount">
            <paginate
              v-model="page"
              :page-count="pageCount"
              :page-range="10"
              :margin-pages="1"
              :click-handler="clickCallback"
              :prev-text="$t('common.Prev')"
              :next-text="$t('common.Next')"
              :prev-class="isRtl ? 'page-item ml-auto' : 'page-item mr-auto'"
              :prev-link-class="'page-link'"
              :next-class="isRtl ? 'page-item mr-auto' : 'page-item ml-auto'"
              :next-link-class="'page-link'"
              :container-class="'pagination'"
              :page-class="'page-item'"
              :page-link-class="'dark-link-text page-link border-0 rounded'">
            </paginate>
          </nav>
        </div>

      </section>

    </div>

    <!--body content end-->
  </div>
</template>

<script>
import Paginate from 'vuejs-paginate'
import SellerPartSearch from '../../components/SellerPartSearch'

export default {
  name: 'sales',
  data () {
    return {
      items: null,
      page: null,
      pageCount: null,
      isSpinnerShown: false,
      dates: {
        from_date: null,
        to_date: null
      },
      searchData: null
    }
  },
  components: {
    Paginate,
    SellerPartSearch
  },
  mounted () {
    if (!this.$route.query.page) {
      this.page = 1
    } else {
      this.page = parseInt(this.$route.query.page)
    }

    this.getSales(this.page)
  },
  computed: {
    isFilteringEnabled () {
      var isValidRange = true
      if (this.dates.from_date && this.dates.to_date) {
        isValidRange = this.dates.from_date <= this.dates.to_date
      }
      return isValidRange && (this.dates.from_date || this.dates.to_date || (this.searchData && this.searchData.brand_id) || (this.searchData && this.searchData.model_id))
    }
  },
  methods: {
    getSales (page) {
      var dataObject = Object.assign({}, this.dates, this.searchData)

      this.isSpinnerShown = true
      this.$store.dispatch('getSales', {
        page: page,
        searchData: dataObject
      })
        .then(res => {
          this.isSpinnerShown = false

          this.items = res.data.data.data
          this.pageCount = res.data.data.last_page
        }).catch(error => {
          this.isSpinnerShown = false
          console.log(error)
        })
    },
    clickCallback (pageNum) {
      this.$router.push({ name: 'Sales', query: { page: pageNum } })
      this.getSales(pageNum)
    },
    filterSales () {
      console.log('page', this.page)
      this.getSales(this.page)
    }
  }
}
</script>
