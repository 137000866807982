export const PricedSellerPartMixin = {
  data () {
    return {
      pageNumber: null,
      pageCount: null,
      offers: null,
      isSpinnerShown: false,
      isPendingOffersList: false,
      status: null,
      searchParams: null,
      id: null,
      routeName: 'sellerOffers'
    }
  },
  mounted () {
    this.id = parseInt(this.$route.query.id)
    // this.getCurrentPageOffers()
  },
  updated () {
    if (this.id) {
      this.$nextTick(function () {
        if (document.getElementById('view-details-btn-' + this.id)) {
          document.getElementById('view-details-btn-' + this.id).click()
          this.id = null
        }
      })
    }
  },
  methods: {
    searchPricedParts (page) {
      this.isSpinnerShown = true
      this.$store.dispatch('getPricedParts', { page, searchParams: this.searchParams })
        .then(res => {
          this.isSpinnerShown = false

          if (res.data.data.current_page > res.data.data.last_page) {
            this.page = res.data.data.last_page
            this.clickCallback(res.data.data.last_page)
          }

          this.pricedParts = res.data.data.data
          this.pageCount = res.data.data.last_page
        }).catch(error => {
          this.isSpinnerShown = false
          console.log(error)
        })
    },
    getSellerOffersByStatus (status, page) {
      this.isSpinnerShown = true
      this.$store.dispatch('getSellerOffersByStatus', { status: status, page: page, searchParams: this.searchParams })
        .then(res => {
          this.isSpinnerShown = false

          if (res.data.data.current_page > res.data.data.last_page) {
            this.getOffers(this.status, res.data.data.last_page)
          }

          this.searchForElement(res.data.data, this.status)

          this.offers = res.data.data.data
          this.pageCount = res.data.data.last_page
        }).catch(error => {
          this.isSpinnerShown = false
          console.log(error)
        })
    },
    getOffers (status = null, pageNumber = 1, isRoutePushed = true) {
      if (status) {
        this.status = status
        if (isRoutePushed) {
          this.$router.push({ name: this.routeName, query: { page: pageNumber, status: status, id: this.$route.query.id } })
        }
        this.getSellerOffersByStatus(this.status, pageNumber)
      } else {
        this.status = null
        if (isRoutePushed) {
          this.$router.push({ name: this.routeName, query: { page: pageNumber, id: this.$route.query.id } })
        }
        this.getSellerOffers(pageNumber)
      }
    },
    searchForElement (responseData, status) {
      if (this.$route.query.id) {
        if ((responseData.data.filter(elem => elem.id === this.id).length === 0) && (responseData.current_page < responseData.last_page)) {
          this.getOffers(this.status, responseData.current_page + 1, false)
        } else if ((responseData.data.filter(elem => elem.id === this.id).length === 0)) {
          this.id = null
          this.removeIdFromRoute()
          this.getOffers(this.status, 1, false)
        } else if ((responseData.data.filter(elem => elem.id === this.id).length !== 0)) {
          this.removeIdFromRoute(responseData.current_page)
        }
      }
    },
    clickCallback (pageNum) {
      this.getOffers(this.status, pageNum)
    },
    doSearch () {
      this.searchPricedParts(1)
    }
  }
}
