<template>
  <div class="main-page-wrapper-bg" :style="{'background-image': `url(${require('@/assets/images/other/bg.jpg')})`}">
    <!--hero section start-->

    <HeroSection>
      <h1>{{ $t('profile.Edit Password') }}</h1>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb justify-content-center bg-transparent p-0 m-0">
          <li class="breadcrumb-item"><router-link class="text-dark" :to="{ name: 'Home', params: { lang: this.$i18n.locale }}">{{ $t('menu.Home') }}</router-link></li>
          <li class="breadcrumb-item active text-primary" aria-current="page">{{ $t('profile.Edit Password') }}</li>
        </ol>
      </nav>
    </HeroSection>

    <!--hero section end-->

    <!--body content start-->

    <div class="page-content">

    <!--login start-->

    <section class="register">
      <div class="container" style="position: relative;">
        <Spinner v-if="isSpinnerShown"/>
        <div class="row justify-content-center text-center">
          <div class="col-lg-8 col-md-12">
            <div class="mb-6"> <span class="badge badge-primary-soft p-2">
                    <img class="user-profile-icon" src="@/assets/images/other/profile.png">
                  </span>
              <h2 class="mt-3">{{ $t('profile.Edit Password') }}</h2>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-8 col-md-10 ml-auto mr-auto">
            <div class="register-form">
              <form method="post">
                <div class="messages"></div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="password">{{ $t('profile.Current Password') }}</label>
                      <input id="password" v-model="passwords.password" type="password" name="password" class="form-control" :placeholder="$t('profile.Current Password')" required="required" :data-error="$t('profile.Current password is required')">
                      <div v-if="!error.password"  class="help-block with-errors"></div>
                      <div class="error-message-block">{{ error.password }}</div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="new_password">{{ $t('profile.New Password') }}</label>
                      <input id="new_password" v-model="passwords.new_password" type="password" name="new_password" class="form-control" :placeholder="$t('profile.New Password')" required="required" :data-error="$t('profile.New password is required')">
                      <div v-if="showPasswordDataError"  class="help-block with-errors"></div>
                      <div class="error-message-block">
                        {{ error.new_password }}
                        <span v-if="isShownPasswordLengthError">{{ $t('profile.Password should have at least 6 characters') }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group" id="confirmPasswordForm">
                      <label for="form_password1">{{ $t('profile.Confirm New Password') }}</label>
                      <input id="form_password1" v-model="passwords.new_password_confirmation" type="password" name="confirm_password" class="form-control" :placeholder="$t('profile.Confirm New Password')" required="required" :data-error="$t('profile.Confirm new password is required')">
                      <div v-if="showConfirmPasswordDataError" class="help-block with-errors"></div>
                      <div class="error-message-block">
                        <span v-if="isShownrequiredConfirmPasswordError">{{ $t('profile.Confirm new password is required') }}</span>
                        <span v-if="isShownPasswordsDoNotMatchError">{{ $t('profile.Passwords do not match') }}</span>
                        {{ error.new_password_confirmation }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-5">
                  <div class="col-md-12">
                    <div class="remember-checkbox clearfix mb-5">
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 text-center">
                    <button class="btn btn-primary" @click.prevent="updatePassword" :disabled="!isReadyForSubmission">{{ $t('common.Update') }}</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!--login end-->

    </div>

    <!--body content end-->
  </div>
</template>

<script>

export default {
  name: 'BuyerSignupForm',
  data () {
    return {
      isSpinnerShown: false,
      isShownrequiredConfirmPasswordError: false,
      isShownPasswordsDoNotMatchError: false,
      isShownPasswordLengthError: false,
      error: {
        password: null,
        new_password: null,
        new_password_confirmation: null
      },
      passwords: {
        password: null,
        new_password: null,
        new_password_confirmation: null
      }
    }
  },
  computed: {
    isReadyForSubmission () {
      return this.passwords.password && this.passwords.new_password && this.passwords.new_password_confirmation
    },
    showPasswordDataError () {
      return !(this.error.new_password || this.isShownPasswordLengthError)
    },
    showConfirmPasswordDataError () {
      return !(this.error.new_password_confirmation || this.isShownrequiredConfirmPasswordError || this.isShownPasswordsDoNotMatchError)
    }
  },
  methods: {
    updatePassword () {
      this.error = {
        password: null,
        new_password: null,
        new_password_confirmation: null
      }

      this.isShownrequiredConfirmPasswordError = false
      this.isShownPasswordsDoNotMatchError = false
      this.isShownPasswordLengthError = false

      if (this.passwords.new_password.length < 6) {
        this.isShownPasswordLengthError = true
      } else if (this.passwords.new_password_confirmation === null) {
        this.isShownrequiredConfirmPasswordError = true
      } else if (this.passwords.new_password !== this.passwords.new_password_confirmation) {
        this.isShownPasswordsDoNotMatchError = true
      } else {
        this.isSpinnerShown = true
        this.$store.dispatch('updatePassword', this.passwords)
          .then(res => {
            this.isSpinnerShown = false
            this.$router.push({ name: 'Dashboard', params: { lang: this.$i18n.locale } })
          }).catch(error => {
            this.isSpinnerShown = false
            if (error.response) {
              for (var field in error.response.data[0]) {
                this.error[field] = error.response.data[0][field][0]
              }
            }
          })
      }
    }
  }
}
</script>
