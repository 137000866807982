import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import BuyerSignup from '../views/loginSignup/BuyerSignup.vue'
import SellerSignup from '../views/loginSignup/SellerSignup.vue'
import BuyerCars from '../views/buyerCars/ListCar.vue'
import AddCar from '../views/buyerCars/AddCar.vue'
import EditCar from '../views/buyerCars/EditCar.vue'
import Requests from '../views/requests/List.vue'
import EditRequest from '../views/requests/Edit.vue'
import NewRequest from '../views/requests/Add.vue'
import RequestsArchive from '../views/requests/Archive.vue'
import Offers from '../views/offers/List.vue'
import Orders from '../views/orders/List.vue'
import ViewOrder from '../views/orders/View.vue'
import Cart from '../views/orders/Cart.vue'
import Checkout from '../views/orders/Checkout.vue'
import EditProfile from '../views/profile/Edit.vue'
import UpdatePassword from '../views/profile/UpdatePassword.vue'
import partCategories from '../views/sellerParts/List.vue'
import AddPartCategory from '../views/sellerParts/Add.vue'
import pricedParts from '../views/pricedSellerParts/List.vue'
import AddPricedPart from '../views/pricedSellerParts/Add.vue'
import sellerOffers from '../views/offers/SellerList.vue'
import PendingSellerPricing from '../views/offers/PendingSellerPricing.vue'
import Dashboard from '../views/dashboards/Dashboard'
import Notifications from '../views/notifications/Notifications'
import Sales from '../views/sales/List.vue'
import ContactUs from '../views/contactUs/ContactUs.vue'
import i18n from '../i18n'
import store from '../store/index'
import BuyerSignupRequest from '../views/loginSignup/BuyerSignupRequest'
import CheckoutConfirm from '../views/orders/CheckoutConfirm'

Vue.use(VueRouter)

const checkAuthentication = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    next()
    return
  }
  next({ name: 'Home', params: { lang: i18n.locale } })
}

const checkAuthenticatedBuyer = (to, from, next) => {
  if (store.getters.isAuthenticatedBuyer) {
    next()
    return
  }
  next({ name: 'Home', params: { lang: i18n.locale } })
}

const checkAuthenticatedSeller = (to, from, next) => {
  if (store.getters.isAuthenticatedSeller) {
    next()
    return
  }
  next({ name: 'Home', params: { lang: i18n.locale } })
}

const checkLoggedIn = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    next({ name: 'Dashboard', params: { lang: i18n.locale } })
    return
  }
  next()
}

const checkCartNotEmpty = (to, from, next) => {
  if (!store.getters.isAuthenticatedBuyer) {
    next({ name: 'Home', params: { lang: i18n.locale } })
  }

  var cartCount = store.getters.getCartCount

  if (cartCount && cartCount > 0) {
    next()
    return
  }
  next({ name: 'Cart', params: { lang: i18n.locale } })
}

const routes = [
  {
    path: '/',
    redirect: `/${i18n.locale}`
  },
  {
    path: '/:lang',
    component: {
      render (c) {
        return c('router-view')
      }
    },
    children: [
      {
        path: '/',
        name: 'Home',
        component: Home,
        meta: { title: 'menu.Home' }
      },
      {
        path: 'contact-us',
        name: 'ContactUs',
        component: ContactUs,
        meta: { title: 'contactUs.Contact Us' }
      },
      {
        path: 'buyer-signup-request',
        name: 'BuyerSignupRequest',
        component: BuyerSignupRequest,
        beforeEnter: checkLoggedIn,
        meta: { title: 'loginSignup.Sign Up Buyer' }
      },
      {
        path: 'buyer-signup',
        name: 'BuyerSignup',
        component: BuyerSignup,
        beforeEnter: checkLoggedIn,
        meta: { title: 'loginSignup.Sign Up Buyer' }
      },
      {
        path: 'seller-signup',
        name: 'SellerSignup',
        component: SellerSignup,
        beforeEnter: checkLoggedIn,
        meta: { title: 'loginSignup.Sign Up Seller' }
      },
      {
        path: 'buyer-cars',
        name: 'BuyerCars',
        component: BuyerCars,
        beforeEnter: checkAuthenticatedBuyer,
        meta: { title: 'cars.My Cars' }
      },
      {
        path: 'buyer-cars/edit/:id',
        name: 'EditCar',
        component: EditCar,
        beforeEnter: checkAuthenticatedBuyer,
        meta: { title: 'cars.Edit Car' }
      },
      {
        path: 'add-car',
        name: 'AddCar',
        component: AddCar,
        beforeEnter: checkAuthenticatedBuyer,
        meta: { title: 'cars.Add New Car' }
      },
      {
        path: 'requests',
        name: 'Requests',
        component: Requests,
        beforeEnter: checkAuthenticatedBuyer,
        meta: { title: 'requests.Request List' }
      },
      {
        path: 'requests-archive',
        name: 'RequestsArchive',
        component: RequestsArchive,
        beforeEnter: checkAuthenticatedBuyer,
        meta: { title: 'requests.Requests Archive' }
      },
      {
        path: 'requests/:id/edit-request',
        name: 'EditRequest',
        component: EditRequest,
        beforeEnter: checkAuthenticatedBuyer,
        meta: { title: 'requests.Edit Request' }
      },
      {
        path: 'buyer-cars/new-request',
        name: 'NewRequest',
        component: NewRequest,
        beforeEnter: checkAuthenticatedBuyer,
        meta: { title: 'requests.Create New Request' }
      },
      {
        path: 'offers',
        name: 'Offers',
        component: Offers,
        beforeEnter: checkAuthenticatedBuyer,
        meta: { title: 'buyer.Offers' }
      },
      {
        path: 'orders',
        name: 'Orders',
        component: Orders,
        beforeEnter: checkAuthenticatedBuyer,
        meta: { title: 'orders.Orders' }
      },
      {
        path: 'orders/:id',
        name: 'ViewOrder',
        component: ViewOrder,
        beforeEnter: checkAuthenticatedBuyer,
        meta: { title: 'orders.Purchase Order Details' }
      },
      {
        path: 'cart',
        name: 'Cart',
        component: Cart,
        beforeEnter: checkAuthenticatedBuyer,
        meta: { title: 'cart.Cart' }
      },
      {
        path: 'cart/checkout',
        name: 'Checkout',
        component: Checkout,
        beforeEnter: checkCartNotEmpty,
        meta: { title: 'cart.Checkout' }
      },
      {
        path: 'cart/checkout-confirm',
        name: 'CheckoutConfirm',
        component: CheckoutConfirm,
        meta: { title: 'cart.Checkout' }
      },
      {
        path: 'profile',
        name: 'EditProfile',
        component: EditProfile,
        beforeEnter: checkAuthentication,
        meta: { title: 'profile.Profile' }
      },
      {
        path: 'update-password',
        name: 'UpdatePassword',
        component: UpdatePassword,
        beforeEnter: checkAuthentication,
        meta: { title: 'profile.Edit Password' }
      },
      {
        path: 'part-categories',
        name: 'partCategories',
        component: partCategories,
        beforeEnter: checkAuthenticatedSeller,
        meta: { title: 'sellerParts.My Store' }
      },
      {
        path: 'parts-categories/add',
        name: 'AddPartCategory',
        component: AddPartCategory,
        beforeEnter: checkAuthenticatedSeller,
        meta: { title: 'sellerParts.Add New Part Categories' }
      },
      {
        path: 'priced-parts',
        name: 'pricedParts',
        component: pricedParts,
        beforeEnter: checkAuthenticatedSeller,
        meta: { title: 'sellerParts.Priced Parts' }
      }, {
        path: 'priced-parts/add',
        name: 'AddPricedPart',
        component: AddPricedPart,
        beforeEnter: checkAuthenticatedSeller,
        meta: { title: 'sellerParts.Add New Priced Part' }
      },
      {
        path: 'seller-offers',
        name: 'sellerOffers',
        component: sellerOffers,
        beforeEnter: checkAuthenticatedSeller,
        meta: { title: 'offers.Offers List' }
      },
      {
        path: 'new-part-requests',
        name: 'PendingSellerPricing',
        component: PendingSellerPricing,
        beforeEnter: checkAuthenticatedSeller,
        meta: { title: 'offers.New Requests' }
      },
      {
        path: 'sales',
        name: 'Sales',
        component: Sales,
        beforeEnter: checkAuthenticatedSeller,
        meta: { title: 'menu.Sales' }
      },
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: Dashboard,
        beforeEnter: checkAuthentication,
        meta: { title: 'dashboard.Dashboard' }
      },
      {
        path: 'notifications',
        name: 'Notifications',
        component: Notifications,
        beforeEnter: checkAuthentication,
        meta: { title: 'notifications.Notifications' }
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
