<template>
  <div>
    <!--hero section start-->

    <HeroSection>
      <h1>{{ $t('cars.My Cars') }}</h1>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb justify-content-center bg-transparent p-0 m-0">
          <li class="breadcrumb-item"><router-link class="text-dark" :to="{ name: 'Dashboard', params: { lang: this.$i18n.locale }}">{{ $t('menu.Home') }}</router-link></li>
          <li class="breadcrumb-item active text-primary" aria-current="page">{{ $t('cars.My Cars') }}</li>
        </ol>
      </nav>
    </HeroSection>

    <!--hero section end-->

    <!--body content start-->

    <div class="page-content">

      <section style="position: relative;">
        <Spinner v-if="isSpinnerShown"/>
        <div class="container">
          <div class="custom-button-wrapper">
            <router-link :to="{ name: 'AddCar', params: { lang: $i18n.locale}}"  class="background-amber font-weight-600 shadow-primary d-inline custom-button"> + {{ $t('menu.Add car') }}</router-link>
          </div>
          <div class="row">
            <div class="col-lg-12 col-md-12 order-lg-12">
              <div class="row text-center">
                <div v-for="car in cars" :key="car.id" class="col-xl-3 col-lg-4 col-md-6 mb-5">
                  <div class="product-item car-item">
                    <a data-toggle="modal" data-target="#view_modal" @click.prevent="showCar(car)">
                      <div class="product-img">
                        <img v-if="!car.images" class="car-brand-logo" :src="car.brand.img" alt="">
                        <img v-else class="img-fluid" :src="getFirstImage(car.images)" alt="">
                      </div>
                    </a>
                    <div class="product-desc"><a class="product-name mt-4 mb-2 d-block link-title" data-toggle="modal" data-target="#view_modal" @click.prevent="showCar(car)">{{ carCategories[car.car_category_id][getFieldByLocale()] +' - '+ car.brand[getFieldByLocale()] }}</a>
                      <span class="product-price text-muted">{{ car.model[getFieldByLocale()] + ' ('+ car.model_year +')' }}</span><br>
                      <div class="product-link mt-4 d-flex align-items-center justify-content-between">
                          <router-link v-if="car.status !== 0" :to="{ name: 'NewRequest', params: { lang: $i18n.locale }, query: { id: car.id }}" class="add-cart mb-2 px-2">
                            <img src="@/assets/images/other/request-part.png" class="img-fluid menu-icon" :class="isRtl? 'ml-2': 'mr-2'" alt="..."><div class="d-inline-block font-weight-600 font-size-14 text-in-grey">{{ $t('cars.Request A Part') }}</div>
                          </router-link>
                          <router-link :to="{ name: 'EditCar', params: { lang: $i18n.locale, id: car.id}}" class="add-cart font-weight-600 font-size-14 text-in-grey mb-2">{{ $t('common.Edit') }}</router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Modal :modal_id="'view_modal'">
        <section v-if="car" class="gallery-text-section">
          <div class="container">
            <div class="row">
              <div class="col-md-6 col-12" >
                  <div id="image-gallery" class="carousel slide" data-ride="carousel">

                    <div v-if="car.images" class="carousel-inner">
                      <div class="carousel-item " v-for="(image, i) in getAllImages(car.images)" :key="i" @click="index = i" :class="{'active': i===0}" >
                          <img :src="image" alt="" width="1100" height="500">
                      </div>
                    </div>
                    <div v-else class="carousel-inner">
                      <div class="carousel-item active" @click="index = 0">
                          <img class="car-brand-logo" :src="car.brand.img" alt="" width="1100" height="500">
                      </div>
                    </div>
                    <ul v-if="car.images" class="carousel-indicators">
                      <li data-target="#image-gallery" v-for="(image, i) in getAllImages(car.images)" :class="{'active': i===0}" :key="i" :data-slide-to="i" >
                        <img :src="image" alt="" width="1100" height="500">
                      </li>
                    </ul>
                    <ul v-else class="carousel-indicators">
                      <li class="active" data-target="#image-gallery">
                        <img :src="car.brand.img" alt="" width="1100" height="500">
                      </li>
                    </ul>
                  </div>
              </div>

              <div class="col-md-6 col-12">
                <h2 class="title">{{ $t('cars.Car Details') }}</h2>
                <p class="lead mb-5">{{ car.details }}</p>
                <ul class="cases-meta list-unstyled text-muted">
                  <li class="mb-3"><span class="text-dark"> {{ $t('cars.Category - Brand') }}: </span> {{ carCategories[car.car_category_id][getFieldByLocale()] +' - '+ car.brand[getFieldByLocale()]}}</li>
                  <li class="mb-3"><span class="text-dark"> {{ $t('cars.Model') }}: </span> {{ car.model[getFieldByLocale()] + ' ('+ car.model_year +')' }}</li>
                  <li class="mb-3"><span class="text-dark"> {{ $t('cars.Chasse No') }}: </span> {{ car.vin }}</li>
                </ul>
                <div class="col-12 p-0 mt-5">
                  <a v-if="car.can_delete" @click.prevent="removeCar(car.id)" class="background-amber view-details-btn add-cart font-weight-600 font-size-14 text-in-grey">{{ $t('common.Remove') }}</a>
                </div>
              </div>

            </div>
          </div>
        </section>
      </Modal>
      <CoolLightBox
        :items="items"
        :index="index"
        @close="index = null">
      </CoolLightBox>
    </div>

    <!--body content end-->
  </div>
</template>

<script>
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import Modal from '../../components/Modal.vue'

export default {
  name: 'BuyerCars',
  data () {
    return {
      cars: null,
      car: null,
      srcImage: null,
      items: [],
      index: null,
      isSpinnerShown: false
    }
  },
  components: {
    Modal,
    CoolLightBox

  },
  mounted () {
    this.getCars()
  },
  methods: {
    getCars () {
      this.isSpinnerShown = true
      this.$store.dispatch('getBuyerCars')
        .then(res => {
          this.isSpinnerShown = false
          this.cars = res.data.data
        }).catch(error => {
          this.isSpinnerShown = false
          console.log(error)
        })
    },
    showCar (car) {
      this.items = []
      this.index = null

      this.car = car
      if (car.images) {
        car.images.split(',').forEach((element, index) => {
          this.items.push(element)
        })
      } else {
        this.items.push(car.brand.img)
      }
    },
    removeCar (carId) {
      const swalWithBootstrapButtons = this.$swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger'
        },
        buttonsStyling: false
      })

      swalWithBootstrapButtons.fire({
        title: this.$t('common.Are you sure?'),
        text: this.$t('cars.You are about to remove a car!'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('common.Yes, remove it'),
        cancelButtonText: this.$t('common.No, cancel'),
        reverseButtons: false
      }).then((result) => {
        if (result.isConfirmed) {
          document.getElementsByClassName('close')[0].click()
          this.isSpinnerShown = true
          this.$store.dispatch('removeCar', carId)
            .then(res => {
              this.isSpinnerShown = false
              this.cars = null
              this.getCars()
              swalWithBootstrapButtons.fire({
                title: this.$t('common.Removed!'),
                text: this.$t('cars.The car has been removed'),
                icon: 'success',
                confirmButtonText: this.$t('common.Ok')
              })
            }).catch(error => {
              this.isSpinnerShown = false
              console.log(error)
            })
        }
      })
    }
  }
}
</script>
