import { render, staticRenderFns } from "./CheckoutConfirm.vue?vue&type=template&id=03bb5859&scoped=true&"
import script from "./CheckoutConfirm.vue?vue&type=script&lang=js&"
export * from "./CheckoutConfirm.vue?vue&type=script&lang=js&"
import style0 from "./CheckoutConfirm.vue?vue&type=style&index=0&id=03bb5859&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03bb5859",
  null
  
)

export default component.exports