<template>
<div>

<section class="bg-img home-slider" :style="{'background-image': `url(${require('../assets/images/home/Layer-2.jpg')})`}">
  <div class="container">
    <div v-if="!auth || authBuyer" class="text-center mb-8 d-flex">
      <router-link v-if="!auth" :to="{ name: 'BuyerSignupRequest', params: { lang: $i18n.locale} }"  class="w-100 call-to-action shadow-primary d-inline">{{ $t('home.Request A Part') }}</router-link>
      <router-link v-if="authBuyer" :to="{ name: 'NewRequest', params: { lang: $i18n.locale}}"  class="w-100 call-to-action shadow-primary d-inline">{{ $t('home.Request A Part') }}</router-link>
    </div>
    <div class="row align-items-center">
      <div class="col-12">
        <div class="owl-carousel" data-items="1" data-autoplay="true">
          <div class="item home-slide home-slide--1">
            <div class="row align-items-center">
              <div class="col-12 col-lg-5 col-xl-6 order-lg-2 mb-8 mb-lg-0 home-slide--img">
                <!-- Image -->
                <img v-if="$i18n.locale === 'ar'" src="@/assets/images/home/1-MainSliderPhones-ar.png" class="img-fluid w-100" alt="...">
                <img v-else src="@/assets/images/home/1-MainSliderPhones-en.png" class="img-fluid w-100" alt="...">
              </div>
              <div class="col-12 col-lg-7 col-xl-6 order-lg-1 home-slide--text">
                <!-- Heading -->
                <h1 class="display-4 mt-3 font-weight-normal text-in-grey">
                  {{ $t('home.Download the app and start your journey now') }}
                </h1>
                <!-- Text -->
                <p class="lead text-muted mb-7 font-weight-normal font-size-24">{{ $t('home.Buy and Sell Auto Spare Parts') }}</p>
                <!-- Buttons -->
                <div class="row mr-0">
                  <div class="col-6 col-md-4 mb-md-0" :class="isRtl ? 'pl-1' : 'pr-1'">
                    <a target="_blank" href="https://play.google.com/store/apps/details?id=com.boraq.sparaat&amp;hl=ar">
                      <img src="@/assets/images/home/google-play.png" class="img-fluid" alt="...">
                    </a>
                  </div>
                  <div class="col-6 col-md-4" :class="isRtl ? 'pr-1' : 'pl-1'">
                    <a target="_blank" href="https://itunes.apple.com/us/app/sparaat/id1383728719?mt=8">
                      <img src="@/assets/images/home/app-store.png" class="img-fluid" alt="...">
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="item home-slide home-slide--2">
            <div class="row align-items-center">
              <div class="col-12 col-lg-5 col-xl-6 order-lg-2 mb-8 mb-lg-0 home-slide--img">
                <!-- Image -->
                <img v-if="$i18n.locale === 'ar'" src="@/assets/images/home/slider-6-AAA-ar.png" class="img-fluid w-100" alt="...">
                <img v-else src="@/assets/images/home/slider-6-AAA.png" class="img-fluid w-100" alt="...">
              </div>
              <div class="col-12 col-lg-7 col-xl-6 order-lg-1 home-slide--text">
                <!-- Heading -->
                <h1 class="display-4 mt-3 mb-0 font-weight-bold text-center text-uppercase text-in-grey">
                  {{ $t('home.Shop Your Parts With') }}
                </h1>
                <h1 class="display-4 mt-0 font-weight-bold text-center text-uppercase text-in-amber">
                  {{ $t('home.Sparaat') }}
                </h1>
                <!-- Buttons -->
                <div class="row">
                  <div class="col-12 mt-4 mb-7 text-center">
                    <div class="text-in-grey text-uppercase font-weight-bold font-size-20 py-0 px-8" >
                      {{ $t('home.Download App') }}
                    </div>
                  </div>
                </div>
                <div class="row mr-0 justify-content-center">
                  <div class="col-6 col-md-4 mb-md-0" :class="isRtl ? 'pl-1' : 'pr-1'">
                    <a target="_blank" href="https://play.google.com/store/apps/details?id=com.boraq.sparaat&amp;hl=ar">
                      <img src="@/assets/images/home/google-play.png" class="img-fluid" alt="...">
                    </a>
                  </div>
                  <div class="col-6 col-md-4" :class="isRtl ? 'pr-1' : 'pl-1'">
                    <a target="_blank" href="https://itunes.apple.com/us/app/sparaat/id1383728719?mt=8">
                      <img src="@/assets/images/home/app-store.png" class="img-fluid" alt="...">
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="item home-slide home-slide--3">
            <div class="row align-items-center">
              <div class="col-12 col-lg-5 col-xl-6 order-lg-2 mb-8 mb-lg-0 home-slide--img">
                <!-- Image -->
                <img v-if="$i18n.locale === 'ar'" src="@/assets/images/home/slider-3--BBB-ar.png" class="img-fluid w-100 px-1" alt="...">
                <img v-else src="@/assets/images/home/slider-3--BBB.png" class="img-fluid w-100 px-1" alt="...">
              </div>
              <div class="col-12 col-lg-7 col-xl-6 order-lg-1 home-slide--text">
                <!-- Heading -->
                <h1 class="display-4 mt-3 mb-0 font-weight-bold text-center text-uppercase text-in-grey">
                  {{ $t('home.Sell Your Parts With') }}
                </h1>
                <h1 class="display-4 mt-0 font-weight-bold text-center text-uppercase text-in-amber">
                  {{ $t('home.Sparaat') }}
                </h1>
                <!-- Buttons -->
                <div class="row">
                  <div class="col-12 mt-4 mb-7 text-center">
                    <div href="#" class="text-in-grey text-uppercase font-weight-bold font-size-20 py-0 px-8" >
                      {{ $t('home.Download App') }}
                    </div>
                  </div>
                </div>
                <div class="row mr-0 justify-content-center">
                  <div class="col-6 col-md-4 mb-md-0" :class="isRtl ? 'pl-1' : 'pr-1'">
                    <a target="_blank" href="https://play.google.com/store/apps/details?id=com.boraq.sparaat&amp;hl=ar">
                      <img src="@/assets/images/home/google-play.png" class="img-fluid" alt="...">
                    </a>
                  </div>
                  <div class="col-6 col-md-4" :class="isRtl ? 'pr-1' : 'pl-1'">
                    <a target="_blank" href="https://itunes.apple.com/us/app/sparaat/id1383728719?mt=8">
                      <img src="@/assets/images/home/app-store.png" class="img-fluid" alt="...">
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- / .row -->
  </div>
  <!-- / .container -->
</section>

<!--hero section end-->

<!--body content start-->

<div class="page-content">

<!--feature start-->
<section class="custom-pt-1 position-relative bg-img bg-mask fixed-background" :style="{'background-image': `url(${require('../assets/images/home/bg2.jpg')})`,'-webkit-mask-image': `url(${require('../assets/images/home/section-mask.png')})`}">
  <div class="container">

    <div class="row align-items-center justify-content-between">
      <div class="col-12 col-lg-7 col-xl-7 buyer-features">
        <div>
          <h2 class="mt-0 text-uppercase display-3 text-white font-weight-bold mb-6">{{ $t('home.How to Request A Part') }}</h2>
        </div>
        <div class="row">
          <div class="col-xl-10 mb-5">
            <div class="box p-4 height-340 rounded hover-translate background-white d-flex align-items-center">
              <span class="number">1</span>
              <div>
                <img src="@/assets/images/home/home-page-icon.png" class="img-fluid" alt="...">
              </div>
              <h5 class="text-in-grey font-weight-bold">{{ $t('home.Request Spare Parts') }}</h5>
              <p class="text-in-grey font-weight-500">{{ $t('home.Fill out the order form and select the specifications of the piece you want') }}</p>
            </div>
          </div>
          <div class="col-xl-11 mb-5">
            <div class="box p-4 height-340 rounded hover-translate background-white d-flex align-items-center">
              <span class="number">2</span>
              <div>
                <img src="@/assets/images/home/home-page-icon.png" class="img-fluid" alt="...">
              </div>
              <h5 class="text-in-grey font-weight-bold">{{ $t('home.Get a spec view and price') }}</h5>
              <p class="text-in-grey font-weight-500">{{ $t('home.Your order will be answered by the distributors and send the price offer and specifications available on your registered mail') }}</p>
            </div>
          </div>
          <div class="col-xl-12 mb-5">
            <div class="box p-4 height-340 rounded hover-translate background-white d-flex align-items-center">
              <span class="number">3</span>
              <div>
                <img src="@/assets/images/home/home-page-icon.png" class="img-fluid" alt="...">
              </div>
              <h5 class="text-in-grey font-weight-bold">{{ $t('home.Pay and get your order') }}</h5>
              <p class="text-in-grey font-weight-500">{{ $t('home.Fill the bank transfer form after the transfer and enter your address and the item will be delivered to you or received from the distributos headquarters') }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-5 mb-8 mb-lg-0 request-part-img">
        <img v-if="$i18n.locale === 'ar'" src="@/assets/images/home/2ndPhone-ar.png" alt="Image">
        <img v-else src="@/assets/images/home/2ndPhone-en.png" alt="Image">
      </div>
    </div>
  </div>
</section>

<!--feature end-->

<!--about start-->

<section  class="custom-py-1 bg-img bg-mask" :style="{'background-image': `url(${require('../assets/images/home/Layer-1.jpg')})`,'-webkit-mask-image': `url(${require('../assets/images/home/section-mask.png')})`,'-webkit-mask-image': `url(${require('../assets/images/home/section-mask.png')})`}">
  <div class="container">
    <div class="row align-items-center justify-content-between who-is-seller">
      <div class="col-12 col-sm-12  col-md-5  order-2 order-md-1 mb-0 mb-lg-0 align-self-end who-is-seller--img">
        <img v-if="$i18n.locale === 'ar'" src="@/assets/images/home/Layer-8-ar.png" alt="Image" class="img-fluid w-100">
        <img v-else src="@/assets/images/home/Layer-8-en.png" alt="Image" class="img-fluid w-100">

      </div>
      <div class="col-12 col-sm-12 col-md-7 order-1 order-md-2 col-xl-6 align-self-start who-is-seller--text">
        <div class="mb-8">
          <h2 class="mt-0 text-uppercase display-3 text-in-amber font-weight-bold mb-6">{{ $t('home.Who is seller') }}</h2>
          <p class="lead text-in-grey font-weight-bold">{{ $t('home.The seller is a supplier of spare parts for trucks or cars, whether new or used or commercial parts according to the specialty of each seller') }}</p>
          <p class="lead text-in-grey font-weight-bold">{{ $t('home.The seller adds on the site the types of spare parts available to him by types of trucks to facilitate the arrival of the search by the buyer') }}</p>
        </div>
        <div class="row  justify-content-between custom-buttons">
          <div class="col-md-6 col-sm-12 mb-2 mb-md-0">
            <a class="btn background-grey text-white mr-1 w-100 d-flex align-items-center mb-3" :class="isRtl? 'text-right': 'text-left'">
              <img src="@/assets/images/home/light.png" class="img-fluid" :class="isRtl? 'ml-3': 'mr-3'" alt="...">
              <div class="d-inline-block font-weight-bold text-uppercase text-in-amber">
                {{ $t('home.Add Parts') }}</div>
            </a>
            <p class="lead text-in-grey">{{ $t('home.You can add the pieces you have available to your account and manage it as you like') }}</p>
          </div>
          <div class="col-md-6 col-sm-12 mb-2 mb-md-0">
            <a class="btn background-grey text-white w-100 d-flex align-items-center mb-3" :class="isRtl? 'text-right': 'text-left'">
              <img src="@/assets/images/home/Layer-14.png" class="img-fluid" :class="isRtl? 'ml-3': 'mr-3'" alt="..." style="margin-top: 6px;">
              <div class="d-inline-block font-weight-bold text-uppercase text-in-amber">
               {{ $t('home.Send offer') }}</div>
            </a>
            <p class="lead text-in-grey">{{ $t('home.When sending a request by the buyer you can send offers for the required parts and follow up their status whether approved or not') }}</p>
          </div>
        </div>

      </div>
    </div>

  </div>
</section>

<!--about end-->

<!--feature start-->

<section class="custom-py-1 position-relative bg-img how-sparaat-works bg-mask fixed-background" :style="{'background-image': `url(${require('../assets/images/home/layer.jpg')})`,'-webkit-mask-image': `url(${require('../assets/images/home/section-mask.png')})`}">
  <div class="container">
    <div class="row align-items-center justify-content-between">
      <div class="col-12  col-md-5  order-2 order-md-1 mb-6 mb-lg-0">
        <img src="@/assets/images/home/Grey-Ferrari-GTC4-Lusso-Car.png" alt="Image" class="ferrari-car">
      </div>
      <div class="col-12 col-md-7 order-1 order-md-2 col-xl-6 how-sparaat-works--text">
        <div class="mb-8">
          <h2 class="mt-0 text-uppercase display-3 text-white font-weight-bold mb-6 text-no-wrap">{{ $t('home.How sparaat works') }}</h2>
          <p class="lead text-in-grey font-weight-bold">{{ $t('home.This is done by selecting the search order, choosing the type of vehicle and its model, sorting the item that the buyer wishes to search for, then writing the name of the item, It is possible to attach a picture of the clarification and some details, The request is then sent to the sellers who have the same classification of the item and the type of vehicle, The widget can also attach a picture that provides it') }}</p>
        </div>
      </div>
    </div>
  </div>
</section>

<!--feature end-->

</div>

<!--body content end-->
</div>
</template>

<script>

export default {
  name: 'Home',
  mounted () {
    const $carousel = window.$('.owl-carousel')

    $carousel.owlCarousel({
      items: $carousel.data('items'),
      slideBy: $carousel.data('slideby'),
      center: $carousel.data('center'),
      loop: true,
      rtl: true,
      margin: $carousel.data('margin'),
      dots: $carousel.data('dots'),
      nav: $carousel.data('nav'),
      // autoplay: $carousel.data('autoplay'),
      autoplay: true,
      autoplayTimeout: $carousel.data('autoplay-timeout'),
      navText: ['<span class="la la-angle-left"><span>', '<span class="la la-angle-right"></span>'],
      responsive: {
        0: { items: $carousel.data('xs-items') ? $carousel.data('xs-items') : 1 },
        576: { items: $carousel.data('sm-items') },
        768: { items: $carousel.data('md-items') },
        1024: { items: $carousel.data('lg-items') },
        1200: { items: $carousel.data('items') }
      }
    })
  },
  computed: {
    auth () {
      return this.$store.getters.isAuthenticated
    },
    authBuyer () {
      return this.$store.getters.isAuthenticatedBuyer
    }
  }
}
</script>

<style scoped>
  .owl-carousel .item {
    direction: ltr;
  }
  .rtl .owl-carousel .item {
    direction: rtl;
  }
</style>
