import axios from '@/plugins/axios'

const state = {
  cart_count: ''
}

const getters = {
  getCartCount: (state) => {
    return state.cart_count
  }
}

const mutations = {
  setCartCount: (state, cartCount) => {
    state.cart_count = cartCount !== 0 ? cartCount : ''
  },
  clearOrderState: (state) => {
    state.cart_count = ''
  }
}

const actions = {
  getOrders: ({ commit, state, rootState }, page) => {
    return axios.get('/api/orders?page=' + page, {
      headers: {
        Authorization: 'Bearer ' + rootState.loginSignup.api_token
      }
    })
      .then(res => {
        return res
      }).catch(error => {
        return Promise.reject(error)
      })
  },
  getOrderDetails: ({ commit, state, rootState }, id) => {
    return axios.get('/api/orders/' + id, {
      headers: {
        Authorization: 'Bearer ' + rootState.loginSignup.api_token
      }
    })
      .then(res => {
        return res
      }).catch(error => {
        return Promise.reject(error)
      })
  },
  getCart: ({ commit, state, rootState }) => {
    return axios.get('/api/cart', {
      headers: {
        Authorization: 'Bearer ' + rootState.loginSignup.api_token
      }
    })
      .then(res => {
        if (res.data.data) {
          commit('setCartCount', res.data.data.offers ? res.data.data.offers.length : 0)
        } else {
          commit('setCartCount', 0)
        }

        return res
      }).catch(error => {
        return Promise.reject(error)
      })
  },
  addToCart: ({ commit, state, rootState }, offerId) => {
    return axios.put('/api/cart/add/offer/' + offerId, {}, {
      headers: {
        Authorization: 'Bearer ' + rootState.loginSignup.api_token
      }
    })
      .then(res => {
        return res
      }).catch(error => {
        return Promise.reject(error)
      })
  },
  updateCart: ({ commit, state, rootState }, order) => {
    return axios.post('/api/cart/update', order, {
      headers: {
        Authorization: 'Bearer ' + rootState.loginSignup.api_token
      }
    })
      .then(res => {
        commit('setCartCount', 0)
        return res
      }).catch(error => {
        return Promise.reject(error)
      })
  },
  removeItemFromCart: ({ commit, state, rootState }, offerId) => {
    return axios.put('/api/cart/remove/offer/' + offerId, {}, {
      headers: {
        Authorization: 'Bearer ' + rootState.loginSignup.api_token
      }
    })
      .then(res => {
        return res
      }).catch(error => {
        return Promise.reject(error)
      })
  },
  removePartFromOrder: ({ commit, state, dispatch, rootState }, data) => {
    return axios.put('/api/orders/offers/' + data.orderId + '/' + data.offerId, {}, {
      headers: {
        Authorization: 'Bearer ' + rootState.loginSignup.api_token
      }
    })
      .then(res => {
        return res
      }).catch(error => {
        return Promise.reject(error)
      })
  },
  getAllAddresses: ({ commit, state, rootState }) => {
    return axios.get('/api/address', {
      headers: {
        Authorization: 'Bearer ' + rootState.loginSignup.api_token
      }
    })
      .then(res => {
        return res
      }).catch(error => {
        return Promise.reject(error)
      })
  },
  getAddressById: ({ commit, state, rootState }, addrssId) => {
    return axios.get('/api/address/' + addrssId, {
      headers: {
        Authorization: 'Bearer ' + rootState.loginSignup.api_token
      }
    })
      .then(res => {
        return res
      }).catch(error => {
        return Promise.reject(error)
      })
  },
  createAddress: ({ commit, state, rootState }, address) => {
    return axios.post('/api/address', address, {
      headers: {
        Authorization: 'Bearer ' + rootState.loginSignup.api_token
      }
    })
      .then(res => {
        return res
      }).catch(error => {
        return Promise.reject(error)
      })
  },
  editAddress: ({ commit, state, rootState }, address) => {
    return axios.put('/api/address/' + address.id, address.info, {
      headers: {
        Authorization: 'Bearer ' + rootState.loginSignup.api_token
      }
    })
      .then(res => {
        return res
      }).catch(error => {
        return Promise.reject(error)
      })
  },
  applyCoupon: ({ commit, state, rootState }, couponCode) => {
    return axios.post('api/cart/apply-coupon', {
      coupon_code: couponCode
    }, {
      headers: {
        Authorization: 'Bearer ' + rootState.loginSignup.api_token
      }
    })
      .then(res => {
        return res
      }).catch(error => {
        return Promise.reject(error)
      })
  },
  removeCoupon: ({ commit, state, rootState }) => {
    return axios.post('api/cart/remove-coupon', { }, {
      headers: {
        Authorization: 'Bearer ' + rootState.loginSignup.api_token
      }
    })
      .then(res => {
        return res
      }).catch(error => {
        return Promise.reject(error)
      })
  },
  createCheckout: ({ commit, state, rootState }, orderId) => {
    return axios.post('api/cart/checkout?order_id=' + orderId, {}, {
      headers: {
        Authorization: 'Bearer ' + rootState.loginSignup.api_token
      }
    })
      .then(res => {
        return res
      }).catch(error => {
        return Promise.reject(error)
      })
  },
  confirmCheckout: ({ commit, state, rootState }, { orderId, checkoutId }) => {
    return axios.post('api/cart/checkout-success', {
      order_id: orderId,
      checkout_session_id: checkoutId
    }, {
      headers: {
        Authorization: 'Bearer ' + rootState.loginSignup.api_token
      }
    })
      .then(res => {
        return res
      }).catch(error => {
        return Promise.reject(error)
      })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
