<template>
  <div>
    <Modal :modal_id="'view_modal'">
      <section v-if="offer" class="gallery-text-section">
        <div class="container" style="position: relative;">
          <Spinner v-if="isSpinnerShownOnModal"/>
          <div class="row">
            <div class="col-md-6" >
                <div id="image-gallery"  class="carousel slide" data-ride="carousel">

                  <div v-if="offer.images" class="carousel-inner">
                    <div class="carousel-item " v-for="(image, i) in getAllImages(offer.images)" :key="i" @click="index = i" :class="{'active': i===0}" >
                        <img :src="image" alt="" width="1100" height="500">
                    </div>
                  </div>
                  <div v-else class="carousel-inner">
                    <div class="carousel-item active" @click="index = 0">
                        <img  src="/assets/images/product/01.jpg" alt="" width="1100" height="500">
                    </div>
                  </div>
                  <ul v-if="offer.images" class="carousel-indicators">
                    <li data-target="#image-gallery" v-for="(image, i) in getAllImages(offer.images)" :class="{'active': i===0}" :key="i" :data-slide-to="i" >
                      <img :src="image" alt="" width="1100" height="500" style="width: 24%;">
                    </li>
                  </ul>
                  <ul v-else class="carousel-indicators">
                    <li class="active" data-target="#image-gallery">
                      <img src="/assets/images/product/01.jpg" alt="" width="1100" height="500" style="width: 24%;">
                    </li>
                  </ul>
                </div>
                <slot name="buttons"></slot>
            </div>

            <div class="col-md-6 d-flex flex-column">
              <h2 class="title">{{ $t('offers.Offer Details') }}</h2>
              <p class="font-weight-600 font-size-20 mb-0 text-in-grey custom-line-height">{{ offer.request ? offer.request.part_name : offer.request_part_name }} <small class="text-in-amber font-size-14" :class="isRtl ? 'mr-2' : 'ml-2'">({{ partConditions[offer.condition][getFieldByLocale()] }})</small></p>
              <p>{{ offer.brand ? offer.brand[getFieldByLocale()] : offer.request.brand[getFieldByLocale()] }} {{ offer.model ? offer.model[getFieldByLocale()] : offer.request.model[getFieldByLocale()] }} {{offer.request && offer.request.car ? ' - ' + offer.request.car.model_year : '' }}</p>
              <div class="row mt-4">
                <div class="col-md-6 col-sm-6 col-xs-12">
                  <ul class="cases-meta list-unstyled text-muted">
                    <li v-if="offer.part_category" class="mb-3"><span class="text-dark"> {{ $t('sellerParts.Part Category') }}: </span><br> {{ offer.part_category[getFieldByLocale()] }}</li>
                    <li class="mb-3" v-if="offer.condition === newPartCondition && offer.origin"><span class="text-dark"> {{ $t('sellerParts.Part Origin') }}: </span><br> {{ offer.origin[getFieldByLocale()] }}</li>
                    <li class="mb-3"><span class="text-dark"> {{ $t('sellerParts.Warranty Duration') }}: </span><br> {{ offer.warranty_duration ? offer.warranty_duration : 0 }} {{ $t('sellerParts.day(s)') }}</li>
                  </ul>
                </div>
                <div class="col-md-6 col-sm-6 col-xs-12">
                  <ul class="cases-meta list-unstyled text-muted">
                    <li class="mb-3" v-if="offer.condition === usedPartCondition" ><span class="text-dark"> {{ $t('sellerParts.Disassemble Cost') }}: </span><br> {{ offer.disassemble_cost }} {{ getCurrency () }}</li>
                    <li class="mb-3"><span class="text-dark"> {{ $t('sellerParts.Part Price') }}: </span><br> {{ offer.sparaat_price }} {{ getCurrency () }}</li>
                    <li class="mb-3"><span class="text-dark"> {{ $t('sellerParts.Total Price') }}: </span><br> {{ offer.total_price }} {{ getCurrency () }}<br><small>{{ $t('sellerParts.The price includes the added tax') }}</small></li>
                  </ul>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                    <div class="mb-3 text-muted"><span class="text-dark"> {{ $t('requests.Request Details') }}: </span><br> {{ offer.request ? offer.request.details : offer.request_details }}</div>
                </div>
                <div v-if="offer.seller && offer.seller.seller_code" class="col-md-6 col-sm-6 col-xs-12">
                    <div class="mb-3 text-muted"><span class="text-dark"> {{ $t('sellerParts.Seller') }}: </span><br> {{ offer.seller.seller_code }}</div>
                </div>
                <div v-if="offer.seller && offer.seller.city" class="col-md-6 col-sm-6 col-xs-12">
                    <div class="mb-3 text-muted"><span class="text-dark"> {{ $t('sellerParts.Seller Place') }}: </span><br> {{ offer.seller.city[getFieldByLocale()] }}</div>
                </div>
                <div class="col-12">
                    <div class="mb-3 text-muted"><span class="text-dark"> {{ $t('sellerParts.Seller Note') }}: </span><br> {{ offer.seller_note }}</div>
                </div>
              </div>
              <span class="product-price text-in-red">{{ $t('offers.Offer Status') }}:  {{ offerStatus[offer.status][getFieldByLocale()] }} {{offer.order_status_label ? (' - ' + offer.order_status_label) : '' }}</span>
              <div class="row mt-auto">
                <div class="col-md-6" :class="isRtl ? 'mr-auto' : 'ml-auto'">
                  <ul class="cases-meta list-unstyled text-muted mt-3">
                    <li class="mb-3"><span class="text-dark"> {{ $t('requests.Request No') }}: </span> {{ offer.request ? offer.request.id : offer.request_id }}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Modal>
    <CoolLightBox
      :items="items"
      :index="index"
      @close="index = null">
    </CoolLightBox>
  </div>
</template>

<script>
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import Modal from '../../components/Modal.vue'

export default {
  name: 'OfferViewModal',
  props: ['offer', 'isSpinnerShownOnModal'],
  data () {
    return {
      items: [],
      index: null
    }
  },
  components: {
    Modal,
    CoolLightBox
  },
  watch: {
    'offer.images' () {
      this.items = []
      this.index = null
      if (this.offer.images) {
        this.offer.images.split(',').forEach((element, index) => {
          this.items.push(element)
        })
      } else {
        this.items.push('/assets/images/product/01.jpg')
      }
    }
  }
}
</script>
