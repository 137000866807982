import axios from '@/plugins/axios'

const actions = {
  getBuyerCars: ({ commit, state, rootState }) => {
    return axios.get('/api/cars', {
      headers: {
        Authorization: 'Bearer ' + rootState.loginSignup.api_token
      }
    })
      .then(res => {
        return res
      }).catch(error => {
        return Promise.reject(error)
      })
  },
  getCarById: ({ commit, state, rootState }, id) => {
    return axios.get('/api/cars/' + id, {
      headers: {
        Authorization: 'Bearer ' + rootState.loginSignup.api_token
      }
    })
      .then(res => {
        return res
      }).catch(error => {
        return Promise.reject(error)
      })
  },
  addCar: ({ commit, state, rootState }, carInfo) => {
    return axios.post('/api/cars', carInfo, {
      headers: {
        Authorization: 'Bearer ' + rootState.loginSignup.api_token
      }
    })
      .then(res => {
        return res
      }).catch(error => {
        return Promise.reject(error)
      })
  },
  editCar: ({ commit, state, rootState }, carInfo) => {
    return axios.put('/api/cars/' + carInfo.id, carInfo, {
      headers: {
        Authorization: 'Bearer ' + rootState.loginSignup.api_token
      }
    })
      .then(res => {
        return res
      }).catch(error => {
        return Promise.reject(error)
      })
  },
  removeCar: ({ commit, state, rootState }, carId) => {
    return axios.delete('/api/cars/' + carId, {
      headers: {
        Authorization: 'Bearer ' + rootState.loginSignup.api_token
      }
    })
      .then(res => {
        return res
      }).catch(error => {
        return Promise.reject(error)
      })
  }
}

export default {
  actions
}
