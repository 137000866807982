<template>

  <div id="app" class="conditional-class" :class=" isRtl ? 'rtl' : 'ltr'">
    <!-- page wrapper start -->
    <div class="page-wrapper">
    <!-- preloader start -->

    <div id="ht-preloader">
      <div class="loader clear-loader">
        <img class="sparaat-logo mb-5" src="@/assets/images/header/Sparaat-logo.png">
        <div><img class="spinner-img" src="/assets/images/spinner/Spinner-in-amber.svg"></div>

      </div>
    </div>

    <!-- preloader end -->

    <Header />

    <div v-if="auth && $route.name !== 'Home'" class="sidebar-view-container">
      <SideBar />
      <router-view class="main-page-wrapper main-page-wrapper-bg"  :style="{'background-image': `url(${require('@/assets/images/other/background.jpg')})`}" />
    </div>
    <div v-else>
      <router-view/>
    </div>

    <notifications  group="custom-template" position="bottom right" width="400px" animation-type="velocity">
      <template slot="body" slot-scope="props">
        <div class="p-4">
            <router-link :to="{ name: 'Notifications', params: { lang: locale }}" class="title">
              {{props.item.title}}
            </router-link>
            <a class="close-notification" @click="props.close">
              <i class="fa fa-fw fa-close"></i>
            </a>
            <div class="font-size-14" v-html="props.item.text">
            </div>
        </div>
      </template>
    </notifications>

    <Footer />
    <LoginForm />

    </div>
    <!-- page wrapper end -->

    <!--back-to-top start-->
    <div class="scroll-top"><a class="smoothscroll" href="#top"><i class="las la-angle-up"></i></a></div>
    <!--back-to-top end-->

  </div>

</template>

<script>
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'
import SideBar from './components/SideBar.vue'
import LoginForm from './components/signup/Login.vue'

export default {
  name: 'App',
  components: {
    Header,
    Footer,
    SideBar,
    LoginForm
  },
  mounted () {
    document.title = this.$t(this.$route.meta.title) || this.$t('Sparaat')

    // this.$notify({ group: 'custom-template', title: 'New Request', text: 'Wrong password, please try again later', duration: -30 })
    try {
      this.$store.dispatch('sendFirebaseToken')
        .then(res => {
        }).catch(error => {
          console.log(error)
        })
    } catch (ex) {
      console.log(ex)
    }

    if (this.$store.getters.rtlLangs.includes(this.$i18n.locale)) {
      const themePlugin = document.createElement('link')
      themePlugin.rel = 'stylesheet'
      themePlugin.id = 'theme-plugin'

      const themeMin = document.createElement('link')
      themeMin.rel = 'stylesheet'
      themeMin.id = 'theme-min'
      themePlugin.href = '/assets/rtl-css/theme-plugin.css'
      themeMin.href = '/assets/rtl-css/theme.min.css'

      const themeMinCss = document.getElementById('theme-min-css')
      themeMinCss.parentNode.insertBefore(themeMin, themeMinCss.nextSibling)
      themeMinCss.parentNode.insertBefore(themePlugin, themeMinCss.nextSibling)

      const html = document.getElementsByTagName('html')[0]
      html.lang = this.$i18n.locale
    }
  },
  computed: {
    auth () {
      return this.$store.getters.isAuthenticated
    },
    isValidToken () {
      return this.$store.getters.getIsValidToken
    },
    locale () {
      return this.$i18n.locale
    }
  },
  watch: {
    '$route' (to, from) {
      document.title = this.$t(this.$route.meta.title) || this.$t('Sparaat')
    },
    isValidToken () {
      if (this.auth && !this.isValidToken) {
        window.$('#login-form').modal()
        window.$('#login-form-close-btn').hide()
      }
    }

  }
}
</script>

<style>

</style>
