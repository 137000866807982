import axios from '@/plugins/axios'
import Vue from 'vue'

const actions = {
  sendFirebaseToken: ({ commit, state, rootState }) => {
    var firebaseConfig = {
      apiKey: 'AIzaSyALqLN14oH3yRmf2SoDJbSBpz8zqSs9P1Q',
      authDomain: 'sparat-test.firebaseapp.com',
      projectId: 'sparat-test',
      storageBucket: 'sparat-test.appspot.com',
      messagingSenderId: '593040732735',
      appId: '1:593040732735:web:d556913f384d7f8dd37e8a'
    }
    // Initialize Firebase
    window.firebase.initializeApp(firebaseConfig)
    const messaging = window.firebase.messaging()
    messaging.onMessage((payload) => {
      Vue.notify({
        group: 'custom-template',
        title: payload.notification.title,
        text: payload.notification.body,
        duration: -30
      })
      console.log('Message received. ', payload)
      // ...
    })

    messaging.getToken({ vapidKey: 'BHdKNJmVqLjm0ysPmgZvCUyPdT2DtOywA5yDHVfhoqNAUPjMg_Pu989s3PqVwnJAWJ46ZmVlv4JWw4n3inOjp0w' }).then((currentToken) => {
      if (currentToken) {
        localStorage.setItem('fb_token', currentToken)
        console.log('token vue', currentToken)
        return axios.post('/api/fb_token', { token: currentToken }, {
          headers: {
            Authorization: 'Bearer ' + rootState.loginSignup.api_token
          }
        })
          .then(res => {
            return res
          }).catch(error => {
            return Promise.reject(error)
          })

        //   sendTokenToServer(currentToken);
        //  updateUIForPushEnabled(currentToken);
      } else {
        // Show permission request.
        console.log('No registration token available. Request permission to generate one.')
        // Show permission UI.
        //   updateUIForPushPermissionRequired();
        // setTokenSentToServer(false);
      }
    }).catch((err) => {
      console.log('An error occurred while retrieving token. ', err)
      // showToken('Error retrieving registration token. ', err);
    //  setTokenSentToServer(false);
    })
  }
}

export default {
  actions
}
