<template>
  <div>
    <!--hero section start-->

    <HeroSection>
      <h1>{{ $t('offers.Offers List') }}</h1>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb justify-content-center bg-transparent p-0 m-0">
          <li class="breadcrumb-item"><router-link class="text-dark" :to="{ name: 'Dashboard', params: { lang: this.$i18n.locale }}">{{ $t('menu.Home') }}</router-link></li>
          <li class="breadcrumb-item active text-primary" aria-current="page">{{ $t('offers.Offers List') }}</li>
        </ol>
      </nav>
    </HeroSection>

    <!--hero section end-->

    <!--body content start-->

    <div class="page-content">

    <section>
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <div class="tab" style="position: relative;">
              <Spinner v-if="isSpinnerShown"/>
              <!-- Nav tabs -->
              <nav class="seller-offers-tabs">
                <div class="nav nav-tabs d-flex justify-content-start w-100 border-0" id="nav-tab" role="tablist">
                  <a class="nav-item nav-link rounded mb-2" :class="{ 'active': status === offerStatus[offerPendingBuyerApprove].slug }" id="nav-tab2" data-toggle="tab" @click="getOffers(offerStatus[offerPendingBuyerApprove].slug)" href="#tab1-2" role="tab" :aria-selected="status === offerStatus[offerPendingBuyerApprove].slug"> {{ $t('offers.Pending Buyer Approve') }}</a>
                  <a class="nav-item nav-link rounded mb-2" :class="{ 'active': status === offerStatus[OfferAccepted].slug }" id="nav-tab6" data-toggle="tab" @click="getOffers(offerStatus[OfferAccepted].slug)" href="#tab1-6" role="tab" :aria-selected="status === offerStatus[OfferAccepted].slug"> {{ $t('offers.Accepted Offers') }}</a>
                  <a class="nav-item nav-link rounded mb-2" :class="{ 'active': status === offerStatus[offerIgnored].slug }" id="nav-tab3" data-toggle="tab" @click="getOffers(offerStatus[offerIgnored].slug)" href="#tab1-3" role="tab" :aria-selected="status === offerStatus[offerIgnored].slug"> {{ $t('offers.Ignored Offers') }}</a>
                  <a class="nav-item nav-link rounded mb-2" :class="{ 'active': status === offerStatus[buyerTookAnotherOffer].slug }"  id="nav-tab5" data-toggle="tab" @click="getOffers(offerStatus[buyerTookAnotherOffer].slug)" href="#tab1-5" role="tab" :aria-selected="status === offerStatus[buyerTookAnotherOffer].slug"> {{ $t('offers.Buyer Took Another Offer') }}</a>
                  <a class="nav-item nav-link rounded mb-2" :class="{ 'active': status === offerStatus[offerCanceled].slug }"  id="nav-tab4" data-toggle="tab" @click="getOffers(offerStatus[offerCanceled].slug)" href="#tab1-4" role="tab" :aria-selected="status === offerStatus[offerCanceled].slug"> {{ $t('offers.Cancelled Offers') }}</a>
                </div>
              </nav>
              <div class="row border-top pt-3">
                <div class="col-md-3 col-12 mb-4">
                  <div>
                    <p class="text-in-grey border-bottom py-1">{{ $t('offers.Search') }}</p>
                    <SellerPartSearch :dir="'vertical'" :show-part-name="true" :show-request-id="true" v-model="searchParams" @input="doSearch()"></SellerPartSearch>
                  </div>
                </div>
                <div class="col-md-9 col-12">
                  <div class="tab-content px-5" id="nav-tabContent">

                    <div role="tabpanel" class="tab-pane fade" :class="{ 'show active': status === offerStatus[offerPendingBuyerApprove].slug }" id="tab1-2">
                      <OfferList v-if="status === offerStatus[offerPendingBuyerApprove].slug && offers && offers.length" :offers="offers"  :pageCount="pageCount" @getCurrentPageOffers="getCurrentPageOffers" @clickCallback="clickCallback"/>
                      <EmptyListMessage v-else-if="!isSpinnerShown">{{ $t('offers.offers Pending Buyer Approve list is empty') }}</EmptyListMessage>
                    </div>

                    <div role="tabpanel" class="tab-pane fade" :class="{ 'show active': status === offerStatus[OfferAccepted].slug }" id="tab1-6">
                      <OfferList v-if="status === offerStatus[OfferAccepted].slug && offers && offers.length" :offers="offers"  :pageCount="pageCount" @getCurrentPageOffers="getCurrentPageOffers" @clickCallback="clickCallback"/>
                      <EmptyListMessage v-else-if="!isSpinnerShown">{{ $t('offers.Accepted Offers list is empty') }}</EmptyListMessage>
                    </div>

                    <div role="tabpanel" class="tab-pane fade" :class="{ 'show active': status === offerStatus[offerIgnored].slug }" id="tab1-3">
                      <OfferList v-if="status === offerStatus[offerIgnored].slug && offers && offers.length" :offers="offers"  :pageCount="pageCount" @getCurrentPageOffers="getCurrentPageOffers" @clickCallback="clickCallback"/>
                      <EmptyListMessage v-else-if="!isSpinnerShown">{{ $t('offers.Ignored Offers list is empty') }}</EmptyListMessage>
                    </div>
                    <div role="tabpanel" class="tab-pane fade" :class="{ 'show active': status === offerStatus[buyerTookAnotherOffer].slug }" id="tab1-5">
                      <OfferList v-if="status === offerStatus[buyerTookAnotherOffer].slug && offers && offers.length" :offers="offers"  :pageCount="pageCount" @getCurrentPageOffers="getCurrentPageOffers" @clickCallback="clickCallback"/>
                      <EmptyListMessage v-else-if="!isSpinnerShown">{{ $t('offers.Buyer Took Another Offer list is empty') }}</EmptyListMessage>
                    </div>
                    <div role="tabpanel" class="tab-pane fade" :class="{ 'show active': status === offerStatus[offerCanceled].slug }" id="tab1-4">
                      <OfferList v-if="status === offerStatus[offerCanceled].slug && offers && offers.length" :offers="offers"  :pageCount="pageCount" @getCurrentPageOffers="getCurrentPageOffers" @clickCallback="clickCallback"/>
                      <EmptyListMessage v-else-if="!isSpinnerShown">{{ $t('offers.Cancelled Offers list is empty') }}</EmptyListMessage>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Tab panes -->
          </div>
        </div>

        <!------->
      </div>
    </section>

    </div>

    <!--body content end-->
  </div>
</template>

<script>
import OfferList from '../../components/offer/OfferList.vue'
import EmptyListMessage from '../../components/EmptyListMessage.vue'
import SellerPartSearch from '../../components/SellerPartSearch'
import { sellerOffersMixin } from '../../mixins/sellerOffersMixin'

export default {
  name: 'SellerOffers',
  components: {
    SellerPartSearch,
    OfferList,
    EmptyListMessage
  },
  mixins: [sellerOffersMixin],
  methods: {
    getCurrentPageOffers () {
      var pageNumber

      if (!this.$route.query.page) {
        pageNumber = 1
      } else {
        pageNumber = parseInt(this.$route.query.page)
      }

      if (this.$route.query.status) {
        this.status = this.$route.query.status
        this.getSellerOffersByStatus(this.status, pageNumber)
      } else {
        this.status = null
        this.getSellerOffers(pageNumber)
      }
    }
  }
}
</script>

<style>
  .dark-link-text {
    color: #1d1d33;
  }
  .tab-content {
    padding-left: 0!important;
    padding-right: 0!important;
  }
  #nav-tab a{
    padding: 8px 10px;
    box-shadow: none !important;
    margin:0;
  }
  #nav-tab a +  a{
    margin-right:15px;
  }
  .search-wrapper{
    border: 1px solid #f3f3f3;
    border-right: none;
    border-left: none;
  }
</style>
