import axios from 'axios'
import store from '../store'
import router from '../router/index'

axios.defaults.baseURL = process.env.VUE_APP_API_URL
// doing something with the request
axios.interceptors.request.use(
  (config) => {
    config.headers.common.Accept = 'application/json'
    config.headers.common['Accept-Language'] = localStorage.getItem('locale') || 'en'
    return config
  }
)
axios.interceptors.response.use((response) => {
  // Return a successful response back to the calling service

  return response
}, (error) => {
  // Return any error which is not due to authentication back to the calling service
  if (error.response.status !== 403 && error.response.status !== 401) {
    return new Promise((resolve, reject) => {
      reject(error)
    })
  } else {
    store.commit('setIsValidToken', false)
    store.commit('logout')
    if (router.currentRoute.path !== '/ar' && router.currentRoute.path !== '/en') {
      router.replace('/')
    }
  }
})

export default axios
