<template>
  <div>
    <Agreements />
    <ShippingPolicy />
  <footer class="pt-9 position-relative bg-curvy-img bg-mask" :style="{'background-image': `url(${require('../assets/images/footer/Layer-7.jpg')})`,'-webkit-mask-image': `url(${require('../assets/images/footer/Layer-7b.png')})`}">
    <div class="container mt-8 mb-2 footer-content">
      <div class="row">
        <div class="col-12 col-lg-4 col-md-4 mb-6 mb-lg-0" :class="isRtl? 'ml-auto': 'mr-auto'">
          <div class="p-0 rounded">
            <router-link :to="{ name: 'Home', params: { lang: this.$i18n.locale }}">
              <img src="@/assets/images/footer/logo-white.png" class="img-fluid logo-white" alt="...">
            </router-link>
          </div>
        </div>
        <div class="col-12 col-lg-8 col-md-12">
          <div class="row">
            <div class="col-12 col-sm-6 navbar-dark">
              <h5 class="mb-3 text-in-amber text-uppercase font-weight-600">{{ $t('footer.Contact Info') }}</h5>
              <ul class="navbar-nav list-unstyled mb-0">
                <li class="mb-4 nav-item contact-info">
                  <a href="mailto:info@old.sparaat.com" target="_blank" class="text-white text-left">
                    <img src="@/assets/images/footer/envelope.png" class="img-fluid" :class="isRtl ? 'ml-2' : 'mr-2'" alt="...">
                    <div class="d-inline-block">Info@old.sparaat.com</div>
                  </a>
                </li>
                <li class="mb-3 nav-item d-flex justify-content-between contact-info">
                  <a class="nav-link" target="_blank" href="https://www.instagram.com/sparaat.sa/">
                    <img src="@/assets/images/footer/instagram.png" class="img-fluid" alt="...">
                  </a>
                  <a class="nav-link" target="_blank" href="https://api.whatsapp.com/send?phone=966543555266&amp;text=مرحباً%20سبيرات">
                    <img src="@/assets/images/footer/whatsapp.png" class="img-fluid" alt="...">
                  </a>
                  <a class="nav-link" target="_blank" href="https://www.snapchat.com/add/sparaat">
                    <img src="@/assets/images/footer/snapchat.png" class="img-fluid" alt="...">
                  </a>
                </li>
                <li class="mb-3 nav-item d-flex justify-content-between">
                  <div class="stores">
                    <a class="nav-link mb-1" target="_blank" href="https://play.google.com/store/apps/details?id=com.boraq.sparaat&amp;hl=ar">
                      <img src="@/assets/images/footer/google-play.png" class="img-fluid" alt="...">
                    </a>
                    <a class="nav-link" target="_blank" href="https://itunes.apple.com/us/app/sparaat/id1383728719?mt=8">
                      <img src="@/assets/images/footer/app-store.png" class="img-fluid" alt="...">
                    </a>
                  </div>
                  <div class="maroof">
                    <a class="nav-link" target="_blank" href="https://maroof.sa/65783">
                      <img src="@/assets/images/footer/maroof-logo.png" class="img-fluid" alt="...">
                    </a>
                  </div>
                </li>
              </ul>
            </div>
            <div class="col-12 col-sm-6 mt-6 mt-sm-0 navbar-dark">
              <h5 class="mb-3 text-in-amber text-uppercase font-weight-600">{{ $t('footer.About Sparaat') }}</h5>
              <ul class="navbar-nav list-unstyled mb-0 footer-links">
                <li class="mb-3 nav-item"><a data-toggle="modal" data-target="#agreements-modal" class="nav-link">{{ $t('footer.Agreements') }}</a>
                </li>
                <li class="mb-3 nav-item"><a data-toggle="modal" data-target="#shipping-policy-form" class="nav-link">{{ $t('footer.Warranty and Shipping Policy') }}</a>
                </li>
                <li class="nav-item"><router-link :to="{ name: 'ContactUs', params: { lang: this.$i18n.locale }}" class="nav-link">{{ $t('footer.Contact us') }}</router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row text-center bottom-footer py-3">
        <div class="copyright col-12 text-in-amber" >
          @ {{ new Date().getFullYear() }} - {{ $t('footer.Sparaat') }} - {{ $t('footer.All Rights Reserved') }} -   {{ $t('footer.Tax No') }}: 310109945600003
        </div>
        <div class="developed-by col-12 text-white">
          {{ $t('footer.Developed by') }} <a class="text-white boraq-group" href="https://boraq-group.com" target="_blank">{{ $t('footer.Boraq Group') }}</a> {{ $t('footer.for Business Solutions') }}
        </div>
      </div>
  </footer>
  </div>
</template>

<script>
import Agreements from './footer/Agreements.vue'
import ShippingPolicy from './footer/ShippingPolicy.vue'

export default {
  name: 'Footer',
  components: {
    Agreements,
    ShippingPolicy
  }
}
</script>
