import axios from '@/plugins/axios'

const actions = {
  getBuyerNewRequests: ({ commit, state, rootState }, page) => {
    return axios.get('/api/requests/new?page=' + page, {
      headers: {
        Authorization: 'Bearer ' + rootState.loginSignup.api_token
      }
    })
      .then(res => {
        return res
      }).catch(error => {
        return Promise.reject(error)
      })
  },
  getBuyerArchivedRequests: ({ commit, state, rootState }, data) => {
    return axios.get('/api/requests/archive?status=' + data.status + '&page=' + data.page, {
      headers: {
        Authorization: 'Bearer ' + rootState.loginSignup.api_token
      }
    })
      .then(res => {
        return res
      }).catch(error => {
        return Promise.reject(error)
      })
  },
  getBuyerRequestById: ({ commit, state, rootState }, id) => {
    return axios.get('/api/requests/' + id, {
      headers: {
        Authorization: 'Bearer ' + rootState.loginSignup.api_token
      }
    })
      .then(res => {
        return res
      }).catch(error => {
        return Promise.reject(error)
      })
  },
  updateRequest: ({ commit, state, rootState }, request) => {
    return axios.put('/api/requests/' + request.id, request, {
      headers: {
        Authorization: 'Bearer ' + rootState.loginSignup.api_token
      }
    })
      .then(res => {
        return res
      }).catch(error => {
        return Promise.reject(error)
      })
  },
  createRequest: ({ commit, state, rootState }, request) => {
    return axios.post('/api/requests', request, {
      headers: {
        Authorization: 'Bearer ' + rootState.loginSignup.api_token
      }
    })
      .then(res => {
        return res
      }).catch(error => {
        return Promise.reject(error)
      })
  },
  createBulkRequest: ({ commit, state, rootState }, arrayOfRequests) => {
    return axios.post('/api/requests/bulk', arrayOfRequests, {
      headers: {
        Authorization: 'Bearer ' + rootState.loginSignup.api_token
      }
    })
      .then(res => {
        return res
      }).catch(error => {
        return Promise.reject(error)
      })
  },
  searchStandardParts: ({ commit, state, rootState }, search) => {
    return axios.post('/api/standard-parts/search', search)
      .then(res => {
        return res
      }).catch(error => {
        return Promise.reject(error)
      })
  },
  removeRequest: ({ commit, state, rootState }, requestId) => {
    return axios.delete('/api/requests/' + requestId, {
      headers: {
        Authorization: 'Bearer ' + rootState.loginSignup.api_token
      }
    })
      .then(res => {
        return res
      }).catch(error => {
        return Promise.reject(error)
      })
  }
}

export default {
  actions
}
