<template>
  <div>

    <div class="modal fade" id="agreements-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
         aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content amber-border-on-modal">
          <div class="modal-header border-bottom-0">
            <button type="button" class="close modal-close-button" data-dismiss="modal">×</button>
          </div>
          <div class="modal-body" v-if="!isRtl">
            <!-- [NEW] : view contract according to user type -->
            <div v-if="isBuyer">
            <h4>Buyer Agreement</h4>
            <p>
              1. Upon registering, the buyer must write the mobile number to be sent a message with the verification
              number to enter. <br>

              2. The buyer is committed to the serious demand for spare parts where he actually wants to buy the piece
              and not just to see the market prices. <br>

              3. The buyer should confirm the type of vehicle and the classification of the item to be requested. The
              search is done through the classification. The more precise the request is, the more details will be
              available. <br>

              4. If the request is not answered by the sellers, the application will remain open for one month until the
              request is available or closed by the buyer in the absence of a refund. <br>

              5. Not all required pieces are available, but savings are made on the basis of offers from sellers. <br>

              6. The buyer must ensure the safety and validity of the piece required and it is exactly like the
              specifications that he wants, as Sparaat does not bear the error in the request and preparation of pieces.
              <br>

              7. The guarantee of the sold piece shall be made by the seller according to the specified period and not
              for any spiders to bear responsibility for the safety and guarantee of the pieces. <br>

              8. In the case of a request for error by the buyer in terms of type of vehicle or model or specifications
              of the piece, the buyer bears 10% of the value of the piece in addition to the delivery value of 25
              riyals. <br>

              9. The buyer is not entitled to open the piece, replace part thereof or tamper with it, and its guarantee
              shall be deemed null and void if it is tampered with. <br>

              10. The buyer is delivered invoice from the Sparaat shown by the price, item description and warranty
              period <br>

              11. In any case, it is not permitted to send the mobile number by request or to send an image showing the
              mobile number or work card or to clarify the place where the connection is intended. <br>

              12. Sparaat shall have the right to suspend any application contrary to the Agreement and to suspend the
              membership of the purchaser without recourse to it <br></p>
            <hr>
            </div>
            <div v-if="isSeller">
            <h4>Seller Agreement</h4>
            <p>
              1. The seller shall not place any information in the quotation on his place or mobile number by writing or
              appearing in pictures for the shop name. <br>

              2. The seller shall abide by the correctness and seriousness of recording the information for the parts of
              vehicles available to him <br>

              3. When the price is determined by the seller, it must be calculated from the proportion of 10% of the
              value of the piece for sprints <br>

              4. Minimum warranty period must be 3 days and maximum 30 days <br>

              5. When the buyer agrees to the request, the item will be delivered to Sparaat without receiving the
              payment. The amount will be guaranteed at Sparaat until the end of the specified warranty period and the
              amount will be transferred to the seller. <br>

              6. The seller must identify and teach the used piece to be sold to be identified and confirmed in case of
              return as it has not been opened, changed or used, but is the same as that sold. <br>

              7. Do not return to the seller for the price that has been determined and to be bound by it if it is
              selected by the buyer. <br>

              8. Seller shall be entitled to deduct the decommissioning value of the used piece 5% of its value if
              returned due to the buyer <br>

              9. The seller must provide the details of the license and the commercial register at the time of the
              registration process <br>

              10. The seller must locate his place at the time of registration and by sending the site, and specify the
              way he wishes to deliver the pieces to Sparaat or deliver them from his shop. <br>

              11. Sibirat is committed to pay the seller the value of the item after the end of the warranty period
              after the calculation of 10% commission Sparaat. <br>

              12. The seller shall bear the responsibility of the sold item, its quality and specifications as mentioned
              or damages caused by it <br>

              13. The seller must clarify the defects of the piece if any of the used pieces are found where the buyer
              is on it and approval of it <br>

              14. The seller must submit a sales invoice for the sprites showing the item's price, description and
              warranty period <br>

              15. The seller is not entitled to offer and sell suspicious or stolen items. If proven, he shall bear full
              responsibility and put himself under full responsibility. <br></p>
            <hr>
            </div>
            <h4>General Agreements</h4>
            <p>
              1. Sparaat may amend this Agreement at any time by notice or without notice to the Vendor or Buyer as per
              the Variables. <br>

              2. Registration in Sparaat by the Seller or Buyer shall be deemed to be in full agreement with all the
              terms of the Agreement. Sparaat shall have the right to suspend any application or membership without
              reference to the Member. <br>

              3. In the event of disagreement between the seller and the buyer, the laws and courts of the Kingdom of
              Saudi Arabia shall be referred to <br>

              4. Sparaat is a site to facilitate and provide the search for spare parts for vehicles and does not have
              any warehouses or goods, it connects the seller and buyer and uses the connection to link them and has no
              relation to the quality of the pieces or quality. <br>

              5. Any loss or damage due to the item sold during the warranty period is directly borne by the seller and
              Sparaat bears no responsibility for this and is only an intermediary. <br>

              6. The seller and the buyer must comply with the laws of commerce in the Kingdom of Saudi Arabia and the
              laws of electronic commerce <br>

              7. Sprinters are entitled to cancel any application if they are found to be serious by the buyer <br>

              8. The membership of the seller or buyer in case of violation of any of the terms of this agreement or the
              display of the mobile number by writing or pictures through the request or messages sent in the price
              offers is prohibited. <br>

              9. Must take into account the weekend holidays and official holidays in the request for pieces <br></p>
            <hr>
            <h4>Payment Agreements</h4>
            <p>
              1. Payment shall be from the buyer to the Sparaat directly through the bank transfer or by credit card or
              cash payment before the preparation and receipt of the piece. <br>

              2. A 10% discount is paid to the buyer in the event of a wrong request or wish to return without a reason
              in the piece. <br>

              3. The piece is delivered by the seller to the Sparaat without receiving payment and the Sparaat will
              guarantee the amount converted upon expiry of the warranty period. <br>

              4. Sparaat does not save your credit card information when you pay and are not exposed to it <br>

              5. An additional amount of loading and unloading is required if large parts are required and equipment is
              required for lifting <br>

              6. The amount of 25 riyals is charged to the buyer for delivery inside Jeddah. If the request is outside
              Jeddah, it will be sent by Saudi post or other shipping company according to the buyer's desire. The
              shipping value will be added to the buyer. In case of return, the buyer will pay the return if the request
              is wrong. The seller bears the return shipping value if it is within the warranty period and the reason
              for the refund is damaged by the piece. <br></p>
          </div>

          <div v-else class="modal-body">
            <!-- [NEW] : view contract according to user type -->
            <div v-if="isBuyer">
              <h4>اتفاقية المشتري</h4>
              <p>
                ١. يتوجب على المشتري عند التسجيل كتابة رقم الجوال ليتم ارسال رسالة له برقم التحقق للدخول. <br>

                ٢. يلتزم المشتري بالطلب الجاد لقطع الغيار حيث يكون فعلاً يرغب بشراء القطعة وليس مجرد الاطلاع على اسعار
                السوق. <br>

                ٣. يجب على المشتري التأكد من نوع المركبة وتصنيف القطعة المراد طلبها حيث أن البحث بتم عن طريق التصنيف،
                وكلما كان الطلب اكثر دقة في ذكر التفاصيل كان الوصول للطلب اسهل واسرع. <br>

                ٤. عند عدم الرد على الطلب من قبل البائعين فإن الطلب يبقى مفتوحاً لمدة عشرة ايام لحين توفر الطلب أو
                اغلاقه من قبل المشتري في حال عدم وجود رد. <br>

                ٥. ليس بالضرورة توفر جميع القطع المطلوبة وإنما يتم التوفير حسب العروض المقدمة من البائعين. <br>

                ٦. يجب على المشتري التأكد من سلامة وصحة القطعة المطلوبة وإنها تماماً مثل المواصفات التي يريدها حيث ان
                سبيرات لا يتحمل الخطأ في طلب و تحضير القطع. <br>

                ٧. ضمان القطعة المباعة يكون من قبل البائع حسب المدة المحددة وليس لسبيرات أي تحمل مسؤولية عن سلامة وضمان
                القطع. <br>

                ٨. في حال طلب القطع بالخطأ من قبل المشتري من حيث نوع المركبة او موديلها او مواصفات القطعة فإن المشتري
                يتحمل ١٠٪&rlm; من قيمة القطعة بالإضافة لقيمة الشحن ذهاباً واياباً بملبغ 60 ريال. <br>

                ٩. لا يحق للمشتري فتح القطعة وتبديل جزء منها او العبث بها ويعتبر ضمانها لاغي في حال تم العبث بها. <br>

                ١٠. يتم تسليم المشتري فاتورة من سبيرات موضح بها السعر ووصف القطعة ومدة الضمان.<br>

                ١١. لا يسمح بأي حال من الاحوال ارسال رقم الجوال عن طريق الطلب او ارسال صورة توضح رقم الجوال او كرت عمل
                او توضيح المكان الذي يراد التوصيل له، ويكون التواصل عن طريق سبيرات حيث عند التسجيل يتم تخزين رقم المشتري
                لدى سبيرات للتواصل معه.<br>

                ١٢. يحق لسبيرات ايقاف اي طلب مخالف للاتفاقية وايقاف عضوية المشتري دون الرجوع له.<br></p>
              <hr>
            </div>
            <div v-if="isSeller">
              <h4>اتفاقية البائع</h4>
              <p>

                1. يتحمل البائع كامل المسؤولية عن القطع المباعة حسب وصفها في عرض السعر، ويتحمل المسؤولية في حال لم تكن
                القطع اصلية وقد ذكر انها اصلية او كانت مغلفة اومقلدة وفي عرض السعر مذكور انها اصلية ويلزمه تحمل كافة
                الضرر اللاحق بذلك.
                <br>
                2. يلتزم البائع بعدم وضع معلومات في عرض السعر عن محله او رقم جواله عن طريق الكتابة او الظهور بالصور لاسم
                المحل واي اخلال بالاتفاقية يحق لسبيرات ايقاف عضوية البائع.
                <br>
                3. . يلتزم البائع بصحة وجدية تسجيل المعلومات لقطع المركبات التي تتوفر عنده.
                <br>
                4. عند تحديد السعر من قبل البائع فإنه لابد ان يحتسب اقل سعر ممكن بيعه بعد الخصم وشامل الضريبة المضافة.
                <br>
                5. يجب تحديد فترة الضمان اقلها ا يوم ضمان الاستلام السليم والصحيح للعميل واقصاها ٣٠ يوم.
                <br>
                6. عند موافقة المشتري على الطلب يتم تسليم القطعة لسبيرات بدون استلام المبلغ ويتم ضمان المبلغ عند سبيرات
                لحين انتهاء فترة الضمان المحددة ثم يتم تحويل المبلغ للبائع.
                <br>
                7. يجب على البائع تحديد وتعليم القطعة المستعملة المراد بيعها ليتم التعرف عليها والتأكد منها في حالة
                الارجاع حيث انها لم تفتح او تبدل او تستخدم وانما هي نفسها التي تم بيعها.
                <br>
                8. لا تراجع للبائع عن السعر الذي تم تحديده وعليه الالتزام به في حال وقع عليه الاختيار من قبل المشتري.
                <br>
                9. يحق للبائع احتساب قيمة فك للقطعة المستعملة ٥٪&rlm; من قيمتها في حال ارجاعها بسبب المشتري.
                <br>
                10. على البائع ذكر تفاصيل الترخيص والسجل التجاري عند عملية التسجيل وتقع عليه المسؤولية في حال تقديم
                معلومات خاطئة.
                <br>
                11. على البائع تحديد موقع محله عند التسجيل بالوصف وعن طريق ارسال الموقع، وتحديد الطريقة التي يرغب بتوصيل
                القطع لسبيرات او تسليمها من محله.
                <br>
                ١2. يلتزم سبيرات بتسديد قيمة القطعة للبائع بعد انتهاء فترة الضمان بعد احتساب ١٠٪&rlm; عمولة سبيرات.
                <br>
                ١3. يتحمل البائع مسؤولية القطعة المباعة وجودتها ومواصفاتها حسب ماذكر او اضرار تحدث بسببها.
                <br>
                ١4. يجب على البائع توضيح عيوب القطعة ان وجد فيها من القطع المستعملة حيث يكون المشتري على بينه وموافقة
                منه
                <br>
                ١5. يجب على البائع تقديم فاتورة بيع لسبيرات موضح فيها سعر القطعة ووصفها وفترة الضمان.
                <br>
                ١6. لا يحق للبائع عرض وبيع قطع مشبوهة او مسروقه وفي حال ثبوت ذلك فإنه يتحمل كامل المسؤولية ويضع نفسه تحت
                طائلة المسؤولية الكاملة.
              </p>
              <hr>
            </div>
            <h4>اتفاقيات عامة</h4>
            <p>

              ١. يحق لسبيرات تعديل هذه الاتفاقية في أي وقت بإشعار او بدون إشعار البائع او المشتري حسب المتغيرات.
              <br>
              ٢. التسجيل في سبيرات من قبل البائع او المشتري يعتبر موافقة تامة على جميع بنود الاتفاقية ويحق لسبيرات ايقاف
              اي طلب او اي عضوية دون الرجوع لصاحب العضوية.
              <br>
              ٣. في حال الخلاف بين البائع او المشتري فإنه يتم الرجوع لقوانين ومحاكم المملكة العربية السعوديه.
              <br>
              ٤. سبيرات هو موقع لتسهيل وتوفير عملية البحث عن قطع الغيار للمركبات ولا يملك اي مستودعات او بضاعة فهو يربط
              بين البائع والمشتري ويستخدم التوصيل للربط بينهم وليس له علاقة بجودة القطع او نوعيتها.
              <br>
              ٥. اي خسارات او تلفيات بسبب القطعة المباعة خلال فترة الضمان يتحملها البائع مباشرة ولا يتحمل سبيرات أي
              مسؤولية تجاه ذلك وهو وسيط فقط.
              <br>
              ٦. يجب على البائع والمشتري الالتزام بقوانين التجارة في المملكة العربية السعودية وقوانين التجارة
              الالكترونية.
              <br>
              ٧. يحق لسبيرات إلغاء اي طلب في حال وجدت عن الجدية من المشتري.
              <br>
              ٨. يتم حظر وايقاف عضوية البائع او المشتري في حال أخل بأحد بنود هذه الاتفاقية او عرض رقم الجوال عن طريق
              الكتابة او الصور من خلال طلب او الرسائل المرسلة في عروض السعر.
              <br>
              ٩. يجب مراعاة ايام العطل نهاية الاسبوع والاجازات الرسمية في طلب القطع.
            </p>
            <hr>
            <h4>اتفاقيات الدفع</h4>
            <p>

              ١. يكون الدفع من المشتري لسبيرات مباشرة عن طريق الحوالة البنكية او عن طريق البطاقة الائتمانية أو الدفع كاش
              قبل تحضير القطعة واستلامها.
              <br>
              ٢. يتم خصم ١٠٪&rlm; على المشتري في حال الطلب الخاطئ او رغبته بالإرجاع بدون سبب في القطعة.
              <br>
              ٣. يتم تسليم القطعة من قبل البائع لسبيرات دون استلام المبلغ ويضمن سبيرات تحويل المبلغ عند انتهاء فترة
              الضمان.
              <br>
              ٤. ان سبيرات لا تقوم بحفظ معلومات البطاقات الائتمانية عند السداد ولا تتعرض لها.
              <br>
              ٥. يتم احتساب مبلغ اضافي للتحميل والرافعة في حال طلب قطع كبيرة وتحتاج لمعدات لرفعها.
              <br>
              ٦. يتم احتساب مبلغ 30 ريال على المشتري للتوصيل داخل المملكة العربية السعودية للوزن اقل من25 كجم والحجم اقل
              من 50*50*40سم ،وما زاد عن ذلك لكل كيلو ريال اضافي، وفي حال الطلب خارج المملكة يتم ارسالها بشركة شحن حسب
              رغبة المشتري وتكون قيمة الشحن اضافي على المشتري وفي حال الارجاع يتحمل المشتري الارجاع اذا كان الطلب خاطئ
              او رغبة منه، و يتم تحمل البائع قيمة الشحن الراجع اذا كان ضمن فترة الضمان وسبب الارجاع تلف بالقطعة.
            </p>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: 'Agreements',
  data () {
    return {
      isSeller: false,
      isBuyer: false
    }
  },
  mounted () {
    // s
    this.isBuyer = this.$store.getters.isAuthenticatedBuyer || this.$route.query.buyer
    this.isSeller = this.$store.getters.isAuthenticatedSeller || this.$route.query.seller
    if (!this.isBuyer && !this.isSeller) {
      this.isBuyer = this.isSeller = true
    }
    if (this.$route.query && this.$route.query.contracts === 'true') {
      window.$('#agreements-modal').modal('show')
    }
  }
}
</script>

<style scoped>
h4 {
  text-align: center;
}
</style>
