<template>
  <div>
    <!--hero section start-->

    <HeroSection>
      <h1>{{ $t('profile.Profile') }}</h1>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb justify-content-center bg-transparent p-0 m-0">
          <li class="breadcrumb-item"><router-link class="text-dark" :to="{ name: 'Dashboard', params: { lang: this.$i18n.locale }}">{{ $t('menu.Home') }}</router-link></li>
          <li class="breadcrumb-item active text-primary" aria-current="page">{{ $t('profile.Profile') }}</li>
        </ol>
      </nav>
    </HeroSection>

    <!--hero section end-->

    <!--body content start-->

    <div class="page-content">

    <section>
      <div class="container" style="position: relative;">
          <Spinner v-if="isSpinnerShown"/>
        <div class="row justify-content-center text-center">
          <div class="col-12 col-md-12 col-lg-8 mb-8 mb-lg-0">
            <div class="mb-8"> <span class="badge badge-primary p-2">
                      <img class="user-profile-icon" src="@/assets/images/other/profile.png">
                  </span>
              <h2 class="mt-4">{{ $t('profile.Edit Profile') }}</h2>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <form method="post">
                <div class="messages"></div>
                <span v-if="profile">
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="full_name">{{ $t('profile.Full name') }}</label>
                      <input v-model="profile.full_name" id="full_name" type="text" name="full_name" class="form-control" :placeholder="$t('profile.Full name')" required="required" :data-error="$t('profile.Full name is required')">
                      <div v-if="!error.full_name" class="help-block with-errors"></div>
                      <div class="error-message-block">{{ error.full_name }}</div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <label for="mobile">{{ $t('profile.Phone number') }}</label>
                    <div class="row dial-phone-number" dir="ltr">
                      <div class="form-group col-sm-6 dial-code">
                        <input type="text" value="+966" class="form-control input-1" readonly>
                        <div class="help-block with-errors"></div>
                      </div>
                      <div class="form-group col-sm-6 phone-number">
                        <input-mask mask="599999999" v-model="profile.mobile" id="mobile" type="text" class="form-control text-center" :placeholder="$t('profile.Phone number')" required="required" :data-error="$t('profile.Phone number is required')" readonly></input-mask>
                        <div class="help-block with-errors"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="username">{{ $t('profile.Username') }}</label>
                      <input v-model="profile.username" id="username" type="text" name="username" class="form-control" :placeholder="$t('profile.Username')" required="required" :data-error="$t('profile.Username is required')">
                      <div v-if="!error.username" class="help-block with-errors"></div>
                      <div class="error-message-block">{{ error.username }}</div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="email">{{ $t('profile.Email') }}</label>
                      <input v-model="profile.email" id="email" type="email" name="email" class="form-control" :placeholder="$t('profile.Email')"  :data-error="$t('profile.Email is required')">
                      <div v-if="!error.email" class="help-block with-errors"></div>
                      <div class="error-message-block">{{ error.email }}</div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="country_id">{{ $t('profile.Country') }}</label>
                      <select id="country_id" v-model="country_id" class="form-control">
                        <option value="0" disabled>{{ $t('profile.Country') }}</option>
                        <option v-for="country in countries" :key="country.id" :value="country.id">{{ country[getFieldByLocale()] }}</option>
                      </select>
                      <div v-if="!error.country_id" class="help-block with-errors"></div>
                      <div class="error-message-block">{{ error.country_id }}</div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="city_id">{{ $t('profile.City') }}</label>
                      <select id="city_id" v-model="profile.city_id" class="form-control" :disabled="isCityFieldDisabled">
                        <option value="0" disabled>{{ $t('profile.City') }}</option>
                        <option v-for="city in cities" :key="city.id" :value="city.id">{{ city[getFieldByLocale()] }}</option>
                      </select>
                      <div v-if="!error.city_id" class="help-block with-errors"></div>
                      <div class="error-message-block">{{ error.city_id }}</div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="area">{{ $t('profile.Area') }}</label>
                      <input v-model="profile.area" id="area" type="text" name="area" class="form-control" :placeholder="$t('profile.Area')">
                      <div v-if="!error.area" class="help-block with-errors"></div>
                      <div class="error-message-block">{{ error.area }}</div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="street">{{ $t('profile.Street') }}</label>
                      <input v-model="profile.street" id="street" type="text" name="street" class="form-control" :placeholder="$t('profile.Street')">
                      <div v-if="!error.street" class="help-block with-errors"></div>
                      <div class="error-message-block">{{ error.street }}</div>
                    </div>
                  </div>
                </div>
                <div class="row" v-if="isSeller && profile">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="store_name">{{ $t('loginSignup.Store Name') }}</label>
                      <input id="store_name" v-model="profile.store_name" type="text" name="store_name" class="form-control" :placeholder="$t('loginSignup.Store Name')">
                      <div v-if="!error.store_name" class="help-block with-errors"></div>
                      <div class="error-message-block">{{ error.store_name }}</div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="address">{{ $t('profile.Address') }}</label>
                      <textarea v-model="profile.address" id="address" name="Address" class="form-control" :placeholder="$t('profile.Address')" rows="4"></textarea>
                      <div v-if="!error.address" class="help-block with-errors"></div>
                      <div class="error-message-block">{{ error.address }}</div>
                    </div>
                  </div>
                  <div v-if="isSeller && profile" class="col-md-6">
                    <div class="form-group">
                      <label for="dropzone">{{ $t('profile.Store Image') }}</label>
                      <ImageUpload :imageType="imageType" :errorMessage="error.store_img" :isEditImages="true" :stringOfImages="profile.store_img" :maxFilesUpload="maxFilesUpload" @isfileUplaodingChange="setIsfileUplaoding" @addedImagesChange="setAddedImages" @deletedImagesChange="setDeletedImages" />
                    </div>
                  </div>
                </div>
                </span>
                <div class="row" v-show="profile">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label>{{ isSeller ? $t('loginSignup.Store Location') : $t('loginSignup.Location') }}</label><br>
                      <div id="map"></div>
                    </div>
                  </div>
                </div>
                <span  v-if="profile">
                  <div class="row">
                    <div class="col-md-12 mt-3 text-center">
                      <button @click.prevent="editProfile()" class="btn btn-primary" :disabled="!isReadyForSubmission || isfileUplaoding">{{ $t('common.Update') }}
                      </button>
                    </div>
                  </div>
                </span>
              </form>
          </div>
        </div>
      </div>
    </section>

    </div>

    <!--body content end-->
  </div>
</template>

<script>
import ImageUpload from '../../components/ImageUpload'
import InputMask from 'vue-input-mask'

export default {
  name: 'EditProfile',
  data () {
    return {
      map: null,
      marker: null,
      geocoder: null,
      infowindow: null,
      profile: {
        added_images: null
      },
      cities: null,
      country_id: null,
      countries: null,
      countryCounter: 1,
      isSpinnerShown: false,
      isCityFieldDisabled: true,

      isfileUplaoding: false,
      imageType: null,
      maxFilesUpload: null,

      error: {
        full_name: null,
        username: null,
        email: null,
        city_id: null,
        country_id: null,
        area: null,
        street: null,
        address: null,
        store_img: null,
        store_name: null
      },
      mapState: window.mapState
    }
  },
  components: {
    InputMask,
    ImageUpload
  },
  mounted () {
    this.mapState.initMap = false
    // document.getElementById('google-apis-link').src += '&language=' + this.$i18n.locale
    var oldScript
    oldScript = document.getElementById('google-apis-link')
    oldScript.parentNode.removeChild(oldScript)

    delete window.google.maps

    this.loadScript(this.$i18n.locale)

    this.imageType = 'clients'

    this.isSpinnerShown = true
    this.$store.dispatch('getProfile')
      .then(res => {
        this.isSpinnerShown = false
        this.profile = res.data.data
        this.profile.added_images = null
        this.profile.deleted_images = null
        this.country_id = this.profile.city.country_id

        if (this.map && this.profile.lat && this.profile.lng) {
          var latlng = new window.google.maps.LatLng(this.profile.lat, this.profile.lng)
          this.map.setCenter(latlng)
          this.marker.setPosition(latlng)
        }

        if (!res.data.data.store_img) {
          this.maxFilesUpload = 1
        } else {
          this.maxFilesUpload = 0
        }
      }).catch(error => {
        this.isSpinnerShown = false
        console.log(error)
      })

    this.$store.dispatch('getCountries')
      .then(res => {
        this.countries = res.data.data
      }).catch(error => {
        console.log(error)
      })
  },
  computed: {
    isReadyForSubmission () {
      return this.profile.full_name && this.profile.username && this.profile.city_id && this.country_id
    },
    isSeller () {
      if (this.profile) {
        return parseInt(this.profile.type) === this.sellerType
      } else {
        return false
      }
    }
  },
  watch: {
    country_id (val) {
      if (this.countryCounter === 1) {
        this.countryCounter = this.countryCounter + 1
      } else {
        this.profile.city_id = 0
      }

      if (val === '0') {
        this.isCityFieldDisabled = true
      } else {
        this.getCitiesByCountry(val)
      }
    },
    'mapState.initMap' (value) {
      if (value) {
        this.initMap()
      }
    }
  },
  methods: {
    loadScript (lang) {
      var script = document.createElement('script')
      script.type = 'text/javascript'
      script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyCa4nxWsb11cNyW1vd9xSK9r95VaJpMYuY&callback=initMap'
      if (lang) {
        script.src += '&language=' + lang
      }

      script.id = 'google-apis-link'
      document.body.appendChild(script)
    },
    setIsfileUplaoding (value) {
      this.isfileUplaoding = value
    },
    setAddedImages (value) {
      this.profile.added_images = value.join(',')
    },
    setDeletedImages (value) {
      this.profile.deleted_images = value.join(',')
    },
    initMap () {
      var myLatLng

      if (this.profile.lat && this.profile.lng) {
        myLatLng = { lat: this.profile.lat, lng: this.profile.lng }
      } else {
        myLatLng = { lat: 21.27520372782979, lng: 39.235387667280975 }
      }

      this.map = new window.google.maps.Map(document.getElementById('map'), {
        zoom: 10,
        center: myLatLng
      })

      this.marker = new window.google.maps.Marker({
        position: myLatLng,
        map: this.map,
        draggable: true,
        language: 'ar',
        title: 'Customer here!'
      })

      this.geocoder = new window.google.maps.Geocoder()
      this.infowindow = new window.google.maps.InfoWindow()

      window.google.maps.event.addListener(this.marker, 'dragend', (event) => {
        this.profile.lat = this.marker.getPosition().lat()
        this.profile.lng = this.marker.getPosition().lng()
        this.geocodeLatLng(this.geocoder, this.map, this.infowindow)
      })
    },
    geocodeLatLng (geocoder, map, infowindow) {
      const latlng = {
        lat: parseFloat(this.profile.lat),
        lng: parseFloat(this.profile.lng)
      }
      geocoder.geocode({ location: latlng }, (results, status) => {
        if (status === 'OK') {
          if (results[0]) {
            map.setZoom(11)
            if (this.profile) {
              this.profile.address = results[0].formatted_address
            }
            infowindow.setContent(results[0].formatted_address)
            infowindow.open(map, this.marker)
          } else {
            window.alert('No results found')
          }
        } else {
          window.alert('Geocoder failed due to: ' + status)
        }
      })
    },
    getCitiesByCountry (countryId) {
      this.$store.dispatch('getCitiesbyCountry', countryId)
        .then(res => {
          this.cities = res.data.data
          this.isCityFieldDisabled = false
        }).catch(error => {
          console.log(error)
        })
    },
    editProfile () {
      this.error = {
        full_name: null,
        username: null,
        email: null,
        city_id: null,
        country_id: null,
        area: null,
        street: null,
        address: null,
        store_name: null
      }

      this.isSpinnerShown = true
      this.$store.dispatch('updateProfile', {
        full_name: this.profile.full_name,
        username: this.profile.username,
        email: this.profile.email,
        city_id: this.profile.city_id,
        area: this.profile.area,
        street: this.profile.street,
        address: this.profile.address,
        added_images: this.profile.added_images,
        deleted_images: this.profile.deleted_images,
        store_img: this.profile.store_img,
        lat: this.profile.lat,
        lng: this.profile.lng,
        ...(this.isSeller && { store_name: this.profile.store_name })
      })
        .then(res => {
          this.isSpinnerShown = false
          this.$router.push({ name: 'Dashboard', params: { lang: this.$i18n.locale } })
        }).catch(error => {
          this.isSpinnerShown = false
          if (error.response) {
            for (var field in error.response.data[0]) {
              this.error[field] = error.response.data[0][field][0]
            }
          }
        })
    }
  }
}
</script>
