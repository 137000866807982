<template>
  <div class="header-container">
    <header v-if="!auth || $route.name === 'Home'" class="site-header">
      <div class="header-inner" id="header-wrap">
        <div class="container">
          <div class="row">
            <div class="col d-flex align-items-center justify-content-start header-logo-nav" :class="isRtl? 'pr-sm-0': 'pl-sm-0'">
                <router-link v-if="!auth || $route.name === 'Home'" :to="{ name: 'Home', params: { lang: this.$i18n.locale }}" class="navbar-brand logo text-dark h2 mb-0 p-0">
                  <img src="@/assets/images/header/speraat_logo.png" class="img-fluid" alt="...">
                </router-link>
                <nav :class="['navbar navbar-expand-lg navbar-light', isRtl ? 'mr-auto' : 'ml-auto',]">
                  <div>
                      <ul :class="['navbar-nav', isRtl ? 'mr-auto' : 'ml-auto']">
                        <li v-if="!auth" class="nav-item"><a data-toggle="modal" data-target="#login-form" class="nav-link" exact-active-class="active"><img src="@/assets/images/header/locked-padlock.png" class="img-fluid menu-icon" :class="isRtl? 'ml-2': 'mr-2'" alt="..."><div class="d-inline-block font-weight-bold text-uppercase">{{ $t('menu.Login') }}</div></a></li>
                        <li v-if="!auth" class="nav-item"><router-link :to="{ name: 'SellerSignup', params: { lang: this.$i18n.locale }}" class="nav-link" exact-active-class="active"><img src="@/assets/images/header/seller.png" class="img-fluid signup-seller-icon" :class="isRtl? 'ml-2': 'mr-2'" alt="..."><div class="d-inline-block font-weight-bold text-uppercase">{{ $t('menu.Register Seller') }}</div></router-link></li>
                        <li v-if="!auth" class="nav-item"><router-link :to="{ name: 'BuyerSignup', params: { lang: this.$i18n.locale }}" class="nav-link" exact-active-class="active"><img src="@/assets/images/header/buyer.png" class="img-fluid signup-buyer-icon" :class="isRtl? 'ml-2': 'mr-2'" alt="..."><div class="d-inline-block font-weight-bold text-uppercase">{{ $t('menu.Register Buyer') }}</div></router-link></li>
                        <li v-if="auth && $route.name === 'Home'" class="nav-item"><router-link :to="{ name: 'Dashboard', params: { lang: this.$i18n.locale }}" class="nav-link font-weight-bold text-uppercase" exact-active-class="active">{{ $t('menu.Dashboard') }}</router-link></li>
                        <LanguageSwitcher v-if="!auth || $route.name === 'Home'" />
                      </ul>
                  </div>
                </nav>
            </div>
          </div>
        </div>
      </div>
    </header>
    <header class="logged-user-header-wrapper" v-else>
      <div class="header-inner top-menu bg-img" id="header-logged-in-user" :style="{'background-image': `url(${require('../assets/images/header/top-menu.png')})`}">
        <div class="container">
          <div class="row">

            <div class="col d-flex align-items-center justify-content-start header-logo-nav" :class="isRtl? 'pr-sm-0': 'pl-sm-0'">
              <button v-if="auth && $route.name !== 'Home'" class="navbar-toggler" :class="isRtl ? 'ml-3' : 'mr-3'" type="button" data-toggle="collapse" data-target="#navbarNav" aria-expanded="false" aria-label="Toggle navigation navbar-dark">
                <div class="nav-line"></div>
                <div class="nav-line"></div>
                <div class="nav-line"></div>
              </button>

              <div v-if="auth && $route.name !== 'Home'" class="nav-item dropdown mb-0 profile-dropdown" :class="isRtl? 'pr-0': 'pl-0'"><a class="nav-link dropdown-toggle px-0" href="#" data-toggle="dropdown"><img src="@/assets/images/header/user.png" class="img-fluid header-user-icon" :class="isRtl? 'ml-2': 'mr-2'" alt="..."><div class="d-inline-block text-white font-weight-600 font-italic client-name">{{ getClientFullName }}</div></a>
                  <ul class="dropdown-menu mt-2">
                    <li><router-link v-if="auth && $route.name !== 'Home'" :to="{ name: 'EditProfile', params: { lang: this.$i18n.locale }}" class="dropdown-item font-weight-500 text-white">{{ $t('profile.Edit Profile') }}</router-link></li>
                    <li><router-link v-if="auth && $route.name !== 'Home'" :to="{ name: 'UpdatePassword', params: { lang: this.$i18n.locale }}" class="dropdown-item font-weight-500 text-white">{{ $t('profile.Edit Password') }}</router-link></li>
                  </ul>
              </div>

              <nav :class="['navbar navbar-expand-lg navbar-light', isRtl ? 'mr-auto' : 'ml-auto',]">
                <div>
                    <ul :class="['navbar-nav', isRtl ? 'mr-auto' : 'ml-auto']">
                    <li v-if="auth  && $route.name !== 'Home'" class="nav-item">
                      <span class="nav-link">
                        <img src="@/assets/images/header/user-type.png" class="img-fluid menu-icon" :class="isRtl? 'ml-2': 'mr-2'" alt="...">
                        <div class="d-inline-block text-white font-weight-light font-size-14">{{ $t('menu.User Type') }}: {{ authBuyer ? $t('menu.Buyer') : $t('menu.Seller') }}</div>
                      </span>
                    </li>
                    <li v-if="auth && $route.name === 'Home'" class="nav-item"><router-link :to="{ name: 'Dashboard', params: { lang: this.$i18n.locale }}" class="nav-link font-weight-bold text-uppercase" exact-active-class="active">{{ $t('menu.Dashboard') }}</router-link></li>
                    <li v-if="auth" class="nav-item position-relative" @click="logout()">
                      <a class="nav-link">
                        <img src="@/assets/images/header/locked-padlock.png" class="img-fluid locked-padlock" :class="isRtl? 'ml-2': 'mr-2'" alt="...">
                        <div class="d-inline-block" :class="$route.name === 'Home' ? ' font-weight-bold text-uppercase': 'text-white font-weight-light font-size-14'">{{ $t('menu.Logout') }}</div>
                      </a>
                    </li>
                    </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div v-if="auth" id="header-bottom" class="header-bottom">
        <nav :class="['navbar navbar-expand-lg navbar-light', isRtl ? 'mr-auto' : 'ml-auto']">
        <ul :class="['navbar-nav', isRtl ? 'mr-auto' : 'ml-auto']">
          <li v-if="authBuyer" class="nav-item my-car" :class="$route.name === 'BuyerCars' ? 'active': ''"><router-link :to="{ name: 'BuyerCars', params: { lang: this.$i18n.locale }}" class="nav-link text-in-grey font-weight-500 font-size-16 background-amber">{{ $t('menu.My Cars') }}</router-link></li>
          <li v-if="authBuyer" class="nav-item mx-3" :class="$route.name === 'Cart' ? 'active': ''"><router-link :to="{ name: 'Cart', params: { lang: this.$i18n.locale }}" class="nav-link text-in-grey font-weight-500 font-size-16"><img src="@/assets/images/header/cart-icon-default-A.png" class="img-fluid header-cart-img" alt="..."><span class="badge font-weight-500 background-amber text-white notifications-count">{{ cartCount }}</span></router-link></li>
          <LanguageSwitcher style="margin-top: 2px;" />
          <li class="nav-item" :class=" isRtl ? 'ml-3' : 'mr-3'"><router-link :to="{ name: 'Notifications', params: { lang: this.$i18n.locale }}" class="nav-link" exact-active-class="active"><img src="@/assets/images/header/notification.png" class="img-fluid menu-icon" alt="..."><span class="badge font-weight-500 background-amber text-white notifications-count">{{ notificationsCount }}</span></router-link></li>
        </ul>
        </nav>
      </div>
    </header>
  </div>
</template>

<script>
import LanguageSwitcher from './LanguageSwitcher.vue'

export default {
  name: 'Header',
  components: {
    LanguageSwitcher
  },
  mounted () {
    function sticktothetop () {
      if (document.getElementsByClassName('logged-user-header-wrapper')[0]) {
        var windowTop = window.$(window).scrollTop()
        var top = 1
        if (windowTop > top) {
          window.$('#header-bottom').addClass('fixed-bottom-header')
        } else if (!document.getElementById('navbarNav').classList.contains('show')) {
          window.$('#header-bottom').removeClass('fixed-bottom-header')
        }
      }
    }
    window.$(function () {
      window.$(window).scroll(sticktothetop)
      sticktothetop()
    })

    if (this.auth) {
      this.getNotificationsCount()
    }
    if (this.authBuyer) {
      this.getCartCount()
    }
  },
  computed: {
    auth () {
      return this.$store.getters.isAuthenticated
    },
    notificationsCount () {
      return this.$store.getters.getNotificationsCount
    },
    cartCount () {
      return this.$store.getters.getCartCount
    },
    getClientFullName () {
      return this.$store.getters.getClientFullName
    },
    authBuyer () {
      return this.$store.getters.isAuthenticatedBuyer
    }
  },
  watch: {
    auth (newVal, oldVal) {
      if (newVal && !oldVal) {
        this.getNotificationsCount()
        if (this.authBuyer) {
          this.getCartCount()
        }
      }
    }
  },
  methods: {
    getNotificationsCount () {
      this.$store.dispatch('getNotificationsCount')
        .then(res => {
        }).catch(error => {
          console.log(error)
        })
    },
    getCartCount () {
      this.$store.dispatch('getCart')
        .then(res => {
        }).catch(error => {
          console.log(error)
        })
    },
    logout () {
      this.$store.dispatch('logout')
        .then(res => {
        }).catch(error => {
          console.log(error)
        })
      if (this.$route.name !== 'Home') {
        this.$router.push({ name: 'Home', params: { lang: this.$i18n.locale } })
      }
    }
  }
}
</script>

<style scoped>
@media (max-width: 991.98px){
  .rtl .navbar-nav .dropdown-toggle::after {
    right: unset;
    left: 0;
  }
  .navbar-brand {
    width: fit-content;
  }
}
</style>
