import axios from '@/plugins/axios'

const actions = {
  getProfile: ({ commit, state, rootState }) => {
    return axios.get('/api/clients/profile', {
      headers: {
        Authorization: 'Bearer ' + rootState.loginSignup.api_token
      }
    })
      .then(res => {
        return res
      }).catch(error => {
        return Promise.reject(error)
      })
  },
  updateProfile: ({ commit, state, rootState }, profile) => {
    return axios.post('/api/clients/update-profile', profile, {
      headers: {
        Authorization: 'Bearer ' + rootState.loginSignup.api_token
      }
    })
      .then(res => {
        return res
      }).catch(error => {
        return Promise.reject(error)
      })
  },
  updatePassword: ({ commit, state, rootState }, passwords) => {
    return axios.post('/api/clients/update-password', passwords, {
      headers: {
        Authorization: 'Bearer ' + rootState.loginSignup.api_token
      }
    })
      .then(res => {
        return res
      }).catch(error => {
        return Promise.reject(error)
      })
  }
}

export default {
  actions
}
