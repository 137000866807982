<template>
  <div>
     <!--hero section start-->

    <HeroSection>
      <h1>{{ $t('sellerParts.Priced Parts') }}</h1>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb justify-content-center bg-transparent p-0 m-0">
          <li class="breadcrumb-item"><router-link class="text-dark" :to="{ name: 'Dashboard', params: { lang: this.$i18n.locale }}">{{ $t('menu.Home') }}</router-link></li>
          <li class="breadcrumb-item active text-primary" aria-current="page">{{ $t('sellerParts.Priced Parts') }}</li>
        </ol>
      </nav>
    </HeroSection>

    <!--hero section end-->

    <!--body content start-->

    <div class="page-content">

    <section style="position: relative;">
      <Spinner v-if="isSpinnerShown"/>
      <div class="container">
        <div class="custom-button-wrapper">
          <router-link :to="{ name: 'AddPricedPart', params: { lang: $i18n.locale}}"  class="background-amber font-weight-600 shadow-primary d-inline custom-button"> + {{ $t('sellerParts.Add Priced Part') }}</router-link>
        </div>
        <div class="row mx-1">

          <div class="col-md-3 col-12 mb-4">
            <div>
              <p class="text-in-grey border-bottom py-1">{{ $t('offers.Search') }}</p>
              <PricedSellerPartSearch :dir="'vertical'" :show-part-name="true" :show-request-id="true" v-model="searchParams" @input="doSearch()"></PricedSellerPartSearch>
            </div>
          </div>

          <div class="col-sm-9 order-lg-9 ">
            <div v-if="pricedParts && pricedParts.length">
              <div class="part-item mb-5 px-3" v-for="pricedPart in pricedParts" :key="pricedPart.id">
                <div class="row align-items-center">
                  <div class="col-12 p-0">
                    <div class="row part-item-content">
                      <div  class="part-item--img">
                        <a data-toggle="modal" data-target="#view_modal" @click.prevent="showPricedPart(pricedPart)">
                          <div class="part-img">
                            <img v-if="pricedPart.images === null || pricedPart.images === ''" src="/assets/images/product/01.jpg" alt="">
                            <img v-else :src="getFirstImage(pricedPart.images)" alt="">
                          </div>
                        </a>
                      </div>
                      <div class="part-item--desc d-flex flex-wrap">
                        <div class="part-name-model">
                          <a data-toggle="modal" data-target="#view_modal" @click.prevent="showPricedPart(pricedPart)">
                            <div class="text-in-grey part-name">{{ pricedPart.standard_part[getFieldByLocale()] }} <small class="text-in-amber font-size-14" :class="isRtl ? 'mr-2' : 'ml-2'">({{ partConditions[pricedPart.condition][getFieldByLocale()] }})</small></div>
                          </a>
                          <div class="text-muted"><span v-for="(car_model, i) in pricedPart.car_models" :key="i"><span v-if="i !== 0">, </span> {{ pricedPart.car_models[i].car_model.brand[getFieldByLocale()] }}- {{ car_model.car_model[getFieldByLocale()] }} ({{ car_model.from_year }} - {{ car_model.to_year }})</span></div>
                        </div>
                        <div>
                          <div v-if="pricedPart.part_origin && pricedPart.condition === newPartCondition" class="d-flex flex-nowrap">
                            <div class="part-item-label text-muted">{{ $t('sellerParts.Part Origin') }}</div>
                            <div class="part-item-value text-in-amber">{{ pricedPart.part_origin[getFieldByLocale()] }}</div>
                          </div>
                          <div v-if="pricedPart.warranty_duration && pricedPart.condition === newPartCondition" class="d-flex flex-nowrap">
                            <div class="part-item-label text-muted  text-truncate">{{ $t('sellerParts.Warranty Duration') }}</div>
                            <div class="part-item-value text-in-amber">{{ pricedPart.warranty_duration }}</div>
                          </div>
                        </div>
                        <div>
                          <div class="d-flex flex-nowrap">
                            <div class="part-item-label text-muted">{{ $t('sellerParts.Seller Note') }}</div>
                            <div class="part-item-value text-in-amber one-line-text part-item-lv-w-110">{{ pricedPart.note }}</div>
                          </div>
                          <div class="d-flex flex-nowrap">
                            <div class="part-item-label text-muted">{{ $t('sellerParts.Part Price') }}</div>
                            <div class="part-item-value text-in-amber font-weight-600 part-item-lv-w-110 font-size-16">{{ pricedPart.price }} {{ getCurrency () }} <small>{{ $t('sellerParts.The price includes the added tax') }}</small></div>
                          </div>
                        </div>
                        <div>
                          <div class="d-flex flex-nowrap">
                            <div class="part-item-label text-muted">{{ $t('sellerParts.Stock') }}</div>
                            <div class="part-item-value text-in-amber part-item-lv-w-110">{{ pricedPart.stock }}</div>
                          </div>
                          <div class="d-flex flex-nowrap">
                            <div class="part-item-label text-muted">{{ $t('offers.Offer Date') }}</div>
                            <div class="part-item-value text-in-amber part-item-lv-w-110">{{ pricedPart.created_at.split('T')[0].split('-').reverse().join('/') }}</div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="mt-3 d-flex flex-nowrap part-item-buttons">
                          <div class="align-self-center">
                            <a :id="'view-details-btn-' + pricedPart.id" data-toggle="modal" data-target="#view_modal" @click.prevent="showPricedPart(pricedPart)" class="btn-amber text-white d-block">{{ $t('common.Details') }}</a>
                          </div>
                          <div class="align-self-center">
                            <a @click.prevent="showEditForm(pricedPart)" data-toggle="modal" data-target="#edit_form" class="btn-grey text-in-grey">{{ $t('common.Edit') }}</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <nav aria-label="..." class="pagination-navigation mt-8" v-if="pageCount">
              <paginate
                v-model="page"
                :page-count="pageCount"
                :page-range="10"
                :margin-pages="1"
                :click-handler="clickCallback"
                :prev-text="$t('common.Prev')"
                :next-text="$t('common.Next')"
                :prev-class="isRtl ? 'page-item ml-auto' : 'page-item mr-auto'"
                :prev-link-class="'page-link'"
                :next-class="isRtl ? 'page-item mr-auto' : 'page-item ml-auto'"
                :next-link-class="'page-link'"
                :container-class="'pagination'"
                :page-class="'page-item'"
                :page-link-class="'dark-link-text page-link border-0 rounded'">
              </paginate>
            </nav>
            </div>
            <EmptyListMessage v-else-if="!isSpinnerShown">{{ $t('sellerParts.Priced Parts List is Empty') }}</EmptyListMessage>

          </div>

        </div>
      </div>
    </section>

    <Modal :modal_id="'view_modal'">
      <section v-if="pricedPart" class="gallery-text-section">
        <div class="container">
          <div class="row">
            <div class="col-md-6" >
                <div id="image-gallery"  class="carousel slide" data-ride="carousel">

                  <div v-if="pricedPart.images" class="carousel-inner">
                    <div class="carousel-item " v-for="(image, i) in getAllImages(pricedPart.images)" :key="i" @click="index = i" :class="{'active': i===0}" >
                        <img :src="image" alt="" width="1100" height="500">
                    </div>
                  </div>
                  <div v-else class="carousel-inner">
                    <div class="carousel-item active" @click="index = 0">
                        <img  src="/assets/images/product/01.jpg" alt="" width="1100" height="500">
                    </div>
                  </div>
                  <ul v-if="pricedPart.images" class="carousel-indicators">
                    <li data-target="#image-gallery" v-for="(image, i) in getAllImages(pricedPart.images)" :class="{'active': i===0}" :key="i" :data-slide-to="i" >
                      <img :src="image" alt="" width="1100" height="500" style="width: 24%;">
                    </li>
                  </ul>
                  <ul v-else class="carousel-indicators">
                    <li class="active" data-target="#image-gallery">
                      <img src="/assets/images/product/01.jpg" alt="" width="1100" height="500" style="width: 24%;">
                    </li>
                  </ul>
                </div>
            </div>

            <div class="col-md-6">
              <h2 class="title">{{ $t('sellerParts.Priced Part Details') }}</h2>
              <p class="font-weight-600 font-size-20 mb-0 text-in-grey custom-line-height">{{ pricedPart.standard_part[getFieldByLocale()] }} <small class="text-in-amber font-size-14" :class="isRtl ? 'mr-2' : 'ml-2'">({{ partConditions[pricedPart.condition][getFieldByLocale()] }})</small></p>
              <p class="lead mb-5">
                {{ pricedPart.car_models[0].car_model.brand[getFieldByLocale()] }} <span v-for="(car_model, i) in pricedPart.car_models" :key="i">{{ i === 0 ? '': ',' }} {{ car_model.car_model[getFieldByLocale()] }} ({{ car_model.from_year }} - {{ car_model.to_year }})</span>
                <br>
                {{ $t('sellerParts.Date') }}: {{ pricedPart.created_at.split('T')[0].split('-').reverse().join('-') }}
              </p>
              <div class="row mt-4">
                <div class="col-md-6 col-sm-6 col-xs-12">
                  <ul class="cases-meta list-unstyled text-muted">
                    <li class="mb-3"><span class="text-dark"> {{ $t('sellerParts.Part Category') }}: </span> {{ pricedPart.standard_part.part_category[getFieldByLocale()] }}</li>
                    <li class="mb-3"><span class="text-dark"> {{ $t('sellerParts.Part No') }}: </span> {{ pricedPart.number }}</li>
                    <li class="mb-3"><span class="text-dark"> {{ $t('sellerParts.Stock') }}: </span> {{ pricedPart.stock }}</li>
                    <li class="mb-3" v-if="pricedPart.condition === newPartCondition && pricedPart.part_origin"><span class="text-dark"> {{ $t('sellerParts.Part Origin') }}: </span> {{ pricedPart.part_origin[getFieldByLocale()] }}</li>
                  </ul>
                </div>
                <div class="col-md-6 col-sm-6 col-xs-12">
                  <ul class="cases-meta list-unstyled text-muted">
                    <li class="mb-3"><span class="text-dark"> {{ $t('sellerParts.Price') }}: </span> {{ pricedPart.price }} {{ getCurrency () }}<br><small>{{ $t('sellerParts.The price includes the added tax') }}</small></li>
                    <li class="mb-3" v-if="pricedPart.condition === usedPartCondition" ><span class="text-dark"> {{ $t('sellerParts.Disassemble Cost') }}: </span> {{ pricedPart.disassemble_cost }} {{ getCurrency () }}</li>
                    <li class="mb-3"><span class="text-dark"> {{ $t('sellerParts.Warranty Duration') }}: </span> {{ pricedPart.warranty_duration }} {{ $t('sellerParts.day(s)') }}</li>
                  </ul>
                </div>
                <div class="col-md-12 col-sm-12 col-xs-12">
                  <span class="product-price text-in-red">{{ $t('sellerParts.Note') }}: {{ pricedPart.note }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Modal>

    <Modal :modal_id="'edit_form'">
        <section>
          <div class="container" style="position: relative;">
            <Spinner v-if="isSpinnerShownOnEditModal"/>
            <div class="row justify-content-center mb-5 text-center">
              <div class="col-12 col-lg-8">
                <div> <span class="badge badge-primary-soft p-2">
                          <img class="total-parts-icon" src="@/assets/images/other/total-parts.png">
                      </span>
                  <h2 class="mt-4 mb-0">{{ $t('sellerParts.Edit Priced Part') }}</h2>
                </div>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-12 col-lg-10">
                  <form id="edit-priced-parts-form" class="row" method="post">
                    <div class="messages"></div>
                    <div class="form-group col-md-12" style="position: relative;" v-for="(car_model, i) in pricedPartEditObject.car_models" :key="i">
                      <div class="row">
                        <button v-if="i !== 0" @click.prevent="removeCarModel(i)"  class="car-model-removal close" :class="isRtl? 'mr-auto': 'ml-auto'">×</button>
                      </div>

                      <CarBrandModel v-if="car_brand_model.length" :chosenModelIds="chosenModelIds" :brands="brands" :car_model="car_model" :car_brand_model="car_brand_model[i]" :error="error.car_models[i]" :isBrandFieldDisabled="isBrandFieldDisabled" :index="i" @brandIdChanged="getModelList" />

                    </div>
                    <div class="col-md-12 form-group">
                      <button id="upload-btn" @click.prevent="addCarModel()" class="btn btn-link text-in-grey btn-sm p-0"><span>+ {{ $t('sellerParts.Add Car Model') }}</span></button>
                    </div>
                    <div class="form-group col-md-6">
                      <label for="condition">{{ $t('sellerParts.Parts Status') }}</label>
                      <select id="condition" v-model="pricedPartEditObject.condition" class="form-control" required="required" :data-error="$t('sellerParts.Part Status is required')">
                        <option value="0" disabled>{{ $t('sellerParts.Parts Status') }}</option>
                        <option v-for="condition in [partConditions[newPartCondition],partConditions[usedPartCondition]]" :key="condition.id" :value="condition.id">{{ condition[getFieldByLocale()] }}</option>
                      </select>
                      <div v-if="!error.condition" class="help-block with-errors"></div>
                      <div class="error-message-block">{{ error.condition }}</div>
                    </div>
                    <div class="form-group col-md-6">
                      <label for="number">{{ $t('sellerParts.Number') }}</label>
                      <input id="number" v-model="pricedPartEditObject.number" type="text" name="number" class="form-control" :placeholder="$t('sellerParts.Number')" required="required" :data-error="$t('sellerParts.Number is required')">
                      <div v-if="!error.number" class="help-block with-errors"></div>
                      <div class="error-message-block">{{ error.number }}</div>
                    </div>
                    <div class="form-group col-md-6">
                      <label for="price">{{ $t('sellerParts.Price') }} <small>({{ getCurrency() }})</small> <small>{{ $t('sellerParts.The price includes the added tax') }}</small></label>
                      <input id="price" v-model="pricedPartEditObject.price" data-parsley-type="number" type="text" name="price" class="form-control" :placeholder="$t('sellerParts.Price')" required="required" :data-error="$t('sellerParts.Price is required')">
                      <div v-if="!error.price" class="help-block with-errors"></div>
                      <div class="error-message-block">{{ error.price }}</div>
                    </div>
                    <div class="form-group col-md-6" v-if="pricedPartEditObject.condition && pricedPartEditObject.condition === usedPartCondition">
                      <label for="disassemble_cost">{{ $t('sellerParts.Disassemble Cost') }} <small>({{ getCurrency() }})</small></label>
                      <input id="disassemble_cost" v-model="pricedPartEditObject.disassemble_cost" data-parsley-type="number" type="text" name="disassemble_cost" class="form-control" :placeholder="$t('sellerParts.Disassemble Cost')" required="required" :data-error="$t('sellerParts.Disassemble Cost is required')">
                      <div v-if="!error.disassemble_cost" class="help-block with-errors"></div>
                      <div class="error-message-block">{{ error.disassemble_cost }}</div>
                    </div>
                    <div class="form-group col-md-6" v-if="pricedPartEditObject.condition && pricedPartEditObject.condition === newPartCondition">
                      <label for="part_origin_id">{{ $t('sellerParts.Part Origin') }}</label>
                      <select id="part_origin_id" v-model="pricedPartEditObject.part_origin_id" class="form-control" required="required" :data-error="$t('sellerParts.Part Origin is required')">
                        <option value="0" disabled>{{ $t('sellerParts.Part Origin') }}</option>
                        <option v-for="partOrigin in partOrigins" :key="partOrigin.id" :value="partOrigin.id">{{ partOrigin[getFieldByLocale()] }}</option>
                      </select>
                      <div v-if="!error.part_origin_id" class="help-block with-errors"></div>
                      <div class="error-message-block">{{ error.part_origin_id }}</div>
                    </div>
                    <div class="form-group col-md-6">
                      <label for="stock">{{ $t('sellerParts.Stock') }}</label>
                      <input id="stock" v-model="pricedPartEditObject.stock" type="number" name="stock" min=0 oninput="validity.valid||(value='');" class="form-control" :placeholder="$t('sellerParts.Stock')" required="required" :data-error="$t('sellerParts.Stock is required')">
                      <div v-if="!error.stock" class="help-block with-errors"></div>
                      <div class="error-message-block">{{ error.stock }}</div>
                    </div>
                    <div class="form-group col-md-6">
                      <label for="warranty_duration">{{ $t('sellerParts.Warranty Duration') }} <small>({{ $t('sellerParts.day(s)') }})</small></label>
                      <input id="warranty_duration" v-model="pricedPartEditObject.warranty_duration" type="number" name="warranty_duration" min=0 oninput="validity.valid||(value='');" class="form-control" :placeholder="$t('sellerParts.Warranty Duration')" required="required" :data-error="$t('sellerParts.Warranty Duration is required')">
                      <div v-if="!error.warranty_duration" class="help-block with-errors"></div>
                      <div class="error-message-block">{{ error.warranty_duration }}</div>
                    </div>
                    <div class="form-group col-md-6">
                      <label for="note">{{ $t('sellerParts.Note') }}</label>
                      <textarea id="note" v-model="pricedPartEditObject.note" name="note" class="form-control" :placeholder="$t('sellerParts.Note')" rows="3"></textarea>
                      <div v-if="!error.note" class="help-block with-errors"></div>
                      <div class="error-message-block">{{ error.note }}</div>
                    </div>
                    <div class="form-group col-md-12">
                      <label for="dropzone">{{ $t('sellerParts.Part Images') }}</label>
                      <ImageUpload :imageType="imageType" :errorMessage="error.images" :isEditImages="true" :stringOfImages="pricedPartEditObject.images" :maxFilesUpload="maxFilesUpload" :clearDropzone="clearDropzone" @isfileUplaodingChange="setIsfileUplaoding" @addedImagesChange="setAddedImages" @deletedImagesChange="setDeletedImages" />
                      <div class="row">
                        <div class="col-md-12">
                          <UploadedImageConditions />
                        </div>
                      </div>

                    </div>
                    <div class="col-md-12 text-center mt-4">
                      <button class="btn btn-primary" @click.prevent="editSellerPart()" :disabled="isfileUplaoding"><span>{{ $t('common.Update') }}</span>
                      </button>
                    </div>
                  </form>
            </div>
            </div>
          </div>
        </section>
      </Modal>

    <CoolLightBox
      :items="items"
      :index="index"
      @close="index = null">
    </CoolLightBox>

    </div>

    <!--body content end-->
  </div>
</template>

<script>
import Paginate from 'vuejs-paginate'
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import Modal from '../../components/Modal.vue'
import UploadedImageConditions from '../../components/UploadedImageConditions.vue'
import ImageUpload from '../../components/ImageUpload'
import CarBrandModel from '../../components/CarBrandModel.vue'
import PricedSellerPartSearch from '@/components/PricedSellerPartSearch'
import { PricedSellerPartMixin } from '@/mixins/PricedSellerPartMixin'
import EmptyListMessage from '@/components/EmptyListMessage'

export default {
  name: 'PricedParts',
  mixins: [PricedSellerPartMixin],
  data () {
    return {
      pricedParts: null,
      pricedPart: null,
      partOrigins: null,
      page: null,
      pageCount: null,
      isSpinnerShown: false,
      isSpinnerShownOnEditModal: false,
      items: [],
      index: null,
      chosenModelIds: [],
      brands: null,
      car_brand_model: [],
      isBrandFieldDisabled: true,
      isSubmittingPrevented: false,

      clearDropzone: false,
      isfileUplaoding: false,
      imageType: null,
      maxFilesUpload: null,

      error: {
        part_origin_id: null,
        number: null,
        price: null,
        stock: null,
        note: null,
        condition: null,
        warranty_duration: null,
        disassemble_cost: null,
        images: null,
        car_models: []
      },
      pricedPartEditObject: {
        id: null,
        part_origin_id: null,
        car_category_id: null,
        number: null,
        price: null,
        stock: null,
        note: null,
        condition: null,
        warranty_duration: null,
        disassemble_cost: null,
        images: null,
        added_images: '',
        deleted_images: '',
        car_models: []
      }
    }
  },
  components: {
    EmptyListMessage,
    PricedSellerPartSearch,
    Paginate,
    CoolLightBox,
    Modal,
    ImageUpload,
    UploadedImageConditions,
    CarBrandModel
  },
  mounted () {
    this.imageType = 'seller_parts'

    if (!this.$route.query.page) {
      this.page = 1
    } else {
      this.page = parseInt(this.$route.query.page)
    }

    this.getPricedParts(this.page)

    this.$store.dispatch('getPartOrigins')
      .then(res => {
        this.partOrigins = res.data.data
      }).catch(error => {
        console.log(error)
      })
  },
  computed: {
    isReadyForSubmission () {
      return parseInt(this.pricedPartEditObject.part_origin_id) && this.pricedPartEditObject.number && this.pricedPartEditObject.price !== null && this.pricedPartEditObject.price !== '' && this.pricedPartEditObject.stock !== null && this.pricedPartEditObject.stock !== '' && parseInt(this.pricedPartEditObject.condition) && this.pricedPartEditObject.warranty_duration !== null && this.pricedPartEditObject.warranty_duration !== '' && this.pricedPartEditObject.disassemble_cost !== null && this.pricedPartEditObject.disassemble_cost !== ''
    }
  },
  watch: {
    'pricedPartEditObject.car_models': {
      handler: function (val, oldVal) {
        this.chosenModelIds.splice(0, this.chosenModelIds.length)

        for (var i = 0; i < this.pricedPartEditObject.car_models.length; i++) {
          if (this.pricedPartEditObject.car_models[i].to_year < this.pricedPartEditObject.car_models[i].from_year) {
            this.pricedPartEditObject.car_models[i].to_year = 0
          }

          if (this.pricedPartEditObject.car_models[i].car_model_id) {
            this.chosenModelIds.push(this.pricedPartEditObject.car_models[i].car_model_id)
          }
        }
      },
      deep: true
    },
    'pricedPartEditObject.condition' () {
      if (this.pricedPartEditObject.condition === this.newPartCondition) {
        this.pricedPartEditObject.disassemble_cost = null
      } else {
        this.pricedPartEditObject.part_origin_id = 0
      }
    }
  },
  methods: {
    setIsfileUplaoding (value) {
      this.isfileUplaoding = value
    },
    setAddedImages (value) {
      this.pricedPartEditObject.added_images = value.join(',')
    },
    setDeletedImages (value) {
      this.pricedPartEditObject.deleted_images = value.join(',')
    },
    getPricedParts (page) {
      this.isSpinnerShown = true
      this.$store.dispatch('getPricedParts', { page })
        .then(res => {
          this.isSpinnerShown = false

          if (res.data.data.current_page > res.data.data.last_page) {
            this.page = res.data.data.last_page
            this.clickCallback(res.data.data.last_page)
          }

          this.pricedParts = res.data.data.data
          this.pageCount = res.data.data.last_page
        }).catch(error => {
          this.isSpinnerShown = false
          console.log(error)
        })
    },
    clickCallback (pageNum) {
      this.$router.push({ name: 'pricedParts', query: { page: pageNum } })
      this.getPricedParts(pageNum)
    },
    showPricedPart (pricedPart) {
      this.items = []
      this.index = null
      this.pricedPart = pricedPart
      if (pricedPart.images) {
        pricedPart.images.split(',').forEach((element, index) => {
          this.items.push(element)
        })
      } else {
        this.items.push('/assets/images/product/01.jpg')
      }
    },
    getModelList (modelIndex) {
      this.pricedPartEditObject.car_models[modelIndex].car_model_id = 0

      if (parseInt(this.car_brand_model[modelIndex].brand_id) === 0) {
        this.car_brand_model[modelIndex].isModelFieldDisabled = true
      } else {
        this.getModelsByCategoryBrand(this.pricedPartEditObject.car_category_id, this.car_brand_model[modelIndex].brand_id, modelIndex)
      }
    },
    getBrandsByCarCategory (carCategoryId) {
      this.$store.dispatch('getBrandsByCarCategory', carCategoryId)
        .then(res => {
          this.brands = res.data.data
          this.isBrandFieldDisabled = false
        }).catch(error => {
          console.log(error)
        })
    },
    addCarModel () {
      this.car_brand_model.push({
        models: null,
        brand_id: 0,
        isModelFieldDisabled: true
      })

      this.pricedPartEditObject.car_models.push({
        car_model_id: 0,
        from_year: 0,
        to_year: 0
      })

      this.error.car_models.push({
        brand_id: null,
        car_model_id: null,
        from_year: null,
        to_year: null
      })
    },
    removeCarModel (index) {
      this.pricedPartEditObject.car_models.splice(index, 1)
      this.car_brand_model.splice(index, 1)
      this.error.car_models.splice(index, 1)
    },
    getModelsByCategoryBrand (carCategoryId, brandId, carBrandModelIndex) {
      this.$store.dispatch('getModelsByCategoryBrand', { car_category_id: carCategoryId, brand_id: brandId })
        .then(res => {
          this.car_brand_model[carBrandModelIndex].models = res.data.data
          this.car_brand_model[carBrandModelIndex].isModelFieldDisabled = false
        }).catch(error => {
          console.log(error)
        })
    },
    showEditForm (pricedPart) {
      this.clearDropzone = !this.clearDropzone
      this.pricedPartEditObject.id = pricedPart.id
      this.pricedPartEditObject.car_category_id = pricedPart.standard_part.car_category_id
      this.pricedPartEditObject.part_origin_id = pricedPart.part_origin_id ? pricedPart.part_origin_id : 0
      this.pricedPartEditObject.number = pricedPart.number
      this.pricedPartEditObject.price = pricedPart.price
      this.pricedPartEditObject.stock = pricedPart.stock
      this.pricedPartEditObject.note = pricedPart.note
      this.pricedPartEditObject.condition = pricedPart.condition
      this.pricedPartEditObject.warranty_duration = pricedPart.warranty_duration
      this.pricedPartEditObject.disassemble_cost = pricedPart.disassemble_cost
      this.pricedPartEditObject.images = pricedPart.images
      this.pricedPartEditObject.added_images = ''
      this.pricedPartEditObject.deleted_images = ''
      this.pricedPartEditObject.car_models = []
      this.chosenModelIds = []
      this.car_brand_model = []

      this.clearErrorData()

      this.getBrandsByCarCategory(this.pricedPartEditObject.car_category_id)

      pricedPart.car_models.forEach((element, index) => {
        this.$nextTick(function () {
          this.pricedPartEditObject.car_models.push({
            car_model_id: element.car_model_id,
            from_year: element.from_year,
            to_year: element.to_year
          })
        })

        this.chosenModelIds.push(element.car_model_id)

        this.car_brand_model.push({
          brand_id: element.car_model.brand.id,
          models: null,
          isModelFieldDisabled: true
        })

        this.error.car_models.push({
          brand_id: null,
          car_model_id: null,
          from_year: null,
          to_year: null
        })

        this.getModelsByCategoryBrand(this.pricedPartEditObject.car_category_id, this.car_brand_model[index].brand_id, index)
      })

      if (!pricedPart.images) {
        this.maxFilesUpload = 4
      } else if (pricedPart.images.split(',').length < 4) {
        this.maxFilesUpload = 4 - pricedPart.images.split(',').length
      } else {
        this.maxFilesUpload = 0
      }
    },
    checkDataReadyForSubmission () {
      this.isSubmittingPrevented = false
      document.querySelectorAll('#edit-priced-parts-form [required="required"]').forEach(elem => {
        elem.nextSibling.innerText = ''
        if ((parseInt(elem.value) === 0 && elem.getAttribute('name') !== 'disassemble_cost') || elem.value === null || elem.value === '') {
          this.isSubmittingPrevented = true
          elem.nextSibling.innerText = elem.getAttribute('data-error')
        }
      })
    },
    clearErrorData () {
      this.error = {
        part_origin_id: null,
        number: null,
        price: null,
        stock: null,
        note: null,
        condition: null,
        warranty_duration: null,
        disassemble_cost: null,
        images: null,
        car_models: []
      }
    },
    editSellerPart () {
      this.clearErrorData()

      for (var i = 0; i < this.pricedPartEditObject.car_models.length; i++) {
        this.error.car_models.push({
          brand_id: null,
          car_model_id: null,
          from_year: null,
          to_year: null
        })
      }

      this.checkDataReadyForSubmission()

      if (this.isSubmittingPrevented) {
        return
      }

      if (this.pricedPartEditObject.condition === this.newPartCondition) {
        this.pricedPartEditObject.disassemble_cost = 0
      } else {
        this.$delete(this.pricedPartEditObject, 'part_origin_id')
      }

      this.isSpinnerShownOnEditModal = true
      this.$store.dispatch('updatePricedPart', this.pricedPartEditObject)
        .then(res => {
          this.isSpinnerShownOnEditModal = false
          document.getElementById('modal-close-btn').click()
          this.getPricedParts(this.page)
        }).catch(error => {
          this.isSpinnerShownOnEditModal = false
          if (error.response) {
            for (var field in error.response.data[0]) {
              this.error[field] = this.joinArrayByComma(error.response.data[0][field])
            }
          }
        })
    }
  }
}
</script>

<style scoped>
  .dark-link-text {
    color: #1d1d33;
  }
  .product-img {
    height: 21rem;
  }
  .product-img img{
    width: 100%!important;
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
  }
  .rtl .pagination{
    direction: rtl;
  }
</style>
