<template>
  <section class="text-center pb-11">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <h3 class="mb-4"><slot></slot></h3>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'EmptyListMessage'
}
</script>
