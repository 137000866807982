<template>
  <div>
    <!--hero section start-->

    <HeroSection>
      <h1>{{ $t('cart.Cart') }}</h1>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb justify-content-center bg-transparent p-0 m-0">
          <li class="breadcrumb-item"><router-link class="text-dark" :to="{ name: 'Dashboard', params: { lang: this.$i18n.locale }}">{{ $t('menu.Home') }}</router-link></li>
          <li class="breadcrumb-item active text-primary" aria-current="page">{{ $t('cart.Cart') }}</li>
        </ol>
      </nav>
    </HeroSection>

    <!--hero section end-->

    <!--body content start-->
    <div class="page-content"  style="position: relative;min-height: 500px;">
      <Spinner v-if="isPageLoading"/>

    <section v-if="offers && offers.length">
      <div class="container">
        <CartIcons :isHyperpayShown="false" />
        <div class="row">

          <div class="col">
            <div class="table-responsive">
          <table class="cart-table table table-bordered">
            <thead>
              <tr>
                <th scope="col">{{ $t('cart.Seller') }}</th>
                <th scope="col">{{ $t('cart.Part') }}</th>
                <th scope="col">{{ $t('cart.Price') }}</th>
                <th scope="col">{{ $t('cart.Disassemble Cost') }}</th>
                <th scope="col">{{ $t('cart.Total') }}</th>
                <th scope="col">{{ $t('cart.Shipping Fee') }}</th>
                <th scope="col">{{ $t('cart.Remove') }}</th>
              </tr>
            </thead>
            <tbody v-for="(sellerOffers, j) in offersBySellers" :key="j">
              <tr v-for="(offer, i) in sellerOffers" :key="i">
                <td v-if="i === 0" :rowspan="sellerOffers.length">
                  <h5 class="mb-0">
                    {{ offer.seller.seller_code }}
                    <span v-if="offer.seller.city"> {{ offer.seller.city.name }} </span>
                  </h5>
                </td>
                <td>
                  <div class="media align-items-center">
                    <a data-toggle="modal" data-target="#view_modal" @click.prevent="showOffer(offer)">
                      <img v-if="!offer.images" class="img-fluid mb-2 mb-lg-0 part-image" :class="isRtl ? 'ml-lg-2' : 'mr-lg-2'" src="/assets/images/product/01.jpg" alt="">
                      <img v-else class="img-fluid mb-2 mb-lg-0 part-image" :class="isRtl ? 'ml-lg-2' : 'mr-lg-2'" :src="getFirstImage(offer.images)" alt="">
                    </a>
                    <div class="media-body" :class="isRtl ? 'text-right': 'text-left'">
                      <a data-toggle="modal" data-target="#view_modal" @click.prevent="showOffer(offer)">
                        <h5 class="mb-0 font-weight-500 text-muted">{{ offer.request.part_name }}</h5>
                      </a>
                    </div>
                  </div>
                </td>
                <td>
                  <h5 class="mb-0">{{ offer.sparaat_price + ' ' + getCurrency() }}</h5>
                </td>
                <td>
                  <h5 class="mb-0">{{ offer.disassemble_cost + ' ' + getCurrency() }}</h5>
                </td>
                <td>
                  <h5 class="mb-0">{{ offer.total_price + ' ' + getCurrency() }}<br><small>{{ $t('sellerParts.The price includes the added tax') }}</small></h5>
                </td>
                <td v-if="i === 0" :rowspan="sellerOffers.length">
                  <div class="d-flex justify-content-center align-items-center">
                    {{ cart.shipping_fee / cart.sellers_count }} {{ getCurrency() }}
                  </div>
                </td>
                <td>
                  <button @click.prevent="removeItemFromCart(offer.id)" class="btn btn-primary btn-sm"><i class="ti-close"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
          </div>
        </div>
        <div class="row mt-8">
          <div class="col-lg-6">
          </div>
          <div class="col-lg-6 pl-5 mt-5 mt-lg-0">
            <div class="row justify-content-end">
              <div class="col-md-7">
                <CartTotals :cartTotals="cart" :btnRoute="{ name: 'Checkout', params: { lang: this.$i18n.locale }}" :btnLabel="$t('cart.Proceed To Checkout')"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section v-else-if="!isPageLoading && !offers.length" class="text-center pb-11">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <h3 class="mb-4">{{ $t('cart.Your cart is empty') }}</h3>
            <router-link :to="{ name: 'Dashboard', params: { lang: this.$i18n.locale }}" class="btn btn-primary" :class="isRtl ? 'ml-1' : 'mr-1'">{{ $t('menu.Home') }}</router-link>
            <router-link :to="{ name: 'Offers', params: { lang: this.$i18n.locale }}" class="btn btn-primary" exact-active-class="active">{{ $t('menu.Offers') }}</router-link>
          </div>
        </div>
      </div>
    </section>

    <OfferViewModal :offer="offer" />

    </div>

    <!--body content end-->
  </div>
</template>

<script>
import OfferViewModal from '../../components/offer/OfferViewModal.vue'
import CartIcons from '../../components/CartIcons.vue'
import CartTotals from '../../components/CartTotals.vue'

export default {
  name: 'Cart',
  data () {
    return {
      cart: null,
      offers: [],
      offersBySellers: {},
      isPageLoading: false,
      offer: null
    }
  },
  components: {
    OfferViewModal,
    CartIcons,
    CartTotals
  },
  mounted () {
    this.getCart()
  },
  methods: {
    showOffer (offer) {
      this.offer = offer
    },
    getCart () {
      this.isPageLoading = true
      this.$store.dispatch('getCart')
        .then(res => {
          this.isPageLoading = false
          this.offers = res.data.data.offers
          if (this.offersBySellers.length) {
            this.offersBySellers.splice(0, this.offersBySellers.length)
          }
          this.offersBySellers = {}
          res.data.data.offers.map((elem, i) => {
            if (!this.offersBySellers[elem.seller_id]) {
              this.offersBySellers[elem.seller_id] = []
            }
            this.offersBySellers[elem.seller_id].push(elem)
          })
          this.cart = res.data.data
        }).catch(error => {
          this.isPageLoading = false
          console.log(error)
        })
    },
    removeItemFromCart (offerId) {
      const swalWithBootstrapButtons = this.$swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger'
        },
        buttonsStyling: false
      })

      swalWithBootstrapButtons.fire({
        title: this.$t('common.Are you sure?'),
        text: this.$t('cart.You are about to remove an item from cart!'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('common.Yes, remove it'),
        cancelButtonText: this.$t('common.No, cancel'),
        reverseButtons: false
      }).then((result) => {
        if (result.isConfirmed) {
          this.isPageLoading = true
          this.$store.dispatch('removeItemFromCart', offerId)
            .then(res => {
              this.isPageLoading = false
              this.getCart()
              swalWithBootstrapButtons.fire({
                title: this.$t('common.Removed!'),
                text: this.$t('common.The item has been removed'),
                icon: 'success',
                confirmButtonText: this.$t('common.Ok')
              })
            }).catch(error => {
              this.isPageLoading = false
              console.log(error)
            })
        }
      })
    }
  }
}
</script>
