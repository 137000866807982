<template>
  <div>
    <!--hero section start-->
    <HeroSection>
      <h1>{{ $t('sellerParts.Add New Priced Part') }}</h1>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb justify-content-center bg-transparent p-0 m-0">
          <li class="breadcrumb-item"><router-link class="text-dark" :to="{ name: 'Dashboard', params: { lang: this.$i18n.locale }}">{{ $t('menu.Home') }}</router-link></li>
          <li class="breadcrumb-item"><router-link class="text-dark" :to="{ name: 'pricedParts', params: { lang: this.$i18n.locale }}">{{ $t('sellerParts.Priced Parts') }}</router-link></li>
          <li class="breadcrumb-item active text-primary" aria-current="page">{{ $t('sellerParts.Add New Priced Part') }}</li>
        </ol>
      </nav>
    </HeroSection>
    <!--hero section end-->

    <!--body content start-->

    <div class="page-content">

    <section>
      <div class="container" style="position: relative;">
        <Spinner v-if="isSpinnerShown"/>
        <div class="row justify-content-center mb-5 text-center">
          <div class="col-12 col-lg-8">
            <div> <span class="badge badge-primary-soft p-2">
                      <img class="total-parts-icon" src="@/assets/images/other/total-parts.png">
                  </span>
              <p class="lead mt-4 mb-0">{{ $t('sellerParts.Please enter the priced part info that you have in your store') }}</p>
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-12 col-lg-10">
              <form id="add-priced-part-form" class="row" method="post">
                <div class="form-group col-12">
                  <div v-if="message" class="messages success-message">{{ message }}</div>
                </div>
                <div class="form-group col-md-6">
                  <label for="car_category_id">{{ $t('cars.Car Category') }}</label>
                  <select id="car_category_id" v-model="car_category_id" class="form-control" required="required" :data-error="$t('sellerParts.Car Category is required')">
                    <option value="0" disabled>{{ $t('cars.Car Category') }}</option>
                    <option v-for="category in carCategories" :key="category.id" :value="category.id">{{ category[getFieldByLocale()] }}</option>
                  </select>
                  <div v-if="!error.car_category_id" class="help-block with-errors"></div>
                  <div class="error-message-block">{{ error.car_category_id }}</div>
                </div>

                <div class="form-group col-md-12" style="position: relative;" v-for="(car_model, i) in pricedPart.car_models" :key="i">
                  <div class="row">
                    <button v-if="i !== 0" @click.prevent="removeCarModel(i)"  class="car-model-removal close" :class="isRtl? 'mr-auto': 'ml-auto'">×</button>
                  </div>

                    <CarBrandModel :chosenModelIds="chosenModelIds" :brands="brands" :car_model="car_model" :car_brand_model="car_brand_model[i]" :error="error.car_models[i]" :isBrandFieldDisabled="isBrandFieldDisabled" :index="i" @brandIdChanged="getModelList" />

                </div>
                <div class="col-md-12 form-group">
                  <button id="upload-btn" @click.prevent="addCarModel()" class="btn btn-link text-in-grey btn-sm p-0"><span>+ {{ $t('sellerParts.Add Car Model') }}</span></button>
                </div>
                <div class="form-group col-md-6">
                  <label for="condition">{{ $t('sellerParts.Part Status') }}</label>
                  <select id="condition" v-model="pricedPart.condition" class="form-control" required="required" :data-error="$t('sellerParts.Part Status is required')">
                    <option value="0" disabled>{{ $t('sellerParts.Part Status') }}</option>
                    <option v-for="condition in [partConditions[newPartCondition],partConditions[usedPartCondition]]" :key="condition.id" :value="condition.id">{{ condition[getFieldByLocale()] }}</option>
                  </select>
                  <div v-if="!error.condition" class="help-block with-errors"></div>
                  <div class="error-message-block">{{ error.condition }}</div>
                </div>
                <div class="form-group col-md-6">
                  <label for="part_category_id">{{ $t('sellerParts.Part Category') }}</label>
                  <select id="part_category_id" v-model="part_category_id" class="form-control" :disabled="isPartCategoryFieldDisabled" required="required" :data-error="$t('sellerParts.Part Category is required')">
                    <option value="0" disabled>{{ $t('sellerParts.Part Category') }}</option>
                    <option v-for="partCategory in partCategories" :key="partCategory.id" :value="partCategory.id">{{ partCategory[getFieldByLocale()] }}</option>
                  </select>
                  <div v-if="!error.part_category_id" class="help-block with-errors"></div>
                  <div class="error-message-block">{{ error.part_category_id }}</div>
                </div>
                <AutocompletePartName :triggerPartNameOnFocus="triggerPartNameOnFocus" :isPartNameFieldDisabled="!isPartNameFieldDisabled" :errorMessage="error.standard_part_id" :standardPartInfo="standardPartInfo" :isStandardPartIdRequested="true" :clearData="clearStandardPartData" @standardPartIdChanged="setStandardPartId" />
                <div class="form-group col-md-6" v-if="pricedPart.condition && pricedPart.condition === newPartCondition">
                  <label for="origin_id">{{ $t('sellerParts.Part Origin') }}</label>
                  <select id="origin_id" v-model="pricedPart.part_origin_id" class="form-control" required="required" :data-error="$t('sellerParts.Part Origin is required')">
                    <option value="0" disabled>{{ $t('sellerParts.Part Origin') }}</option>
                    <option v-for="partOrigin in partOrigins" :key="partOrigin.id" :value="partOrigin.id">{{ partOrigin[getFieldByLocale()] }}</option>
                  </select>
                  <div v-if="!error.part_origin_id" class="help-block with-errors"></div>
                  <div class="error-message-block">{{ error.part_origin_id }}</div>
                </div>
                <div class="form-group col-md-6">
                  <label for="number">{{ $t('sellerParts.Number') }}</label>
                  <input id="number" v-model="pricedPart.number" type="text" name="number" class="form-control" :placeholder="$t('sellerParts.Number')" required="required" :data-error="$t('sellerParts.Number is required')">
                  <div v-if="!error.number" class="help-block with-errors"></div>
                  <div class="error-message-block">{{ error.number }}</div>
                </div>
                <div class="form-group col-md-6">
                  <label for="price">{{ $t('sellerParts.Price') }} <small>({{ getCurrency() }})</small> <small>{{ $t('sellerParts.The price includes the added tax') }}</small></label>
                  <input id="price" v-model="pricedPart.price" data-parsley-type="number" type="text" name="price" class="form-control" :placeholder="$t('sellerParts.Price')" required="required" :data-error="$t('sellerParts.Price is required')">
                  <div v-if="!error.price" class="help-block with-errors"></div>
                  <div class="error-message-block">{{ error.price }}</div>
                </div>
                <div class="form-group col-md-6" v-if="pricedPart.condition && pricedPart.condition === usedPartCondition">
                  <label for="disassemble_cost">{{ $t('sellerParts.Disassemble Cost') }} <small>({{ getCurrency() }})</small></label>
                  <input id="disassemble_cost" v-model="pricedPart.disassemble_cost" data-parsley-type="number" type="text" name="disassemble_cost" class="form-control" :placeholder="$t('sellerParts.Disassemble Cost')" required="required" :data-error="$t('sellerParts.Disassemble Cost is required')">
                  <div v-if="!error.disassemble_cost" class="help-block with-errors"></div>
                  <div class="error-message-block">{{ error.disassemble_cost }}</div>
                </div>
                <div class="form-group col-md-6">
                  <label for="stock">{{ $t('sellerParts.Stock') }}</label>
                  <input id="stock" v-model="pricedPart.stock" type="number" name="stock" min=0 oninput="validity.valid||(value='');" class="form-control" :placeholder="$t('sellerParts.Stock')" required="required" :data-error="$t('sellerParts.Stock is required')">
                  <div v-if="!error.stock" class="help-block with-errors"></div>
                  <div class="error-message-block">{{ error.stock }}</div>
                </div>
                <div class="form-group col-md-6">
                  <label for="warranty_duration">{{ $t('sellerParts.Warranty Duration') }} <small>({{ $t('sellerParts.day(s)') }})</small></label>
                  <input id="warranty_duration" v-model="pricedPart.warranty_duration" type="number" name="warranty_duration" min=0 oninput="validity.valid||(value='');" class="form-control" :placeholder="$t('sellerParts.Warranty Duration')" required="required" :data-error="$t('sellerParts.Warranty Duration is required')">
                  <div v-if="!error.warranty_duration" class="help-block with-errors"></div>
                  <div class="error-message-block">{{ error.warranty_duration }}</div>
                </div>
                <div class="form-group col-md-6">
                  <label for="note">{{ $t('sellerParts.Note') }}</label>
                  <textarea id="note" v-model="pricedPart.note" name="note" class="form-control" :placeholder="$t('sellerParts.Note')" rows="3"></textarea>
                  <div v-if="!error.note" class="help-block with-errors"></div>
                  <div class="error-message-block">{{ error.note }}</div>
                </div>

                <div class="form-group col-md-12">
                  <label for="dropzone">{{ $t('sellerParts.Part Images') }}</label>
                  <ImageUpload :imageType="imageType" :errorMessage="error.images" :isEditImages="false" @isfileUplaodingChange="setIsfileUplaoding" @addedImagesChange="setAddedImages" />
                  <div class="row">
                    <div class="col-md-12">
                      <UploadedImageConditions />
                    </div>
                  </div>
                </div>

                <div class="col-md-12 text-center mt-4">
                  <button class="btn btn-primary" @click.prevent="addPricedPart()" :disabled="isfileUplaoding"><span>{{ $t('common.Add') }}</span>
                  </button>
                </div>
              </form>
        </div>
        </div>
      </div>
    </section>

    </div>

    <!--body content end-->
  </div>
</template>
<script>
import UploadedImageConditions from '../../components/UploadedImageConditions.vue'
import ImageUpload from '../../components/ImageUpload'
import AutocompletePartName from '../../components/AutocompletePartName'
import CarBrandModel from '../../components/CarBrandModel.vue'

export default {
  name: 'AddPartCategory',
  data () {
    return {
      message: null,
      isSpinnerShown: false,
      car_category_id: 0,
      brand_id: 0,
      part_category_id: 0,
      part_name: null,
      partCategories: null,
      brands: null,
      models: null,
      isBrandFieldDisabled: true,
      isModelFieldDisabled: true,
      isPartCategoryFieldDisabled: true,
      partOrigins: null,
      triggerPartNameOnFocus: true,
      chosenModelIds: [],
      clearStandardPartData: false,
      isSubmittingPrevented: false,

      imageType: null,
      isfileUplaoding: false,
      car_brand_model: [{
        models: null,
        brand_id: 0,
        isModelFieldDisabled: true
      }],
      pricedPart: {
        standard_part_id: 0,
        car_models: [{
          car_model_id: 0,
          from_year: 0,
          to_year: 0
        }],
        part_origin_id: 0,
        number: null,
        price: null,
        stock: null,
        note: null,
        condition: 0,
        warranty_duration: null,
        disassemble_cost: null,
        images: null
      },
      error: {
        car_category_id: null,
        brand_id: null,
        part_category_id: null,
        part_name: null,
        standard_part_id: null,
        car_models: [{
          brand_id: null,
          car_model_id: null,
          from_year: null,
          to_year: null
        }],
        part_origin_id: null,
        number: null,
        price: null,
        stock: null,
        note: null,
        condition: null,
        warranty_duration: null,
        disassemble_cost: null,
        images: null
      }
    }
  },
  components: {
    UploadedImageConditions,
    ImageUpload,
    AutocompletePartName,
    CarBrandModel
  },
  mounted () {
    this.imageType = 'seller_parts'

    this.getPartOrigins()
  },
  computed: {
    isReadyForSubmission () {
      var areModelFielsdFilled = true
      for (let i = 0; i < this.pricedPart.car_models.length; i++) {
        if (this.pricedPart.car_models[i].car_model_id && this.pricedPart.car_models[i].from_year && this.pricedPart.car_models[i].to_year) {
          continue
        } else {
          areModelFielsdFilled = false
          break
        }
      }

      return areModelFielsdFilled && this.pricedPart.standard_part_id && ((this.pricedPart.condition === this.newPartCondition && this.pricedPart.part_origin_id) || (this.pricedPart.condition === this.usedPartCondition && this.pricedPart.disassemble_cost !== null && this.pricedPart.disassemble_cost !== '')) && this.pricedPart.number && this.pricedPart.price !== null && this.pricedPart.price !== '' && this.pricedPart.stock !== null && this.pricedPart.stock !== '' && this.pricedPart.condition && this.pricedPart.warranty_duration !== null && this.pricedPart.warranty_duration !== ''
    },
    standardPartInfo () {
      return {
        part_category_id: this.part_category_id,
        car_category_id: this.car_category_id
      }
    },
    isPartNameFieldDisabled () {
      return this.part_category_id && this.car_category_id
    },
    car_brand_modelForWatcher () {
      return this.car_brand_model
    }
  },
  watch: {
    message (val) {
      if (val) {
        window.setTimeout(() => {
          this.message = null
        }, 10000)
      }
    },
    'pricedPart.car_models': {
      handler: function (val, oldVal) {
        this.chosenModelIds.splice(0, this.chosenModelIds.length)

        for (var i = 0; i < this.pricedPart.car_models.length; i++) {
          if (this.pricedPart.car_models[i].to_year < this.pricedPart.car_models[i].from_year) {
            this.pricedPart.car_models[i].to_year = 0
          }

          if (this.pricedPart.car_models[i].car_model_id) {
            this.chosenModelIds.push(this.pricedPart.car_models[i].car_model_id)
          }
        }
      },
      deep: true
    },
    car_category_id (val) {
      this.models = null
      this.car_brand_model.forEach(element => {
        element.brand_id = 0
      })
      this.pricedPart.car_models = this.pricedPart.car_models.map(elem => {
        elem.car_model_id = 0
        return elem
      })
      this.part_category_id = 0

      this.car_brand_model.forEach(element => {
        element.isModelFieldDisabled = true
      })

      if (parseInt(val) === 0) {
        this.isBrandFieldDisabled = true
        this.isPartCategoryFieldDisabled = true
      } else {
        this.getBrandsByCarCategory(val)
        this.getPartCategoriesByCarCategory(val)
      }
    },
    'pricedPart.condition' () {
      if (this.pricedPart.condition === this.newPartCondition) {
        this.pricedPart.disassemble_cost = null
      } else {
        this.pricedPart.part_origin_id = 0
      }
    }
  },
  methods: {
    getModelList (modelIndex) {
      this.pricedPart.car_models[modelIndex].car_model_id = 0

      if (parseInt(this.car_brand_model[modelIndex].brand_id) === 0) {
        this.car_brand_model[modelIndex].isModelFieldDisabled = true
      } else {
        this.getModelsByCategoryBrand(this.car_category_id, this.car_brand_model[modelIndex].brand_id, modelIndex)
      }
    },
    setIsfileUplaoding (value) {
      this.isfileUplaoding = value
    },
    setAddedImages (value) {
      this.pricedPart.images = value.join(',')
    },
    addCarModel () {
      this.car_brand_model.push({
        models: null,
        brand_id: 0,
        isModelFieldDisabled: true
      })

      this.pricedPart.car_models.push({
        car_model_id: 0,
        from_year: 0,
        to_year: 0
      })

      this.error.car_models.push({
        brand_id: null,
        car_model_id: null,
        from_year: null,
        to_year: null
      })
    },
    removeCarModel (index) {
      this.pricedPart.car_models.splice(index, 1)
      this.car_brand_model.splice(index, 1)
      this.error.car_models.splice(index, 1)
    },
    setStandardPartId (standardPartId) {
      this.pricedPart.standard_part_id = standardPartId
    },
    getPartOrigins () {
      this.isSpinnerShown = true
      this.$store.dispatch('getPartOrigins')
        .then(res => {
          this.isSpinnerShown = false
          this.partOrigins = res.data.data
        }).catch(error => {
          this.isSpinnerShown = false
          console.log(error)
        })
    },
    getPartCategoriesByCarCategory (carCategoryId) {
      this.$store.dispatch('getPartCategoriesByCarCategory', carCategoryId)
        .then(res => {
          this.partCategories = res.data
          this.isPartCategoryFieldDisabled = false
        }).catch(error => {
          console.log(error.response.data)
        })
    },
    getBrandsByCarCategory (carCategoryId) {
      this.$store.dispatch('getBrandsByCarCategory', carCategoryId)
        .then(res => {
          this.brands = res.data.data
          this.isBrandFieldDisabled = false
        }).catch(error => {
          console.log(error)
        })
    },
    getModelsByCategoryBrand (carCategoryId, brandId, carBrandModelIndex) {
      this.$store.dispatch('getModelsByCategoryBrand', { car_category_id: carCategoryId, brand_id: brandId })
        .then(res => {
          this.car_brand_model[carBrandModelIndex].models = res.data.data
          this.car_brand_model[carBrandModelIndex].isModelFieldDisabled = false
        }).catch(error => {
          console.log(error)
        })
    },
    checkDataReadyForSubmission () {
      this.isSubmittingPrevented = false
      document.querySelectorAll('#add-priced-part-form [required="required"]').forEach(elem => {
        elem.nextSibling.innerText = ''
        if ((parseInt(elem.value) === 0 && elem.getAttribute('name') !== 'disassemble_cost') || elem.value === null || elem.value === '') {
          this.isSubmittingPrevented = true
          elem.nextSibling.innerText = elem.getAttribute('data-error')
        }
      })
    },
    addPricedPart () {
      this.error = {
        car_category_id: null,
        brand_id: null,
        part_category_id: null,
        part_name: null,
        standard_part_id: null,
        car_models: [{
          brand_id: null,
          car_model_id: null,
          from_year: null,
          to_year: null
        }],
        part_origin_id: null,
        number: null,
        price: null,
        stock: null,
        note: null,
        condition: null,
        warranty_duration: null,
        disassemble_cost: null,
        images: null
      }

      for (var i = 0; i < this.pricedPart.car_models.length; i++) {
        this.error.car_models.push({
          brand_id: null,
          car_model_id: null,
          from_year: null,
          to_year: null
        })
      }

      this.checkDataReadyForSubmission()

      if (this.isSubmittingPrevented) {
        return
      }

      if (this.pricedPart.condition === this.newPartCondition) {
        this.pricedPart.disassemble_cost = 0
      } else {
        this.$delete(this.pricedPart, 'part_origin_id')
      }

      this.isSpinnerShown = true
      this.$store.dispatch('addPricedPart', this.pricedPart)
        .then(res => {
          this.isSpinnerShown = false
          this.message = this.$t('sellerParts.The part was added successfully, you can add another one')
          window.scrollTo(0, 0)
          this.clearData()
        }).catch(error => {
          this.isSpinnerShown = false
          if (error.response) {
            for (var field in error.response.data[0]) {
              if (field.includes('car_models')) {
                var splittedField = field.split('.')
                this.error[splittedField[0]][splittedField[1]][splittedField[2]] = this.joinArrayByComma(error.response.data[0][field])
              } else {
                this.error[field] = this.joinArrayByComma(error.response.data[0][field])
              }
            }
          }
        })
    },
    clearData () {
      this.clearStandardPartData = !this.clearStandardPartData

      this.isSpinnerShown = false
      this.car_category_id = 0
      this.brand_id = 0
      this.part_category_id = 0
      this.part_name = null
      this.partCategories = null
      this.brands = null
      this.models = null
      this.isBrandFieldDisabled = true
      this.isModelFieldDisabled = true
      this.isPartCategoryFieldDisabled = true
      this.partOrigins = null
      this.triggerPartNameOnFocus = true
      this.chosenModelIds = []

      this.imageType = null
      this.isfileUplaoding = false
      this.car_brand_model = [{
        models: null,
        brand_id: 0,
        isModelFieldDisabled: true
      }]
      this.pricedPart = {
        standard_part_id: 0,
        car_models: [{
          car_model_id: 0,
          from_year: 0,
          to_year: 0
        }],
        part_origin_id: 0,
        number: null,
        price: null,
        stock: null,
        note: null,
        condition: 0,
        warranty_duration: null,
        disassemble_cost: null,
        images: null
      }
    }
  }
}
</script>

<style scoped>
  .car-model-removal{
    margin: 0 15px;
  }

</style>
