import Vue from 'vue'
import Vuex from 'vuex'
import lang from './modules/lang'
import common from './modules/common'
import loginSignup from './modules/loginSignup'
import buyerCars from './modules/buyerCars'
import request from './modules/request'
import order from './modules/order'
import offer from './modules/offer'
import profile from './modules/profile'
import sellerParts from './modules/sellerParts'
import pricedParts from './modules/pricedParts'
import notifications from './modules/notifications'
import sales from './modules/sales'
import dashboard from './modules/dashboard'
import firebase from './modules/firebase'
import contactUs from './modules/contactUs'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    lang,
    common,
    loginSignup,
    buyerCars,
    request,
    order,
    offer,
    profile,
    sellerParts,
    pricedParts,
    notifications,
    sales,
    dashboard,
    firebase,
    contactUs
  }
})
