<template>
  <li class="nav-item lang-dropdown dropdown"><a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown"><div class="d-inline-block font-weight-bold text-uppercase"><img class="mb-1 selected-lang-flag" :src="getImageSrcByLocale($i18n.locale)"></div></a>
      <ul class="dropdown-menu mt-2">
      <li v-for="locale in $i18n.availableLocales" :key="locale" @click="switchLocale(locale)"><a class="dropdown-item" ><img :src="getImageSrcByLocale(locale)"></a>
      </li>
      </ul>
  </li>
</template>

<script>
export default {
  name: 'LanguageSwitcher',
  methods: {
    getImageSrcByLocale (locale) {
      switch (locale) {
        case 'ar':
          return require('@/assets/images/header/saudi-flag.png')
        case 'en':
          return require('@/assets/images/header/usa-flag.png')
      }
    },
    switchLocale (locale) {
      locale = locale.charAt(0).toLowerCase() + locale.slice(1)
      if (this.$i18n.locale !== locale) {
        const params = Object.assign({}, this.$route.params)
        delete params.lang
        Object.assign(params, { lang: locale })

        const r = this.$router.resolve({
          name: this.$route.name,
          params: params,
          query: this.$route.query
        })
        window.location.assign(r.href)
      }
    }
  }
}
</script>
