<template>

    <div class="form-group col-md-6" :class="sizeClass">
      <label for="part_name">{{ $t('requests.Part Name') }} <small v-if="isStandardPartIdRequested">({{ $t('sellerParts.Please select it from the list') }})</small></label>
      <div class="autocomplete">
        <input id="part_name" autocomplete="off"
        @input="onChange"
        @keydown.down="onArrowDown()"
        @keydown.up="onArrowUp()"
        @keydown.enter.prevent="onEnter()"
        v-model="part_name"

        type="text" name="part_name"
        class="form-control" :placeholder="$t('requests.Part Name')"
        required="required" :data-error="$t('requests.Part Name is required')"
        :disabled="isPartNameFieldDisabled">
        <div style="right: 0;" class="error-message-block">{{ errorMessage }}</div>
        <ul v-show="isOpen" class="autocomplete-results">
          <li class="autocomplete-result" v-if="isLoading">{{ $t('requests.Loading results') }}...</li>
          <li  v-else v-for="(result, i) in results" :key="i" :class="['autocomplete-result', i === arrowCounter ? 'is-active': '' ]"
               @click="setResult(result[getFieldByLocale()], result)">
           <b>{{ result.name }}</b> <span v-if="showPartCategory"> <br> {{result.part_category.name}} </span>
          </li>
        </ul>
      </div>
    </div>
</template>

<script>
export default {
  name: 'AutocompletePartName',
  props: [
    'isPartNameFieldDisabled',
    'errorMessage',
    'standardPartInfo',
    'isStandardPartIdRequested',
    'triggerPartNameOnFocus',
    'clearData',
    'showPartCategory',
    // custom size for priced seller part search
    'sizeClass'
  ],
  data () {
    return {
      results: null,
      isOpen: false,
      isLoading: false,
      arrowCounter: -1,
      part_name: null,
      isStandardPartChosen: false,
      standard_part_id: null,
      standardPart: null
    }
  },
  mounted () {
    document.addEventListener('click', this.handleClickOutside)
    if (this.triggerPartNameOnFocus) {
      document.getElementById('part_name').addEventListener('focus', () => {
        this.onChange()
      })
    }
  },
  destroyed () {
    document.removeEventListener('click', this.handleClickOutside)
  },
  watch: {
    standard_part_id () {
      this.$emit('standardPartIdChanged', this.standard_part_id)
    },
    standardPart () {
      this.$emit('input', this.standardPart)
    },
    part_name () {
      this.$emit('partNameChanged', this.part_name)
    },
    clearData () {
      this.results = null
      this.isOpen = false
      this.isLoading = false
      this.arrowCounter = -1
      this.part_name = null
      this.isStandardPartChosen = false
      this.standard_part_id = null
      this.standardPart = null
      this.$emit('input', this.standardPart)
    }
  },
  methods: {
    handleClickOutside (evt) {
      var autocompleteElement = document.getElementsByClassName('autocomplete')
      if (autocompleteElement && !autocompleteElement[0].contains(evt.target)) {
        this.isOpen = false
        this.arrowCounter = -1
        if (!this.isStandardPartChosen && this.isStandardPartIdRequested) {
          this.part_name = null
        }
      }
    },
    onArrowDown () {
      if (this.results && this.arrowCounter < this.results.length - 1) {
        this.arrowCounter = this.arrowCounter + 1
      } else {
        this.arrowCounter = 0
      }
    },
    onArrowUp () {
      if (this.results && this.arrowCounter > 0) {
        this.arrowCounter = this.arrowCounter - 1
      } else if (this.results) {
        this.arrowCounter = this.results.length - 1
      } else {
        this.arrowCounter = 0
      }
    },
    onEnter () {
      this.part_name = this.results[this.arrowCounter][this.getFieldByLocale()]
      this.standard_part_id = this.results[this.arrowCounter].id
      this.standardPart = this.results[this.arrowCounter]

      this.isStandardPartChosen = true
      this.isOpen = false
      this.arrowCounter = -1
    },
    onChange () {
      this.$emit('enablePartCategoryField', this.standardPart)

      this.isStandardPartChosen = false
      this.standard_part_id = null

      this.results = null
      if (this.part_name === '' && !this.triggerPartNameOnFocus) {
        this.isOpen = false
        this.isLoading = false
        return
      }
      this.isOpen = true
      this.isLoading = true

      this.$store.dispatch('searchStandardParts', {
        part_category_id: this.standardPartInfo.part_category_id,
        car_category_id: this.standardPartInfo.car_category_id,
        searchable_names: this.part_name
      })
        .then(res => {
          this.isLoading = false
          this.results = res.data.data
        }).catch(error => {
          this.isLoading = false
          console.log(error)
        })
    },
    setResult (partName, standardPart) {
      this.part_name = partName
      this.standard_part_id = standardPart.id
      this.standardPart = standardPart
      this.isStandardPartChosen = true
      this.isOpen = false
    }
  }
}
</script>

<style scoped>
  .autocomplete {
    position: relative;
    width: 100%;
  }

  .autocomplete-results {
    padding: 0;
    margin: 0;
    position: absolute;
    z-index: 3;
    background-color: #fff;
    width: 100%;
    border: 1px solid #ced4da;
    height: auto;
    overflow: auto;
    box-shadow: 1px 3px 5px 1px #0000002e
  }

  .autocomplete-result {
    list-style: none;
    text-align: left;
    padding: 4px 2px;
    cursor: pointer;

    display: block;
    width: 100%;
    /* height: calc(1.5em + .75rem + 2px); */
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    font-size: 0.8rem;
    font-weight: normal;
    display: block;
    min-height: 1.2em;

  }
  .rtl .autocomplete-result{
    text-align: right;
  }

  .autocomplete-result:hover {
    background-color: #4AAE9B;
    color: white;
  }
  .autocomplete-result.is-active,
  .autocomplete-result:hover {
    background-color: #4AAE9B;
    color: white;
  }
</style>
