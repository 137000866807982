<template>
  <div>
    <!--hero section start-->

    <HeroSection>
            <h1>{{ $t('cars.Edit Car') }}</h1>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb justify-content-center bg-transparent p-0 m-0">
                <li class="breadcrumb-item"><router-link class="text-dark" :to="{ name: 'Dashboard', params: { lang: this.$i18n.locale }}">{{ $t('menu.Home') }}</router-link></li>
                <li class="breadcrumb-item"><router-link class="text-dark" :to="{ name: 'BuyerCars', params: { lang: this.$i18n.locale }}">{{ $t('cars.My Cars') }}</router-link></li>
                <li class="breadcrumb-item active text-primary" aria-current="page">{{ $t('cars.Edit Car') }}</li>
              </ol>
            </nav>
    </HeroSection>

    <!--hero section end-->

    <!--body content start-->

    <div class="page-content">

    <section>
      <div class="container" style="position: relative;">
        <Spinner v-if="isSpinnerShown"/>
        <div class="row justify-content-center mb-5 text-center">
          <div v-if="car.model_year" class="col-12 col-lg-10 p-3 border-in-amber mb-6 d-flex justify-content-center align-items-center">
            <span>{{ carCategories[car.car_category_id][getFieldByLocale()] +' - '+ car.brand[getFieldByLocale()] +' - '+ car.model[getFieldByLocale()] + ' ('+ car.model_year +')' }}</span>
          </div>
          <div class="col-12 col-lg-8">
            <div> <span class="badge badge-primary-soft p-2">
                      <img class="car-icon" src="@/assets/images/other/dashboard2.png">
                  </span>
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-12 col-lg-10">
              <form class="row" method="post">
                <div class="messages"></div>
                <div class="form-group col-md-6">
                  <label for="chasse-no">{{ $t('cars.Chasse No') }}</label>
                  <input id="chasse-no" v-model="car.vin" type="text"  name="chasse-no"  minlength="17" maxlength="17" class="form-control" :placeholder="$t('cars.Chasse No')" required="required" :data-error="$t('cars.Chasse No is required')">
                  <div v-if="showVinDataError" class="help-block with-errors"></div>
                  <div class="error-message-block"><span v-if="isShownVinCharCountError">{{ $t('cars.Chasse No should have 17 characters') }}</span>{{ error.vin }}</div>
                </div>
                <div class="form-group col-md-6">
                  <label for="details">{{ $t('cars.Car Specifications') }}</label>
                  <textarea id="details" v-model="car.details" name="details" class="form-control" :placeholder="$t('cars.Ex: 6 cylinders')" rows="3"></textarea>
                  <div v-if="!error.details" class="help-block with-errors"></div>
                  <div class="error-message-block">{{ error.details }}</div>
                </div>
                <div class="form-group col-md-12">
                  <label for="dropzone">{{ $t('cars.Car Images') }}</label>
                  <ImageUpload :imageType="imageType" :errorMessage="error.images" :isEditImages="true" :stringOfImages="car.images" :maxFilesUpload="maxFilesUpload" @isfileUplaodingChange="setIsfileUplaoding" @addedImagesChange="setAddedImages" @deletedImagesChange="setDeletedImages" />

                  <div class="row">
                    <div class="col-md-12">
                      <UploadedImageConditions />
                    </div>
                  </div>
                </div>
                <div class="col-md-12 text-center mt-4">
                  <button class="btn btn-primary" @click.prevent="editCar()" :disabled="!isReadyForSubmission || isfileUplaoding"><span>{{ $t('common.Update') }}</span>
                  </button>
                </div>
              </form>
        </div>
        </div>

      </div>
    </section>

    </div>

    <!--body content end-->
  </div>
</template>

<script>
import UploadedImageConditions from '../../components/UploadedImageConditions.vue'
import ImageUpload from '../../components/ImageUpload'

export default {
  components: {
    ImageUpload,
    UploadedImageConditions
  },
  data () {
    return {
      brands: null,
      models: null,
      modelYears: null,
      isSpinnerShown: false,
      isShownVinCharCountError: false,

      isfileUplaoding: false,
      imageType: null,
      maxFilesUpload: null,

      error: {
        vin: null,
        details: null,
        images: null
      },
      car: {
        id: null,
        vin: null,
        details: null,
        images: null,
        car_category_id: null,
        brand: null,
        model: null,
        model_year: null,
        status: null,
        added_images: '',
        deleted_images: ''
      }
    }
  },
  mounted () {
    this.imageType = 'client_cars'

    this.isSpinnerShown = true
    this.$store.dispatch('getCarById', this.$route.params.id)
      .then(res => {
        this.isSpinnerShown = false
        this.car.id = res.data.data.id
        this.car.vin = res.data.data.vin
        this.car.details = res.data.data.details
        this.car.images = res.data.data.images
        this.car.car_category_id = res.data.data.car_category_id
        this.car.brand = res.data.data.brand
        this.car.model = res.data.data.model
        this.car.model_year = res.data.data.model_year

        if (!res.data.data.images) {
          this.maxFilesUpload = 4
        } else if (res.data.data.images.split(',').length < 4) {
          this.maxFilesUpload = 4 - res.data.data.images.split(',').length
        } else {
          this.maxFilesUpload = 0
        }
      }).catch(error => {
        this.isSpinnerShown = false
        console.log(error)
      })
  },
  computed: {
    isReadyForSubmission () {
      return this.car.vin
    },
    showVinDataError () {
      return !(this.error.vin || this.isShownVinCharCountError)
    }
  },
  methods: {
    setIsfileUplaoding (value) {
      this.isfileUplaoding = value
    },
    setAddedImages (value) {
      this.car.added_images = value.join(',')
    },
    setDeletedImages (value) {
      this.car.deleted_images = value.join(',')
    },
    editCar () {
      this.error = {
        vin: null,
        details: null,
        images: null
      }
      this.isShownVinCharCountError = false

      if (this.car.vin.length < 17) {
        this.isShownVinCharCountError = true
        return
      }

      this.isSpinnerShown = true
      this.$store.dispatch('editCar', this.car)
        .then(res => {
          this.isSpinnerShown = false
          this.$router.push({ name: 'BuyerCars', params: { lang: this.$i18n.locale } })
        }).catch(error => {
          this.isSpinnerShown = false
          if (error.response) {
            for (var field in error.response.data[0]) {
              console.log('error.response.data', error.response.data[0], field)

              this.error[field] = this.joinArrayByComma(error.response.data[0][field])
            }
          }
        })
    }
  }
}
</script>
