import axios from '@/plugins/axios'

const state = {
  client_full_name: localStorage.getItem('client_full_name') || '',
  api_token: localStorage.getItem('api_token') || '',
  client_type: localStorage.getItem('client_type') || '',
  is_valid_token: localStorage.getItem('is_valid_token') || ''
}

const getters = {
  showCodeNotSentMessage: (state) => {
    return state.showCodeNotSentMessage
  },
  isAuthenticated: state => !!state.api_token,
  isAuthenticatedBuyer: (state) => {
    return !!state.api_token && parseInt(state.client_type) === 2
  },
  isAuthenticatedSeller: (state) => {
    return !!state.api_token && parseInt(state.client_type) === 1
  },
  getClientFullName: (state) => {
    return state.client_full_name
  },
  getIsValidToken: (state) => {
    return state.is_valid_token
  }
}

const mutations = {
  storeUserInfo: (state, clientInfo) => {
    state.api_token = clientInfo.api_token
    state.client_type = clientInfo.client.type
    state.client_full_name = clientInfo.client.full_name
    state.is_valid_token = true
    localStorage.setItem('api_token', clientInfo.api_token)
    localStorage.setItem('client_type', clientInfo.client.type)
    localStorage.setItem('client_full_name', clientInfo.client.full_name)
    localStorage.setItem('is_valid_token', true)
  },
  afterLogout: (state) => {
    state.api_token = ''
    state.client_type = ''
    state.client_full_name = ''
    state.is_valid_token = ''
    localStorage.removeItem('api_token')
    localStorage.removeItem('client_type')
    localStorage.removeItem('client_full_name')
    localStorage.removeItem('is_valid_token')
  },
  setIsValidToken: (state, isValidToken) => {
    state.is_valid_token = isValidToken
    localStorage.setItem('is_valid_token', isValidToken)
  }
}

const actions = {
  requestLoginCode: ({ commit, state }, phone) => {
    return axios.post('/api/clients/request-login-code', phone)
      .then((res) => {
        return res
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  },
  verifyCode: ({ commit, state }, codeVerification) => {
    return axios.post('/api/clients/login-by-code', codeVerification)
      .then(res => {
        commit('storeUserInfo', res.data.data)
        return res
      }).catch(error => {
        return Promise.reject(error)
      })
  },
  loginByPassword: ({ commit, state }, userPassword) => {
    return axios.post('/api/clients/login', userPassword)
      .then(res => {
        commit('storeUserInfo', res.data.data)
        return res
      }).catch(error => {
        return Promise.reject(error)
      })
  },
  signupBuyer: ({ commit, state }, buyer) => {
    return axios.post('/api/clients/buyer', buyer)
      .then(res => {
        return res
      }).catch(error => {
        return Promise.reject(error)
      })
  },
  createRequestRegister: ({ commit, state, rootState }, data) => {
    return axios.post('/api/full-register', data)
      .then(res => {
        res.data.data.api_token = res.data.data.token
        commit('storeUserInfo', res.data.data)
        return res
      }).catch(error => {
        return Promise.reject(error)
      })
  },
  signupSeller: ({ commit, state }, seller) => {
    return axios.post('/api/clients/seller', seller)
      .then(res => {
        return res
      }).catch(error => {
        return Promise.reject(error)
      })
  },
  logout: ({ commit, state, rootState }) => {
    const fbToken = localStorage.getItem('fb_token')
    axios.post('/api/logout', { fb_token: fbToken }, {
      headers: {
        Authorization: 'Bearer ' + rootState.loginSignup.api_token
      }
    }).then(r => {
    })
    commit('afterLogout')
    commit('clearOrderState', null, { root: true })
    commit('clearDashboardState', null, { root: true })
    commit('clearLangState', null, { root: true })
    commit('clearNotificationState', null, { root: true })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
